import ActionTypes from './types'
import { GlobalConfig } from 'galarm-config'
import {
  AlarmUtils,
  FirebaseManager,
  TaskManager,
  AlarmUtilsWithExtras,
  WebUtils
} from 'galarm-shared'
import DateTimeUtils from '../utils/DateTimeUtils'
import {
  NotificationManager,
  StorageManager,
  AlarmManager,
  LogUtils,
  NavigationUtils
} from 'galarm-ps-api'
import { Constants } from 'galarm-config'
import arrayDifferenceWith from 'lodash/differenceWith'
import arrayIntersectionWith from 'lodash/intersectionWith'
import arrayUniq from 'lodash/uniq'
import Utils from '../utils/Utils'
import { I18n } from 'galarm-config'
import { Platform } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import objGet from 'lodash/get'
import objCompact from 'lodash/compact'
import moment from 'moment-timezone'
import uuid from 'react-native-uuid'
import {
  makeParticipantAlarmSelector,
  participantAlarmsSelector,
  makeOwnAlarmSelector,
  ownAlarmsSelector,
  allAlarmsSelector,
  makeSubscribedAlarmSystemAlertsSelector,
  upgradePurchasedSelector,
  ringerSettingsSelector,
  makeAlarmAcknowledgementsSelector,
  makeAlarmStatusChangesSelector,
  alarmCategoriesSelector,
  makeAlarmCategoryForAlarmIdSelector,
  makeAlarmSelector,
  canCreateNewAlarmDataSelector,
  makeRecentlyDeletedAlarmSelector
} from '../store/selectors'
import isEmpty from 'lodash/isEmpty'
import objOmit from 'lodash/omit'
import isObjEqual from 'fast-deep-equal'
import { FirebaseProxy } from 'galarm-ps-api'
import * as RNLocalize from 'react-native-localize'

const setConnectionStatus = isConnected => {
  return {
    type: ActionTypes.SET_CONNECTION_STATUS,
    payload: {
      isConnected
    }
  }
}

const addSystemAlerts = systemAlerts => ({
  type: ActionTypes.ADD_SYSTEM_ALERTS,
  payload: {
    systemAlerts
  }
})

const addSystemAlert = alert => ({
  type: ActionTypes.ADD_SYSTEM_ALERT,
  payload: {
    alert
  }
})

const removeSystemAlert = id => ({
  type: ActionTypes.REMOVE_SYSTEM_ALERT,
  payload: {
    id
  }
})

const removeSystemMessage = id => ({
  type: ActionTypes.REMOVE_SYSTEM_MESSAGE,
  payload: {
    id
  }
})

const addHiddenSystemMessage = id => ({
  type: ActionTypes.ADD_HIDDEN_SYSTEM_MESSAGE,
  payload: {
    id
  }
})

const updateAlarm = (alarmId, alarm) => ({
  type: ActionTypes.UPDATE_ALARM,
  payload: {
    alarmId,
    alarm
  }
})

// eslint-disable-next-line no-unused-vars
const incrementOwnAlarmsLocalCount = (alarmType, countType) => ({
  type: ActionTypes.INCREMENT_OWN_ALARMS_COUNT,
  payload: {
    alarmType,
    countType
  }
})

const setAlarmsCount = alarmsCount => ({
  type: ActionTypes.SET_ALARMS_COUNT,
  payload: { alarmsCount }
})

const setDeviceOs = deviceOs => ({
  type: ActionTypes.SET_DEVICE_OS,
  payload: { deviceOs }
})

const setConnectedToIap = status => ({
  type: ActionTypes.SET_CONNECTED_TO_IAP,
  payload: {
    status
  }
})

const setMuteAlarms = muteAlarms => ({
  type: ActionTypes.SET_MUTE_ALARMS,
  payload: {
    muteAlarms
  }
})

const setPurchases = purchases => ({
  type: ActionTypes.SET_PURCHASES,
  payload: {
    purchases
  }
})

const addPurchase = purchase => ({
  type: ActionTypes.ADD_PURCHASE,
  payload: {
    purchase
  }
})

const setMaxAllowedAlarms = maxAllowedAlarms => ({
  type: ActionTypes.SET_MAX_ALLOWED_ALARMS,
  payload: {
    maxAllowedAlarms
  }
})

// eslint-disable-next-line no-unused-vars
const updateAlarms = alarms => ({
  type: ActionTypes.UPDATE_ALARMS,
  payload: {
    alarms
  }
})

const addLocalAlarm = alarm => ({
  type: ActionTypes.ADD_LOCAL_ALARM,
  payload: {
    alarm
  }
})

const addLocalChecklist = (checklist, persistedOnline) => ({
  type: ActionTypes.ADD_LOCAL_CHECKLIST,
  payload: {
    checklist,
    persistedOnline
  }
})

const updateLocalChecklist = checklist => ({
  type: ActionTypes.UPDATE_LOCAL_CHECKLIST,
  payload: {
    checklist
  }
})

const removeLocalChecklist = checklistId => ({
  type: ActionTypes.REMOVE_LOCAL_CHECKLIST,
  payload: {
    checklistId
  }
})

const updateLocalAlarm = alarm => ({
  type: ActionTypes.UPDATE_LOCAL_ALARM,
  payload: {
    alarm
  }
})

const softUpdateLocalAlarm = alarm => ({
  type: ActionTypes.SOFT_UPDATE_LOCAL_ALARM,
  payload: {
    alarm
  }
})

const softUpdateLocalParticipantAlarm = alarm => ({
  type: ActionTypes.SOFT_UPDATE_LOCAL_PARTICIPANT_ALARM,
  payload: {
    alarm
  }
})

const updateGroup = (groupId, groupData, isRemovedGroup = false) => ({
  type: ActionTypes.UPDATE_GROUP,
  payload: {
    groupId,
    groupData,
    isRemovedGroup
  }
})

const updateGroupAvatarImages = (groupId, images) => ({
  type: ActionTypes.UPDATE_GROUP_AVATAR_IMAGES,
  payload: {
    groupId: groupId,
    images: images
  }
})

const deleteGroupAvatarImages = groupId => ({
  type: ActionTypes.DELETE_GROUP_AVATAR_IMAGES,
  payload: {
    groupId: groupId
  }
})

const removeGroup = groupId => ({
  type: ActionTypes.REMOVE_GROUP,
  payload: {
    groupId
  }
})

const addConnection = (id, connectionInfo) => ({
  type: ActionTypes.ADD_CONNECTION,
  payload: {
    id,
    ...connectionInfo
  }
})

const addConnections = connections => ({
  type: ActionTypes.ADD_CONNECTIONS,
  payload: {
    connections
  }
})

const addContact = contact => ({
  type: ActionTypes.ADD_CONTACT,
  payload: {
    contact
  }
})

const addInvitedContact = contact => ({
  type: ActionTypes.ADD_INVITED_CONTACT,
  payload: {
    contact
  }
})

const deleteInvitedContact = contactId => ({
  type: ActionTypes.DELETE_INVITED_CONTACT,
  payload: {
    contactId
  }
})

const addContacts = contacts => ({
  type: ActionTypes.ADD_CONTACTS,
  payload: {
    contacts
  }
})

const addPhoneContacts = phoneContacts => ({
  type: ActionTypes.ADD_PHONE_CONTACTS,
  payload: {
    phoneContacts
  }
})

const deleteContact = contactId => ({
  type: ActionTypes.DELETE_CONTACT,
  payload: {
    contactId
  }
})

const addBackupAlarm = (alarmId, alarm) => ({
  type: ActionTypes.ADD_BACKUP_ALARM,
  payload: {
    alarmId,
    alarm
  }
})

const removeBackupAlarm = alarmId => ({
  type: ActionTypes.REMOVE_BACKUP_ALARM,
  payload: {
    alarmId
  }
})

const setRecentlyDeletedAlarms = alarms => ({
  type: ActionTypes.SET_RECENTLY_DELETED_ALARMS,
  payload: {
    alarms
  }
})

const removeParticipantAlarms = alarmIds => ({
  type: ActionTypes.REMOVE_PARTICIPANT_ALARMS,
  payload: {
    alarmIds
  }
})

const removeLocalAlarm = alarmId => ({
  type: ActionTypes.REMOVE_LOCAL_ALARM,
  payload: {
    alarmId
  }
})

const removeLocalAlarms = alarmIds => ({
  type: ActionTypes.REMOVE_LOCAL_ALARMS,
  payload: {
    alarmIds
  }
})

const removePendingAction = actionId => ({
  type: ActionTypes.REMOVE_PENDING_ACTION,
  payload: {
    id: actionId
  }
})

const removeSoftPendingAction = actionId => ({
  type: ActionTypes.REMOVE_SOFT_PENDING_ACTION,
  payload: {
    id: actionId
  }
})

const addAddChecklistPendingAction = checklist => ({
  type: ActionTypes.ADD_ADD_CHECKLIST_PENDING_ACTION,
  payload: {
    checklist
  }
})

const addEditChecklistPendingAction = checklist => ({
  type: ActionTypes.ADD_EDIT_CHECKLIST_PENDING_ACTION,
  payload: {
    checklist
  }
})

const addRemoveChecklistPendingAction = checklistId => ({
  type: ActionTypes.ADD_REMOVE_CHECKLIST_PENDING_ACTION,
  payload: {
    checklistId
  }
})

const addAddAlarmPendingAction = alarm => ({
  type: ActionTypes.ADD_ADD_ALARM_PENDING_ACTION,
  payload: {
    alarm
  }
})

const addEditAlarmPendingAction = (
  editedAlarm,
  prevAlarm,
  removeSubscription
) => ({
  type: ActionTypes.ADD_EDIT_ALARM_PENDING_ACTION,
  payload: {
    editedAlarm,
    prevAlarm,
    removeSubscription
  }
})

const addEnableDisableAlarmPendingAction = (alarm, timestamp, alarmStatus) => ({
  type: ActionTypes.ADD_ENABLE_DISABLE_ALARM_PENDING_ACTION,
  payload: {
    alarm,
    alarmStatus,
    timestamp
  }
})

const addDeleteAlarmPendingAction = alarm => ({
  type: ActionTypes.ADD_DELETE_ALARM_PENDING_ACTION,
  payload: {
    alarm
  }
})

const addUpdateParticipantAlarmRingerSettingsSoftPendingAction = (
  alarm,
  participantId,
  ringerSettings
) => ({
  type: ActionTypes.ADD_UPDATE_PARTICIPANT_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    ringerSettings
  }
})

const addUpdateParticipantPreReminderDurationSoftPendingAction = (
  alarm,
  participantId,
  preReminderDuration
) => ({
  type: ActionTypes.ADD_UPDATE_PARTICIPANT_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    preReminderDuration
  }
})

const addUpdateAlarmPreReminderSoftPendingAction = (
  alarm,
  preReminderDuration
) => ({
  type: ActionTypes.ADD_UPDATE_ALARM_PRE_REMINDER_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    preReminderDuration
  }
})

const addUpdateAlarmRingerSettingsSoftPendingAction = (
  alarm,
  ringerSettings
) => ({
  type: ActionTypes.ADD_UPDATE_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    ringerSettings
  }
})

const setUserInfo = userInfo => ({
  type: ActionTypes.SET_USER_INFO,
  payload: {
    ...userInfo
  }
})

const setUserName = userName => ({
  type: ActionTypes.SET_USER_NAME,
  payload: {
    name: userName
  }
})

const setUserMobileNumber = (mobileNumber, countryCode) => ({
  type: ActionTypes.SET_USER_MOBILE_NUMBER,
  payload: {
    mobileNumber: mobileNumber,
    countryCode: countryCode
  }
})

const setUserAvatarImages = images => ({
  type: ActionTypes.SET_USER_AVATAR_IMAGES,
  payload: {
    images
  }
})

const deleteUserAvatarImages = () => ({
  type: ActionTypes.DELETE_USER_AVATAR_IMAGES
})

const setContactAvatarImages = (contactId, images) => ({
  type: ActionTypes.SET_CONTACT_AVATAR_IMAGES,
  payload: {
    contactId,
    images
  }
})

const deleteContactAvatarImages = contactId => ({
  type: ActionTypes.DELETE_CONTACT_AVATAR_IMAGES,
  payload: {
    contactId
  }
})

const setConnectionAvatarImages = (id, images) => ({
  type: ActionTypes.SET_CONNECTION_AVATAR_IMAGES,
  payload: {
    id,
    images
  }
})

const deleteConnectionAvatarImages = id => ({
  type: ActionTypes.DELETE_CONNECTION_AVATAR_IMAGES,
  payload: {
    id
  }
})

const setTotalNumberOfContacts = totalNumberOfContacts => ({
  type: ActionTypes.SET_TOTAL_NUMBER_OF_CONTACTS,
  payload: {
    totalNumberOfContacts
  }
})

const setContactsLastUpdatedAt = contactsLastUpdatedAt => ({
  type: ActionTypes.SET_CONTACTS_LAST_UPDATED_AT,
  payload: {
    contactsLastUpdatedAt
  }
})

const setAuthenticatedWithFirebase = authenticatedWithFirebase => ({
  type: ActionTypes.SET_AUTHENTICATED_WITH_FIREBASE,
  payload: {
    authenticatedWithFirebase
  }
})

const setProgress = (state, message, closeable = false) => ({
  type: ActionTypes.SET_PROGRESS,
  payload: {
    state,
    message,
    closeable
  }
})

const resetProgress = () => ({
  type: ActionTypes.RESET_PROGRESS
})

const setUserSettings = userSettings => ({
  type: ActionTypes.SET_USER_SETTINGS,
  payload: {
    userSettings
  }
})

const setRingParticipantAlarmsByDefault = ringParticipantAlarmsByDefault => ({
  type: ActionTypes.SET_RING_PARTICIPANT_ALARMS_BY_DEFAULT,
  payload: {
    ringParticipantAlarmsByDefault
  }
})

const setRingOnEarphoneOnly = ringOnEarphoneOnly => ({
  type: ActionTypes.SET_RING_ON_EARPHONE_ONLY,
  payload: {
    ringOnEarphoneOnly
  }
})

const setSpecifyTimezoneForAlarm = specifyTimezoneForAlarm => ({
  type: ActionTypes.SET_SPECIFY_TIMEZONE_FOR_ALARM,
  payload: {
    specifyTimezoneForAlarm
  }
})

const setGestureToDismissAnAlarm = gestureToDismissAnAlarm => ({
  type: ActionTypes.SET_GESTURE_TO_DISMISS_ALARM,
  payload: {
    gestureToDismissAnAlarm: gestureToDismissAnAlarm
  }
})

const setNotificationSettingsUserSettings = notificationSettings => ({
  type: ActionTypes.SET_NOTIFICATION_SETTINGS,
  payload: {
    notificationSettings: notificationSettings
  }
})

const setGraduallyIncreaseVolume = graduallyIncreaseVolume => ({
  type: ActionTypes.SET_GRADUALLY_INCREASE_VOLUME,
  payload: {
    graduallyIncreaseVolume: graduallyIncreaseVolume
  }
})

const setGlobalAlarmRingerSettings = ringerSettings => ({
  type: ActionTypes.SET_GLOBAL_ALARM_RINGER_SETTINGS,
  payload: {
    ringerSettings
  }
})

const setTimeFormat = timeFormat => ({
  type: ActionTypes.SET_TIME_FORMAT,
  payload: {
    timeFormat
  }
})

const setNotificationsEnabled = state => ({
  type: ActionTypes.SET_NOTIFICATIONS_ENABLED,
  payload: {
    notificationsEnabled: state
  }
})

const setCriticalAlertsEnabled = state => ({
  type: ActionTypes.SET_CRITICAL_ALERTS_ENABLED,
  payload: {
    criticalAlertsEnabled: state
  }
})

const setAlarmNotificationChannelPopupEnabled = state => ({
  type: ActionTypes.SET_ALARM_NOTIFICATION_CHANNEL_ENABLED,
  payload: {
    alarmNotificationChannelPopupEnabled: state
  }
})

const setAlarmLockScreenNotificationEnabled = state => ({
  type: ActionTypes.SET_LOCK_SCREEN_NOTIFICATION_ENABLED,
  payload: {
    alarmLockScreenNotificationEnabled: state
  }
})

const setAppUpdateAvailable = appUpdateAvailable => ({
  type: ActionTypes.SET_APP_UPDATE_AVAILABLE,
  payload: {
    appUpdateAvailable
  }
})

const setBackgroundAppRefreshEnabled = state => ({
  type: ActionTypes.SET_BACKGROUND_APP_REFRESH_ENABLED,
  payload: {
    backgroundAppRefreshEnabled: state
  }
})

const markAlarmsAsExpired = expiredAlarmIds => ({
  type: ActionTypes.EXPIRE_ALARMS,
  payload: {
    alarmIds: expiredAlarmIds
  }
})

const updateAlarmsLocally = alarmIds => ({
  type: ActionTypes.UPDATE_ALARMS_LOCALLY,
  payload: {
    alarmIds
  }
})

const unsnoozeAlarmsLocally = alarmIds => ({
  type: ActionTypes.UNSNOOZE_ALARMS_LOCALLY,
  payload: {
    alarmIds
  }
})

const unsnoozeParticipantAlarmsLocally = (alarmIds, uid) => ({
  type: ActionTypes.UNSNOOZE_PARTICIPANT_ALARMS_LOCALLY,
  payload: {
    alarmIds,
    uid
  }
})

const updateParticipantsAlarmsLocally = alarmIds => ({
  type: ActionTypes.UPDATE_PARTICIPANT_ALARMS_LOCALLY,
  payload: {
    alarmIds
  }
})

const markParticipantAlarmsAsExpired = participantExpiredAlarmIds => ({
  type: ActionTypes.EXPIRE_PARTICIPANT_ALARMS,
  payload: {
    alarmIds: participantExpiredAlarmIds
  }
})

const setCurrentScreen = screenName => ({
  type: ActionTypes.SET_CURRENT_SCREEN,
  payload: {
    currentScreen: screenName
  }
})

const setShowRecentlyDeletedAlarms = showRecentlyDeletedAlarms => ({
  type: ActionTypes.SET_SHOW_RECENTLY_DELETED_ALARMS,
  payload: {
    showRecentlyDeletedAlarms
  }
})

const setDeviceToken = deviceToken => ({
  type: ActionTypes.SET_DEVICE_TOKEN,
  payload: {
    deviceToken
  }
})

const addEnterpriseSubscriptions = enterpriseSubscriptions => ({
  type: ActionTypes.ADD_ENTERPRISE_SUBSCRIPTIONS,
  payload: {
    enterpriseSubscriptions
  }
})

const addBlockedContacts = blockedContacts => ({
  type: ActionTypes.ADD_BLOCKED_CONTACTS,
  payload: {
    blockedContacts
  }
})

const setDeviceManufacturer = deviceManufacturer => ({
  type: ActionTypes.SET_DEVICE_MANUFACTURER,
  payload: {
    deviceManufacturer
  }
})

const addNewMessageToAlarmConversation = (alarmId, message) => ({
  type: ActionTypes.ADD_NEW_MESSAGE_TO_ALARM_CONVERSATION,
  payload: {
    alarmId,
    message
  }
})

const addMessagesToAlarmConversation = (alarmId, messages) => ({
  type: ActionTypes.ADD_MESSAGES_TO_ALARM_CONVERSATION,
  payload: {
    alarmId,
    messages
  }
})

const setIsLoadingEarlierMessagesForAlarm = (alarmId, isLoadingEarlier) => ({
  type: ActionTypes.SET_IS_LOADING_EARLIER_MESSAGES_FOR_ALARM,
  payload: {
    alarmId,
    isLoadingEarlier
  }
})

const processFirstMessageKeyForAlarmConversation = (
  alarmId,
  firstMessageKey
) => ({
  type: ActionTypes.PROCESS_FIRST_MESSAGE_KEY_FOR_ALARM_CONVERSATION,
  payload: {
    alarmId,
    firstMessageKey
  }
})

const updatePendingConversationMessageForAlarm = (alarmId, pendingMessage) => ({
  type: ActionTypes.UPDATE_PENDING_CONVERSATION_MESSAGE_FOR_ALARM,
  payload: {
    alarmId,
    pendingMessage
  }
})

const updatePendingFeedback = feedback => ({
  type: ActionTypes.UPDATE_PENDING_FEEDBACK,
  payload: {
    feedback
  }
})

const updatePendingProblem = problem => ({
  type: ActionTypes.UPDATE_PENDING_PROBLEM,
  payload: {
    problem
  }
})

const setUserEmail = email => ({
  type: ActionTypes.SET_USER_EMAIL,
  payload: {
    email
  }
})

const setShowSidebar = showSidebar => ({
  type: ActionTypes.SET_SHOW_SIDEBAR,
  payload: {
    showSidebar
  }
})

const updateUnseenMessagesForAlarm = (alarmId, unseenMessages) => ({
  type: ActionTypes.SET_UNSEEN_MESSAGES_FOR_ALARM,
  payload: {
    alarmId,
    unseenMessages
  }
})

const updateCurrentlyTypingUsersForAlarm = (alarmId, currentlyTypingUsers) => ({
  type: ActionTypes.UPDATE_CURRENTLY_TYPING_USERS_FOR_ALARM,
  payload: {
    alarmId,
    currentlyTypingUsers
  }
})

const addStartSnoozeForCreatorSoftPendingAction = (
  alarmId,
  snoozeInterval,
  snoozeDate
) => ({
  type: ActionTypes.ADD_START_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION,
  payload: {
    alarmId,
    snoozeInterval,
    snoozeDate
  }
})

const addStopSnoozeForCreatorSoftPendingAction = alarmId => ({
  type: ActionTypes.ADD_STOP_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION,
  payload: {
    alarmId
  }
})

const addStartSnoozeForParticipantSoftPendingAction = (
  alarm,
  snoozeInterval,
  snoozeDate
) => ({
  type: ActionTypes.ADD_START_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    snoozeInterval,
    snoozeDate
  }
})

const addStopSnoozeForParticipantSoftPendingAction = alarm => ({
  type: ActionTypes.ADD_STOP_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION,
  payload: {
    alarm
  }
})

const addMarkPersonalAlarmDoneSoftPendingAction = (
  alarm,
  occurrenceTime,
  timestamp
) => ({
  type: ActionTypes.ADD_MARK_PERSONAL_ALARM_DONE_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    occurrenceTime,
    timestamp
  }
})

const addMarkCreatorResponseForGroupAlarmSoftPendingAction = (
  alarm,
  response,
  rescheduleAlarm,
  occurrenceTime,
  timestamp
) => ({
  type: ActionTypes.ADD_MARK_CREATOR_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    response,
    rescheduleAlarm,
    occurrenceTime,
    timestamp
  }
})

const addMarkParticipantResponseForGroupAlarmSoftPendingAction = (
  alarm,
  response,
  rescheduleAlarm,
  updateAlarmResponseStatus,
  occurrenceTime,
  timestamp
) => ({
  type: ActionTypes.ADD_MARK_PARTICIPANT_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    response,
    rescheduleAlarm,
    updateAlarmResponseStatus,
    occurrenceTime,
    timestamp
  }
})

const addSkipPersonalAlarmSoftPendingAction = (
  alarm,
  occurrenceTime,
  timestamp
) => ({
  type: ActionTypes.ADD_SKIP_PERSONAL_ALARM_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    occurrenceTime,
    timestamp
  }
})

const addSkipPersonalParticipantAlarmSoftPendingAction = (
  alarm,
  occurrenceTime,
  timestamp
) => ({
  type: ActionTypes.ADD_SKIP_PERSONAL_PARTICIPANT_ALARM_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    occurrenceTime,
    timestamp
  }
})

const addSkipRecipientAlarmSoftPendingAction = (
  alarm,
  occurrenceTime,
  timestamp
) => ({
  type: ActionTypes.ADD_SKIP_RECIPIENT_ALARM_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    occurrenceTime,
    timestamp
  }
})

const addSkipRecipientCreatorAlarmSoftPendingAction = (
  alarm,
  occurrenceTime,
  timestamp
) => ({
  type: ActionTypes.ADD_SKIP_RECIPIENT_CREATOR_ALARM_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    occurrenceTime,
    timestamp
  }
})

const addMarkRecipientAlarmDoneSoftPendingAction = (
  alarm,
  occurrenceTime,
  timestamp
) => ({
  type: ActionTypes.ADD_MARK_RECIPIENT_ALARM_DONE_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    occurrenceTime,
    timestamp
  }
})

const addSetParticipantAlarmResponseSoftPendingAction = (
  alarm,
  participantResponse
) => ({
  type: ActionTypes.ADD_SET_PARTICIPANT_ALARM_RESPONSE_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    participantResponse
  }
})

const addSetRecipientAlarmResponseSoftPendingAction = (
  alarm,
  participantResponse
) => ({
  type: ActionTypes.ADD_SET_RECIPIENT_ALARM_RESPONSE_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    participantResponse
  }
})

const addSetPastAlarmOccurrenceResponseSoftPendingAction = (
  alarm,
  occurrenceTime,
  timestamp,
  response
) => ({
  type: ActionTypes.ADD_SET_PAST_ALARM_OCCURRENCE_RESPONSE_SOFT_PENDING_ACTION,
  payload: {
    alarm,
    occurrenceTime,
    timestamp,
    response
  }
})

const addUpdateGlobalAlarmRingerSettingsSoftPendingAction = ringerSettings => ({
  type: ActionTypes.ADD_UPDATE_GLOBAL_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION,
  payload: {
    ringerSettings
  }
})

const addUpdateRingParticipantAlarmsByDefaultSettingSoftPendingAction =
  ringParticipantAlarmsByDefault => ({
    type: ActionTypes.ADD_UPDATE_RING_PARTICIPANT_ALARMS_BY_DEFAULT_SETTING_SOFT_PENDING_ACTION,
    payload: {
      ringParticipantAlarmsByDefault
    }
  })

const addUpdateRingOnEarphoneOnlySoftPendingAction = ringOnEarphoneOnly => ({
  type: ActionTypes.ADD_UPDATE_RING_ON_EARPHONE_ONLY_SOFT_PENDING_ACTION,
  payload: {
    ringOnEarphoneOnly
  }
})

const addUpdateSpecifyTimezoneForAlarmSoftPendingAction =
  specifyTimezoneForAlarm => ({
    type: ActionTypes.ADD_UPDATE_SPECIFY_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION,
    payload: {
      specifyTimezoneForAlarm
    }
  })

const addUpdateGestureToDimissAnAlarmSoftPendingAction =
  gestureToDismissAnAlarm => ({
    type: ActionTypes.ADD_UPDATE_GESTURE_TO_DISMISS_ALARM_SOFT_PENDING_ACTION,
    payload: {
      gestureToDismissAnAlarm
    }
  })

const addUpdateNotificationSettingsSoftPendingAction =
  notificationSettings => ({
    type: ActionTypes.ADD_UPDATE_NOTIFICATION_SETTINGS_SOFT_PENDING_ACTION,
    payload: {
      notificationSettings
    }
  })

const addUpdateGraduallyIncreaseVolumeSoftPendingAction =
  graduallyIncreaseVolume => ({
    type: ActionTypes.ADD_UPDATE_GRADUALLY_INCREASE_VOLUME_SOFT_PENDING_ACTION,
    payload: {
      graduallyIncreaseVolume
    }
  })

const resetApp = () => ({
  type: ActionTypes.RESET_APP
})

const setRateTheAppUserInfo = rateTheAppInfo => ({
  type: ActionTypes.SET_RATE_THE_APP_USER_INFO,
  payload: {
    rateTheAppInfo
  }
})

const setExistingAlarmsLoaded = () => ({
  type: ActionTypes.SET_EXISTING_ALARMS_LOADED
})

const batchActions = (...actions) => ({
  type: ActionTypes.BATCH_ACTIONS,
  actions
})

const setContactsPermissionStatus = contactsPermissionStatus => ({
  type: ActionTypes.SET_CONTACTS_PERMISSION_STATUS,
  payload: {
    contactsPermissionStatus
  }
})

const setLatestRelease = latestRelease => ({
  type: ActionTypes.SET_LATEST_RELEASE,
  payload: {
    latestRelease
  }
})

const setTabActiveIndex = tabActiveIndex => ({
  type: ActionTypes.SET_TAB_ACTIVE_INDEX,
  payload: {
    tabActiveIndex
  }
})

const genericAction = (type, payload = {}) => ({
  type: type,
  payload
})

const willChangesBeDiscarded = appState => {
  return (
    objGet(appState, 'inspectorPanelConfig.newAlarmWizard.visible', false) ||
    objGet(appState, 'inspectorPanelConfig.editAlarmWizard.visible', false) ||
    objGet(appState, 'inspectorPanelConfig.newGroupWizard.visible', false) ||
    objGet(appState, 'inspectorPanelConfig.editGroupWizard.visible', false) ||
    objGet(
      appState,
      'inspectorPanelConfig.newInstantAlarmWizard.visible',
      false
    ) ||
    objGet(
      appState,
      'inspectorPanelConfig.changeMemberStateScreen.visible',
      false
    )
  )
}

const showNewInstantAlarmWizard = props => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_NEW_INSTANT_ALARM_WIZARD, props)
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.SHOW_NEW_INSTANT_ALARM_WIZARD, props))
}

const showNewAlarmWizard = props => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.SHOW_NEW_ALARM_WIZARD, props))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.SHOW_NEW_ALARM_WIZARD, props))
}

const showDuplicateAlarmWizard = props => dispatch => {
  dispatch(genericAction(ActionTypes.SHOW_DUPLICATE_ALARM_WIZARD, props))
}

const showEditAlarmWizard = alarmId => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_EDIT_ALARM_WIZARD, {
              alarmId
            })
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(
    genericAction(ActionTypes.SHOW_EDIT_ALARM_WIZARD, {
      alarmId
    })
  )
}

const showAlarmConversationScreen = alarmId => ({
  type: ActionTypes.SHOW_ALARM_CONVERSATION_SCREEN,
  payload: {
    alarmId
  }
})

const hideAlarmConversationScreen = () => ({
  type: ActionTypes.HIDE_ALARM_CONVERSATION_SCREEN
})

const showAlarmHistoryScreen = alarm => ({
  type: ActionTypes.SHOW_ALARM_HISTORY_SCREEN,
  payload: {
    alarm
  }
})

const hideAlarmHistoryScreen = () => ({
  type: ActionTypes.HIDE_ALARM_HISTORY_SCREEN
})

const showNewGroupWizard = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.SHOW_NEW_GROUP_WIZARD))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.SHOW_NEW_GROUP_WIZARD))
}

const showEditGroupWizard = groupId => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_EDIT_GROUP_WIZARD, {
              groupId
            })
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(
    genericAction(ActionTypes.SHOW_EDIT_GROUP_WIZARD, {
      groupId
    })
  )
}

const hideNewAlarmWizard = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.HIDE_NEW_ALARM_WIZARD))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.HIDE_NEW_ALARM_WIZARD))
}

const hideDuplicateAlarmWizard = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.HIDE_DUPLICATE_ALARM_WIZARD))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.HIDE_DUPLICATE_ALARM_WIZARD))
}

const hideNewInstantAlarmWizard = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.HIDE_NEW_INSTANT_ALARM_WIZARD))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.HIDE_NEW_INSTANT_ALARM_WIZARD))
}

const hideEditAlarmWizard = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.HIDE_EDIT_ALARM_WIZARD))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.HIDE_EDIT_ALARM_WIZARD))
}

// Close action directly closes the wizard without checking for
// discarded changes. These are called to close the wizard after
// user saves the alarm/group.

// eslint-disable-next-line no-unused-vars
const closeNewAlarmWizard = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_NEW_ALARM_WIZARD))
}

// eslint-disable-next-line no-unused-vars
const closeDuplicateAlarmWizard = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_DUPLICATE_ALARM_WIZARD))
}

// eslint-disable-next-line no-unused-vars
const closeInstantAlarmWizard = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_NEW_INSTANT_ALARM_WIZARD))
}

// eslint-disable-next-line no-unused-vars
const closeEditAlarmWizard = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_EDIT_ALARM_WIZARD))
}

// eslint-disable-next-line no-unused-vars
const closeNewGroupWizard = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_NEW_GROUP_WIZARD))
}

// eslint-disable-next-line no-unused-vars
const closeEditGroupWizard = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_EDIT_GROUP_WIZARD))
}

const resetInspectorPanel = () => ({
  type: ActionTypes.RESET_INSPECTOR_PANEL
})

const hideNewGroupWizard = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.HIDE_NEW_GROUP_WIZARD))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.HIDE_NEW_GROUP_WIZARD))
}

const hideEditGroupWizard = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.HIDE_EDIT_GROUP_WIZARD))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.HIDE_EDIT_GROUP_WIZARD))
}

const showSelectAlarmTypeScreen = props => (dispatch, getState) => {
  const canCreateNewAlarmData = canCreateNewAlarmDataSelector(getState())

  // This is only called for galarm-web and hence not passed a navigation object
  // to canCreateNewAlarmAndShowAlertsIfNeeded. If called from mobile, this method
  // requires navigation object
  const canCreateNewAlarm =
    AlarmUtilsWithExtras.canCreateNewAlarmAndShowAlertsIfNeeded(
      canCreateNewAlarmData
    )
  if (!canCreateNewAlarm) {
    return
  }

  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_SELECT_ALARM_TYPE_SCREEN, props)
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.SHOW_SELECT_ALARM_TYPE_SCREEN, props))
}

const hideSelectAlarmTypeScreen = () => ({
  type: ActionTypes.HIDE_SELECT_ALARM_TYPE_SCREEN
})

const showAlarmDetailsScreen = alarmId => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_ALARM_DETAILS_SCREEN, {
              alarmId
            })
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(
    genericAction(ActionTypes.SHOW_ALARM_DETAILS_SCREEN, {
      alarmId
    })
  )
}

const showInstantAlarmDetailsScreen = alarmId => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_INSTANT_ALARM_DETAILS_SCREEN, {
              alarmId
            })
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(
    genericAction(ActionTypes.SHOW_INSTANT_ALARM_DETAILS_SCREEN, {
      alarmId
    })
  )
}

// eslint-disable-next-line no-unused-vars
const hideAlarmDetailsScreen = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_ALARM_DETAILS_SCREEN))
}

// eslint-disable-next-line no-unused-vars
const hideInstantAlarmDetailsScreen = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_INSTANT_ALARM_DETAILS_SCREEN))
}

const showInstantParticipantAlarmDetailsScreen =
  alarmId => (dispatch, getState) => {
    if (willChangesBeDiscarded(getState().appState)) {
      NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
        {
          text: I18n.t('yes'),
          onPress: () =>
            dispatch(
              genericAction(
                ActionTypes.SHOW_INSTANT_PARTICIPANT_ALARM_DETAILS_SCREEN,
                {
                  alarmId
                }
              )
            )
        },
        {
          text: I18n.t('no')
        }
      ])

      return
    }
    dispatch(
      genericAction(ActionTypes.SHOW_INSTANT_PARTICIPANT_ALARM_DETAILS_SCREEN, {
        alarmId
      })
    )
  }

const showParticipantAlarmDetailsScreen = alarmId => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_PARTICIPANT_ALARM_DETAILS_SCREEN, {
              alarmId
            })
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(
    genericAction(ActionTypes.SHOW_PARTICIPANT_ALARM_DETAILS_SCREEN, {
      alarmId
    })
  )
}

// eslint-disable-next-line no-unused-vars
const hideParticipantAlarmDetailsScreen = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_PARTICIPANT_ALARM_DETAILS_SCREEN))
}

const showGroupDetailsScreen = groupId => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_GROUP_DETAILS_SCREEN, { groupId })
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.SHOW_GROUP_DETAILS_SCREEN, { groupId }))
}

// eslint-disable-next-line no-unused-vars
const hideGroupDetailsScreen = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_GROUP_DETAILS_SCREEN))
}

const showChangeMemberStateScreen =
  (groupId, groupName, members, updateMemberState, memberToChange) =>
  (dispatch, getState) => {
    if (willChangesBeDiscarded(getState().appState)) {
      NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
        {
          text: I18n.t('yes'),
          onPress: () =>
            dispatch(
              genericAction(ActionTypes.SHOW_CHANGE_MEMBER_STATE_SCREEN, {
                groupId,
                groupName,
                members,
                updateMemberState,
                memberToChange
              })
            )
        },
        {
          text: I18n.t('no')
        }
      ])

      return
    }
    dispatch(
      genericAction(ActionTypes.SHOW_CHANGE_MEMBER_STATE_SCREEN, {
        groupId,
        groupName,
        members,
        updateMemberState,
        memberToChange
      })
    )
  }

const showContactDetailsScreen = contactId => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(
            genericAction(ActionTypes.SHOW_CONTACT_DETAILS_SCREEN, {
              contactId
            })
          )
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(
    genericAction(ActionTypes.SHOW_CONTACT_DETAILS_SCREEN, { contactId })
  )
}

// eslint-disable-next-line no-unused-vars
const hideChangeGroupMemberStateScreen = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.HIDE_CHANGE_MEMBER_STATE_SCREEN))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.HIDE_CHANGE_MEMBER_STATE_SCREEN))
}

// eslint-disable-next-line no-unused-vars
const closeChangeGroupMemberStateScreen = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_CHANGE_MEMBER_STATE_SCREEN))
}

// eslint-disable-next-line no-unused-vars
const hideContactDetailsScreen = () => (dispatch, getState) => {
  dispatch(genericAction(ActionTypes.HIDE_CONTACT_DETAILS_SCREEN))
}

const showReportAProblemScreen = () => ({
  type: ActionTypes.SHOW_REPORT_A_PROBLEM_SCREEN
})

const hideReportAProblemScreen = () => ({
  type: ActionTypes.HIDE_REPORT_A_PROBLEM_SCREEN
})

const showProfileAndSettingsScreen = () => (dispatch, getState) => {
  if (willChangesBeDiscarded(getState().appState)) {
    NavigationUtils.showAlert(I18n.t('confirm'), I18n.t('discardChanges'), [
      {
        text: I18n.t('yes'),
        onPress: () =>
          dispatch(genericAction(ActionTypes.SHOW_PROFILE_AND_SETTINGS_SCREEN))
      },
      {
        text: I18n.t('no')
      }
    ])

    return
  }
  dispatch(genericAction(ActionTypes.SHOW_PROFILE_AND_SETTINGS_SCREEN))
}

const hideProfileAndSettingsScreen = () => ({
  type: ActionTypes.HIDE_PROFILE_AND_SETTINGS_SCREEN
})

// rescheduleAlarm is used by group alarm if a user
// confirm the group alarm in advance but still wants
// the alarm to ring at the set time. For other types
// of alarms, it is defualted to true which would have
// no affect on the functioning of the alarm. But it could
// be used for other alarm types also. For e.g. when user
// marks an alarm done in advance, they can be asked the
// same question.
const addAlarmAcknowledgementAction = (
  alarm,
  occurrenceTime,
  uid,
  timestamp,
  response,
  rescheduleAlarm
) => {
  const occurrenceTimeString = AlarmUtils.getOccurrenceTimeString(
    occurrenceTime,
    alarm.creatorTimezoneOffset,
    alarm.creatorTimezone
  )
  return {
    type: ActionTypes.ADD_ALARM_ACKNOWLEDGEMENT,
    payload: {
      alarmId: alarm.id,
      occurrenceTimeString,
      uid,
      timestamp,
      response,
      rescheduleAlarm
    }
  }
}

const removeAlarmAcknowledgementActions = alarmId => ({
  type: ActionTypes.REMOVE_ALARM_ACKNOWLEDGEMENTS,
  payload: {
    alarmId
  }
})

const addAlarmStatusChangeAction = (alarmId, timestamp, status) => ({
  type: ActionTypes.ADD_ALARM_STATUS_CHANGE,
  payload: {
    alarmId,
    timestamp,
    status
  }
})

const removeAlarmStatusChangeActions = alarmId => ({
  type: ActionTypes.REMOVE_ALARM_STATUS_CHANGES,
  payload: {
    alarmId
  }
})

const addAlarmAcknowledgements = (alarmId, alarmAcknowledgements) => ({
  type: ActionTypes.ADD_ALARM_ACKNOWLEDGEMENTS,
  payload: {
    alarmId,
    alarmAcknowledgements
  }
})

const updateAlarmsAcknowledgements = alarmsAcknowledgements => ({
  type: ActionTypes.UPDATE_ALARMS_ACKNOWLEDGEMENTS,
  payload: {
    alarmsAcknowledgements
  }
})

const addAlarmStatusChanges = (alarmId, alarmStatusChanges) => ({
  type: ActionTypes.ADD_ALARM_STATUS_CHANGES,
  payload: {
    alarmId,
    alarmStatusChanges
  }
})

const updateAlarmsStatusChanges = alarmsStatusChanges => ({
  type: ActionTypes.UPDATE_ALARMS_STATUS_CHANGES,
  payload: {
    alarmsStatusChanges
  }
})

const addSkuDetails = skuDetails => ({
  type: ActionTypes.ADD_SKU_DETAILS,
  payload: {
    skuDetails
  }
})

const setJoinDate = joinDate => ({
  type: ActionTypes.SET_JOIN_DATE,
  payload: {
    joinDate
  }
})

const setShareableAlarmLinksCount = shareableAlarmLinksCount => ({
  type: ActionTypes.SET_SHAREABLE_ALARM_LINKS_COUNT,
  payload: {
    shareableAlarmLinksCount
  }
})

const setAlarmsSavedToCalendarCount = alarmsSavedToCalendarCount => ({
  type: ActionTypes.SET_ALARMS_SAVED_TO_CALENDAR_COUNT,
  payload: {
    alarmsSavedToCalendarCount
  }
})

const setInstantAlarmsCount = instantAlarmsCount => ({
  type: ActionTypes.SET_INSTANT_ALARMS_COUNT,
  payload: {
    instantAlarmsCount
  }
})

const setColorScheme = colorScheme => ({
  type: ActionTypes.SET_COLOR_SCHEME,
  payload: {
    colorScheme
  }
})

const setWebColorScheme = webColorScheme => ({
  type: ActionTypes.SET_WEB_COLOR_SCHEME,
  payload: {
    webColorScheme
  }
})

const toggleCategorizedAlarmView = () => ({
  type: ActionTypes.TOGGLE_CATEGORIZED_ALARM_VIEW
})

const setSeenSpecifyTimezoneAlert = () => ({
  type: ActionTypes.SET_SEEN_SPECIFY_TIMEZONE_ALERT
})

const setAlarmCategories = alarmCategories => ({
  type: ActionTypes.SET_ALARM_CATEGORIES,
  payload: {
    alarmCategories
  }
})

const createAlarmCategoryLocally = (id, alarmCategory) => ({
  type: ActionTypes.CREATE_ALARM_CATEGORY,
  payload: {
    id,
    alarmCategory
  }
})

const addAlarmToAlarmCategoryLocally = (id, alarmId) => ({
  type: ActionTypes.ADD_ALARM_TO_ALARM_CATEGORY,
  payload: {
    id,
    alarmId
  }
})

const removeAlarmFromAlarmCategoryLocally = (id, alarmId) => ({
  type: ActionTypes.REMOVE_ALARM_FROM_ALARM_CATEGORY,
  payload: {
    id,
    alarmId
  }
})

const deleteAlarmCategoryLocally = id => ({
  type: ActionTypes.DELETE_ALARM_CATEGORY,
  payload: {
    id
  }
})

const updateAlarmCategoryLocally = (id, alarmCategory) => ({
  type: ActionTypes.UPDATE_ALARM_CATEGORY,
  payload: {
    id,
    alarmCategory
  }
})

const unhideAlarmCategoryLocally = id => ({
  type: ActionTypes.UNHIDE_ALARM_CATEGORY,
  payload: {
    id
  }
})

const hideAlarmCategoryLocally = id => ({
  type: ActionTypes.HIDE_ALARM_CATEGORY,
  payload: {
    id
  }
})

const addAddAlarmToAlarmCategorySoftPendingAction = (
  alarmCategoryId,
  alarmId
) => ({
  type: ActionTypes.ADD_ADD_ALARM_TO_ALARM_CATEGORY_SOFT_PENDING_ACTION,
  payload: {
    alarmCategoryId,
    alarmId
  }
})

const addRemoveAlarmFromAlarmCategorySoftPendingAction = (
  alarmCategoryId,
  alarmId
) => ({
  type: ActionTypes.ADD_REMOVE_ALARM_FROM_ALARM_CATEGORY_SOFT_PENDING_ACTION,
  payload: {
    alarmCategoryId,
    alarmId
  }
})

const addDeleteAlarmCategorySoftPendingAction = alarmCategoryId => ({
  type: ActionTypes.ADD_DELETE_ALARM_CATEGORY_SOFT_PENDING_ACTION,
  payload: {
    alarmCategoryId
  }
})

const setShowAppRunningElsewhereAlert = showAppRunningElsewhereAlert => ({
  type: ActionTypes.SET_SHOW_APP_RUNNING_ELSEWHERE_ALERT,
  payload: {
    showAppRunningElsewhereAlert
  }
})

const setMobileAndWebTimezoneMismatchDetected =
  mobileAndWebTimezoneMismatchDetected => ({
    type: ActionTypes.SET_MOBILE_AND_WEB_TIMEZONE_MISMATCH_DETECTED,
    payload: {
      mobileAndWebTimezoneMismatchDetected
    }
  })

const setIosNumNotificationsScheduled = iosNumNotificationsScheduled => ({
  type: ActionTypes.SET_IOS_NUM_NOTIFICATIONS_SCHEDULED,
  payload: {
    iosNumNotificationsScheduled
  }
})

const setShowQuickReminder = showQuickReminder => ({
  type: ActionTypes.SET_SHOW_QUICK_REMINDER,
  payload: {
    showQuickReminder
  }
})

const setCurrentlySelectedAlarmCategoryId = id => ({
  type: ActionTypes.SET_CURRENTLY_SELECTED_ALARM_CATEGORY_ID,
  payload: {
    id
  }
})

const setCurrentlySelectedTaskListId = id => ({
  type: ActionTypes.SET_CURRENTLY_SELECTED_TASK_LIST_ID,
  payload: {
    id
  }
})

const setShowAlertIncidents = showAlertIncidents => ({
  type: ActionTypes.SET_SHOW_ALERT_INCIDENTS,
  payload: {
    showAlertIncidents
  }
})

const setBatteryOptimizationDisabled = batteryOptimizationDisabled => ({
  type: ActionTypes.SET_BATTERY_OPTIMIZATION_DISABLED,
  payload: {
    batteryOptimizationDisabled
  }
})

const setScheduleExactAlarmsEnabled = scheduleExactAlarmsEnabled => ({
  type: ActionTypes.SET_SCHEDULE_EXACT_ALARMS_ENABLED,
  payload: {
    scheduleExactAlarmsEnabled
  }
})

const addUpdateAlarmCategorySoftPendingAction = (
  alarmCategoryId,
  alarmCategory
) => ({
  type: ActionTypes.ADD_UPDATE_ALARM_CATEGORY_SOFT_PENDING_ACTION,
  payload: {
    alarmCategoryId,
    alarmCategory
  }
})

const addCreateAlarmCategorySoftPendingAction = (
  alarmCategoryId,
  alarmCategory
) => ({
  type: ActionTypes.ADD_CREATE_ALARM_CATEGORY_SOFT_PENDING_ACTION,
  payload: {
    alarmCategoryId,
    alarmCategory
  }
})

const updateWebColorScheme = colorScheme => dispatch => {
  GlobalConfig.webColorScheme = colorScheme
  dispatch(setWebColorScheme(colorScheme))
  AlarmUtils.setWebColorScheme(colorScheme)
}

const updateColorScheme = colorScheme => dispatch => {
  StorageManager.storeData({ colorScheme: colorScheme })
  GlobalConfig.colorScheme = colorScheme
  dispatch(setColorScheme(colorScheme))
  GlobalConfig.eventEmitter.emit('changeTheme', colorScheme)
}

const addAlarmToAlarmCategory =
  (alarmCategoryId, alarmId) => (dispatch, getState) => {
    dispatch(addAlarmToAlarmCategoryLocally(alarmCategoryId, alarmId))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    if (persistOnFirebase) {
      AlarmUtils.addAlarmToAlarmCategory(alarmCategoryId, alarmId)
    } else {
      dispatch(
        addAddAlarmToAlarmCategorySoftPendingAction(alarmCategoryId, alarmId)
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.ADD_ALARM_TO_ALARM_CATEGORY,
      {}
    )
  }

const removeAlarmFromAlarmCategory =
  (alarmCategoryId, alarmId) => (dispatch, getState) => {
    dispatch(removeAlarmFromAlarmCategoryLocally(alarmCategoryId, alarmId))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    if (persistOnFirebase) {
      AlarmUtils.removeAlarmFromAlarmCategory(alarmCategoryId, alarmId)
    } else {
      dispatch(
        addRemoveAlarmFromAlarmCategorySoftPendingAction(
          alarmCategoryId,
          alarmId
        )
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.REMOVE_ALARM_FROM_ALARM_CATEGORY,
      {}
    )
  }

const deleteAlarmCategory = alarmCategoryId => (dispatch, getState) => {
  dispatch(deleteAlarmCategoryLocally(alarmCategoryId))

  const persistOnFirebase =
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase

  if (persistOnFirebase) {
    AlarmUtils.deleteAlarmCategory(alarmCategoryId)
  } else {
    dispatch(addDeleteAlarmCategorySoftPendingAction(alarmCategoryId))
  }

  FirebaseProxy.logEvent(
    Constants.UserAnalyticsEvents.DELETE_ALARM_CATEGORY,
    {}
  )
}

const deleteAlarmsInCategory = (alarmCategoryId, alarmIds) => dispatch => {
  if (alarmIds.length !== 0) {
    dispatch(deleteAllAlarms(alarmIds, 'deleteAlarmsInCategory'))
  }
}

const unhideAlarmCategory = alarmCategoryId => (dispatch, getState) => {
  dispatch(unhideAlarmCategoryLocally(alarmCategoryId))

  const persistOnFirebase =
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase

  const alarmCategoryUpdate = { disabled: null }

  if (persistOnFirebase) {
    AlarmUtils.updateAlarmCategory(alarmCategoryId, alarmCategoryUpdate)
  } else {
    dispatch(
      addUpdateAlarmCategorySoftPendingAction(
        alarmCategoryId,
        alarmCategoryUpdate
      )
    )
  }

  FirebaseProxy.logEvent(
    Constants.UserAnalyticsEvents.UNHIDE_ALARM_CATEGORY,
    {}
  )
}

const hideAlarmCategory = alarmCategoryId => (dispatch, getState) => {
  dispatch(hideAlarmCategoryLocally(alarmCategoryId))

  const persistOnFirebase =
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase

  const alarmCategoryUpdate = { disabled: true, alarmIds: null }

  if (persistOnFirebase) {
    AlarmUtils.updateAlarmCategory(alarmCategoryId, alarmCategoryUpdate)
  } else {
    dispatch(
      addUpdateAlarmCategorySoftPendingAction(
        alarmCategoryId,
        alarmCategoryUpdate
      )
    )
  }

  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.HIDE_ALARM_CATEGORY, {})
}

const updateAlarmCategory =
  (alarmCategoryId, alarmCategory) => (dispatch, getState) => {
    dispatch(updateAlarmCategoryLocally(alarmCategoryId, alarmCategory))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    if (persistOnFirebase) {
      AlarmUtils.updateAlarmCategory(alarmCategoryId, alarmCategory)
    } else {
      dispatch(
        addUpdateAlarmCategorySoftPendingAction(alarmCategoryId, alarmCategory)
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.UPDATE_ALARM_CATEGORY,
      {}
    )
  }

const createAlarmCategory = alarmCategory => (dispatch, getState) => {
  const id = GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('alarmCategories')
    .push().key
  const alarmCategoryWithId = {
    id,
    ...alarmCategory
  }

  dispatch(createAlarmCategoryLocally(id, alarmCategoryWithId))

  const persistOnFirebase =
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase

  if (persistOnFirebase) {
    AlarmUtils.createAlarmCategory(id, alarmCategoryWithId)
  } else {
    dispatch(addCreateAlarmCategorySoftPendingAction(id, alarmCategoryWithId))
  }

  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.CREATE_ALARM_CATEGORY, {
    [Constants.UserAnalyticsEventParameters.SOURCE]: alarmCategory.source
  })

  return Promise.resolve(alarmCategoryWithId)
}

// eslint-disable-next-line no-unused-vars
const deleteAllUserDefinedAlarmCategories = () => (dispatch, getState) => {
  const alarmCategories = alarmCategoriesSelector(getState())
  Object.values(alarmCategories).forEach(alarmCategory => {
    if (alarmCategory.source !== Constants.ALARM_CATEGORY_SOURCES.SYSTEM) {
      dispatch(deleteAlarmCategory(alarmCategory.id))
    }
  })
  if (Object.values(alarmCategories).length > 0) {
    const alert = {
      id: 'allUserCategoriesDeleted',
      type: Constants.AlertTypes.ALL_USER_CATEGORIES_DELETED,
      message: I18n.t('allUserCategoriesDeleted')
    }

    dispatch(addSystemAlert(alert))
  }
}

const handlePendingActions = () => async (dispatch, getState) => {
  console.tron.log('Handling pending actions')
  while (getState().pendingActions.pendingActions.length > 0) {
    const pendingAction = getState().pendingActions.pendingActions[0]
    try {
      switch (pendingAction.name) {
        case Constants.ADD_ALARM_PENDING_ACTION_NAME:
          await handleAddAlarmPendingAction(pendingAction.alarm)
          break
        case Constants.DELETE_ALARM_PENDING_ACTION_NAME:
          await handleDeleteAlarmPendingAction(pendingAction.alarm)
          break
        case Constants.EDIT_ALARM_PENDING_ACTION_NAME:
          await handleEditAlarmPendingAction(
            pendingAction.editedAlarm,
            pendingAction.prevAlarm,
            pendingAction.removeSubscription
          )
          break
        case Constants.ADD_CHECKLIST_PENDING_ACTION_NAME:
          await handleAddChecklistPendingAction(pendingAction.checklist)
          break
        case Constants.EDIT_CHECKLIST_PENDING_ACTION_NAME:
          await handleEditChecklistPendingAction(pendingAction.checklist)
          break
        case Constants.REMOVE_CHECKLIST_PENDING_ACTION_NAME:
          await handleRemoveChecklistPendingAction(pendingAction.checklistId)
          break
        case Constants.ADD_ENABLE_DISABLE_ALARM_PENDING_ACTION_NAME:
          await handleEnableDisableAlarmPendingAction(
            pendingAction.alarm,
            pendingAction.timestamp,
            pendingAction.alarmStatus
          )
          break
        default:
          LogUtils.logError(
            new Error('Unknown pending action ' + pendingAction.name)
          )
      }
      dispatch(removePendingAction(pendingAction.id))
    } catch (error) {
      dispatch(removePendingAction(pendingAction.id))
      LogUtils.logError(error, 'Unable to handle pending action', pendingAction)
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.HANDLE_PENDING_ACTION,
      {
        [Constants.UserAnalyticsEventParameters.ACTION_NAME]: pendingAction.name
      }
    )
  }

  while (getState().pendingActions.softPendingActions.length > 0) {
    const softPendingAction = getState().pendingActions.softPendingActions[0]
    try {
      switch (softPendingAction.name) {
        case Constants.START_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION_NAME:
          await handleStartSnoozeForCreatorPendingAction(softPendingAction)
          break
        case Constants.STOP_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION_NAME:
          await handleStopSnoozeForCreatorPendingAction(
            softPendingAction.alarmId
          )
          break
        case Constants.START_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION_NAME:
          await handleStartSnoozeForParticipantPendingAction(softPendingAction)
          break
        case Constants.STOP_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION_NAME:
          await handleStopSnoozeForParticipantPendingAction(
            softPendingAction.alarm
          )
          break
        case Constants.MARK_PERSONAL_ALARM_DONE_SOFT_PENDING_ACTION_NAME:
          await handleMarkPersonalAlarmDonePendingAction(
            softPendingAction.alarm,
            softPendingAction.occurrenceTime,
            softPendingAction.timestamp
          )
          break
        case Constants.SKIP_PERSONAL_ALARM_SOFT_PENDING_ACTION_NAME:
          await handleSkipPersonalAlarmPendingAction(
            softPendingAction.alarm,
            softPendingAction.occurrenceTime,
            softPendingAction.timestamp
          )
          break
        case Constants.SKIP_PERSONAL_PARTICIPANT_ALARM_SOFT_PENDING_ACTION_NAME:
          await handleSkipPersonalParticipantAlarmPendingAction(
            softPendingAction.alarm,
            softPendingAction.occurrenceTime,
            softPendingAction.timestamp
          )
          break
        case Constants.SKIP_RECIPIENT_ALARM_SOFT_PENDING_ACTION_NAME:
          await handleSkipRecipientAlarmPendingAction(
            softPendingAction.alarm,
            softPendingAction.occurrenceTime,
            softPendingAction.timestamp
          )
          break
        case Constants.SKIP_RECIPIENT_CREATOR_ALARM_SOFT_PENDING_ACTION_NAME:
          await handleSkipRecipientCreatorAlarmPendingAction(
            softPendingAction.alarm,
            softPendingAction.occurrenceTime,
            softPendingAction.timestamp
          )
          break
        case Constants.MARK_RECIPIENT_ALARM_DONE_SOFT_PENDING_ACTION_NAME:
          await handleMarkRecipientAlarmDonePendingAction(
            softPendingAction.alarm,
            softPendingAction.occurrenceTime,
            softPendingAction.timestamp
          )
          break
        case Constants.SET_PARTICIPANT_ALARM_RESPONSE_SOFT_PENDING_ACTION_NAME:
          await handleSetParticipantAlarmResponsePendingAction(
            softPendingAction.alarm,
            softPendingAction.participantResponse
          )
          break
        case Constants.SET_RECIPIENT_ALARM_RESPONSE_SOFT_PENDING_ACTION_NAME:
          await handleSetRecipientAlarmResponsePendingAction(
            softPendingAction.alarm,
            softPendingAction.participantResponse
          )
          break
        case Constants.SET_PAST_ALARM_OCCURRENCE_RESPONSE_SOFT_PENDING_ACTION_NAME:
          await handleSetPastAlarmOccurrenceResponsePendingAction(
            softPendingAction.alarm,
            softPendingAction.occurrenceTime,
            softPendingAction.timestamp,
            softPendingAction.response
          )
          break
        case Constants.ADD_UPDATE_PARTICIPANT_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME:
          await handleUpdateParticipantAlarmRingerSettingsPendingAction(
            softPendingAction.alarm,
            softPendingAction.ringerSettings
          )
          break
        case Constants.ADD_UPDATE_PARTICIPANT_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION_NAME:
          await handleUpdateParticipantAlarmPreReminderDurationPendingAction(
            softPendingAction.alarm,
            softPendingAction.preReminderDuration
          )
          break
        case Constants.ADD_UPDATE_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME:
          await handleUpdateAlarmRingerSettingsPendingAction(
            softPendingAction.alarm,
            softPendingAction.ringerSettings
          )
          break
        case Constants.ADD_UPDATE_ALARM_PRE_REMINDER_SOFT_PENDING_ACTION_NAME:
          await handleUpdateAlarmPreReminderPendingAction(
            softPendingAction.alarm,
            softPendingAction.preReminderDuration
          )
          break
        case Constants.ADD_UPDATE_GLOBAL_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME:
          await handleUpdateGlobalAlarmRingerSettingsPendingAction(
            softPendingAction.ringerSettings
          )
          break
        case Constants.ADD_UPDATE_RING_PARTICIPANT_ALARMS_BY_DEFAULT_SETTING_SOFT_PENDING_ACTION_NAME:
          await handleUpdateRingParticipantAlarmsByDefaultSettingPendingAction(
            softPendingAction.ringParticipantAlarmsByDefault
          )
          break
        case Constants.ADD_ALARM_TO_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME:
          await handleAddAlarmToAlarmCategoryPendingAction(softPendingAction)
          break
        case Constants.REMOVE_ALARM_FROM_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME:
          await handleRemoveAlarmFromAlarmCategoryPendingAction(
            softPendingAction
          )
          break
        case Constants.DELETE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME:
          await handleDeleteAlarmCategoryPendingAction(softPendingAction)
          break
        case Constants.UPDATE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME:
          await handleUpdateAlarmCategoryPendingAction(softPendingAction)
          break
        case Constants.CREATE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME:
          await handleCreateAlarmCategoryPendingAction(softPendingAction)
          break
        case Constants.MARK_CREATOR_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION_NAME:
          await handleMarkCreatorResponseForGroupAlarmPendingAction(
            softPendingAction
          )
          break
        case Constants.MARK_PARTICIPANT_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION_NAME:
          await handleMarkParticipantResponseForGroupAlarmPendingAction(
            softPendingAction
          )
          break
        case Constants.UPDATE_RING_ON_EARPHONE_ONLY_SOFT_PENDING_ACTION_NAME:
          await handleAddUpdateRingOnEarphoneOnlySoftPendingAction(
            softPendingAction.ringOnEarphoneOnly
          )
          break
        case Constants.UPDATE_GESTURE_TO_DISMISS_AN_ALARM_SOFT_PENDING_ACTION_NAME:
          await handleUpdateGestureToDismissAlarmSoftPendingAction(
            softPendingAction.gestureToDismissAnAlarm
          )
          break
        case Constants.UPDATE_NOTIFICATION_SETTINGS_SOFT_PENDING_ACTION_NAME:
          await handleUpdateNotificationSettingsUserSettingsSoftPendingAction(
            softPendingAction.notificationSettings
          )
          break
        case Constants.ADD_UPDATE_SPECIFY_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION_NAME:
          await handleUpdateSpecifyTimezoneForAlarm(
            softPendingAction.specifyTimezoneForAlarm
          )
          break
        case Constants.UPDATE_GRADUALLY_INCREASE_VOLUME_SOFT_PENDING_ACTION_NAME:
          await handleUpdateGraduallyIncreaseVolumeSoftPendingAction(
            softPendingAction.graduallyIncreaseVolume
          )
          break
        default:
          LogUtils.logError(
            new Error('Unknown soft pending action ' + softPendingAction.name)
          )
      }
      dispatch(removeSoftPendingAction(softPendingAction.id))
    } catch (error) {
      dispatch(removeSoftPendingAction(softPendingAction.id))
      LogUtils.logError(
        error,
        'Unable to handle soft pending action',
        softPendingAction
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.HANDLE_PENDING_ACTION,
      {
        [Constants.UserAnalyticsEventParameters.ACTION_NAME]:
          softPendingAction.name
      }
    )
  }
  return Promise.resolve()
}

const handleAddAlarmToAlarmCategoryPendingAction = async pendingAction => {
  console.tron.log('Handling add alarm to alarm category pending action')
  const { alarmCategoryId, alarmId } = pendingAction
  await AlarmUtils.addAlarmToAlarmCategory(alarmCategoryId, alarmId)
}

const handleRemoveAlarmFromAlarmCategoryPendingAction = async pendingAction => {
  console.tron.log('Handling remove alarm from alarm category pending action')
  const { alarmCategoryId, alarmId } = pendingAction
  await AlarmUtils.removeAlarmFromAlarmCategory(alarmCategoryId, alarmId)
}

const handleDeleteAlarmCategoryPendingAction = async pendingAction => {
  console.tron.log('Handling delete alarm category pending action')
  const { alarmCategoryId } = pendingAction
  await AlarmUtils.deleteAlarmCategory(alarmCategoryId)
}

const handleUpdateAlarmCategoryPendingAction = async pendingAction => {
  console.tron.log('Handling update alarm category pending action')
  const { alarmCategoryId, alarmCategory } = pendingAction
  await AlarmUtils.updateAlarmCategory(alarmCategoryId, alarmCategory)
}

const handleCreateAlarmCategoryPendingAction = async pendingAction => {
  console.tron.log('Handling create alarm category pending action')
  const { alarmCategoryId, alarmCategory } = pendingAction
  await AlarmUtils.createAlarmCategory(alarmCategoryId, alarmCategory)
}
const handleStartSnoozeForCreatorPendingAction = async pendingAction => {
  console.tron.log('Handling start snooze for creator pending action')
  const { alarmId, snoozeInterval, snoozeDate } = pendingAction
  const firebaseUpdateObj = AlarmUtils.createFirebaseObjForSnoozingCreatorAlarm(
    alarmId,
    snoozeInterval,
    snoozeDate
  )
  await GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const handleStopSnoozeForCreatorPendingAction = async alarmId => {
  console.tron.log('Handling stop snooze for creator pending action')
  const firebaseUpdateObj =
    AlarmUtils.createFirebaseObjForStoppingSnoozeForCreatorAlarm(alarmId)
  await GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const handleStartSnoozeForParticipantPendingAction = async pendingAction => {
  console.tron.log('Handling start snooze for participant pending action')
  const { alarm, snoozeInterval, snoozeDate } = pendingAction
  const firebaseUpdateObj =
    AlarmUtils.createFirebaseObjForSnoozingParticipantAlarm(
      alarm,
      snoozeInterval,
      snoozeDate
    )
  await GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const handleStopSnoozeForParticipantPendingAction = async alarm => {
  console.tron.log('Handling stop snooze for participant pending action')
  const firebaseUpdateObj =
    AlarmUtils.createFirebaseObjForStoppingSnoozeForParticipantAlarm(alarm)
  await GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const handleMarkPersonalAlarmDonePendingAction = async (
  alarm,
  occurrenceTime,
  timestamp
) => {
  console.tron.log('Handling mark personal alarm done pending action')
  await AlarmUtils.markAlarmAsAcknowledgedCore(alarm, occurrenceTime, timestamp)
}

const handleSkipPersonalAlarmPendingAction = async (
  alarm,
  occurrenceTime,
  timestamp
) => {
  console.tron.log('Handling skip personal alarm pending action')
  await AlarmUtils.skipPersonalAlarmCore(alarm, occurrenceTime, timestamp)
}

const handleSkipPersonalParticipantAlarmPendingAction = async (
  alarm,
  occurrenceTime,
  timestamp
) => {
  console.tron.log('Handling skip personal participant alarm pending action')
  await AlarmUtils.skipPersonalParticipantAlarmCore(
    alarm,
    occurrenceTime,
    timestamp
  )
}

const handleSkipRecipientAlarmPendingAction = async (
  alarm,
  occurrenceTime,
  timestamp
) => {
  console.tron.log('Handling skip recipient alarm pending action')
  await AlarmUtils.skipRecipientAlarmCore(alarm, occurrenceTime, timestamp)
}

const handleSkipRecipientCreatorAlarmPendingAction = async (
  alarm,
  occurrenceTime,
  timestamp
) => {
  console.tron.log('Handling skip recipient creator alarm pending action')
  await AlarmUtils.skipRecipientCreatorAlarmCore(
    alarm,
    occurrenceTime,
    timestamp
  )
}

const handleMarkRecipientAlarmDonePendingAction = async (
  alarm,
  occurrenceTime,
  timestamp
) => {
  console.tron.log('Handling mark recipient alarm done pending action')
  await AlarmUtils.markRecipientAlarmAsDoneCore(
    alarm,
    occurrenceTime,
    timestamp
  )
}

const handleMarkCreatorResponseForGroupAlarmPendingAction =
  async actionData => {
    console.tron.log(
      'Handling mark creator response for group alarm pending action'
    )
    const { alarm, response, rescheduleAlarm, occurrenceTime, timestamp } =
      actionData
    await AlarmUtils.markCreatorResponseForSimultaneousAlarmCore(
      alarm,
      occurrenceTime,
      timestamp,
      response,
      rescheduleAlarm
    )
  }

const handleMarkParticipantResponseForGroupAlarmPendingAction =
  async actionData => {
    console.tron.log(
      'Handling mark participant response for group alarm pending action'
    )
    const {
      alarm,
      response,
      rescheduleAlarm,
      updateAlarmResponseStatus,
      occurrenceTime,
      timestamp
    } = actionData
    await AlarmUtils.markParticipantResponseForSimultaneousAlarmCore(
      alarm,
      occurrenceTime,
      timestamp,
      response,
      updateAlarmResponseStatus,
      rescheduleAlarm
    )
  }

const handleSetParticipantAlarmResponsePendingAction = async (
  alarm,
  participantResponse
) => {
  console.tron.log('Handling set participant alarm response pending action')
  await AlarmUtils.setBackupResponseStatusForAlarmCore(
    alarm,
    alarm.responseStatus,
    participantResponse,
    alarm.order,
    true
  )
}

const handleSetRecipientAlarmResponsePendingAction = async (
  alarm,
  participantResponse
) => {
  console.tron.log('Handling set participant alarm response pending action')
  await AlarmUtils.setRecipientResponseStatusForAlarmCore(
    alarm,
    participantResponse,
    true
  )
}

const handleSetPastAlarmOccurrenceResponsePendingAction = async (
  alarm,
  occurrenceTime,
  timestamp,
  response
) => {
  console.tron.log('Handling set past alarm occurrence response pending action')
  await AlarmUtils.setPastAlarmOccurrenceResponse(
    alarm,
    occurrenceTime,
    timestamp,
    response
  )
}

const handleAddAlarmPendingAction = async alarm => {
  console.tron.log('Handling add alarm pending action')
  const nextDate = AlarmUtils.getNextDateForAlarm(
    alarm.date,
    alarm.endDate,
    alarm.repeatType,
    alarm.repeat,
    alarm.creatorTimezone
  )
  if (Date.now() > nextDate) {
    alarm.status = false
  }

  await AlarmUtils.addAlarm(alarm)

  if (alarm.status) {
    sendRemoteNotificationsForNewAlarm(alarm)
  }
}

const handleDeleteAlarmPendingAction = async alarm => {
  console.tron.log('Handling delete alarm pending action')
  await AlarmUtils.deleteAlarm(alarm)
}

const handleEditAlarmPendingAction = async (
  editedAlarm,
  prevAlarm,
  removeSubscription = true
) => {
  console.tron.log('Handling edit alarm pending action')
  const nextDate = AlarmUtils.getNextDateForAlarm(
    editedAlarm.date,
    editedAlarm.endDate,
    editedAlarm.repeatType,
    editedAlarm.repeat,
    editedAlarm.creatorTimezone
  )
  if (Date.now() > nextDate) {
    editedAlarm.status = false
  }

  await AlarmUtils.editAlarm(editedAlarm, prevAlarm, removeSubscription)

  // Schedule a notification if alarm is enabled. If the alarm date is in past, the alarm status
  // will be disabled and no notification will be scheduled
  if (editedAlarm.status) {
    sendRemoteNotificationsForEditAlarm(editedAlarm, prevAlarm)
  }
}

const handleAddChecklistPendingAction = checklist => {
  AlarmUtils.addChecklist(checklist)
}

const handleEditChecklistPendingAction = checklist => {
  AlarmUtils.editChecklist(checklist)
}

const handleRemoveChecklistPendingAction = checklistId => {
  AlarmUtils.removeChecklist(checklistId)
}

const handleEnableDisableAlarmPendingAction = async (
  alarm,
  timestamp,
  alarmStatus
) => {
  console.tron.log('Handling enable/disable alarm pending action')
  await updateAlarmStatusCore(alarm, timestamp, alarmStatus)
}

const handleUpdateParticipantAlarmPreReminderDurationPendingAction = async (
  alarm,
  preReminderDuration
) => {
  console.tron.log(
    'Handling update participant alarm pre-reminder duration pending action'
  )
  const firebaseUpdateObj =
    AlarmUtils.createFirebaseObjForUpdatingParticipantAlarmPreReminderDuration(
      alarm,
      preReminderDuration
    )
  await GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const handleUpdateParticipantAlarmRingerSettingsPendingAction = async (
  alarm,
  ringerSettings
) => {
  console.tron.log(
    'Handling update participant alarm ringer settings pending action'
  )
  const firebaseUpdateObj =
    AlarmUtils.createFirebaseObjForUpdatingParticipantAlarmRingerSettings(
      alarm,
      ringerSettings
    )
  await GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const handleUpdateAlarmPreReminderPendingAction = async (
  alarm,
  ringerSettings
) => {
  console.tron.log('Handling update alarm pre reminder pending action')
  const firebaseUpdateObj =
    AlarmUtils.createFirebaseObjForUpdatingAlarmPreReminderDuration(
      alarm,
      ringerSettings
    )
  await GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const handleUpdateAlarmRingerSettingsPendingAction = async (
  alarm,
  ringerSettings
) => {
  console.tron.log('Handling update alarm ringer settings pending action')
  const firebaseUpdateObj =
    AlarmUtils.createFirebaseObjForUpdatingAlarmRingerSettings(
      alarm,
      ringerSettings
    )
  await GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const handleUpdateGlobalAlarmRingerSettingsPendingAction =
  async ringerSettings => {
    console.tron.log(
      'Handling update global alarm ringer settings pending action'
    )

    await GlobalConfig.rootFirebaseRef
      .child('userSettings')
      .child(GlobalConfig.uid)
      .update(ringerSettings)
  }

const handleUpdateRingParticipantAlarmsByDefaultSettingPendingAction =
  async ringParticipantAlarmsByDefault => {
    console.tron.log(
      'Handling update ring participant alarms by default setting pending action'
    )

    await GlobalConfig.rootFirebaseRef
      .child('userSettings')
      .child(GlobalConfig.uid)
      .update({ ringParticipantAlarmsByDefault })
  }

const handleAddUpdateRingOnEarphoneOnlySoftPendingAction =
  async ringOnEarphoneOnly => {
    console.tron.log(
      'Handling update ring on earphones only setting pending action'
    )

    await GlobalConfig.rootFirebaseRef
      .child('userSettings')
      .child(GlobalConfig.uid)
      .update({ ringOnEarphoneOnly })
  }

const handleUpdateGestureToDismissAlarmSoftPendingAction =
  async gestureToDismissAnAlarm => {
    console.tron.log(
      'Handling update gesture to dismiss an alarm setting pending action'
    )

    await GlobalConfig.rootFirebaseRef
      .child('userSettings')
      .child(GlobalConfig.uid)
      .update({ gestureToDismissAnAlarm })
  }

const handleUpdateNotificationSettingsUserSettingsSoftPendingAction =
  async notificationSettings => {
    console.tron.log('Handling update notification settings pending action')

    await GlobalConfig.rootFirebaseRef
      .child('userSettings')
      .child(GlobalConfig.uid)
      .update({ notificationSettings })
  }

const handleUpdateSpecifyTimezoneForAlarm = async specifyTimezoneForAlarm => {
  console.tron.log('Handling update specify timezone for alarm pending action')

  await GlobalConfig.rootFirebaseRef
    .child('userSettings')
    .child(GlobalConfig.uid)
    .update({ specifyTimezoneForAlarm })
}
const handleUpdateGraduallyIncreaseVolumeSoftPendingAction =
  async graduallyIncreaseVolume => {
    console.tron.log(
      'Handling update gradually increase volume setting pending action'
    )

    await GlobalConfig.rootFirebaseRef
      .child('userSettings')
      .child(GlobalConfig.uid)
      .update({ graduallyIncreaseVolume })
  }

const sendRemoteNotificationsForNewAlarm = alarm => {
  const participants = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  )
  const notificationKey =
    alarm.type === Constants.AlarmTypes.RECIPIENT
      ? Constants.NotificationKeys.AddedAsRecipientNotification
      : alarm.type === Constants.AlarmTypes.CASCADING
      ? Constants.NotificationKeys.AddedAsBackupNotification
      : Constants.NotificationKeys.AddedAsParticipantNotification
  participants.forEach(participant => {
    console.tron.log(
      'Sending remote notification for new alarm to ' + participant.name
    )
    const notificationInfo = getNotificationInfoForBackupAlarmAdded(
      alarm,
      participant.order || 0
    )
    NotificationManager.sendRemoteNotification(
      participant.id,
      notificationKey,
      notificationInfo
    )
  })
}

const incrementShareableAlarmLinkCount = alarmType => (dispatch, getState) => {
  const linksCount = objGet(
    getState().userInfo,
    `shareableAlarmLinksCount.${alarmType}`,
    0
  )
  GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('shareableAlarmLinksCount')
    .child(alarmType)
    .set(linksCount + 1)
}

const incrementAlarmsSavedToCalendarCount = () => (dispatch, getState) => {
  const alarmsSavedToCalendarCount =
    getState().userInfo.alarmsSavedToCalendarCount
  GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('alarmsSavedToCalendarCount')
    .set(alarmsSavedToCalendarCount + 1)
}

const incrementInstantAlarmsCount = () => (dispatch, getState) => {
  const instantAlarmsCount = getState().userInfo.instantAlarmsCount
  GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('instantAlarmsCount')
    .set(instantAlarmsCount + 1)
}

const incrementOwnAlarmsCount =
  (alarmType, countType) => (dispatch, getState) => {
    const alarmsCount = objGet(
      getState().userInfo.alarmsCount,
      `ownAlarms.${alarmType}.${countType}`,
      0
    )
    GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('alarmsCount')
      .child('ownAlarms')
      .child(alarmType)
      .child(countType)
      .set(alarmsCount + 1)
  }

const addChecklist = checklist => (dispatch, getState) => {
  dispatch(addLocalChecklist(checklist, false))

  if (
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase
  ) {
    AlarmUtils.addChecklist(checklist)
  } else {
    dispatch(addAddChecklistPendingAction(checklist))
  }
}

const updateChecklist = checklist => (dispatch, getState) => {
  dispatch(updateLocalChecklist(checklist))

  if (
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase
  ) {
    AlarmUtils.editChecklist(checklist)
  } else {
    dispatch(addEditChecklistPendingAction(checklist))
  }
}

const removeChecklist = checklistId => (dispatch, getState) => {
  FirebaseManager.unbindFirebaseEventHandlerForValueChanged(
    'App',
    GlobalConfig.rootFirebaseRef.child('checklists').child(checklistId)
  )

  dispatch(removeLocalChecklist(checklistId))

  if (
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase
  ) {
    AlarmUtils.removeChecklist(checklistId)
  } else {
    dispatch(addRemoveChecklistPendingAction(checklistId))
  }
}

const addAlarm = alarm => (dispatch, getState) => {
  dispatch(addLocalAlarm(alarm))

  if (alarm.creationMode === Constants.AlarmCreationModes.INSTANT_ALARM) {
    dispatch(incrementInstantAlarmsCount())
  } else {
    dispatch(
      incrementOwnAlarmsCount(alarm.type, Constants.ALARMS_COUNT_TYPES.NEW)
    )
  }

  // We still want to schedule the local notification for the alarm such that the alarm rings on the creator
  // phone even if it has not been synced online
  if (alarm.status) {
    scheduleNotificationForOwnAlarm(alarm)
  }

  if (
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase
  ) {
    AlarmUtils.addAlarm(alarm)
    // Schedule a notification if alarm is enabled. If the alarm date is in past, the alarm status
    // will be disabled and no notification will be scheduled
    if (alarm.status) {
      sendRemoteNotificationsForNewAlarm(alarm)
    }
  } else {
    dispatch(addAddAlarmPendingAction(alarm))
  }

  const numParticipants = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  ).length
  const hasParticipants = numParticipants > 0

  var date = moment(alarm.date)
  var dayOfMonth = date.date()
  var dayOfMonthString = dayOfMonth.toString()

  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.NEW_ALARM, {
    [Constants.UserAnalyticsEventParameters.ALARM_TYPE]: alarm.type,
    [Constants.UserAnalyticsEventParameters.HAS_PARTICIPANTS]:
      hasParticipants.toString(),
    [Constants.UserAnalyticsEventParameters.TIME_BETWEEN_PARTICIPANT_ALERTS]:
      alarm.cascadingAlarmInterval || 0,
    [Constants.UserAnalyticsEventParameters.REMIND_ME_AFTER]:
      alarm.recipientAlarmInterval || 0,
    [Constants.UserAnalyticsEventParameters.REPEAT_TYPE]:
      alarm.repeatType || Constants.RepeatTypes.NO_REPEAT,
    [Constants.UserAnalyticsEventParameters.DAY_OF_MONTH]: dayOfMonthString,
    [Constants.UserAnalyticsEventParameters.HAS_END_DATE]:
      (!!alarm.endDate).toString(),
    [Constants.UserAnalyticsEventParameters.ALARM_CREATION_SOURCE]:
      alarm.source,
    [Constants.UserAnalyticsEventParameters.HAS_CATEGORY]: !!alarm.source,
    [Constants.UserAnalyticsEventParameters.HAS_PRE_REMINDER]:
      (!!alarm.preReminderDuration).toString()
  })

  if (hasParticipants) {
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.NEW_ALARM_WITH_PARTICIPANTS,
      {
        [Constants.UserAnalyticsEventParameters.NUM_PARTICIPANTS]:
          numParticipants
      }
    )
  }
}

const sendRemoteNotificationsForEditAlarm = (editedAlarm, prevAlarm) => {
  // If time has has changed, send remote notification to all the backups in the alarm
  // and if user has also changed some backups as part of the edit, then send remote
  // notification to the no longer backups so that the scheduled local notifications
  // for those alarms can be cancelled
  // Else if user has not changed the time, then only send remote notifications to changed
  // backups if any
  if (editedAlarm.type !== Constants.AlarmTypes.RECIPIENT) {
    sendRemoteNotificationsToNewBackupsIfNeeded(editedAlarm, prevAlarm)
    sendRemoteNotificationsToNoLongerBackupsIfNeeded(editedAlarm, prevAlarm)
    sendRemoteNotificationsToExistingBackupsForChangesIfNeeded(
      editedAlarm,
      prevAlarm
    )
  } else if (editedAlarm.type === Constants.AlarmTypes.RECIPIENT) {
    if (editedAlarm.recipient.id !== prevAlarm.recipient.id) {
      const recipientNotificationInfo = getNotificationInfoForBackupAlarmAdded(
        editedAlarm,
        0,
        Constants.ParticipantTypes.CONTACT
      )
      NotificationManager.sendRemoteNotification(
        editedAlarm.recipient.id,
        Constants.NotificationKeys.AddedAsRecipientNotification,
        recipientNotificationInfo
      )

      const creatorNotificationInfo = {
        alarmId: editedAlarm.id,
        type: Constants.NotificationTypes.BACKUP_ALARM_REMOVED,
        alarmCreatorName: editedAlarm.creatorName,
        alarmName: prevAlarm.name
      }

      console.tron.log(
        'Sending remote notification for no longer recipient to ' +
          prevAlarm.recipient.name
      )
      NotificationManager.sendRemoteNotification(
        prevAlarm.recipient.id,
        Constants.NotificationKeys.RemovedAsRecipientNotification,
        creatorNotificationInfo
      )
    } else {
      if (
        AlarmUtils.getNextDateForAlarm(
          editedAlarm.date,
          editedAlarm.endDate,
          editedAlarm.repeatType,
          editedAlarm.repeat,
          editedAlarm.creatorTimezone
        ) !==
          AlarmUtils.getNextDateForAlarm(
            prevAlarm.date,
            prevAlarm.endDate,
            prevAlarm.repeatType,
            prevAlarm.repeat,
            prevAlarm.creatorTimezone
          ) ||
        editedAlarm.repeatType !== prevAlarm.repeatType ||
        editedAlarm.repeat !== prevAlarm.repeat ||
        editedAlarm.recipientAlarmInterval !== prevAlarm.recipientAlarmInterval
      ) {
        const previousBackupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
          prevAlarm,
          prevAlarm.order
        )
        const newBackupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
          editedAlarm,
          editedAlarm.order
        )

        // Fail safe check to make sure that the time has actually changed for the participant
        if (previousBackupAlarmDate !== newBackupAlarmDate) {
          console.tron.log(
            'Sending remote notification for time change to ' +
              editedAlarm.recipient.name
          )
          const notificationInfo = {
            alarmId: editedAlarm.id,
            type: Constants.NotificationTypes.BACKUP_ALARM_ADDED,
            alarmType: editedAlarm.type,
            alarmCreatorName: editedAlarm.creatorName,
            alarmName: editedAlarm.name,
            previousAlarmDate: previousBackupAlarmDate.toString(),
            newAlarmDate: newBackupAlarmDate.toString()
          }
          NotificationManager.sendRemoteNotification(
            editedAlarm.recipient.id,
            Constants.NotificationKeys.AlarmTimeChangedNotification,
            notificationInfo
          )
        }
      }

      if (editedAlarm.name !== prevAlarm.name) {
        console.tron.log(
          'Sending remote notification for name change to ' +
            editedAlarm.recipient.name
        )

        const notificationInfo = {
          alarmId: editedAlarm.id,
          type: Constants.NotificationTypes.BACKUP_ALARM_NAME_CHANGED,
          alarmType: editedAlarm.type,
          alarmCreatorName: editedAlarm.creatorName,
          newAlarmName: editedAlarm.name,
          prevAlarmName: prevAlarm.name
        }
        NotificationManager.sendRemoteNotification(
          editedAlarm.recipient.id,
          Constants.NotificationKeys.AlarmNameChangedNotification,
          notificationInfo
        )
      }

      if (editedAlarm.notes !== prevAlarm.notes) {
        console.tron.log(
          'Sending remote notification for notes change to ' +
            editedAlarm.recipient.name
        )

        const notificationInfo = {
          alarmId: editedAlarm.id,
          type: Constants.NotificationTypes.BACKUP_ALARM_NOTES_CHANGED,
          alarmType: editedAlarm.type,
          alarmCreatorName: editedAlarm.creatorName,
          alarmName: editedAlarm.name
        }
        NotificationManager.sendRemoteNotification(
          editedAlarm.recipient.id,
          Constants.NotificationKeys.AlarmNotesChangedNotification,
          notificationInfo
        )
      }
    }
  }

  if (prevAlarm.link && prevAlarm.link.subscribers) {
    const alarmEditedForSubscribers = AlarmUtils.isAlarmEditedForSubscribers(
      prevAlarm,
      editedAlarm
    )

    if (alarmEditedForSubscribers) {
      const alarmSubscribers = objGet(prevAlarm, 'link.subscribers', {})
      Object.keys(alarmSubscribers).forEach(alarmSubscriber => {
        const subscriberAlarmId = alarmSubscribers[alarmSubscriber]
        NotificationManager.sendRemoteNotification(
          alarmSubscriber,
          Constants.NotificationKeys.SubscribedAlarmEditedNotification,
          {
            type: Constants.NotificationTypes.SUBSCRIBED_ALARM_EDITED,
            subscriberAlarmId: subscriberAlarmId,
            alarmName: prevAlarm.name,
            alarmCreatorName: prevAlarm.creatorName
          }
        )
      })
    }
  }
}

// Find the intersection between the old and new members and send time change message to them
// Send new backup alarm message to the new members
const sendRemoteNotificationsToExistingBackupsForChangesIfNeeded = (
  editedAlarm,
  prevAlarm
) => {
  const currentParticipants = editedAlarm.backupGroup
    ? editedAlarm.backupGroup.members
    : editedAlarm.backupContacts
  const previousParticipants = prevAlarm.backupGroup
    ? prevAlarm.backupGroup.members
    : prevAlarm.backupContacts
  const commonParticipants = arrayIntersectionWith(
    currentParticipants,
    previousParticipants,
    (participant1, participant2) => {
      return participant1.id === participant2.id
    }
  )
  commonParticipants.forEach(participant => {
    const previousParticipant = Utils.getObjectWithId(
      previousParticipants,
      participant.id
    )
    if (
      AlarmUtils.getNextDateForAlarm(
        editedAlarm.date,
        editedAlarm.endDate,
        editedAlarm.repeatType,
        editedAlarm.repeat,
        editedAlarm.creatorTimezone
      ) !==
        AlarmUtils.getNextDateForAlarm(
          prevAlarm.date,
          prevAlarm.endDate,
          prevAlarm.repeatType,
          prevAlarm.repeat,
          prevAlarm.creatorTimezone
        ) ||
      editedAlarm.repeatType !== prevAlarm.repeatType ||
      editedAlarm.repeat !== prevAlarm.repeat ||
      editedAlarm.cascadingAlarmInterval !== prevAlarm.cascadingAlarmInterval ||
      participant.order !== previousParticipant.order
    ) {
      const previousBackupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
        prevAlarm,
        previousParticipant.order
      )
      const newBackupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
        editedAlarm,
        participant.order
      )

      // Fail safe check to make sure that the time has actually changed for the participant
      if (previousBackupAlarmDate !== newBackupAlarmDate) {
        console.tron.log(
          'Sending remote notification for time change to ' + participant.name
        )
        const notificationInfo = {
          alarmId: editedAlarm.id,
          type: Constants.NotificationTypes.BACKUP_ALARM_ADDED,
          alarmType: editedAlarm.type,
          alarmCreatorName: editedAlarm.creatorName,
          alarmName: editedAlarm.name,
          previousAlarmDate: previousBackupAlarmDate.toString(),
          newAlarmDate: newBackupAlarmDate.toString()
        }
        NotificationManager.sendRemoteNotification(
          participant.id,
          Constants.NotificationKeys.AlarmTimeChangedNotification,
          notificationInfo
        )
      }
    }

    if (editedAlarm.name !== prevAlarm.name) {
      console.tron.log(
        'Sending remote notification for name change to ' + participant.name
      )

      const notificationInfo = {
        alarmId: editedAlarm.id,
        type: Constants.NotificationTypes.BACKUP_ALARM_NAME_CHANGED,
        alarmType: editedAlarm.type,
        alarmCreatorName: editedAlarm.creatorName,
        newAlarmName: editedAlarm.name,
        prevAlarmName: prevAlarm.name
      }
      NotificationManager.sendRemoteNotification(
        participant.id,
        Constants.NotificationKeys.AlarmNameChangedNotification,
        notificationInfo
      )
    }

    if (editedAlarm.notes !== prevAlarm.notes) {
      console.tron.log(
        'Sending remote notification for notes change to ' + participant.name
      )

      const notificationInfo = {
        alarmId: editedAlarm.id,
        type: Constants.NotificationTypes.BACKUP_ALARM_NOTES_CHANGED,
        alarmType: editedAlarm.type,
        alarmCreatorName: editedAlarm.creatorName,
        alarmName: editedAlarm.name
      }
      NotificationManager.sendRemoteNotification(
        participant.id,
        Constants.NotificationKeys.AlarmNotesChangedNotification,
        notificationInfo
      )
    }
  })
}

const sendRemoteNotificationsToNewBackupsIfNeeded = (
  editedAlarm,
  prevAlarm
) => {
  let newParticipants

  if (!prevAlarm.backupGroup && editedAlarm.backupGroup) {
    newParticipants = editedAlarm.backupGroup.members
  } else if (
    prevAlarm.backupContacts &&
    editedAlarm.backupContacts &&
    prevAlarm.backupContacts.length === 0 &&
    editedAlarm.backupContacts.length > 0
  ) {
    newParticipants = editedAlarm.backupContacts
  } else {
    const currentParticipants = editedAlarm.backupGroup
      ? editedAlarm.backupGroup.members
      : editedAlarm.backupContacts
    const previousParticipants = prevAlarm.backupGroup
      ? prevAlarm.backupGroup.members
      : prevAlarm.backupContacts
    newParticipants = arrayDifferenceWith(
      currentParticipants,
      previousParticipants,
      (participant1, participant2) => {
        return participant1.id === participant2.id
      }
    )
  }

  newParticipants.forEach(participant => {
    const notificationInfo = getNotificationInfoForBackupAlarmAdded(
      editedAlarm,
      participant.order
    )
    console.tron.log(
      'Sending remote notification for new participant to ' + participant.name
    )
    NotificationManager.sendRemoteNotification(
      participant.id,
      editedAlarm.type === Constants.AlarmTypes.CASCADING
        ? Constants.NotificationKeys.AddedAsBackupNotification
        : Constants.NotificationKeys.AddedAsParticipantNotification,
      notificationInfo
    )
  })
}

const getNotificationInfoForBackupAlarmAdded = (alarm, participantOrder) => {
  const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
    alarm,
    participantOrder
  )
  return {
    alarmId: alarm.id,
    type: Constants.NotificationTypes.BACKUP_ALARM_ADDED,
    alarmType: alarm.type,
    alarmCreatorName: alarm.creatorName,
    alarmName: alarm.name,
    alarmDate: backupAlarmDate.toString(),
    alarmCreationMode: alarm.creationMode
  }
}

const getNotificationInfoForParticipantAlarmEnabled = (
  alarm,
  participantOrder
) => {
  const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
    alarm,
    participantOrder
  )
  return {
    alarmId: alarm.id,
    type: Constants.NotificationTypes.PARTICIPANT_ALARM_ENABLED,
    alarmType: alarm.type,
    alarmCreatorName: alarm.creatorName,
    alarmName: alarm.name,
    alarmDate: backupAlarmDate.toString()
  }
}

const getNotificationInfoForParticipantAlarmDisabled = (
  alarm,
  participantOrder
) => {
  const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
    alarm,
    participantOrder
  )
  return {
    alarmId: alarm.id,
    type: Constants.NotificationTypes.PARTICIPANT_ALARM_DISABLED,
    alarmType: alarm.type,
    alarmCreatorName: alarm.creatorName,
    alarmName: alarm.name,
    alarmDate: backupAlarmDate.toString()
  }
}

const sendRemoteNotificationsToNoLongerBackupsIfNeeded = (
  editedAlarm,
  prevAlarm
) => {
  // Using prevAlarm.name here in case user has also changed the alarm name but the old
  // backups will still know the old name only
  const notificationInfo = {
    alarmId: editedAlarm.id,
    type: Constants.NotificationTypes.BACKUP_ALARM_REMOVED,
    alarmCreatorName: editedAlarm.creatorName,
    alarmName: prevAlarm.name
  }
  const currentParticipants = editedAlarm.backupGroup
    ? editedAlarm.backupGroup.members
    : editedAlarm.backupContacts
  const previousParticipants = prevAlarm.backupGroup
    ? prevAlarm.backupGroup.members
    : prevAlarm.backupContacts
  const noLongerParticipants = arrayDifferenceWith(
    previousParticipants,
    currentParticipants,
    (participant1, participant2) => {
      return participant1.id === participant2.id
    }
  )
  noLongerParticipants.forEach(participant => {
    console.tron.log(
      'Sending remote notification for no longer participant to ' +
        participant.name
    )
    NotificationManager.sendRemoteNotification(
      participant.id,
      editedAlarm.type === Constants.AlarmTypes.CASCADING
        ? Constants.NotificationKeys.RemovedAsBackupNotification
        : Constants.NotificationKeys.RemovedAsParticipantNotification,
      notificationInfo
    )
  })
}

const editTimeAndRestoreAlarm = (alarmId, newDate) => (dispatch, getState) => {
  const recentlyDeletedAlarmsSelector = makeRecentlyDeletedAlarmSelector()
  const prevAlarm = recentlyDeletedAlarmsSelector(getState(), { alarmId })

  if (isEmpty(prevAlarm)) {
    NavigationUtils.showTransientAlert({
      message: I18n.t('alarmDoesntExist')
    })
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
      [Constants.UserAnalyticsEventParameters.SOURCE]: 'editTimeAndRestoreAlarm'
    })
    return
  }

  let alarmEndDate = GlobalConfig.defaultAlarmEndDate
  if (prevAlarm.endDate > newDate) {
    alarmEndDate = prevAlarm.endDate
  }

  const updatedAlarm = {
    ...prevAlarm,
    date: newDate,
    endDate: alarmEndDate
  }

  const participants = AlarmUtils.getAlarmParticipants(
    updatedAlarm.type,
    updatedAlarm.backupGroup,
    updatedAlarm.backupContacts,
    updatedAlarm.recipient
  )
  const filteredParticipants = participants.filter(participant => {
    return participant.responseStatus !== Constants.REJECT_ALARM
  })
  filteredParticipants.forEach(participant => {
    const notificationInfo = getNotificationInfoForBackupAlarmAdded(
      updatedAlarm,
      participant.order
    )
    const notificationKey = Constants.NotificationKeys.ADD_BACKUP_ALARM
    NotificationManager.sendRemoteNotification(
      participant.id,
      notificationKey,
      notificationInfo
    )
  })

  return AlarmUtils.restoreAlarm(updatedAlarm)
}

const editTimeAndEnableAlarm = (alarmId, newDate) => (dispatch, getState) => {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const prevAlarm = ownAlarmSelector(getState(), { alarmId })
  let backupGroup = null
  let backupContacts = []
  let recipient = null

  if (isEmpty(prevAlarm)) {
    NavigationUtils.showTransientAlert({
      message: I18n.t('alarmDoesntExist')
    })
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
      [Constants.UserAnalyticsEventParameters.SOURCE]: 'editTimeAndEnableAlarm'
    })
    return
  }

  if (prevAlarm.backupGroup && prevAlarm.backupGroup.members) {
    let members = prevAlarm.backupGroup.members.map(member => {
      return {
        ...member,
        responseStatus: Constants.ALARM_RESPONSE_PENDING,
        seenOn: null,
        deliveredOn: null
      }
    })
    backupGroup = {
      ...prevAlarm.backupGroup,
      members
    }
  } else if (prevAlarm.backupContacts && prevAlarm.backupContacts.length > 0) {
    backupContacts = prevAlarm.backupContacts.map(backupContact => {
      return {
        ...backupContact,
        responseStatus: Constants.ALARM_RESPONSE_PENDING,
        seenOn: null,
        deliveredOn: null
      }
    })
  } else if (prevAlarm.recipient) {
    recipient = {
      ...prevAlarm.recipient,
      responseStatus: Constants.ALARM_RESPONSE_PENDING,
      seenOn: null,
      deliveredOn: null
    }
  }

  let alarmEndDate = GlobalConfig.defaultAlarmEndDate
  if (prevAlarm.endDate > newDate) {
    alarmEndDate = prevAlarm.endDate
  }

  const updatedAlarm = {
    ...prevAlarm,
    status: true,
    date: newDate,
    endDate: alarmEndDate,
    backupGroup,
    backupContacts,
    recipient,
    lastUpdatedAt: Date.now()
  }

  dispatch(editAlarm(updatedAlarm, false))
}

const updateSubscriberAlarm = alarmId => (dispatch, getState) => {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const alarm = ownAlarmSelector(getState(), { alarmId })

  const subscribedAlarmSystemAlertsSelector =
    makeSubscribedAlarmSystemAlertsSelector()
  const subscribedAlarmEditedSystemAlerts = subscribedAlarmSystemAlertsSelector(
    getState(),
    { alarmId }
  ).filter(alert => alert.type === Constants.AlertTypes.SUBSCRIBED_ALARM_EDITED)

  subscribedAlarmEditedSystemAlerts.forEach(alert =>
    dispatch(removeSystemAlert(alert.id))
  )

  if (isEmpty(alarm)) {
    NavigationUtils.showTransientAlert({
      message: I18n.t('alarmDoesntExist')
    })
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
      [Constants.UserAnalyticsEventParameters.SOURCE]: 'updateSubscriberAlarm'
    })
    return
  }

  const parentAlarmId = alarm.parentLink.alarmId

  if (!parentAlarmId) {
    LogUtils.logError(
      new Error("Can't update subscribed alarm"),
      'Parent alarm id not available',
      { parentAlarmId, alarmId: alarm.id }
    )
    return
  }

  AlarmUtils.retrieveAlarmByAlarmId(parentAlarmId, false).then(parentAlarm => {
    // If the parent link is created relative to the timezone, then convert the time to user's timezone first
    const relativeTimezone = objGet(parentAlarm, 'link.relativeTimezone', false)
    let alarmDate = parentAlarm.date
    if (relativeTimezone) {
      alarmDate = DateTimeUtils.getDateFromFormattedDateString(
        parentAlarm.dateString,
        RNLocalize.getTimeZone()
      )
    }
    const currDate = Date.now()
    const editedAlarm = {
      id: alarmId,
      name: parentAlarm.name,
      date: alarmDate,
      dateString: parentAlarm.dateString,
      status: alarm.status,
      creator: alarm.creator,
      creatorName: alarm.creatorName,
      creatorMobileNumber: alarm.creatorMobileNumber,
      creatorTimezoneOffset: alarm.creatorTimezoneOffset,
      timezoneSetting:
        alarm.timezoneSetting || Constants.TIMEZONE_SETTINGS.DEVICE,
      preReminderDuration:
        alarm.preReminderDuration || GlobalConfig.defaultPreReminderDuration,
      creatorTimezone: alarm.creatorTimezone,
      notes: parentAlarm.notes,
      repeat: parentAlarm.repeat,
      repeatType: parentAlarm.repeatType,
      type: parentAlarm.type,
      cascadingAlarmInterval: parentAlarm.cascadingAlarmInterval || null,
      recipientAlarmInterval: parentAlarm.recipientAlarmInterval || null,
      backupGroup: alarm.backupGroup || null,
      backupContacts: alarm.backupContacts || [],
      lastUpdatedAt: currDate,
      recipient: alarm.recipient || null,
      historyStartDate: currDate,
      release: GlobalConfig.release,
      ringerSettings: alarm.ringerSettings,
      creationMode: alarm.creationMode,
      endDate: parentAlarm.endDate || GlobalConfig.defaultAlarmEndDate,
      source: alarm.source,
      link: null,
      parentLink: alarm.parentLink
    }

    dispatch(editAlarm(editedAlarm, false))
  })
}

// eslint-disable-next-line no-unused-vars
const deleteSubscriberAlarm = alarmId => (dispatch, getState) => {
  // Just a wrapper over delete alarm for now. Keeping a separate function in case
  // more functionality is needed later on.

  // System alerts for this alarm are removed in deleteAlarm as user may
  // delete the alarm from the delete icon also.
  dispatch(deleteAlarm(alarmId))
}

const removeSubscribedAlarmSystemAlerts = alarmId => (dispatch, getState) => {
  const subscribedAlarmSystemAlertsSelector =
    makeSubscribedAlarmSystemAlertsSelector()
  const subscribedAlarmSystemAlerts = subscribedAlarmSystemAlertsSelector(
    getState(),
    { alarmId }
  )

  subscribedAlarmSystemAlerts.forEach(alert =>
    dispatch(removeSystemAlert(alert.id))
  )
}

// Deleting alarm history is same as moving the alarm to current date
const deleteAlarmHistory = alarmId => (dispatch, getState) => {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const prevAlarm = ownAlarmSelector(getState(), { alarmId })

  if (isEmpty(prevAlarm)) {
    NavigationUtils.showTransientAlert({
      message: I18n.t('alarmDoesntExist')
    })
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
      [Constants.UserAnalyticsEventParameters.SOURCE]: 'deleteAlarmHistory'
    })
    return
  }

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(prevAlarm)
  const currDate = Date.now()

  const updatedAlarm = {
    ...prevAlarm,
    date: currAlarmDate,
    firstAlarmDate: currAlarmDate,
    historyStartDate: currDate,
    lastUpdatedAt: currDate
  }

  dispatch(editAlarm(updatedAlarm, false))
}

const editAlarm =
  (editedAlarm, removeSubscription = true) =>
  (dispatch, getState) => {
    const ownAlarmSelector = makeOwnAlarmSelector()
    const prevAlarm = ownAlarmSelector(getState(), { alarmId: editedAlarm.id })
    dispatch(updateLocalAlarm(editedAlarm))
    dispatch(
      incrementOwnAlarmsCount(
        editedAlarm.type,
        Constants.ALARMS_COUNT_TYPES.EDIT
      )
    )

    // We still want to schedule the local notification for the alarm such that the alarm rings on the creator
    // phone even if it has not been synced online
    scheduleNotificationForOwnAlarm(editedAlarm, true)

    // Remove all the pending system alerts for this alarm
    const subscribedAlarmSystemAlertsSelector =
      makeSubscribedAlarmSystemAlertsSelector()
    const subscribedAlarmSystemAlerts = subscribedAlarmSystemAlertsSelector(
      getState(),
      { alarmId: editedAlarm.id }
    )

    subscribedAlarmSystemAlerts.forEach(alert =>
      dispatch(removeSystemAlert(alert.id))
    )

    if (
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    ) {
      AlarmUtils.editAlarm(editedAlarm, prevAlarm, removeSubscription)
      // Schedule a notification if alarm is enabled. If the alarm date is in past, the alarm status
      // will be disabled and no notification will be scheduled
      if (editedAlarm.status) {
        sendRemoteNotificationsForEditAlarm(editedAlarm, prevAlarm)
      }
    } else {
      dispatch(
        addEditAlarmPendingAction(editedAlarm, prevAlarm, removeSubscription)
      )
    }

    const hasParticipants =
      AlarmUtils.getAlarmParticipants(
        editedAlarm.type,
        editedAlarm.backupGroup,
        editedAlarm.backupContacts,
        editedAlarm.recipient
      ).length > 0

    var date = moment(editedAlarm.date)
    var dayOfMonth = date.date()
    var dayOfMonthString = dayOfMonth.toString()
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.EDIT_ALARM, {
      [Constants.UserAnalyticsEventParameters.ALARM_TYPE]: editedAlarm.type,
      [Constants.UserAnalyticsEventParameters.HAS_PARTICIPANTS]:
        hasParticipants.toString(),
      [Constants.UserAnalyticsEventParameters.TIME_BETWEEN_PARTICIPANT_ALERTS]:
        editedAlarm.cascadingAlarmInterval || 0,
      [Constants.UserAnalyticsEventParameters.REMIND_ME_AFTER]:
        editedAlarm.recipientAlarmInterval || 0,
      [Constants.UserAnalyticsEventParameters.REPEAT_TYPE]:
        editedAlarm.repeatType || Constants.RepeatTypes.NO_REPEAT,
      [Constants.UserAnalyticsEventParameters.DAY_OF_MONTH]: dayOfMonthString,
      [Constants.UserAnalyticsEventParameters.HAS_PRE_REMINDER]:
        (!!editedAlarm.preReminderDuration).toString()
    })
  }

const restoreAlarm = alarmId => (dispatch, getState) => {
  const recentlyDeletedAlarmsSelector = makeRecentlyDeletedAlarmSelector()
  const alarm = recentlyDeletedAlarmsSelector(getState(), { alarmId })
  const participants = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  )
  const filteredParticipants = participants.filter(participant => {
    return participant.responseStatus !== Constants.REJECT_ALARM
  })
  filteredParticipants.forEach(participant => {
    const notificationInfo = getNotificationInfoForBackupAlarmAdded(
      alarm,
      participant.order
    )

    const notificationKey = Constants.NotificationKeys.ADD_BACKUP_ALARM
    NotificationManager.sendRemoteNotification(
      participant.id,
      notificationKey,
      notificationInfo
    )
  })
  return AlarmUtils.restoreAlarm(alarm)
}

const updateAlarmStatus = (alarmId, alarmStatus) => (dispatch, getState) => {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const alarm = ownAlarmSelector(getState(), { alarmId })
  const updatedAlarm = {
    ...alarm,
    status: alarmStatus
  }
  const currDate = Date.now()
  dispatch(updateLocalAlarm(updatedAlarm))
  dispatch(addAlarmStatusChangeAction(alarm.id, currDate, alarmStatus))
  if (
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase
  ) {
    updateAlarmStatusCore(alarm, currDate, alarmStatus)
  } else {
    dispatch(addEnableDisableAlarmPendingAction(alarm, currDate, alarmStatus))
  }

  if (alarmStatus) {
    scheduleNotificationForOwnAlarm(updatedAlarm)
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_ENABLED, {})
  } else {
    AlarmManager.cancelNotifications(alarmId)
    NavigationUtils.showTransientAlert({
      message: I18n.t('disabledAlarm', { name: alarm.name }),
      duration: Constants.AlertDurations.LONG
    })
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_DISABLED, {})
  }
}

const updateAlarmStatusCore = (alarm, timestamp, alarmStatus) => {
  const participants = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  )
  const filteredParticipants = participants.filter(participant => {
    return participant.responseStatus !== Constants.REJECT_ALARM
  })
  filteredParticipants.forEach(participant => {
    const notificationInfo = alarmStatus
      ? getNotificationInfoForParticipantAlarmEnabled(alarm, participant.order)
      : getNotificationInfoForParticipantAlarmDisabled(alarm, participant.order)
    const notificationKey = alarmStatus
      ? Constants.NotificationKeys.ParticipantAlarmEnabled
      : Constants.NotificationKeys.ParticipantAlarmDisabled
    NotificationManager.sendRemoteNotification(
      participant.id,
      notificationKey,
      notificationInfo
    )
  })

  let firebaseUpdateObj = {}
  const responseId = GlobalConfig.rootFirebaseRef.push().key
  firebaseUpdateObj['alarms/' + alarm.id + '/status'] = alarmStatus
  firebaseUpdateObj[
    'alarmActions/' +
      alarm.id +
      '/alarmStatusChanges/' +
      responseId +
      '/timestamp'
  ] = timestamp
  firebaseUpdateObj[
    'alarmActions/' + alarm.id + '/alarmStatusChanges/' + responseId + '/status'
  ] = alarmStatus
  firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()
  return GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
}

const removeMetadataForAlarm = alarmId => {
  // We maintain how many times an alarm has been snoozed on Android
  // native side to support the auto-snooze configuration. When the
  // alarm is deleted, that data can be deleted as well for this alarm
  AlarmManager.removeMetadataForAlarm(alarmId)

  // Remove the current date mapping for alarm if any
  AlarmUtils.removeAlarmIdFromAlarmDatesMap(alarmId)
}

const deleteAlarm = alarmId => (dispatch, getState) => {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const alarm = ownAlarmSelector(getState(), { alarmId })
  // Cancel the local notification for the alarm on the device
  // We don't send remote notifications to the backups for the alarm
  // deletion to minimize the number of remote notifications received
  // by a backup
  AlarmManager.cancelNotifications(alarmId)

  // Remove the event handler once the alarm is deleted
  FirebaseManager.unbindFirebaseEventHandlerForValueChanged(
    'App',
    GlobalConfig.rootFirebaseRef.child('alarms').child(alarmId)
  )

  removeMetadataForAlarm(alarmId)

  if (isEmpty(alarm)) {
    NavigationUtils.showTransientAlert({
      message: I18n.t('unableToDeleteAlarm'),
      duration: Constants.AlertDurations.LONG
    })
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
      [Constants.UserAnalyticsEventParameters.SOURCE]: 'deleteAlarm'
    })
    return
  }

  if (alarm.parentLink) {
    dispatch(removeSubscribedAlarmSystemAlerts(alarmId))
  }

  dispatch(removeLocalAlarm(alarmId))

  const alarmCategoryForAlarmSelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = alarmCategoryForAlarmSelector(getState(), {
    alarmId: alarm.id
  })
  if (alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID) {
    dispatch(removeAlarmFromAlarmCategory(alarmCategory.id, alarmId))
  }

  if (
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase
  ) {
    AlarmUtils.deleteAlarm(alarm)
  } else {
    dispatch(addDeleteAlarmPendingAction(alarm))
  }

  // Don't show the alert on web as we use local storage on web and it can
  // be easily cleared leading to the alert being shown again and again
  if (
    Platform.OS !== 'web' &&
    alarm.creationMode !== Constants.AlarmCreationModes.QUICK_ALARM
  ) {
    StorageManager.retrieveData(['shownDeleteAlarmAlert']).then(result => {
      if (!result.shownDeleteAlarmAlert) {
        NavigationUtils.showAlert(
          I18n.t('restoreDeletedAlarms'),
          I18n.t('deleteAlarmAlert')
        )
        StorageManager.storeData({ shownDeleteAlarmAlert: 'true' })
      }
    })
  }

  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.DELETE_ALARM, {
    [Constants.UserAnalyticsEventParameters.ALARM_TYPE]: alarm.type,
    [Constants.UserAnalyticsEventParameters.REPEAT_TYPE]: alarm.repeatType
      ? alarm.repeatType
      : Constants.RepeatTypes.NO_REPEAT
  })
}

const deleteAllAlarms = (alarmIds, source) => (dispatch, getState) => {
  // Remove the event handlers once the alarm is deleted
  alarmIds.forEach(alarmId => {
    FirebaseManager.unbindFirebaseEventHandlerForValueChanged(
      'App',
      GlobalConfig.rootFirebaseRef.child('alarms').child(alarmId)
    )
    AlarmManager.cancelNotifications(alarmId)
    removeMetadataForAlarm(alarmId)
  })

  const alarms = objCompact(
    alarmIds.map(alarmId =>
      getState().alarms.alarms.find(alarm => alarm.id === alarmId)
    )
  )

  dispatch(removeLocalAlarms(alarmIds))
  AlarmUtils.deleteAllAlarms(alarms)
  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.DELETE_ALL_ALARMS, {
    [Constants.UserAnalyticsEventParameters.SOURCE]: source
  })
}

// eslint-disable-next-line no-unused-vars
const loadAppData = () => (dispatch, getState) => {
  dispatch(loadUserSettings())
  dispatch(loadAlarms())
  dispatch(loadBackupAlarms())
  dispatch(loadChecklists())
  dispatch(loadRecentlyDeletedAlarms())
  dispatch(loadContacts())
  dispatch(loadConnections())
  dispatch(loadGroups())
  dispatch(removeDeletedParticipantAlarms())
  dispatch(removeDeletedOwnAlarms())
  dispatch(loadUserInfo())
  dispatch(loadAlerts())
}

// eslint-disable-next-line no-unused-vars
const unloadAppData = () => (dispatch, getState) => {
  FirebaseManager.unbindFirebaseEventHandlers('App')
  FirebaseManager.unbindFirebaseEventHandlers('Conversation')
}

let existingAlarmsLoaded = false
let batchedExistingAlarmsUpdates = []
let batchedExistingAlarmsAcknowledgements = []
let batchedExistingAlarmsStatusChanges = []
let firebaseUpdateForUpdatingAlarmPreReminderDuration = {}

const loadAlarm = (alarmId, dispatch, getState) => {
  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    GlobalConfig.rootFirebaseRef.child('alarms').child(alarmId),
    snapshot => {
      // alarmData will be null when an alarm is deleted. So put console.tron.log with a check
      const alarmData = snapshot.val()

      const ownAlarmSelector = makeOwnAlarmSelector()
      const existingAlarm = ownAlarmSelector(getState(), { alarmId })

      // This code is also present in loadAlarms, keep in sync
      if (alarmData === null || alarmData.isDeleted) {
        if (!isEmpty(existingAlarm)) {
          // Local delete and cancel notification
          dispatch(removeLocalAlarm(alarmId))
          AlarmManager.cancelNotifications(alarmId)
        }
        return
      }

      const alarm = AlarmUtils.createAlarm(alarmData)

      if (alarm === undefined) {
        return
      }

      // Schedule the alarm every time. This is to counteract the
      // autostart setting on Android phones to at least have all the alarm scheduled
      // if the app has been open. Also sometimes, the alarm may be imported as part
      // of some notification action or remote fetch in which case the alarm may not
      // be properly rescheduled
      scheduleNotificationForOwnAlarm(alarm)

      // If there has been no change to the alarm, then skip it
      if (
        !isEmpty(existingAlarm) &&
        existingAlarm.persistedOnline !== false &&
        (!alarm.lastUpdatedAt ||
          alarm.lastUpdatedAt === existingAlarm.lastUpdatedAt)
      ) {
        return
      }

      // Add the alarm to the redux store if all the existing alarms have already been added
      // Existing alarms are added in one pass over all the alarms
      if (existingAlarmsLoaded) {
        dispatch(updateAlarm(alarmId, alarm))
      } else {
        batchedExistingAlarmsUpdates.push(alarm)
        // If the alarm doesn't have a preReminder duration defined,
        // add the default one. This is added in release 7.1.0
        // to migrate existing alarms.
        if (
          !Object.prototype.hasOwnProperty.call(alarm, 'preReminderDuration') ||
          typeof alarm.preReminderDuration !== 'number'
        ) {
          firebaseUpdateForUpdatingAlarmPreReminderDuration[
            'alarms/' + alarm.id + '/preReminderDuration'
          ] = GlobalConfig.defaultPreReminderDuration
          firebaseUpdateForUpdatingAlarmPreReminderDuration[
            'alarms/' + alarm.id + '/lastUpdatedAt'
          ] = Date.now()
        }
      }
    }
  )

  dispatch(loadAlarmActions(alarmId))

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    GlobalConfig.rootFirebaseRef
      .child('conversations/unseenMessages/' + alarmId)
      .child(GlobalConfig.uid),
    snapshot => {
      const currentScreen = getState().appState.currentScreen
      const unseenMessages = snapshot.val() || 0
      const currentUnseenMessagesForAlarm = objGet(
        getState().conversations.alarms,
        `[${alarmId}].unseenMessages`,
        0
      )
      if (currentUnseenMessagesForAlarm !== unseenMessages) {
        dispatch(updateUnseenMessagesForAlarm(alarmId, unseenMessages))
      }
      if (
        unseenMessages &&
        currentScreen === 'AlarmConversationScreen' + alarmId
      ) {
        dispatch(resetUnseenMessagesForAlarm(alarmId))
      }
    }
  )
}

// Load all the existing alarms and future updates to the alarms
const loadAlarms = () => (dispatch, getState) => {
  // eslint-disable-line no-unused-vars
  const uid = GlobalConfig.uid

  // Process all the alarms and setup notifications. Only the new alarms are added to redux
  // store in this event handler. All the existing ones are added in one pass in the below
  // event handler
  FirebaseManager.addFirebaseRefChildAdded(
    'App',
    GlobalConfig.rootFirebaseRef.child('userInfos').child(uid).child('alarms'),
    snapshot => {
      const alarmId = snapshot.key
      loadAlarm(alarmId, dispatch, getState)
    }
  )

  // Load all the existing alarms to the redux store in one pass to improve performance
  GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(uid)
    .child('alarms')
    .once('value')
    .then(alarmsSnapshot => {
      if (!alarmsSnapshot.exists()) {
        return Promise.resolve([])
      }
      const promises = []
      alarmsSnapshot.forEach(alarmSnapshot => {
        const alarmId = alarmSnapshot.key
        promises.push(
          GlobalConfig.rootFirebaseRef
            .child('alarms')
            .child(alarmId)
            .once('value')
        )
      })
      return Promise.all(promises)
    })
    // eslint-disable-next-line no-unused-vars
    .then(alarmSnapshots => {
      existingAlarmsLoaded = true
      dispatch(
        batchActions(
          updateAlarms(batchedExistingAlarmsUpdates),
          updateAlarmsAcknowledgements(batchedExistingAlarmsAcknowledgements),
          updateAlarmsStatusChanges(batchedExistingAlarmsStatusChanges)
        )
      )

      dispatch(handleExistingAlarmsLoaded())

      dispatch(
        rescheduleAlarmsBasedOnLatestAcknowledgementData(
          Object.keys(batchedExistingAlarmsAcknowledgements)
        )
      )

      batchedExistingAlarmsUpdates = []
      batchedExistingAlarmsAcknowledgements = []
      batchedExistingAlarmsStatusChanges = []

      if (!isEmpty(firebaseUpdateForUpdatingAlarmPreReminderDuration)) {
        GlobalConfig.rootFirebaseRef.update(
          firebaseUpdateForUpdatingAlarmPreReminderDuration
        )
        firebaseUpdateForUpdatingAlarmPreReminderDuration = {}
      }
    })
    .catch(error =>
      LogUtils.logError(error, 'Unable to retrieve alarms from database')
    )
}

const updateAlarmAcknowledgementsAndRescheduleAlarm =
  (alarmId, alarmAcknowledgements, cancelSnoozeAlarm = false) =>
  dispatch => {
    dispatch(addAlarmAcknowledgements(alarmId, alarmAcknowledgements))
    dispatch(
      rescheduleAlarmBasedOnLatestAcknowledgementData(
        alarmId,
        cancelSnoozeAlarm
      )
    )
  }

const rescheduleAlarmsBasedOnLatestAcknowledgementData =
  alarmIds =>
  (
    dispatch,
    getState // eslint-disable-line no-unused-vars
  ) => {
    alarmIds.forEach(alarmId => {
      dispatch(rescheduleAlarmBasedOnLatestAcknowledgementData(alarmId))
    })
  }

const rescheduleAlarmBasedOnLatestAcknowledgementData =
  (alarmId, cancelSnoozeAlarm = false) =>
  (dispatch, getState) => {
    return AlarmUtils.retrieveAlarmByAlarmId(alarmId, true).then(alarm => {
      if (!alarm) {
        FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
          [Constants.UserAnalyticsEventParameters.SOURCE]:
            'rescheduleAlarmBasedOnLatestAcknowledgementData'
        })
        return
      }

      if (alarm.creator === GlobalConfig.uid) {
        scheduleNotificationForOwnAlarm(alarm, cancelSnoozeAlarm)
      } else {
        scheduleNotificationForParticipantAlarm(
          alarm,
          getState().contacts.contacts,
          cancelSnoozeAlarm
        )
      }
    })
  }

const scheduleNotificationForOwnAlarm = (alarm, cancelSnoozeAlarm = false) => {
  // Don't schedule notifications for disabled or instant alerts
  if (
    alarm.status &&
    alarm.creationMode !== Constants.AlarmCreationModes.INSTANT_ALARM
  ) {
    const nextAlarmRingDate = AlarmUtils.getNextRingDateForAlarm(alarm)
    const alarmDate = DateTimeUtils.getMyAlarmDate(nextAlarmRingDate)
    const alarmAlert = AlarmUtils.getMyAlarmNotificationAlertMessage(
      alarm.type,
      alarm.name,
      objGet(alarm, 'recipient.name', '')
    )
    const notificationInfo = AlarmUtils.getMyAlarmNotificationInfo(
      alarm,
      alarmDate
    )

    console.tron.log(
      'Scheduling notification for own alarm ' +
        alarm.id +
        ' at ' +
        DateTimeUtils.getDateTimeAsString(alarmDate)
    )

    AlarmManager.scheduleNotification(
      alarmDate,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone,
      alarmAlert,
      notificationInfo,
      cancelSnoozeAlarm,
      false
    )

    // This is to handle schedule existing alarms such that snoozing alarms
    // are also rescheduled
    if (AlarmUtils.isLastSnoozeTimeInSnoozeDurationForMyAlarm(alarm)) {
      // Use the previous alarm date for the snooze notification
      const prevAlarmDate = AlarmUtils.getPrevDateForAlarm(
        alarm.date,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone
      )
      const snoozeNotificationInfo = {
        ...notificationInfo,
        alarmDate: prevAlarmDate
      }
      const snoozeDate =
        alarm.lastSnoozeByCreatorOn + alarm.lastSnoozeByCreatorFor

      // Only schedule the snooze alarm if the current date for the alarm is behind the snooze date
      if (snoozeDate > alarmDate) {
        console.tron.log(
          'Scheduling snooze notification',
          DateTimeUtils.getDateTimeAsString(snoozeDate)
        )
        AlarmManager.scheduleSnoozeNotification(
          DateTimeUtils.shaveSecondsFromDate(snoozeDate),
          snoozeNotificationInfo.alarmAlert,
          snoozeNotificationInfo
        )
      }
    }
  } else {
    console.tron.log('Canceling notification for own alarm ' + alarm.id)
    AlarmManager.cancelNotifications(alarm.id)
  }
}

const scheduleNotificationForParticipantAlarm = (
  alarm,
  contacts,
  cancelSnoozeAlarm = false
) => {
  if (AlarmUtils.willParticipantAlarmRing(alarm)) {
    const creatorName = AlarmUtils.resolveAlarmCreatorName(
      contacts,
      alarm.creator,
      alarm.creatorMobileNumber,
      alarm.creatorName
    )
    const alarmAlert = AlarmUtils.getParticipantAlarmNotificationAlertMessage(
      alarm.type,
      alarm.name,
      alarm.creationMode,
      creatorName
    )

    const nextAlarmRingDate = AlarmUtils.getNextRingDateForAlarm(alarm)
    const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      { ...alarm, date: nextAlarmRingDate },
      alarm.order
    )
    const notificationInfo = AlarmUtils.getParticipantAlarmNotificationInfo(
      alarm,
      backupAlarmDate,
      creatorName
    )

    console.tron.log(
      'Scheduling notification for participant alarm ' +
        alarm.id +
        'at ' +
        DateTimeUtils.getDateTimeAsString(backupAlarmDate)
    )

    AlarmManager.scheduleNotification(
      backupAlarmDate,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone,
      alarmAlert,
      notificationInfo,
      cancelSnoozeAlarm,
      false
    )

    // This is to handle schedule existing alarms such that snoozing alarms
    // are also rescheduled
    if (AlarmUtils.isLastSnoozeTimeInSnoozeDurationForParticipantAlarm(alarm)) {
      // Use the previous alarm date for the snooze notification
      const prevAlarmDate = AlarmUtils.getPrevDateForAlarm(
        alarm.date,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone
      )
      const prevBackupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
        { ...alarm, date: prevAlarmDate },
        alarm.order
      )
      const snoozeNotificationInfo = {
        ...notificationInfo,
        alarmDate: prevBackupAlarmDate
      }
      const participant = AlarmUtils.getAlarmParticipant(alarm)
      const snoozeDate = participant.lastSnoozeOn + participant.lastSnoozeFor

      // Only schedule the snooze alarm if the current date for the alarm is behind the snooze date
      if (snoozeDate > backupAlarmDate) {
        console.tron.log(
          'Scheduling snooze notification',
          DateTimeUtils.getDateTimeAsString(snoozeDate)
        )
        AlarmManager.scheduleSnoozeNotification(
          DateTimeUtils.shaveSecondsFromDate(snoozeDate),
          snoozeNotificationInfo.alarmAlert,
          snoozeNotificationInfo
        )
      }
    }
  } else {
    console.tron.log('Canceling notification for participant alarm ' + alarm.id)
    AlarmManager.cancelNotifications(alarm.id)
  }
}

// eslint-disable-next-line no-unused-vars
const resetUnseenMessagesForAlarm = alarmId => (dispatch, getState) => {
  GlobalConfig.rootFirebaseRef
    .child('conversations/unseenMessages/' + alarmId)
    .child(GlobalConfig.uid)
    // eslint-disable-next-line no-unused-vars
    .transaction(unseenMessagesCount => {
      return null
    })
}

// eslint-disable-next-line no-unused-vars
const updateUserCurrentlyTyping = alarmId => (dispatch, getState) => {
  GlobalConfig.rootFirebaseRef
    .child('conversations/currentlyTyping/' + alarmId)
    .child(GlobalConfig.uid)
    .set(true)
}

// eslint-disable-next-line no-unused-vars
const resetUserCurrentlyTyping = alarmId => (dispatch, getState) => {
  GlobalConfig.rootFirebaseRef
    .child('conversations/currentlyTyping/' + alarmId)
    .child(GlobalConfig.uid)
    .remove()
}

// eslint-disable-next-line no-unused-vars
const loadCurrentlyTypingUsers = alarmId => (dispatch, getState) => {
  FirebaseManager.addFirebaseRefValueChanged(
    'AlarmConversation',
    GlobalConfig.rootFirebaseRef.child(
      'conversations/currentlyTyping/' + alarmId
    ),
    snapshot => {
      const currentlyTypingUsersObj = snapshot.val() || {}
      const currentlyTypingUsers = Object.keys(currentlyTypingUsersObj)
      dispatch(
        updateCurrentlyTypingUsersForAlarm(alarmId, currentlyTypingUsers)
      )
    }
  )
}

// eslint-disable-next-line no-unused-vars
const unloadCurrentlyTypingUsers = alarmId => (dispatch, getState) => {
  FirebaseManager.unbindFirebaseEventHandlerForValueChanged(
    'AlarmConversation',
    GlobalConfig.rootFirebaseRef.child(
      'conversations/currentlyTyping/' + alarmId
    )
  )
}

// eslint-disable-next-line no-unused-vars
const loadAlerts = () => (dispatch, getState) => {
  // Don't process alerts for web as we don't show
  // alerts on the web at the moment
  if (Platform.OS === 'web') {
    return
  }

  FirebaseManager.addFirebaseRefChildAdded(
    'App',
    GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('alerts'),
    snapshot => {
      try {
        const alertId = snapshot.key
        const alertData = snapshot.val()
        const alertJson = JSON.parse(alertData)

        if (!alertJson) {
          throw new Error('Unable to parse alert json')
        }

        if (Array.isArray(alertJson)) {
          alertJson.forEach(alert => {
            handleAlert(alert, dispatch)
          })
        } else {
          handleAlert(alertJson, dispatch)
        }

        // Remove the alert once presented to the user
        GlobalConfig.rootFirebaseRef
          .child('userInfos')
          .child(GlobalConfig.uid)
          .child('alerts')
          .child(alertId)
          .remove()
      } catch (error) {
        LogUtils.logError(error, 'Error showing in app alerts to the user')
      }
    }
  )
}

const handleAlert = (alert, dispatch) => {
  let alerts = []
  let impactedAlarms
  let impactedGroups
  switch (alert.type) {
    case Constants.AlertTypes.MEMBER_ADDED_TO_GROUP:
      impactedAlarms = []
      alert.data.impactedAlarms.forEach(alarm => {
        impactedAlarms.push(alarm.name)
      })
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.MEMBER_ADDED_TO_GROUP,
        message: I18n.t('membersAddedToGroup', {
          memberNames: alert.data.members.join(', '),
          count: alert.data.members.length,
          groupName: alert.data.name
        }),
        impactedAlarms: impactedAlarms
      })

      break
    case Constants.AlertTypes.MEMBER_REMOVED_FROM_GROUP:
      impactedAlarms = []
      alert.data.impactedAlarms.forEach(alarm => {
        impactedAlarms.push(alarm.name)
      })
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.MEMBER_REMOVED_FROM_GROUP,
        message: I18n.t('memberRemovedFromGroup', {
          memberName: alert.data.memberName,
          groupName: alert.data.name
        }),
        impactedAlarms: impactedAlarms
      })
      break
    case Constants.AlertTypes.REMOVED_FROM_GROUP:
      impactedAlarms = []
      alert.data.impactedAlarms.forEach(alarm => {
        impactedAlarms.push(alarm.name)
      })
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.REMOVED_FROM_GROUP,
        message: I18n.t('youRemovedFromGroup', { groupName: alert.data.name }),
        impactedAlarms: impactedAlarms
      })
      break
    case Constants.AlertTypes.LEFT_GROUP:
      impactedAlarms = []
      alert.data.impactedAlarms.forEach(alarm => {
        impactedAlarms.push(alarm.name)
      })
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.LEFT_GROUP,
        message: I18n.t('youLeftGroup', { groupName: alert.data.name }),
        impactedAlarms: impactedAlarms
      })
      break
    case Constants.AlertTypes.MEMBER_LEFT_GROUP:
      impactedAlarms = []
      alert.data.impactedAlarms.forEach(alarm => {
        impactedAlarms.push(alarm.name)
      })
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.MEMBER_LEFT_GROUP,
        message: I18n.t('memberLeftGroup', {
          memberName: alert.data.memberName,
          groupName: alert.data.name
        }),
        impactedAlarms: impactedAlarms
      })
      break
    case Constants.AlertTypes.CREATOR_TIMEZONE_CHANGED:
      impactedAlarms = []
      alert.data.impactedAlarmNames.forEach(alarmName => {
        impactedAlarms.push(alarmName)
      })
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.CREATOR_TIMEZONE_CHANGED,
        message: I18n.t('memberMovedToADifferentTimezone', {
          memberName: alert.data.creatorName
        }),
        impactedAlarms: impactedAlarms
      })
      break
    case Constants.AlertTypes.DST_CHANGED:
      impactedAlarms = []
      alert.data.impactedAlarmNames.forEach(alarmName => {
        impactedAlarms.push(alarmName)
      })
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.DST_CHANGED,
        message: I18n.t('memberDstChanged', {
          memberName: alert.data.creatorName,
          count: alert.dstStart ? 1 : 0
        }),
        impactedAlarms: impactedAlarms
      })
      break
    case Constants.AlertTypes.MEMBER_DELETED_ACCOUNT:
      impactedAlarms = []
      impactedGroups = []
      alert.data.impactedAlarmNames.forEach(alarmName => {
        impactedAlarms.push(alarmName)
      })
      alert.data.impactedGroupNames.forEach(groupName => {
        impactedGroups.push(groupName)
      })
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.MEMBER_DELETED_ACCOUNT,
        message: I18n.t('memberDeletedAccount', {
          memberName: alert.data.memberName
        }),
        impactedAlarms: impactedAlarms,
        impactedGroups: impactedGroups
      })
      break
    case Constants.AlertTypes.SUBSCRIBED_ALARM_EDITED:
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.SUBSCRIBED_ALARM_EDITED,
        message: I18n.t('subscribedAlarmEdited', {
          alarmName: alert.data.alarmName,
          alarmCreatorName: alert.data.alarmCreatorName
        }),
        subscriberAlarmId: alert.data.subscriberAlarmId
      })
      break
    case Constants.AlertTypes.SUBSCRIBED_ALARM_DELETED:
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.SUBSCRIBED_ALARM_DELETED,
        message: I18n.t('subscribedAlarmDeleted', {
          alarmName: alert.data.alarmName,
          alarmCreatorName: alert.data.alarmCreatorName
        }),
        subscriberAlarmId: alert.data.subscriberAlarmId
      })
      break
    case Constants.AlertTypes.RESPONDED_TO_USER_TICKET:
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.RESPONDED_TO_USER_TICKET,
        message: I18n.t('responseReceivedForTicket', {
          ticketId: alert.data.ticketId,
          email: alert.data.email
        })
      })
      break
    case Constants.AlertTypes.NEW_TICKET_RECEIVED:
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.NEW_TICKET_RECEIVED,
        message: I18n.t('newTicketReceived', {
          ticketId: alert.data.ticketId,
          email: alert.data.email
        })
      })
      break
    case Constants.AlertTypes.SENT_ACCOUNT_BACKUP:
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.SENT_ACCOUNT_BACKUP,
        message: I18n.t('sentAccountBackup', {
          email: alert.data.email
        })
      })
      break
    case Constants.AlertTypes.SLACK_VERIFICATION_CODE:
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.SLACK_VERIFICATION_CODE,
        message: I18n.t('slackVerificationCode', {
          verificationCode: alert.data.verificationCode
        })
      })
      break
    case Constants.MEMBER_CHANGED_PHONE_NUMBER:
      alerts.push({
        id: uuid.v4(),
        type: Constants.AlertTypes.MEMBER_CHANGED_PHONE_NUMBER,
        message: I18n.t('memberChangedPhoneNumber', {
          memberName: alert.data.memberName
        })
      })
      break
    default:
      LogUtils.logError(new Error('Unidentified alert ' + alert.type))
      return
  }

  dispatch(addSystemAlerts(alerts))
}

const isUserNoLongerParticipant = alarm => {
  // If the member has been removed as a backup for the alarm either by removing the individual contact
  // or the user is no longer part of the group, then remove the event handler. If the contact was the
  // only backup or alarmCreator was removed from the group, then the backups object will be undefined
  // The alarm will be removed by the backupAlarms change handler

  const participants = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  )

  const participant = Utils.getObjectWithId(participants, GlobalConfig.uid)

  if (participant === undefined) {
    return true
  }

  return false
}

const retrieveAndAddBackupAlarmAndScheduleNotifications = (
  dispatch,
  alarmId,
  getState
) => {
  const ref = GlobalConfig.rootFirebaseRef
  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('alarms').child(alarmId),
    snapshot => {
      const alarmData = snapshot.val()

      const participantAlarmSelector = makeParticipantAlarmSelector()
      const existingAlarm = participantAlarmSelector(getState(), { alarmId })

      // If an alarm is deleted, this handler will be called with null value. Remove the backup alarm
      if (alarmData === null || alarmData.isDeleted) {
        if (!isEmpty(existingAlarm)) {
          removeBackupAlarmAndCancelNotifications(dispatch, alarmId)
        }
        FirebaseManager.unbindFirebaseEventHandlerForValueChanged(
          'App',
          ref.child('alarms').child(alarmId)
        )
        return
      }

      const alarm = AlarmUtils.createAlarm(alarmData)

      if (alarm === undefined) {
        return
      }

      // Since the groups are shared now, if a user added a group as a backup for an alarm, we want to show that alarm as a backup
      // alarm for all the users except the creator
      // Sometimes, we get an error and GlobalConfig.uid is not an object here
      if (alarm.creator === GlobalConfig.uid) {
        return
      }

      const userIsNoLongerParticipant = isUserNoLongerParticipant(alarm)

      // Remove the alarm if the user is no longer a backup
      if (userIsNoLongerParticipant) {
        if (!isEmpty(existingAlarm)) {
          removeBackupAlarmAndCancelNotifications(dispatch, alarmId)
        }
        FirebaseManager.unbindFirebaseEventHandlerForValueChanged(
          'App',
          ref.child('alarms').child(alarmId)
        )
        return
      }

      const blockedContacts = getState().userInfo.blockedContacts

      // Need to remove alarm because when user blocks existing alarms,
      // need to remove those alarms from the app. This should be before
      // the creator check so that the existing alarms can be removed
      // The blocked check if required if the user decided not to restore
      // previously blocked alarms
      if (
        !alarm.status ||
        alarm.state === Constants.ParticipantStates.INACTIVE ||
        alarm.blocked ||
        blockedContacts.includes(alarm.creator)
      ) {
        if (!isEmpty(existingAlarm)) {
          removeBackupAlarmAndCancelNotifications(dispatch, alarmId)
        }
        return
      }

      // Instant alarms are triggered as part of the notifications and needn't be
      // scheduled here again. Scheduling here leads to race condition between schedule
      // and cancel notification calls on iOS leading to instant alarm not ringing at all
      if (alarm.creationMode !== Constants.AlarmCreationModes.INSTANT_ALARM) {
        scheduleNotificationForParticipantAlarm(
          alarm,
          getState().contacts.contacts
        )
      }

      // If there has been no change to the participant alarm, then skip it
      if (
        !isEmpty(existingAlarm) &&
        (!alarm.lastUpdatedAt ||
          alarm.lastUpdatedAt === existingAlarm.lastUpdatedAt)
      ) {
        return
      }

      // Add the alarm to the redux store
      dispatch(addBackupAlarm(alarmId, alarm))
    }
  )

  dispatch(loadAlarmActions(alarmId))

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    GlobalConfig.rootFirebaseRef
      .child('conversations/unseenMessages/' + alarmId)
      .child(GlobalConfig.uid),
    snapshot => {
      const currentScreen = getState().appState.currentScreen
      const unseenMessages = snapshot.val() || 0
      const currentUnseenMessagesForAlarm = objGet(
        getState().conversations.alarms,
        `[${alarmId}].unseenMessages`,
        0
      )
      if (currentUnseenMessagesForAlarm !== unseenMessages) {
        dispatch(updateUnseenMessagesForAlarm(alarmId, unseenMessages))
      }
      if (currentScreen === 'AlarmConversationScreen' + alarmId) {
        dispatch(resetUnseenMessagesForAlarm(alarmId))
      }
    }
  )
}

const createAlarmAcknowledgementsFromAlarmActions = alarmActions => {
  const alarmAcknowledgements = alarmActions.alarmAcknowledgements || {}
  const alarmAcknowledgementsToReturn = []
  Object.keys(alarmAcknowledgements).forEach(occurrenceTimeString => {
    const usersResponses = alarmAcknowledgements[occurrenceTimeString] || {}
    Object.keys(usersResponses).forEach(uid => {
      const userResponses = usersResponses[uid] || {}
      Object.keys(userResponses).forEach(responseId => {
        const response = userResponses[responseId]
        alarmAcknowledgementsToReturn.push({
          occurrenceTimeString,
          uid,
          timestamp: response.timestamp,
          response: response.response,
          rescheduleAlarm: response.rescheduleAlarm
        })
      })
    })
  })
  return alarmAcknowledgementsToReturn
}

// eslint-disable-next-line no-unused-vars
const loadAlarmAcknowledgements = alarmId => (dispatch, getState) => {
  return GlobalConfig.rootFirebaseRef
    .child('alarmActions')
    .child(alarmId)
    .once('value')
    .then(snapshot => {
      const alarmActionsData = snapshot.val()

      if (alarmActionsData !== null) {
        const alarmAcknowledgements =
          createAlarmAcknowledgementsFromAlarmActions(alarmActionsData)

        // Cancel the snooze alarm as this is called when an alarm
        // is acknowledged.
        dispatch(
          updateAlarmAcknowledgementsAndRescheduleAlarm(
            alarmId,
            alarmAcknowledgements,
            true
          )
        )
      }
    })
}
// eslint-disable-next-line no-unused-vars
const loadAlarmActions = alarmId => (dispatch, getState) => {
  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    GlobalConfig.rootFirebaseRef.child('alarmActions').child(alarmId),
    snapshot => {
      const alarmActionsData = snapshot.val()

      if (alarmActionsData === null) {
        const currAlarmAcknowledgements = objGet(
          getState().alarmActions,
          `alarmAcknowledgements.${alarmId}`
        )
        if (currAlarmAcknowledgements) {
          dispatch(removeAlarmAcknowledgementActions(alarmId))
        }

        const currAlarmStatusChanges = objGet(
          getState().alarmActions,
          `alarmStatusChanges.${alarmId}`
        )
        if (currAlarmStatusChanges) {
          dispatch(removeAlarmStatusChangeActions(alarmId))
        }

        return
      }

      const alarmAcknowledgements =
        createAlarmAcknowledgementsFromAlarmActions(alarmActionsData)

      const existingAlarmAcknowledgementsSelector =
        makeAlarmAcknowledgementsSelector()
      const existingAlarmAcknowledgements =
        existingAlarmAcknowledgementsSelector(getState(), { alarmId })

      if (
        alarmAcknowledgements.length > 0 &&
        alarmAcknowledgements.length !== existingAlarmAcknowledgements.length
      ) {
        if (!existingAlarmsLoaded) {
          batchedExistingAlarmsAcknowledgements[alarmId] = alarmAcknowledgements
        } else {
          dispatch(
            updateAlarmAcknowledgementsAndRescheduleAlarm(
              alarmId,
              alarmAcknowledgements
            )
          )
        }
      } else {
        console.tron.log('no change to alarm acknowledgements')
        return
      }

      const alarmStatusChanges = alarmActionsData.alarmStatusChanges || {}
      const alarmStatusChangesToAdd = []
      Object.keys(alarmStatusChanges).forEach(responseId => {
        const response = alarmStatusChanges[responseId]
        alarmStatusChangesToAdd.push({
          timestamp: response.timestamp,
          status: response.status
        })
      })

      const existingAlarmStatusChangesSelector =
        makeAlarmStatusChangesSelector()
      const existingAlarmStatusChanges = existingAlarmStatusChangesSelector(
        getState(),
        { alarmId }
      )

      if (
        alarmStatusChangesToAdd.length > 0 &&
        alarmStatusChangesToAdd.length !== existingAlarmStatusChanges.length
      ) {
        if (!existingAlarmsLoaded) {
          batchedExistingAlarmsStatusChanges[alarmId] = alarmStatusChangesToAdd
        } else {
          dispatch(addAlarmStatusChanges(alarmId, alarmStatusChangesToAdd))
        }
      } else {
        console.tron.log('no change to alarm status changes')
        return
      }
    }
  )
}

const removeBackupAlarmAndCancelNotifications = (dispatch, alarmId) => {
  dispatch(removeBackupAlarm(alarmId))
  AlarmManager.cancelNotifications(alarmId)
}

const removeDeletedParticipantAlarms = () => (dispatch, getState) => {
  const existingAlarmIds = getState().backupAlarms.alarms.map(alarm => alarm.id)
  TaskManager.addHttpsCloudTask('findDeletedAlarms', {
    alarmIds: JSON.stringify(existingAlarmIds)
  }).then(taskResult => {
    const deletedAlarmIds = taskResult.deletedAlarmIds
    if (!deletedAlarmIds || deletedAlarmIds.length <= 0) {
      return
    }
    deletedAlarmIds.forEach(alarmId => {
      AlarmManager.cancelNotifications(alarmId)
    })
    dispatch(removeParticipantAlarms(deletedAlarmIds))
  })
}

// Alarms maybe deleted on the web and the mobile app may not be running
// at that time and hence miss the events. So, delete any such deleted
// alarms from the local store
const removeDeletedOwnAlarms = () => (dispatch, getState) => {
  const existingAlarmIds = getState()
    .alarms.alarms.filter(alarm => alarm.persistedOnline !== false)
    .map(alarm => alarm.id)
  TaskManager.addHttpsCloudTask('findDeletedAlarms', {
    alarmIds: JSON.stringify(existingAlarmIds)
  }).then(taskResult => {
    const deletedAlarmIds = taskResult.deletedAlarmIds
    if (!deletedAlarmIds || deletedAlarmIds.length <= 0) {
      return
    }
    deletedAlarmIds.forEach(alarmId => {
      AlarmManager.cancelNotifications(alarmId)
    })
    dispatch(removeLocalAlarms(deletedAlarmIds))
  })
}

// This function finds own alarms that are only present locally
// and not on the database. This maybe because some of the pending
// actions may have been dropped for some reason. It is not clear
// at the moment why are the pending actions dropped. But this syncs
// those alarms back to the database.
const syncLocalOnlyOwnAlarms = () => (dispatch, getState) => {
  const existingAlarmIds = getState()
    .alarms.alarms.filter(alarm => alarm.persistedOnline === false)
    .map(alarm => alarm.id)
  TaskManager.addHttpsCloudTask('findDeletedAlarms', {
    alarmIds: JSON.stringify(existingAlarmIds)
  }).then(taskResult => {
    const deletedAlarmIds = taskResult.deletedAlarmIds
    if (!deletedAlarmIds || deletedAlarmIds.length <= 0) {
      return
    }
    console.tron.log('local only alarms', deletedAlarmIds)
    deletedAlarmIds.forEach(alarmId => {
      const ownAlarmSelector = makeOwnAlarmSelector()
      const alarm = ownAlarmSelector(getState(), { alarmId })
      AlarmUtils.addAlarm(alarm)
    })
  })
}

// eslint-disable-next-line no-unused-vars
const loadRecentlyDeletedAlarms = () => (dispatch, getState) => {
  const ref = GlobalConfig.rootFirebaseRef

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('recentlyDeletedAlarms'),
    async snapshot => {
      let promises = []
      let alarms = []
      snapshot.forEach(childSnapshot => {
        const alarmId = childSnapshot.key
        promises.push(
          GlobalConfig.rootFirebaseRef
            .child('alarms')
            .child(alarmId)
            .once('value')
            .then(snapshot => snapshot.val())
        )
      })
      // Wait for all promises to resolve before proceeding
      const results = await Promise.all(promises)

      // Filter out null results and merge into the alarms object
      alarms.push(
        ...results
          .filter(result => result !== null)
          .map(alarm => AlarmUtils.createAlarm(alarm))
      )

      dispatch(setRecentlyDeletedAlarms(alarms))
    }
  )
}

const loadChecklist = (checklistId, dispatch) => {
  const ref = GlobalConfig.rootFirebaseRef

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('checklists').child(checklistId),
    snapshot => {
      const checklistData = snapshot.val()

      if (checklistData === null) {
        dispatch(removeLocalChecklist(checklistId))
        return
      }

      const checklist = AlarmUtils.createChecklist(checklistData)

      if (checklist === undefined) {
        return
      }

      dispatch(addLocalChecklist(checklist, true))
    }
  )
}

// eslint-disable-next-line no-unused-vars
const loadChecklists = () => (dispatch, getState) => {
  const ref = GlobalConfig.rootFirebaseRef

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('checklists'),
    async snapshot => {
      snapshot.forEach(childSnapshot => {
        const checklistId = childSnapshot.key
        loadChecklist(checklistId, dispatch)
      })
    }
  )
}

const loadBackupAlarms = () => (dispatch, getState) => {
  const ref = GlobalConfig.rootFirebaseRef

  // Add new backup alarm when the user is added as an individual backup
  FirebaseManager.addFirebaseRefChildAdded(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('backupForAlarms'),
    snapshot => {
      const alarmId = snapshot.key
      retrieveAndAddBackupAlarmAndScheduleNotifications(
        dispatch,
        alarmId,
        getState
      )
    }
  )

  // Handle backup alarms for groups that are backup for an alarm. Whenever a user is added as part of a group,
  // add handlers which will add or remove alarm as
  // 1. Group is added as a backup for an alarm
  // 2. User is added to a group and the group is a backup for some alarms already
  // 3. Group is removed as a backup for an alarm
  FirebaseManager.addFirebaseRefChildAdded(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('groups'),
    groupSnapshot => {
      const groupId = groupSnapshot.key

      // Add new backup alarm when then user is member of a group which is added as a backup for an alarm
      FirebaseManager.addFirebaseRefChildAdded(
        'App',
        ref.child('groupInfos').child(groupId).child('backupForAlarms'),
        alarmIdSnapshot => {
          const alarmId = alarmIdSnapshot.key
          retrieveAndAddBackupAlarmAndScheduleNotifications(
            dispatch,
            alarmId,
            getState
          )
        }
      )
    }
  )

  // If user is no longer part of a group, then remove the backup alarms which were added due to membership of the group
  FirebaseManager.addFirebaseRefChildRemoved(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('groups'),
    groupSnapshot => {
      const groupId = groupSnapshot.key

      ref
        .child('groupInfos')
        .child(groupId)
        .child('backupForAlarms')
        .once('value', snapshot => {
          // If the group is deleted, then the snapshot won't exist. The backup alarms will be removed
          // by earlier handlers
          if (snapshot.exists()) {
            snapshot.forEach(alarmIdSnapshot => {
              const alarmId = alarmIdSnapshot.key
              const participantAlarmSelector = makeParticipantAlarmSelector()
              const alarm = participantAlarmSelector(getState(), { alarmId })
              // If the user is the creator of the alarm, then the alarm won't be found in the backup alarms
              if (!isEmpty(alarm)) {
                removeBackupAlarmAndCancelNotifications(dispatch, alarmId)
              }
            })
          }
        })

      // Remove the event handlers added earlier to add/remove a backup alarm if the groupInfo changes for a group of which the user is a member
      // If the user is no longer member of a group, then we need to remove those handlers such that we don't add backup alarms for user if that
      // group is made backup for some alarm
      // TODO: Test this by adding a user to a group, then removing him and then adding that group as a backup for an alarm
      FirebaseManager.unbindFirebaseEventHandlerForChildAdded(
        'App',
        ref.child('groupInfos').child(groupId).child('backupForAlarms')
      )
    }
  )
}

// eslint-disable-next-line no-unused-vars
const loadUserInfo = () => (dispatch, getState) => {
  const ref = GlobalConfig.rootFirebaseRef

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('users').child(GlobalConfig.uid),
    async snapshot => {
      if (snapshot.exists()) {
        dispatch(setUserInfo(snapshot.val()))

        // Update username and phoneId in storage if not present
        if (snapshot.val().name) {
          const data = await StorageManager.retrieveData(['username'])
          if (!data.username) {
            const username = snapshot.val().name
            StorageManager.storeData({ username })
            GlobalConfig.username = username
          }
        }

        if (snapshot.val().phoneId) {
          const data = await StorageManager.retrieveData(['phoneId'])
          if (!data.phoneId) {
            const phoneId = snapshot.val().phoneId
            StorageManager.storeData({ phoneId })
            GlobalConfig.phoneId = phoneId
          }
        }
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('enterpriseSubscriptions'),
    snapshot => {
      if (snapshot.exists()) {
        let enterpriseSubscriptions = []
        snapshot.forEach(subscriptionSnapshot => {
          enterpriseSubscriptions.push(subscriptionSnapshot.key)
        })
        dispatch(addEnterpriseSubscriptions(enterpriseSubscriptions))
      } else {
        dispatch(addEnterpriseSubscriptions([]))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('blockedContacts'),
    snapshot => {
      if (snapshot.exists()) {
        let blockedContacts = []
        snapshot.forEach(blockedContactSnapshot => {
          blockedContacts.push(blockedContactSnapshot.key)
        })
        dispatch(addBlockedContacts(blockedContacts))
      } else {
        dispatch(addBlockedContacts([]))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('rateTheApp'),
    snapshot => {
      if (snapshot.exists()) {
        dispatch(setRateTheAppUserInfo(snapshot.val()))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('alarmsCount'),
    snapshot => {
      if (snapshot.exists()) {
        dispatch(setAlarmsCount(snapshot.val()))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('deviceOs'),
    snapshot => {
      if (snapshot.exists()) {
        dispatch(setDeviceOs(snapshot.val()))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('maxAllowedAlarms'),
    snapshot => {
      if (snapshot.exists()) {
        dispatch(setMaxAllowedAlarms(snapshot.val()))
      }
    }
  )

  ref
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('joinDate')
    .once('value')
    .then(joinDateSnapshot => {
      if (joinDateSnapshot.exists()) {
        dispatch(setJoinDate(joinDateSnapshot.val()))
      }
    })

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('images'),
    imagesSnapshot => {
      if (imagesSnapshot.exists()) {
        dispatch(setUserAvatarImages(imagesSnapshot.val()))
      } else {
        dispatch(deleteUserAvatarImages())
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('shareableAlarmLinksCount'),
    linksCountSnapshot => {
      if (linksCountSnapshot.exists()) {
        dispatch(setShareableAlarmLinksCount(linksCountSnapshot.val()))
      } else {
        dispatch(setShareableAlarmLinksCount({}))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('alarmsSavedToCalendarCount'),
    alarmsSavedToCalendarCountSnapshot => {
      if (alarmsSavedToCalendarCountSnapshot.exists()) {
        dispatch(
          setAlarmsSavedToCalendarCount(
            alarmsSavedToCalendarCountSnapshot.val()
          )
        )
      } else {
        dispatch(setAlarmsSavedToCalendarCount(0))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('instantAlarmsCount'),
    instantAlarmsCountSnapshot => {
      if (instantAlarmsCountSnapshot.exists()) {
        dispatch(setInstantAlarmsCount(instantAlarmsCountSnapshot.val()))
      } else {
        dispatch(setInstantAlarmsCount(0))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('alarmCategories'),
    alarmCategoriesSnapshot => {
      if (alarmCategoriesSnapshot.exists()) {
        const alarmCategories = {}
        alarmCategoriesSnapshot.forEach(alarmCategorySnapshot => {
          const alarmCategoryId = alarmCategorySnapshot.key
          const alarmCategoryData = alarmCategorySnapshot.val()
          let { alarmIds = {}, ...alarmCategory } = alarmCategoryData
          alarmCategory.alarmIds = Object.keys(alarmIds)
          alarmCategories[alarmCategoryId] = alarmCategory
        })
        dispatch(setAlarmCategories(alarmCategories))
      } else {
        // Don't create the system defined categories if user has deleted their account.
        // GlobalConfig.uid is set to null once user account is deleted.
        if (GlobalConfig.uid) {
          dispatch(createSystemDefinedAlarmCategories())
        }
      }
    }
  )
}

// eslint-disable-next-line no-unused-vars
const createSystemDefinedAlarmCategories = () => (dispatch, getState) => {
  GlobalConfig.systemAlarmCategories.forEach(systemAlarmCategory =>
    dispatch(createAlarmCategory(systemAlarmCategory))
  )
}

const incrementMaxAllowedAlarms = amount => (dispatch, getState) => {
  const currentLimit = getState().userInfo.maxAllowedAlarms
  return GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('maxAllowedAlarms')
    .set(currentLimit + amount)
}

// eslint-disable-next-line no-unused-vars
const loadUserSettings = () => (dispatch, getState) => {
  return GlobalConfig.rootFirebaseRef
    .child('userSettings')
    .child(GlobalConfig.uid)
    .once('value')
    .then(userSettingsSnapshot => {
      if (userSettingsSnapshot.exists()) {
        const userSettings = userSettingsSnapshot.val()
        // If we have a ringtone that's not an object, omit alarmRingtone from userSetting
        let ringtoneObject = objGet(userSettings, 'alarmRingtone', '')
        if (typeof ringtoneObject === 'string') {
          ringtoneObject =
            GlobalConfig.ringtones
              .filter(ringtone => ringtone.value === ringtoneObject)
              .pop() || GlobalConfig.defaultAlarmRingtone
          userSettings.alarmRingtone = ringtoneObject
          GlobalConfig.rootFirebaseRef
            .child('userSettings')
            .child(GlobalConfig.uid)
            .child('alarmRingtone')
            .set(ringtoneObject)
        } else if (
          typeof ringtoneObject === 'object' &&
          Platform.OS === 'ios' &&
          ringtoneObject.type === 'music'
        ) {
          ringtoneObject = GlobalConfig.defaultAlarmRingtone
          userSettings.alarmRingtone = ringtoneObject
          GlobalConfig.rootFirebaseRef
            .child('userSettings')
            .child(GlobalConfig.uid)
            .child('alarmRingtone')
            .set(ringtoneObject)
        }

        let notificationSettingsObject = objGet(
          userSettings,
          'notificationSettings',
          ''
        )

        // If user does not have any notification settings, load the default settingns
        if (!notificationSettingsObject) {
          userSettings.notificationSettings =
            GlobalConfig.defaultNotificationSettings
        }

        dispatch(
          setUserSettings({
            ...GlobalConfig.updatedDefaultUserSettings,
            ...userSettings
          })
        )

        // Also set the user settings on the global config so that they are easily accessible
        Object.keys(userSettings).forEach(userSetting => {
          GlobalConfig[userSetting] = userSettings[userSetting]
        })

        // Set the alarmRingtone and ringOnVibrate settings if user has them changed.
        Platform.OS === 'android' && setAndroidNativePreferences(userSettings)
      } else {
        // If some defaults are changed, they need to be set explicitly
        // to overwrite the cached values in redux-persist
        setUserSettings(GlobalConfig.updatedDefaultUserSettings)
      }
    })
}

const setAndroidNativePreferences = userSettings => {
  if (userSettings.vibrate) {
    AlarmManager.updateVibratePreferences(userSettings.vibrate)
  }
  if (userSettings.volume) {
    AlarmManager.updateVolumePreferences(userSettings.volume)
  }
  if (userSettings.alarmRingtone) {
    AlarmManager.updateRingtonePreferences(userSettings.alarmRingtone)
  }
  if (userSettings.ringOnVibrate) {
    AlarmManager.updateRingOnVibratePreferences(userSettings.ringOnVibrate)
  }
  if (userSettings.ringtoneDuration) {
    AlarmManager.updateRingtoneDurationPreferences(
      userSettings.ringtoneDuration
    )
  }
  if (userSettings.autoSnooze) {
    AlarmManager.updateAutoSnoozePreferences(
      userSettings.autoSnooze,
      userSettings.autoSnoozeDuration || GlobalConfig.defaultAutoSnoozeDuration,
      userSettings.autoSnoozeCount || GlobalConfig.defaultAutoSnoozeCount
    )
  }
  if (userSettings.ringOnEarphoneOnly) {
    AlarmManager.updateRingOnEarphoneOnlyPreferences(
      userSettings.ringOnEarphoneOnly
    )
  }
  if (userSettings.gestureToDismissAnAlarm) {
    AlarmManager.updateGestureToDismissAnAlarmPreferences(
      userSettings.gestureToDismissAnAlarm
    )
  }
  if (userSettings.graduallyIncreaseVolume) {
    AlarmManager.updateGraduallyIncreaseVolumePreferences(
      userSettings.graduallyIncreaseVolume
    )
  }
  if (userSettings.timeFormat) {
    AlarmManager.updateTimeFormatPreferences(userSettings.timeFormat)
  }
  if (userSettings.announceAlarmName) {
    AlarmManager.updateAnnounceAlarmNamePreferences(
      userSettings.announceAlarmName
    )
  }
  if (userSettings.muteAlarms) {
    AlarmManager.muteAlarms(userSettings.muteAlarms)
  }
}

const rescheduleExpiredAlarms = () => (dispatch, getState) => {
  const allAlarms = allAlarmsSelector(getState())
  allAlarms
    .filter(alarm => {
      if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
        const alarmExpired = AlarmUtils.hasAlarmExpired(alarm.id)
        return alarmExpired
      } else {
        const alarmExpired = AlarmUtils.hasParticipantAlarmExpired(alarm.id)
        const alarmDenied = alarm.responseStatus === Constants.REJECT_ALARM
        return alarmExpired && !alarmDenied
      }
    })
    .forEach(alarm => {
      if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
        if (alarm.repeatType === '') {
          AlarmManager.cancelAlarmNotification(alarm.id)
        } else {
          scheduleNotificationForOwnAlarm(alarm)
        }
      } else if (
        alarm.alarmCategory === Constants.AlarmCategories.PARTICIPANT_ALARM
      ) {
        if (alarm.repeatType === '') {
          AlarmManager.cancelAlarmNotification(alarm.id)
        } else {
          scheduleNotificationForParticipantAlarm(
            alarm,
            getState().contacts.contacts
          )
        }
      }
    })
}

const updateRingParticipantAlarmByDefaultUserSetting =
  newValue => (dispatch, getState) => {
    dispatch(setRingParticipantAlarmsByDefault(newValue))
    GlobalConfig.ringParticipantAlarmsByDefault = newValue

    // Schedule or cancel notifications for participants alarms that has not been
    // responded yet based on the new value
    const backupAlarms = participantAlarmsSelector(getState())
    backupAlarms
      .filter(alarm => {
        return (
          alarm.responseStatus === Constants.ALARM_RESPONSE_PENDING &&
          alarm.status === true
        )
      })
      .forEach(alarm => {
        if (newValue === true) {
          scheduleNotificationForParticipantAlarm(
            alarm,
            getState().contacts.contacts
          )
        } else {
          AlarmManager.cancelNotifications(alarm.id)
        }
      })

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      GlobalConfig.rootFirebaseRef
        .child('userSettings')
        .child(GlobalConfig.uid)
        .child('ringParticipantAlarmsByDefault')
        .set(newValue)
    } else {
      console.tron.log(
        'Adding update ring participant alarms by default setting pending action'
      )
      dispatch(
        addUpdateRingParticipantAlarmsByDefaultSettingSoftPendingAction(
          newValue
        )
      )
    }
  }

const updateGraduallyIncreaseVolumeUserSetting =
  newValue => (dispatch, getState) => {
    dispatch(setGraduallyIncreaseVolume(newValue))

    AlarmManager.updateGraduallyIncreaseVolumePreferences(newValue)

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      GlobalConfig.rootFirebaseRef
        .child('userSettings')
        .child(GlobalConfig.uid)
        .child('graduallyIncreaseVolume')
        .set(newValue)
    } else {
      console.tron.log('Adding update gradually increase volume pending action')
      dispatch(addUpdateGraduallyIncreaseVolumeSoftPendingAction(newValue))
    }
  }

const updateGestureToDismissAnAlarmUserSetting =
  newValue => (dispatch, getState) => {
    dispatch(setGestureToDismissAnAlarm(newValue))

    AlarmManager.updateGestureToDismissAnAlarmPreferences(newValue)

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      GlobalConfig.rootFirebaseRef
        .child('userSettings')
        .child(GlobalConfig.uid)
        .child('gestureToDismissAnAlarm')
        .set(newValue)
    } else {
      console.tron.log('Adding update ring on earphone only pending action')
      dispatch(addUpdateGestureToDimissAnAlarmSoftPendingAction(newValue))
    }
  }

const updateRingOnEarphoneOnlyUserSetting =
  newValue => (dispatch, getState) => {
    dispatch(setRingOnEarphoneOnly(newValue))

    AlarmManager.updateRingOnEarphoneOnlyPreferences(newValue)

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      GlobalConfig.rootFirebaseRef
        .child('userSettings')
        .child(GlobalConfig.uid)
        .child('ringOnEarphoneOnly')
        .set(newValue)
    } else {
      console.tron.log('Adding update ring on earphone only pending action')
      dispatch(addUpdateRingOnEarphoneOnlySoftPendingAction(newValue))
    }
  }

const updateSpecifyTimezoneForAlarmUserSetting =
  newValue => (dispatch, getState) => {
    dispatch(setSpecifyTimezoneForAlarm(newValue))
    GlobalConfig.specifyTimezoneForAlarm = newValue

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      GlobalConfig.rootFirebaseRef
        .child('userSettings')
        .child(GlobalConfig.uid)
        .child('specifyTimezoneForAlarm')
        .set(newValue)
    } else {
      console.tron.log(
        'Adding update specify timezone for buddy alarm pending action'
      )
      dispatch(addUpdateSpecifyTimezoneForAlarmSoftPendingAction(newValue))
    }
  }

const updateGlobalAlarmRingerSettings =
  ringerSettings => (dispatch, getState) => {
    dispatch(setGlobalAlarmRingerSettings(ringerSettings))
    GlobalConfig.alarmRingtone = ringerSettings.alarmRingtone
    GlobalConfig.vibrate = ringerSettings.vibrate
    GlobalConfig.volume = ringerSettings.volume
    GlobalConfig.ringtoneDuration = ringerSettings.ringtoneDuration
    GlobalConfig.ringOnVibrate = ringerSettings.ringOnVibrate
    GlobalConfig.autoSnooze = ringerSettings.autoSnooze
    GlobalConfig.autoSnoozeDuration = ringerSettings.autoSnoozeDuration
    GlobalConfig.autoSnoozeCount = ringerSettings.autoSnoozeCount
    GlobalConfig.announceAlarmName = ringerSettings.announceAlarmName
    GlobalConfig.criticalAlerts = ringerSettings.criticalAlerts
    GlobalConfig.criticalAlertsVolume = ringerSettings.criticalAlertsVolume

    if (Platform.OS === 'android') {
      // Update shared prefrences on the android native side
      AlarmManager.updateRingtonePreferences(ringerSettings.alarmRingtone)
      AlarmManager.updateVibratePreferences(ringerSettings.vibrate)
      AlarmManager.updateVolumePreferences(ringerSettings.volume)
      AlarmManager.updateRingOnVibratePreferences(ringerSettings.ringOnVibrate)
      AlarmManager.updateRingtoneDurationPreferences(
        ringerSettings.ringtoneDuration
      )
      AlarmManager.updateAutoSnoozePreferences(
        ringerSettings.autoSnooze,
        ringerSettings.autoSnoozeDuration,
        ringerSettings.autoSnoozeCount
      )
      AlarmManager.updateAnnounceAlarmNamePreferences(
        ringerSettings.announceAlarmName
      )
    }

    if (Platform.OS === 'ios') {
      // Reschedule existing participant alarms that don't have a ringtone set
      const backupAlarms = participantAlarmsSelector(getState())
      backupAlarms.forEach(alarm => {
        const participants = AlarmUtils.getAlarmParticipants(
          alarm.type,
          alarm.backupGroup,
          alarm.backupContacts,
          alarm.recipient
        )
        const participant = Utils.getObjectWithId(
          participants,
          GlobalConfig.uid
        )

        // If the participant alarm has custom ringone specified, don't reschedule
        if (participant.ringerSettings) {
          return
        }

        scheduleNotificationForParticipantAlarm(
          alarm,
          getState().contacts.contacts
        )
      })
    }

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      GlobalConfig.rootFirebaseRef
        .child('userSettings')
        .child(GlobalConfig.uid)
        .update(ringerSettings)
    } else {
      console.tron.log(
        'Adding update alarm global ringer settings pending action'
      )
      dispatch(
        addUpdateGlobalAlarmRingerSettingsSoftPendingAction(ringerSettings)
      )
    }
  }

const updateTimeFormatUserSetting = newValue => dispatch => {
  GlobalConfig.timeFormat = newValue
  dispatch(setTimeFormat(newValue))
  GlobalConfig.rootFirebaseRef
    .child('userSettings')
    .child(GlobalConfig.uid)
    .child('timeFormat')
    .set(newValue)
  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.CHANGED_TIME_FORMAT, {
    [Constants.UserAnalyticsEventParameters.TIME_FORMAT_VALUE]: newValue
  })
  // Update shared prefrences on the native side
  AlarmManager.updateTimeFormatPreferences(newValue)
}

// eslint-disable-next-line no-unused-vars
const updateAppEnvInfo = () => (dispatch, getState) => {
  GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .update({
      deviceLocale: I18n.currentLocale(),
      deviceOs: Platform.OS
    })
}

// eslint-disable-next-line no-unused-vars
const handleExistingAlarmsLoaded = () => (dispatch, getState) => {
  dispatch(setExistingAlarmsLoaded())

  // Once the existing alarms are loaded, we can check if the user has
  // changed timezone and handle it accordingly.
  AlarmUtilsWithExtras.getTimezone().then(timezoneString => {
    dispatch(updateTimezoneAndHandleTimezoneChangeIfNeeded(timezoneString))
  })
}

const updateTimezoneAndHandleTimezoneChangeIfNeeded =
  currentTimezone =>
  (
    dispatch,
    getState // eslint-disable-line no-unused-vars
  ) => {
    const isAuthenticated = getState().appState.authenticatedWithFirebase
    if (!isAuthenticated) {
      return
    }

    const ref = GlobalConfig.rootFirebaseRef
    const currentTimezoneOffset =
      DateTimeUtils.getTimezoneOffsetForTimezone(currentTimezone)

    let promises = [],
      previousTimezone,
      previousTimezoneOffset

    promises.push(
      ref
        .child('userInfos')
        .child(GlobalConfig.uid)
        .child('timezone')
        .once('value')
        .then(snapshot => snapshot.val())
    )
    promises.push(
      ref
        .child('userInfos')
        .child(GlobalConfig.uid)
        .child('timezoneOffset')
        .once('value')
        .then(snapshot => snapshot.val())
    )
    Promise.all(promises)
      .then(values => {
        previousTimezone = values[0]
        previousTimezoneOffset = values[1]

        console.tron.log(
          'updateTimezoneAndHandleTimezoneChangeIfNeeded ' +
            previousTimezone +
            ' ' +
            currentTimezone +
            ' ' +
            previousTimezoneOffset +
            ' ' +
            currentTimezoneOffset
        )

        return dispatch(
          handleTimezoneChange(
            currentTimezoneOffset,
            previousTimezoneOffset,
            currentTimezone
          )
        )
      })
      .then(() => {
        if (
          !previousTimezone ||
          !previousTimezoneOffset ||
          previousTimezone !== currentTimezone ||
          previousTimezoneOffset !== currentTimezoneOffset
        ) {
          ref
            .child('userInfos')
            .child(GlobalConfig.uid)
            .update({
              timezone: currentTimezone,
              timezoneOffset: currentTimezoneOffset
            })
            .then(() => {
              // This is a fallback for very old alarms which do not have timezone set.
              !previousTimezone &&
                TaskManager.addHttpsCloudTask(
                  'updateCreatorTimezoneForAlarms',
                  {
                    uid: GlobalConfig.uid,
                    timezone: currentTimezone
                  }
                )
            })
        }
      })
  }

const handleTimezoneChange =
  (currentTimezoneOffset, previousTimezoneOffset, currentTimezone) =>
  (dispatch, getState) => {
    console.tron.log('Handling timezone change')

    // The timezone offset is in mins. Get the difference in time in msec. This is the amount time by which
    // the alarms needs to be shifted such that they ring at the right time. For e.g. if an alarm is set for
    // 9 AM IST, it is 10:30 PM EST. The timezone offset for IST is -330 mins. The timezone offset for EST is
    // 300 mins. If the person were to move from IST to EST, we will add 300 - (-330) = 630 mins to the time.
    // which will become 9 AM EST
    const ref = GlobalConfig.rootFirebaseRef
    let firebaseUpdateObj = {}
    let participantIds = []
    const currDate = Date.now()
    const alarms = ownAlarmsSelector(getState())
    let isDstChange = false,
      dstStart = true
    alarms.forEach(alarm => {
      // We don't need to adjust the time if the timezone setting is explicit
      if (
        !alarm.dateString ||
        alarm.timezoneSetting === Constants.TIMEZONE_SETTINGS.EXPLICIT
      ) {
        return
      }

      if (
        alarm.creatorTimezone === currentTimezone ||
        alarm.creatorTimezoneOffset === currentTimezoneOffset
      ) {
        return
      }

      // Update the alarm time
      const alarmDateString = alarm.dateString
      const newAlarmDate = DateTimeUtils.getDateFromFormattedDateString(
        alarmDateString,
        currentTimezone
      )

      // This condition takes care of DST. If there is a change in timezone offset
      // but the time is still same, it means the person didn't move to a timezone
      // where the time was always different
      if (newAlarmDate === alarm.date) {
        isDstChange = true
        dstStart = !!(previousTimezoneOffset - currentTimezoneOffset)
      }

      const changeInTime = newAlarmDate - alarm.date

      const newFirstAlarmDate = alarm.firstAlarmDate
        ? alarm.firstAlarmDate + changeInTime
        : newAlarmDate

      const newAlarmEndDate = alarm.endDate
        ? alarm.endDate + changeInTime
        : GlobalConfig.defaultAlarmEndDate

      firebaseUpdateObj['alarms/' + alarm.id + '/date'] = newAlarmDate
      firebaseUpdateObj['alarms/' + alarm.id + '/endDate'] = newAlarmEndDate
      firebaseUpdateObj['alarms/' + alarm.id + '/firstAlarmDate'] =
        newFirstAlarmDate
      firebaseUpdateObj['alarms/' + alarm.id + '/creatorTimezone'] =
        currentTimezone
      firebaseUpdateObj['alarms/' + alarm.id + '/creatorTimezoneOffset'] =
        currentTimezoneOffset
      firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
      // Update the userInfos object alarmDate. Not sure where the date is used right
      // now but we need to keep the data in sync. TODO: Check if the date is used
      firebaseUpdateObj[
        'userInfos/' + GlobalConfig.uid + '/alarms/' + alarm.id
      ] = newAlarmDate
      if (alarm.backupGroup && alarm.backupGroup.members) {
        firebaseUpdateObj[
          'groupInfos/' + alarm.backupGroup.id + '/backupForAlarms/' + alarm.id
        ] = newAlarmDate
        // Add the participants to the list participants which are participating in at least one of the alarms
        alarm.backupGroup.members.forEach(member => {
          if (member.state !== Constants.ParticipantStates.INACTIVE) {
            // Change the response status back to not responded only if accepted
            if (
              member.responseStatus === Constants.ACCEPT_ALARM &&
              !isDstChange
            ) {
              firebaseUpdateObj[
                'alarms/' +
                  alarm.id +
                  '/backups/group/' +
                  alarm.backupGroup.id +
                  '/members/' +
                  member.id +
                  '/responseStatus'
              ] = Constants.ALARM_RESPONSE_PENDING
            }
            participantIds.push(member.id)
          }
        })
      } else if (alarm.backupContacts && alarm.backupContacts.length > 0) {
        alarm.backupContacts.forEach(participant => {
          firebaseUpdateObj[
            'userInfos/' + participant.id + '/backupForAlarms/' + alarm.id
          ] = newAlarmDate
          // Change the response status back to not responded only if accepted
          if (
            participant.responseStatus === Constants.ACCEPT_ALARM &&
            !isDstChange
          ) {
            firebaseUpdateObj[
              'alarms/' +
                alarm.id +
                '/backups/contacts/' +
                participant.id +
                '/responseStatus'
            ] = Constants.ALARM_RESPONSE_PENDING
          }
          // Add the participants to the list participants which are participating in at least one of the alarms
          participantIds.push(participant.id)
        })
      } else if (alarm.recipient) {
        firebaseUpdateObj[
          'userInfos/' + alarm.recipient.id + '/backupForAlarms/' + alarm.id
        ] = newAlarmDate
        // Change the response status back to not responded only if accepted
        if (
          alarm.recipient.responseStatus === Constants.ACCEPT_ALARM &&
          !isDstChange
        ) {
          firebaseUpdateObj[
            'alarms/' + alarm.id + '/recipient/responseStatus'
          ] = Constants.ALARM_RESPONSE_PENDING
        }
        // Add the participants to the list participants which are participating in at least one of the alarms
        participantIds.push(alarm.recipient.id)
      }

      // Cancel the notification for the alarm regardless. We will schedule another one if alarm will still ring.
      AlarmManager.cancelNotifications(alarm.id)

      // Reschedule notifications if the alarm is active
      if (
        alarm.status === true &&
        (newAlarmDate > currDate || alarm.repeatType !== '')
      ) {
        scheduleNotificationForOwnAlarm(alarm)
      }
    })

    // No change detected, return
    if (isEmpty(firebaseUpdateObj)) {
      return Promise.resolve()
    }

    if (!isDstChange) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('adjustingAlarmsAfterTimezoneChange'),
        duration: Constants.AlertDurations.LONG
      })
    }

    // Update the alarms
    return ref
      .update(firebaseUpdateObj)
      .then(() => {
        // Add a task to notify the participants of timezone change
        const uniqueParticipantIds = arrayUniq(participantIds)
        if (uniqueParticipantIds.length > 0)
          TaskManager.addHttpsCloudTask('notifyParticipantsOfTimezoneChange', {
            uid: GlobalConfig.uid,
            currentTimezoneOffset,
            participantIds: JSON.stringify(uniqueParticipantIds),
            isDstChange,
            dstStart
          })
      })
      .catch(error => {
        LogUtils.logError(
          error,
          'Unable to resync participant alarms after detecting timezone change'
        )
      })
  }

const deleteUserAvatar = uid => dispatch => {
  NavigationUtils.showProgress(
    Constants.ProgressStates.IN_PROGRESS,
    I18n.t('deletingImageFromServer')
  )

  let imageFullImageDeletePromise = FirebaseProxy.storage()
    .ref('/images/' + uid + '/avatarFullImage.jpg')
    .delete()
  let imageThumbnailDeletePromise = FirebaseProxy.storage()
    .ref('/images/' + uid + '/avatarThumbnail.jpg')
    .delete()

  let promises = []

  promises.push(imageFullImageDeletePromise)

  promises.push(imageThumbnailDeletePromise)

  return Promise.all(promises)
    .then(() => {
      GlobalConfig.rootFirebaseRef
        .child('userInfos')
        .child(uid)
        .child('images')
        .remove()

      dispatch(deleteUserAvatarImages())

      NavigationUtils.dismissProgress()
    })
    .catch(error => {
      LogUtils.logError(
        error,
        'Error deleting avatar image from server for user: ' + uid
      )
      GlobalConfig.rootFirebaseRef
        .child('userInfos')
        .child(uid)
        .child('images')
        .remove()

      dispatch(deleteUserAvatarImages())

      NavigationUtils.dismissProgress()
    })
}

const uploadImage = (image, location, metadata) => dispatch => {
  const updatedOn = Date.now()

  NavigationUtils.showProgress(
    Constants.ProgressStates.IN_PROGRESS,
    I18n.t('uploadingImageToServer')
  )

  const imageRef = FirebaseProxy.storage().ref(location)
  const customMetadata = {
    ...metadata,
    [Constants.FILE_METADATA_KEYS.UPDATED_ON]: updatedOn.toString(),
    [Constants.FILE_METADATA_KEYS.IMAGE_TYPE]: Constants.IMAGE_TYPES.FULL_IMAGE,
    [Constants.FILE_METADATA_KEYS.CACHE_CONTROL]: 'public,max-age=31536000'
  }

  const imageUploadPromise = FirebaseProxy.putFile(imageRef, image, {
    customMetadata
  })
  return imageUploadPromise
    .then(() => {
      NavigationUtils.dismissProgress()
      return imageRef.getDownloadURL()
    })
    .then(downloadUrl => {
      return {
        status: 'success',
        downloadUrl
      }
    })
    .catch(error => {
      LogUtils.logError(error, 'Error uploading image to server')
      dispatch(
        setProgress(
          Constants.ProgressStates.ERROR,
          I18n.t('errorUploadingImageToServer'),
          true
        )
      )
      return {
        status: 'failure'
      }
    })
}

const deleteImage = downloadUrl => () => {
  const imageRef = FirebaseProxy.storage().refFromURL(downloadUrl)

  return imageRef
    .delete()
    .then(() => {
      return { status: 'success' }
    })
    .catch(error => {
      LogUtils.logError(error, 'Error deleting image from server')
      return {
        status: 'failure'
      }
    })
}

// Set in redux store and upload to firebase
// Assumes that the client is connected
// avatarFullImage is used in the generate_avatar_thumbnail worker
// If the key is changed, it should be changed in the worker also.
// Same for uploadGroupAvatar
// We are not storing the avatarThumbnailUpdatedOn timestamp for user avatar
// locally. May want to change it later
// eslint-disable-next-line no-unused-vars
// From the web, we pass the entire file object and from the mobile we pass the file path
// eslint-disable-next-line no-unused-vars
const uploadUserAvatar = (uid, avatar) => (dispatch, getState) => {
  const avatarUpdatedOn = Date.now()

  NavigationUtils.showProgress(
    Constants.ProgressStates.IN_PROGRESS,
    I18n.t('uploadingImageToServer')
  )

  const imageRef = FirebaseProxy.storage().ref(
    '/images/' + uid + '/avatarFullImage.jpg'
  )

  const customMetadata = {
    [Constants.FILE_METADATA_KEYS.ENTITY_TYPE]: Constants.ENTITY_TYPES.USER,
    [Constants.FILE_METADATA_KEYS.UID]: uid,
    [Constants.FILE_METADATA_KEYS.UPDATED_ON]: avatarUpdatedOn.toString(),
    [Constants.FILE_METADATA_KEYS.IMAGE_TYPE]: Constants.IMAGE_TYPES.FULL_IMAGE,
    [Constants.FILE_METADATA_KEYS.CACHE_CONTROL]: 'public,max-age=31536000'
  }

  const imageUploadPromise = FirebaseProxy.putFile(imageRef, avatar, {
    customMetadata
  })
  return imageUploadPromise
    .then(() => {
      return imageRef.getDownloadURL()
    })
    .then(profileImagePath => {
      GlobalConfig.rootFirebaseRef
        .child('userInfos')
        .child(uid)
        .child('images')
        .update({
          avatarFullImageUrl: profileImagePath,
          avatarFullImageUpdatedOn: avatarUpdatedOn
        })

      if (avatar !== undefined) {
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.UPLOAD_NEW_PROFILE_IMAGE,
          {
            [Constants.UserAnalyticsEventParameters.ENTRY_POINT]:
              Constants.NEW_PROFILE_IMAGE_ENTRY_POINTS.USER
          }
        )
      }
      const images = {
        avatarThumbnailUrl: profileImagePath,
        avatarFullImageUrl: profileImagePath,
        avatarFullImageUpdatedOn: avatarUpdatedOn,
        avatarThumbnailUpdatedOn: avatarUpdatedOn
      }

      dispatch(setUserAvatarImages(images))

      NavigationUtils.dismissProgress()
    })
    .catch(error => {
      LogUtils.logError(error, 'Error uploading user avatar to server')
      dispatch(
        setProgress(
          Constants.ProgressStates.ERROR,
          I18n.t('errorUploadingImageToServer'),
          true
        )
      )
    })
}

// eslint-disable-next-line no-unused-vars
const uploadGroupAvatar = (groupId, avatar) => (dispatch, getState) => {
  const avatarUpdatedOn = Date.now()

  NavigationUtils.showProgress(
    Constants.ProgressStates.IN_PROGRESS,
    I18n.t('uploadingImageToServer')
  )

  const imageRef = FirebaseProxy.storage().ref(
    '/images/' + groupId + '/avatarFullImage.jpg'
  )

  const customMetadata = {
    [Constants.FILE_METADATA_KEYS.ENTITY_TYPE]: Constants.ENTITY_TYPES.GROUP,
    [Constants.FILE_METADATA_KEYS.UID]: groupId,
    [Constants.FILE_METADATA_KEYS.UPDATED_ON]: avatarUpdatedOn.toString(),
    [Constants.FILE_METADATA_KEYS.IMAGE_TYPE]: Constants.IMAGE_TYPES.FULL_IMAGE,
    [Constants.FILE_METADATA_KEYS.CACHE_CONTROL]: 'public,max-age=31536000'
  }

  const imageUploadPromise = FirebaseProxy.putFile(imageRef, avatar, {
    customMetadata
  })
  return imageUploadPromise
    .then(() => {
      return imageRef.getDownloadURL()
    })
    .then(profileImagePath => {
      GlobalConfig.rootFirebaseRef
        .child('groups')
        .child(groupId)
        .child('images')
        .update({
          avatarFullImageUrl: profileImagePath,
          avatarFullImageUpdatedOn: avatarUpdatedOn
        })

      if (avatar !== undefined) {
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.UPLOAD_NEW_PROFILE_IMAGE,
          {
            [Constants.UserAnalyticsEventParameters.ENTRY_POINT]:
              Constants.NEW_PROFILE_IMAGE_ENTRY_POINTS.GROUP
          }
        )
      }
      const images = {
        avatarThumbnailUrl: profileImagePath,
        avatarFullImageUrl: profileImagePath,
        avatarFullImageUpdatedOn: avatarUpdatedOn,
        avatarThumbnailUpdatedOn: avatarUpdatedOn
      }

      dispatch(updateGroupAvatarImages(groupId, images))

      NavigationUtils.dismissProgress()
    })
    .catch(error => {
      LogUtils.logError(error, 'Error uploading group avatar to server')
      dispatch(
        setProgress(
          Constants.ProgressStates.ERROR,
          I18n.t('errorUploadingImageToServer'),
          true
        )
      )
    })
}

const deleteGroupAvatar = groupId => dispatch => {
  NavigationUtils.showProgress(
    Constants.ProgressStates.IN_PROGRESS,
    I18n.t('deletingImageFromServer')
  )

  let imageFullImageDeletePromise = FirebaseProxy.storage()
    .ref('/images/' + groupId + '/avatarFullImage.jpg')
    .delete()
  let imageThumbnailDeletePromise = FirebaseProxy.storage()
    .ref('/images/' + groupId + '/avatarThumbnail.jpg')
    .delete()

  let promises = []

  promises.push(imageFullImageDeletePromise)

  promises.push(imageThumbnailDeletePromise)

  return Promise.all(promises)
    .then(() => {
      GlobalConfig.rootFirebaseRef
        .child('groups')
        .child(groupId)
        .child('images')
        .remove()

      dispatch(deleteGroupAvatarImages(groupId))

      NavigationUtils.dismissProgress()
    })
    .catch(error => {
      LogUtils.logError(
        error,
        'Error deleting avatar image from server for group: ' + groupId
      )
      GlobalConfig.rootFirebaseRef
        .child('groups')
        .child(groupId)
        .child('images')
        .remove()

      dispatch(deleteGroupAvatarImages(groupId))

      NavigationUtils.dismissProgress()
    })
}

const loadGroups = () => (dispatch, getState) => {
  // eslint-disable-line no-unused-vars
  const ref = GlobalConfig.rootFirebaseRef

  FirebaseManager.addFirebaseRefChildAdded(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('groups'),
    snapshot => {
      const groupId = snapshot.key
      const groupData = snapshot.val()
      const existingGroup = Utils.getObjectWithId(
        getState().groups.groups,
        groupId
      )
      if (
        !existingGroup ||
        (groupData.lastUpdatedAt &&
          existingGroup.lastUpdatedAt !== groupData.lastUpdatedAt)
      ) {
        // Update the group in the redux store
        dispatch(updateGroup(groupId, groupData))
      }

      FirebaseManager.addFirebaseRefValueChanged(
        'App',
        ref.child('groups').child(groupId).child('images'),
        imagesSnapshot => {
          const newExistingGroup = Utils.getObjectWithId(
            getState().groups.groups,
            groupId
          )
          if (!newExistingGroup) {
            return
          }
          const existingImages = newExistingGroup.images

          if (imagesSnapshot.exists()) {
            const newImages = imagesSnapshot.val()
            if (!isObjEqual(existingImages, newImages)) {
              dispatch(updateGroupAvatarImages(groupId, newImages))
            }
          } else if (!isEmpty(existingImages)) {
            dispatch(deleteGroupAvatarImages(groupId))
          }
        }
      )
    }
  )

  FirebaseManager.addFirebaseRefChildChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('groups'),
    snapshot => {
      const groupId = snapshot.key
      const groupData = snapshot.val()
      const existingGroup = Utils.getObjectWithId(
        getState().groups.groups,
        groupId
      )
      if (
        !existingGroup ||
        (groupData.lastUpdatedAt &&
          existingGroup.lastUpdatedAt !== groupData.lastUpdatedAt)
      ) {
        // Update the group in the redux store
        dispatch(updateGroup(groupId, groupData))
      }
    }
  )

  // Group deletion not allowed at the moment

  FirebaseManager.addFirebaseRefChildAdded(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('removedFromGroups'),
    snapshot => {
      const groupId = snapshot.key
      const groupData = snapshot.val()
      const existingGroup = Utils.getObjectWithId(
        getState().groups.groups,
        groupId
      )
      if (
        !existingGroup ||
        (groupData.lastUpdatedAt &&
          existingGroup.lastUpdatedAt !== groupData.lastUpdatedAt)
      ) {
        // Update the group in the redux store
        dispatch(updateGroup(groupId, groupData, true))
      }
      FirebaseManager.unbindFirebaseEventHandlerForValueChanged(
        'App',
        ref.child('images').child(groupId).child('avatarThumbnailUpdatedOn')
      )
    }
  )

  FirebaseManager.addFirebaseRefChildRemoved(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('removedFromGroups'),
    snapshot => {
      const groupId = snapshot.key
      // Make sure the group doesn't exist for user before removing it.
      // If the member is added back to the group, the group is removed from
      // removedFromGroups and added back to groups. In that case, we don't want
      // to remove the group locally but only update its status
      ref
        .child('userInfos')
        .child(GlobalConfig.uid)
        .child('groups')
        .child(groupId)
        .child('id')
        .once('value')
        .then(groupSnapshot => {
          if (!groupSnapshot.exists()) {
            // Remove the group from the redux store
            dispatch(removeGroup(groupId))
          }
        })
    }
  )
}

// TODO: Implement contact deletion if user removes the contact from the phone contacts
// Need to define what would happen to the alarms which have that contact as a backup
// because this will happen in the background and user can't be informed like we do in group deletion
const loadContacts = () => (dispatch, getState) => {
  // eslint-disable-line no-unused-vars
  const ref = GlobalConfig.rootFirebaseRef

  ref
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('contacts')
    .once('value')
    .then(contactsSnapshot => {
      if (contactsSnapshot.exists()) {
        let contacts = []
        contactsSnapshot.forEach(snapshot => {
          contacts.push(snapshot.val())
        })
        dispatch(addContacts(contacts))
      }

      FirebaseManager.addFirebaseRefChildAdded(
        'App',
        ref.child('userInfos').child(GlobalConfig.uid).child('contacts'),
        snapshot => {
          const contact = snapshot.val()
          const contactId = contact.id
          const contactName = contact.name
          const contactMobileNumber = contact.mobileNumber
          const contactSource = contact.source
          const existingContact = Utils.getObjectWithId(
            getState().contacts.contacts,
            contactId
          )
          const dontUpdateContact =
            existingContact &&
            existingContact.id === contactId &&
            existingContact.name === contactName &&
            existingContact.mobileNumber === contactMobileNumber &&
            existingContact.source === contactSource
          if (!dontUpdateContact) {
            dispatch(addContact(contact))
          }

          FirebaseManager.addFirebaseRefValueChanged(
            'App',
            ref.child('userInfos').child(contactId).child('images'),
            imagesSnapshot => {
              const newExistingContact = Utils.getObjectWithId(
                getState().contacts.contacts,
                contactId
              )

              if (!newExistingContact) {
                return
              }
              const existingImages = newExistingContact.images

              if (imagesSnapshot.exists()) {
                const newImages = imagesSnapshot.val()
                if (!isObjEqual(existingImages, newImages)) {
                  dispatch(setContactAvatarImages(contactId, newImages))
                }
              } else if (!isEmpty(existingImages)) {
                dispatch(deleteContactAvatarImages(contactId))
              }
            }
          )
        }
      )
    })

  FirebaseManager.addFirebaseRefChildChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('contacts'),
    snapshot => {
      const contact = snapshot.val()
      const contactId = contact.id
      const contactName = contact.name
      const contactMobileNumber = contact.mobileNumber
      const contactSource = contact.source
      const existingContact = Utils.getObjectWithId(
        getState().contacts.contacts,
        contactId
      )
      const dontUpdateContact =
        existingContact &&
        existingContact.id === contactId &&
        existingContact.name === contactName &&
        existingContact.mobileNumber === contactMobileNumber &&
        existingContact.source === contactSource
      if (!dontUpdateContact) {
        dispatch(addContact(contact))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('totalNumberOfContacts'),
    snapshot => {
      if (snapshot.exists()) {
        const totalNumberOfContacts = snapshot.val()
        dispatch(setTotalNumberOfContacts(totalNumberOfContacts))
      }
    }
  )

  FirebaseManager.addFirebaseRefValueChanged(
    'App',
    ref
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('contactsLastUpdatedAt'),
    snapshot => {
      if (snapshot.exists()) {
        const contactsLastUpdatedAt = snapshot.val()
        dispatch(setContactsLastUpdatedAt(contactsLastUpdatedAt))
      }
    }
  )

  FirebaseManager.addFirebaseRefChildRemoved(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('contacts'),
    snapshot => {
      const contactId = snapshot.key
      dispatch(deleteContact(contactId))
    }
  )

  FirebaseManager.addFirebaseRefChildAdded(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('invitedContacts'),
    snapshot => {
      const contact = snapshot.val()
      const contactId = contact.id
      const contactName = contact.name
      const contactMobileNumber = contact.mobileNumber
      const existingContact = Utils.getObjectWithId(
        getState().contacts.invitedContacts,
        contactId
      )
      const dontUpdateContact =
        existingContact &&
        existingContact.id === contactId &&
        existingContact.name === contactName &&
        existingContact.mobileNumber === contactMobileNumber
      if (!dontUpdateContact) {
        dispatch(addInvitedContact(contact))
      }
    }
  )

  FirebaseManager.addFirebaseRefChildChanged(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('invitedContacts'),
    snapshot => {
      const contact = snapshot.val()
      const contactId = contact.id
      const contactName = contact.name
      const contactMobileNumber = contact.mobileNumber
      const existingContact = Utils.getObjectWithId(
        getState().contacts.invitedContacts,
        contactId
      )
      const dontUpdateContact =
        existingContact &&
        existingContact.id === contactId &&
        existingContact.name === contactName &&
        existingContact.mobileNumber === contactMobileNumber
      if (!dontUpdateContact) {
        dispatch(addInvitedContact(contact))
      }
    }
  )

  FirebaseManager.addFirebaseRefChildRemoved(
    'App',
    ref.child('userInfos').child(GlobalConfig.uid).child('invitedContacts'),
    snapshot => {
      const contactId = snapshot.key
      dispatch(deleteInvitedContact(contactId))
    }
  )
}

const loadConnections = () => (dispatch, getState) => {
  // eslint-disable-line no-unused-vars
  const ref = GlobalConfig.rootFirebaseRef

  ref
    .child('connections')
    .child(GlobalConfig.uid)
    .once('value')
    .then(connectionsSnapshot => {
      if (!connectionsSnapshot.exists()) {
        return
      }

      let connections = []
      connectionsSnapshot.forEach(snapshot => {
        const id = snapshot.key
        const name = snapshot.val().name
        const mobileNumber = snapshot.val().mobileNumber
        const countryCode = snapshot.val().countryCode
        const existingConnection = Utils.getObjectWithId(
          getState().connections.connections,
          id
        )
        const dontUpdateConnection =
          existingConnection && existingConnection.name === name
        if (!dontUpdateConnection) {
          connections.push({
            id,
            name,
            mobileNumber,
            countryCode
          })
        }
      })

      if (connections.length > 0) {
        dispatch(addConnections(connections))
      }

      FirebaseManager.addFirebaseRefChildAdded(
        'App',
        ref.child('connections').child(GlobalConfig.uid),
        snapshot => {
          const id = snapshot.key
          const connection = snapshot.val()
          const name = connection.name
          const mobileNumber = connection.mobileNumber
          const countryCode = connection.countryCode
          const existingConnection = Utils.getObjectWithId(
            getState().connections.connections,
            id
          )
          const dontUpdateConnection =
            existingConnection && existingConnection.name === name
          if (!dontUpdateConnection) {
            dispatch(addConnection(id, { name, mobileNumber, countryCode }))
          }

          FirebaseManager.addFirebaseRefValueChanged(
            'App',
            ref.child('userInfos').child(id).child('images'),
            imagesSnapshot => {
              const newExistingConnection = Utils.getObjectWithId(
                getState().connections.connections,
                id
              )

              if (!newExistingConnection) {
                return
              }
              const existingImages = newExistingConnection.images

              if (imagesSnapshot.exists()) {
                const newImages = imagesSnapshot.val()
                if (!isObjEqual(existingImages, newImages)) {
                  dispatch(setConnectionAvatarImages(id, newImages))
                }
              } else if (!isEmpty(existingImages)) {
                dispatch(deleteConnectionAvatarImages(id))
              }
            }
          )
        }
      )
    })

  FirebaseManager.addFirebaseRefChildChanged(
    'App',
    ref.child('connections').child(GlobalConfig.uid),
    snapshot => {
      const id = snapshot.key
      const name = snapshot.val().name
      const mobileNumber = snapshot.val().mobileNumber
      const countryCode = snapshot.val().countryCode
      const existingConnection = Utils.getObjectWithId(
        getState().connections.connections,
        id
      )
      const dontUpdateConnection =
        existingConnection && existingConnection.name === name
      if (!dontUpdateConnection) {
        dispatch(addConnection(id, { name, mobileNumber, countryCode }))
      }
    }
  )
}

const setPastAlarmOccurrenceResponse =
  (alarm, occurrenceTime, timestamp, response) => (dispatch, getState) => {
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        occurrenceTime,
        GlobalConfig.uid,
        timestamp,
        response
      )
    )

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      AlarmUtils.setPastAlarmOccurrenceResponse(
        alarm,
        occurrenceTime,
        timestamp,
        response
      )
    } else {
      console.tron.log(
        'Adding set past alarm occurrence response pending action'
      )
      dispatch(
        addSetPastAlarmOccurrenceResponseSoftPendingAction(
          alarm,
          occurrenceTime,
          timestamp,
          response
        )
      )
    }
  }

const markAlarmAsAcknowledged = alarmId => (dispatch, getState) => {
  retrieveOwnAlarmByAlarmId(alarmId, getState).then(alarm => {
    // We have observed that sometimes the alarm is not found in production
    // Although, we have never been able to reproduce, adding this sanity
    // check here to avoid any aborts in the app.
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToMarkAlarmDone'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'markAlarmAsAcknowledged'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.PERSONAL_ALARM_DONE
      )
    )

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      status: alarm.repeatType !== ''
    })
    dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    const participants = AlarmUtils.getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    const numParticipants = participants.length
    if (persistOnFirebase) {
      AlarmUtils.markAlarmAsAcknowledgedCore(alarm, currAlarmDate, currDate)
      numParticipants > 0 &&
        NavigationUtils.showTransientAlert({
          message: I18n.t('participantsAreNotified')
        })
    } else {
      console.tron.log('Adding mark personal alarm done pending action')
      dispatch(
        addMarkPersonalAlarmDoneSoftPendingAction(
          alarm,
          currAlarmDate,
          currDate
        )
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.MARK_PERSONAL_ALARM_DONE,
      {}
    )
  })
}

const markRecipientCreatorAlarmAsUnskipped =
  alarmId => (dispatch, getState) => {
    const ownAlarmSelector = makeOwnAlarmSelector()
    const alarm = ownAlarmSelector(getState(), { alarmId })

    if (isEmpty(alarm)) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToMarkAlarmUnskipped'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'markRecipientCreatorAlarmAsUnskipped'
      })
      return
    }
    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.RECIPIENT_CREATOR_ALARM_UNKSIP
      )
    )

    AlarmUtils.markRecipientCreatorAlarmAsUnskipped(
      alarm,
      currAlarmDate,
      currDate
    )
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.MARK_RECIPIENT_CREATOR_ALARM_UNSKIPPED,
      {}
    )
  }

const markPersonalAlarmAsUnskipped = alarmId => (dispatch, getState) => {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const alarm = ownAlarmSelector(getState(), { alarmId })

  if (isEmpty(alarm)) {
    NavigationUtils.showTransientAlert({
      message: I18n.t('unableToMarkAlarmUnskipped'),
      duration: Constants.AlertDurations.LONG
    })
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
      [Constants.UserAnalyticsEventParameters.SOURCE]:
        'markPersonalAlarmAsUnskipped'
    })
    return
  }
  const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
  const currDate = Date.now()
  dispatch(
    addAlarmAcknowledgementAction(
      alarm,
      currAlarmDate,
      GlobalConfig.uid,
      currDate,
      Constants.PERSONAL_ALARM_UNSKIP
    )
  )

  const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
    status: true
  })
  dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

  AlarmUtils.markPersonalAlarmAsUnskipped(alarm, currAlarmDate, currDate)
  FirebaseProxy.logEvent(
    Constants.UserAnalyticsEvents.MARK_PERSONAL_ALARM_UNSKIPPED,
    {}
  )
}

const markPersonalAlarmAsUndone = alarmId => (dispatch, getState) => {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const alarm = ownAlarmSelector(getState(), { alarmId })

  if (isEmpty(alarm)) {
    NavigationUtils.showTransientAlert({
      message: I18n.t('unableToMarkAlarmUndone'),
      duration: Constants.AlertDurations.LONG
    })
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
      [Constants.UserAnalyticsEventParameters.SOURCE]:
        'markPersonalAlarmAsUndone'
    })
    return
  }

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
  const currDate = Date.now()
  dispatch(
    addAlarmAcknowledgementAction(
      alarm,
      currAlarmDate,
      GlobalConfig.uid,
      currDate,
      Constants.PERSONAL_ALARM_UNDONE
    )
  )

  const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
    status: true
  })
  dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

  AlarmUtils.markPersonalAlarmAsUndone(alarm, currAlarmDate, currDate)
  FirebaseProxy.logEvent(
    Constants.UserAnalyticsEvents.MARK_PERSONAL_ALARM_UNDONE,
    {}
  )
}

const skipPersonalAlarm = alarmId => (dispatch, getState) => {
  retrieveOwnAlarmByAlarmId(alarmId, getState).then(alarm => {
    // We have observed that sometimes the alarm is not found in production
    // Although, we have never been able to reproduce, adding this sanity
    // check here to avoid any aborts in the app.
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToSkipAlarm'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]: 'skipPersonalAlarm'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.PERSONAL_ALARM_SKIP
      )
    )

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      status: alarm.repeatType !== ''
    })
    dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    const participants = AlarmUtils.getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    const numParticipants = participants.length
    if (persistOnFirebase) {
      AlarmUtils.skipPersonalAlarmCore(alarm, currAlarmDate, currDate)
      numParticipants > 0 &&
        NavigationUtils.showTransientAlert({
          message: I18n.t('participantsAreNotified')
        })
    } else {
      console.tron.log('Adding skip alarm pending action')

      dispatch(
        addSkipPersonalAlarmSoftPendingAction(alarm, currAlarmDate, currDate)
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.SKIP_PERSONAL_ALARM,
      {}
    )
  })
}

const markRecipientAlarmAsDone = alarmId => (dispatch, getState) => {
  retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToMarkAlarmDone'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'markRecipientAlarmAsDone'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.RECIPIENT_ALARM_DONE
      )
    )

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      status: alarm.repeatType !== ''
    })
    dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      AlarmUtils.markRecipientAlarmAsDoneCore(alarm, currAlarmDate, currDate)
      const creatorName = AlarmUtils.resolveAlarmCreatorName(
        getState().contacts.contacts,
        alarm.creator,
        alarm.creatorMobileNumber,
        alarm.creatorName
      )
      NavigationUtils.showTransientAlert({
        message: I18n.t('creatorIsNotified', { creatorName })
      })
    } else {
      console.tron.log('Adding mark recipient alarm done pending action')
      dispatch(
        addMarkRecipientAlarmDoneSoftPendingAction(
          alarm,
          currAlarmDate,
          currDate
        )
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.MARK_BUDDY_ALARM_DONE,
      {}
    )
  })
}
const markPersonalParticipantAlarmAsUnskipped =
  alarmId => (dispatch, getState) => {
    retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
      if (!alarm) {
        NavigationUtils.showTransientAlert({
          message: I18n.t('unableToMarkAlarmUnskipped'),
          duration: Constants.AlertDurations.LONG
        })
        FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
          [Constants.UserAnalyticsEventParameters.SOURCE]:
            'markPersonalParticipantAlarmAsUnskipped'
        })
        return
      }

      const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
      const currDate = Date.now()
      dispatch(
        addAlarmAcknowledgementAction(
          alarm,
          currAlarmDate,
          GlobalConfig.uid,
          currDate,
          Constants.PERSONAL_PARTICIPANT_ALARM_UNSKIP
        )
      )

      AlarmUtils.markPersonalParticipantAlarmAsUnskipped(
        alarm,
        currAlarmDate,
        currDate
      )
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents.MARK_PERSONAL_PARTICIPANT_ALARM_UNSKIPPED,
        {}
      )
    })
  }

const markRecipientAlarmAsUnskipped = alarmId => (dispatch, getState) => {
  retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToMarkAlarmUnskipped'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'markRecipientAlarmAsUnskipped'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.RECIPIENT_CREATOR_ALARM_UNKSIP
      )
    )

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      status: true
    })
    dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

    AlarmUtils.markRecipientAlarmAsUnskipped(alarm, currAlarmDate, currDate)
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.MARK_RECIPIENT_ALARM_UNSKIPPED,
      {}
    )
  })
}
const markRecipientAlarmAsUndone = alarmId => (dispatch, getState) => {
  retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToMarkAlarmUndone'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'markRecipientAlarmAsUndone'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.RECIPIENT_ALARM_UNDONE
      )
    )

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      status: true
    })
    dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

    AlarmUtils.markRecipientAlarmAsUndone(alarm, currAlarmDate, currDate)
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.MARK_BUDDY_ALARM_UNDONE,
      {}
    )
  })
}

const skipPersonalParticipantAlarm = alarmId => (dispatch, getState) => {
  retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
    // We have observed that sometimes the alarm is not found in production
    // Although, we have never been able to reproduce, adding this sanity
    // check here to avoid any aborts in the app.
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToSkipAlarm'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'skipPersonalParticipantAlarm'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.PERSONAL_PARTICIPANT_ALARM_SKIP
      )
    )

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    if (persistOnFirebase) {
      AlarmUtils.skipPersonalParticipantAlarmCore(
        alarm,
        currAlarmDate,
        currDate
      )
    } else {
      console.tron.log('Adding skip participant alarm pending action')
      dispatch(
        addSkipPersonalParticipantAlarmSoftPendingAction(
          alarm,
          currAlarmDate,
          currDate
        )
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.SKIP_PERSONAL_PARTICIPANT_ALARM,
      {}
    )
  })
}

const skipRecipientAlarm = alarmId => (dispatch, getState) => {
  retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
    // We have observed that sometimes the alarm is not found in production
    // Although, we have never been able to reproduce, adding this sanity
    // check here to avoid any aborts in the app.
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToSkipAlarm'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]: 'skipRecipientAlarm'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.RECIPIENT_ALARM_SKIP
      )
    )

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      status: alarm.repeatType !== ''
    })
    dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    if (persistOnFirebase) {
      AlarmUtils.skipRecipientAlarmCore(alarm, currAlarmDate, currDate)
      const creatorName = AlarmUtils.resolveAlarmCreatorName(
        getState().contacts.contacts,
        alarm.creator,
        alarm.creatorMobileNumber,
        alarm.creatorName
      )
      NavigationUtils.showTransientAlert({
        message: I18n.t('creatorIsNotified', { creatorName })
      })
    } else {
      console.tron.log('Adding skip alarm pending action')
      dispatch(
        addSkipRecipientAlarmSoftPendingAction(alarm, currAlarmDate, currDate)
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.SKIP_RECIPIENT_ALARM,
      {}
    )
  })
}

const skipRecipientCreatorAlarm = alarmId => (dispatch, getState) => {
  retrieveOwnAlarmByAlarmId(alarmId, getState).then(alarm => {
    // We have observed that sometimes the alarm is not found in production
    // Although, we have never been able to reproduce, adding this sanity
    // check here to avoid any aborts in the app.
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToSkipAlarm'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'skipRecipientCreatorAlarm'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        Constants.RECIPIENT_CREATOR_ALARM_SKIP
      )
    )

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    if (persistOnFirebase) {
      AlarmUtils.skipRecipientCreatorAlarmCore(alarm, currAlarmDate, currDate)
    } else {
      console.tron.log('Adding skip recipient creator alarm pending action')
      dispatch(
        addSkipRecipientCreatorAlarmSoftPendingAction(
          alarm,
          currAlarmDate,
          currDate
        )
      )
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.SKIP_RECIPIENT_CREATOR_ALARM,
      {}
    )
  })
}

const retrieveBackupAlarmByAlarmId = function (alarmId, getState) {
  if (!alarmId) {
    return Promise.resolve(null)
  }

  const participantAlarmSelector = makeParticipantAlarmSelector()
  const alarm = participantAlarmSelector(getState(), { alarmId })
  if (!isEmpty(alarm)) {
    return Promise.resolve(alarm)
  }

  return GlobalConfig.rootFirebaseRef
    .child('alarms')
    .child(alarmId)
    .once('value')
    .then(snapshot => {
      if (!snapshot.exists()) {
        return null
      }

      const alarmData = snapshot.val()
      const newAlarm = AlarmUtils.createAlarm(alarmData)
      return newAlarm
    })
}

const retrieveOwnAlarmByAlarmId = function (alarmId, getState) {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const alarm = ownAlarmSelector(getState(), { alarmId })
  if (!isEmpty(alarm)) {
    return Promise.resolve(alarm)
  }

  return GlobalConfig.rootFirebaseRef
    .child('alarms')
    .child(alarmId)
    .once('value')
    .then(snapshot => {
      if (!snapshot.exists()) {
        return null
      }

      const alarmData = snapshot.val()
      const newAlarm = AlarmUtils.createAlarm(alarmData)
      return newAlarm
    })
}

const setBackupResponseStatusForAlarm =
  (alarmId, responseStatus) => (dispatch, getState) => {
    retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
      // Sanity checks
      if (!alarm) {
        NavigationUtils.showTransientAlert({
          message: I18n.t('unableToSetAlarmResponse'),
          duration: Constants.AlertDurations.LONG
        })
        LogUtils.logError(
          new Error(
            "Can't set participant response for personal alarm action because alarm is null " +
              alarmId
          )
        )
        return
      }

      const tempSoftUpdatedAlarm = AlarmUtils.updateAlarmParticipant(
        alarm,
        GlobalConfig.uid,
        {
          responseStatus: responseStatus,
          seenOn: Date.now()
        }
      )
      const softUpdatedAlarm = AlarmUtils.updateAlarm(tempSoftUpdatedAlarm, {
        responseStatus: responseStatus
      })

      dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

      const persistOnFirebase =
        getState().appState.isConnected &&
        getState().appState.authenticatedWithFirebase

      AlarmUtilsWithExtras.setBackupResponseStatusForAlarm(
        alarm,
        alarm.responseStatus,
        responseStatus,
        alarm.order,
        persistOnFirebase
      )

      if (!persistOnFirebase) {
        console.tron.log('Adding set participant alarm response pending action')

        dispatch(
          addSetParticipantAlarmResponseSoftPendingAction(alarm, responseStatus)
        )
      }

      responseStatus === Constants.ACCEPT_ALARM
        ? FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ACCEPT_ALARM, {
            [Constants.UserAnalyticsEventParameters.ALARM_TYPE]: alarm.type
          })
        : FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.DECLINE_ALARM, {
            [Constants.UserAnalyticsEventParameters.ALARM_TYPE]: alarm.type
          })
    })
  }

const setRecipientResponseStatusForAlarm =
  (alarmId, responseStatus) => (dispatch, getState) => {
    retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
      // Sanity checks
      if (!alarm) {
        NavigationUtils.showTransientAlert({
          message: I18n.t('unableToSetAlarmResponse'),
          duration: Constants.AlertDurations.LONG
        })
        LogUtils.logError(
          new Error(
            "Can't set recipient response for alarm action because alarm is null " +
              alarmId
          )
        )
        return
      }

      const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
        responseStatus: responseStatus,
        recipient: {
          ...alarm.recipient,
          responseStatus: responseStatus,
          seenOn: Date.now()
        }
      })

      dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

      const persistOnFirebase =
        getState().appState.isConnected &&
        getState().appState.authenticatedWithFirebase

      AlarmUtilsWithExtras.setRecipientResponseStatusForAlarm(
        alarm,
        responseStatus,
        persistOnFirebase
      )

      if (!persistOnFirebase) {
        console.tron.log('Adding set recipient alarm response pending action')

        dispatch(
          addSetRecipientAlarmResponseSoftPendingAction(alarm, responseStatus)
        )
      }

      responseStatus === Constants.ACCEPT_ALARM
        ? FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ACCEPT_ALARM, {
            [Constants.UserAnalyticsEventParameters.ALARM_TYPE]: alarm.type
          })
        : FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.DECLINE_ALARM, {
            [Constants.UserAnalyticsEventParameters.ALARM_TYPE]: alarm.type
          })
    })
  }

// Set alarmInstanceTimestamp to alarm.date as a fail safe in case user presses a notification
// action for an old alarm
const markCreatorResponseForSimultaneousAlarm =
  (alarmId, response, rescheduleAlarm) => (dispatch, getState) => {
    const ownAlarmSelector = makeOwnAlarmSelector()
    const alarm = ownAlarmSelector(getState(), { alarmId })

    if (isEmpty(alarm)) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToSetGroupAlarmResponse', {
          response: response.toLowerCase()
        }),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'markCreatorResponseForSimultaneousAlarm'
      })
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currDate = Date.now()
    dispatch(
      addAlarmAcknowledgementAction(
        alarm,
        currAlarmDate,
        GlobalConfig.uid,
        currDate,
        response,
        rescheduleAlarm
      )
    )

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    if (persistOnFirebase) {
      AlarmUtils.markCreatorResponseForSimultaneousAlarmCore(
        alarm,
        currAlarmDate,
        currDate,
        response,
        rescheduleAlarm
      )
    } else {
      console.tron.log(
        'Adding mark creator response for group alarm pending action'
      )
      dispatch(
        addMarkCreatorResponseForGroupAlarmSoftPendingAction(
          alarm,
          response,
          rescheduleAlarm,
          currAlarmDate,
          currDate
        )
      )
    }

    response === Constants.GROUP_ALARM_YES
      ? FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CONFIRM_GROUP_ALARM,
          {}
        )
      : FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.DECLINE_GROUP_ALARM,
          {}
        )
  }

// Set alarmInstanceTimestamp to alarm.date as a fail safe in case user presses a notification
// action for an old alarm
const markParticipantResponseForSimultaneousAlarm =
  (alarmId, response, rescheduleAlarm) => (dispatch, getState) => {
    retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
      if (!alarm) {
        NavigationUtils.showTransientAlert({
          message: I18n.t('unableToSetGroupAlarmResponse', {
            response: response.toLowerCase()
          }),
          duration: Constants.AlertDurations.LONG
        })
        FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
          [Constants.UserAnalyticsEventParameters.SOURCE]:
            'markParticipantResponseForSimultaneousAlarm'
        })
        return
      }

      const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
      const currDate = Date.now()
      dispatch(
        addAlarmAcknowledgementAction(
          alarm,
          currAlarmDate,
          GlobalConfig.uid,
          currDate,
          response,
          rescheduleAlarm
        )
      )

      let updateAlarmResponseStatus = true
      if (
        response === Constants.GROUP_ALARM_NO &&
        alarm.repeatType !== '' &&
        alarm.responseStatus !== Constants.ALARM_RESPONSE_PENDING
      ) {
        updateAlarmResponseStatus = false
      }

      const persistOnFirebase =
        getState().appState.isConnected &&
        getState().appState.authenticatedWithFirebase
      if (persistOnFirebase) {
        AlarmUtils.markParticipantResponseForSimultaneousAlarmCore(
          alarm,
          currAlarmDate,
          currDate,
          response,
          updateAlarmResponseStatus,
          rescheduleAlarm
        )
      } else {
        console.tron.log(
          'Adding mark participant response for group alarm pending action'
        )
        dispatch(
          addMarkParticipantResponseForGroupAlarmSoftPendingAction(
            alarm,
            response,
            rescheduleAlarm,
            updateAlarmResponseStatus,
            currAlarmDate,
            currDate
          )
        )
      }

      response === Constants.GROUP_ALARM_YES
        ? FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.CONFIRM_GROUP_ALARM,
            {}
          )
        : FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.DECLINE_GROUP_ALARM,
            {}
          )
    })
  }

const syncAlarmsStatus = connected => (dispatch, getState) => {
  const alarms = getState().alarms.alarms
  let firebaseUpdateObj = {}
  let performSync = false
  let batchedActions = []
  const currDate = Date.now()
  alarms.forEach(alarm => {
    // Only update the status for one time enabled alarms
    // acknowledged group alarms because they are not
    // disabled when confirmed or declined
    if (alarm.status === false || AlarmUtils.getNewStatusForAlarm(alarm)) {
      return
    }
    // Get the effective alarm date and check if it is still in the window and disable
    // only if outside the window
    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const currentAlarmOccurrenceAcknowledged =
      AlarmUtils.isCurrentAlarmOccurrenceAcknowledged(alarm)

    if (
      currentAlarmOccurrenceAcknowledged &&
      currAlarmDate + GlobalConfig.alarmPreviousOccurrenceThreshold < currDate
    ) {
      const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
        status: false,
        lastUpdatedAt: currDate
      })
      batchedActions.push(softUpdateLocalAlarm(softUpdatedAlarm))

      firebaseUpdateObj['alarms/' + alarm.id + '/status'] = false
      firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
      performSync = true
    }
  })

  if (performSync) {
    dispatch(batchActions(...batchedActions))
    if (connected) {
      GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj).catch(error => {
        LogUtils.logWarning(error.message, 'Unable to sync alarm status')
      })
    }
  }
}

const syncAlarmsStatusLocally = () => (dispatch, getState) => {
  const alarms = ownAlarmsSelector(getState())
  const participantAlarms = participantAlarmsSelector(getState())
  let myExpiredAlarms = []
  let participantExpiredAlarms = []
  let myAlarmsToBeUpdatedLocally = []
  let participantAlarmsToBeUpdatedLocally = []
  let myAlarmsToBeUnsnoozed = []
  let participantAlarmsToBeUnsnoozed = []
  const currDate = Date.now()
  alarms.forEach(alarm => {
    if (alarm.status === false) {
      return
    }
    // Get the effective alarm date and check if it is still in the window and disable
    // only if outside the window
    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)

    if (currAlarmDate < currDate && !alarm.lastExpiredAt) {
      myExpiredAlarms.push(alarm.id)
    } else if (currAlarmDate > currDate && alarm.lastExpiredAt) {
      myExpiredAlarms.push(alarm.id)
    } else if (currAlarmDate > currDate && !alarm.lastSeenAt) {
      myAlarmsToBeUpdatedLocally.push(alarm.id)
    } else if (currAlarmDate > currDate && alarm.lastSeenAt) {
      myAlarmsToBeUpdatedLocally.push(alarm.id)
    } else if (
      alarm.lastSnoozeByCreatorOn &&
      alarm.lastSnoozeByCreatorFor &&
      alarm.lastSnoozeByCreatorOn + alarm.lastSnoozeByCreatorFor < currDate
    ) {
      myAlarmsToBeUnsnoozed.push(alarm.id)
    }
  })

  participantAlarms.forEach(alarm => {
    if (alarm.status === false) {
      return
    }

    const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
    const backupAlarmDate = DateTimeUtils.getParticipantAlarmDate(
      { ...alarm, date: currAlarmDate },
      alarm.order
    )
    const participants = AlarmUtils.getAlarmParticipants(
      alarm.type,
      alarm.backupGroup,
      alarm.backupContacts,
      alarm.recipient
    )
    const participant = Utils.getObjectWithId(participants, GlobalConfig.uid)

    if (!participant) {
      return
    }

    if (backupAlarmDate < currDate && !alarm.lastExpiredAt) {
      participantExpiredAlarms.push(alarm.id)
    } else if (backupAlarmDate > currDate && alarm.lastExpiredAt) {
      participantExpiredAlarms.push(alarm.id) // Need to rename the action to just update alarm
    } else if (backupAlarmDate > currDate && !alarm.lastSeenAt) {
      participantAlarmsToBeUpdatedLocally.push(alarm.id)
    } else if (backupAlarmDate > currDate && alarm.lastSeenAt) {
      participantAlarmsToBeUpdatedLocally.push(alarm.id)
    } else if (
      participant.lastSnoozeOn &&
      participant.lastSnoozeFor &&
      participant.lastSnoozeOn + participant.lastSnoozeFor < currDate
    ) {
      participantAlarmsToBeUnsnoozed.push(alarm.id)
    }
  })

  if (
    myExpiredAlarms.length > 0 ||
    myAlarmsToBeUpdatedLocally.length > 0 ||
    participantExpiredAlarms.length > 0 ||
    participantAlarmsToBeUpdatedLocally.length > 0 ||
    myAlarmsToBeUnsnoozed.length > 0 ||
    participantAlarmsToBeUnsnoozed.length > 0
  ) {
    dispatch(
      batchActions(
        markAlarmsAsExpired(myExpiredAlarms),
        updateAlarmsLocally(myAlarmsToBeUpdatedLocally),
        markParticipantAlarmsAsExpired(participantExpiredAlarms),
        updateParticipantsAlarmsLocally(participantAlarmsToBeUpdatedLocally),
        unsnoozeAlarmsLocally(myAlarmsToBeUnsnoozed),
        unsnoozeParticipantAlarmsLocally(
          participantAlarmsToBeUnsnoozed,
          GlobalConfig.uid
        )
      )
    )
  }

  // myExpiredAlarms.length > 0 && dispatch(markAlarmsAsExpired(myExpiredAlarms))
  // myAlarmsToBeUpdatedLocally.length > 0 && dispatch(updateAlarmsLocally(myAlarmsToBeUpdatedLocally))
  // participantExpiredAlarms.length > 0 && dispatch(markParticipantAlarmsAsExpired(participantExpiredAlarms))
  // participantAlarmsToBeUpdatedLocally.length > 0 && dispatch(updateParticipantsAlarmsLocally(participantAlarmsToBeUpdatedLocally))
}

// eslint-disable-next-line no-unused-vars
const markBackupAlarmAsDelivered = alarm => (dispatch, getState) => {
  if (!alarm) {
    return
  }

  const currDate = Date.now()
  let firebaseUpdateObj = {}
  if (
    alarm.backupGroup &&
    alarm.backupGroup.members &&
    Utils.getIndexOfObjectWithId(
      alarm.backupGroup.members,
      GlobalConfig.uid
    ) !== -1
  ) {
    const member = Utils.getObjectWithId(
      alarm.backupGroup.members,
      GlobalConfig.uid
    )
    if (!member.deliveredOn) {
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          alarm.backupGroup.id +
          '/members/' +
          GlobalConfig.uid +
          '/deliveredOn'
      ] = currDate
      firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
    }
  } else if (
    alarm.backupContacts &&
    alarm.backupContacts.length > 0 &&
    Utils.getIndexOfObjectWithId(alarm.backupContacts, GlobalConfig.uid) !== -1
  ) {
    const contact = Utils.getObjectWithId(
      alarm.backupContacts,
      GlobalConfig.uid
    )
    if (!contact.deliveredOn) {
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/contacts/' +
          GlobalConfig.uid +
          '/deliveredOn'
      ] = currDate
      firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
    }
  } else if (
    alarm.recipient &&
    alarm.recipient.id === GlobalConfig.uid &&
    !alarm.recipient.deliveredOn
  ) {
    firebaseUpdateObj['alarms/' + alarm.id + '/recipient/deliveredOn'] =
      currDate
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
  }
  GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj).catch(error => {
    LogUtils.logError(error, 'Unable to mark participant alarm as delivered')
  })
}

const markBackupAlarmIdAsSeen = alarmId => async (dispatch, getState) => {
  retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
    if (!alarm) {
      LogUtils.logError(
        new Error(
          'Backup alarm not present while marking it as seen ' + alarmId
        )
      )
      return
    }

    dispatch(markBackupAlarmAsSeen(alarm))
  })
}

// eslint-disable-next-line no-unused-vars
const markBackupAlarmAsSeen = alarm => async (dispatch, getState) => {
  const currDate = Date.now()
  let firebaseUpdateObj = {}

  updateFirebaseObjWithAlarmSeenData(firebaseUpdateObj, alarm, currDate)

  GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj).catch(error => {
    LogUtils.logError(error, 'Unable to mark participant alarm as seen')
  })
}

const updateFirebaseObjWithAlarmSeenData = (
  firebaseUpdateObj,
  alarm,
  currDate
) => {
  if (
    alarm.backupGroup &&
    alarm.backupGroup.members &&
    Utils.getIndexOfObjectWithId(
      alarm.backupGroup.members,
      GlobalConfig.uid
    ) !== -1
  ) {
    const member = Utils.getObjectWithId(
      alarm.backupGroup.members,
      GlobalConfig.uid
    )
    if (!member.seenOn) {
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/group/' +
          alarm.backupGroup.id +
          '/members/' +
          GlobalConfig.uid +
          '/seenOn'
      ] = currDate
      firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
    }
  } else if (
    alarm.backupContacts &&
    alarm.backupContacts.length > 0 &&
    Utils.getIndexOfObjectWithId(alarm.backupContacts, GlobalConfig.uid) !== -1
  ) {
    const contact = Utils.getObjectWithId(
      alarm.backupContacts,
      GlobalConfig.uid
    )
    if (!contact.seenOn) {
      firebaseUpdateObj[
        'alarms/' +
          alarm.id +
          '/backups/contacts/' +
          GlobalConfig.uid +
          '/seenOn'
      ] = currDate
      firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
    }
  } else if (
    alarm.recipient &&
    alarm.recipient.id === GlobalConfig.uid &&
    !alarm.recipient.seenOn
  ) {
    firebaseUpdateObj['alarms/' + alarm.id + '/recipient/seenOn'] = currDate
    firebaseUpdateObj['alarms/' + alarm.id + '/lastUpdatedAt'] = currDate
  }
}

// Need to provide appSecret because this action is also used to store the
// name when the user registers at which time the user is not authenticated yet
// eslint-disable-next-line no-unused-vars
const updateUserName = (uid, username) => (dispatch, getState) => {
  dispatch(setUserName(username))
  GlobalConfig.username = username
  StorageManager.storeData({ username })
  GlobalConfig.rootFirebaseRef
    .child('users')
    .child(uid)
    .update({
      name: username,
      appSecret: GlobalConfig.appSecret
    })
    .then(() => {
      TaskManager.addHttpsCloudTask('updateConnections', {
        uid: GlobalConfig.uid,
        name: username
      })
    })
    .catch(error => {
      LogUtils.logError(error, 'Unable to update user name')
    })
}

const createFirebaseUpdateObjForBlockContact = contactId => {
  let firebaseUpdateObj = {}
  firebaseUpdateObj[
    'userInfos/' + GlobalConfig.uid + '/blockedContacts/' + contactId
  ] = true
  return firebaseUpdateObj
}

const createFirebaseUpdateObjForUnblockContact = contactId => {
  let firebaseUpdateObj = {}
  firebaseUpdateObj[
    'userInfos/' + GlobalConfig.uid + '/blockedContacts/' + contactId
  ] = null
  return firebaseUpdateObj
}

// eslint-disable-next-line no-unused-vars
const blockContact = (contactId, contactName) => (dispatch, getState) => {
  const ref = GlobalConfig.rootFirebaseRef

  NavigationUtils.showProgress(
    Constants.ProgressStates.IN_PROGRESS,
    I18n.t('blockingContact', { name: contactName })
  )

  const blockContactFirebaseUpdateObj =
    createFirebaseUpdateObjForBlockContact(contactId)
  ref
    .update(blockContactFirebaseUpdateObj)
    .then(() => {
      return (
        TaskManager.addHttpsCloudTask('blockExistingAlarms', {
          uid: GlobalConfig.uid,
          contactId: contactId
        })
          // eslint-disable-next-line no-unused-vars
          .then(taskResult => {
            NavigationUtils.dismissProgress()
          })
          .catch(error => {
            LogUtils.logError(
              new Error(error.error),
              'Unable to block contact ' + contactId
            )
            dispatch(
              setProgress(
                Constants.ProgressStates.ERROR,
                I18n.t('unableToBlockContact', { error: error.error }),
                true
              )
            )
          })
      )
    })
    .catch(error => {
      LogUtils.logError(
        new Error(error.error || error.message),
        'Unable to block contact ' + contactId
      )
      dispatch(
        setProgress(
          Constants.ProgressStates.ERROR,
          I18n.t('unableToBlockContact', {
            error: error.error || error.message
          }),
          true
        )
      )
    })

  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.BLOCK_USER, {})
}

// eslint-disable-next-line no-unused-vars
const unblockContact = (contactId, contactName) => (dispatch, getState) => {
  const ref = GlobalConfig.rootFirebaseRef

  NavigationUtils.showProgress(
    Constants.ProgressStates.IN_PROGRESS,
    I18n.t('unblockingContact', { name: contactName })
  )

  // We have to unblock the contact first such that the when the alarms are restored,
  // they are synced with the app or else the restored alarms would still be blocked
  const firebaseUpdateObjForUnblockContact =
    createFirebaseUpdateObjForUnblockContact(contactId)
  ref
    .update(firebaseUpdateObjForUnblockContact)
    .then(() => {
      return (
        TaskManager.addHttpsCloudTask('restoreBlockedAlarms', {
          uid: GlobalConfig.uid,
          contactId: contactId
        })
          // eslint-disable-next-line no-unused-vars
          .then(taskResult => {
            NavigationUtils.dismissProgress()
          })
          .catch(error => {
            LogUtils.logError(
              new Error(error.error),
              'Unable to unblock contact ' + contactId
            )
            dispatch(
              setProgress(
                Constants.ProgressStates.ERROR,
                I18n.t('unableToUnblockContact', { error: error.error }),
                true
              )
            )
          })
      )
    })
    .catch(error => {
      LogUtils.logError(
        new Error(error.error || error.message),
        'Unable to unblock contact ' + contactId
      )
      dispatch(
        setProgress(
          Constants.ProgressStates.ERROR,
          I18n.t('unableToUnblockContact', {
            error: error.error || error.message
          }),
          true
        )
      )
    })

  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.UNBLOCK_USER, {})
}

const sendRemoteNotificationsForNewMessage = (alarm, messageText) => {
  const notificationInfo = {
    alarmId: alarm.id,
    alarmName: alarm.name,
    type: Constants.NotificationTypes.NEW_ALARM_MESSAGE,
    participantName: GlobalConfig.username,
    messageText: messageText
  }
  const participantIds = AlarmUtils.getAlarmParticipants(
    alarm.type,
    alarm.backupGroup,
    alarm.backupContacts,
    alarm.recipient
  ).map(participant => participant.id)

  // If number of participants in the alarm are less than the max limit
  // of the person is the alarm creator, send the push notification to
  // all the participants.
  if (
    participantIds.length <=
      GlobalConfig.maxNumParticipantsForFullNotifications ||
    alarm.creator === GlobalConfig.uid
  ) {
    participantIds.push(alarm.creator)
    participantIds.forEach(participantId => {
      if (participantId !== GlobalConfig.uid) {
        NotificationManager.sendRemoteNotification(
          participantId,
          Constants.NotificationKeys.NewAlarmMessage,
          notificationInfo
        )
      }
    })
  } else {
    NotificationManager.sendRemoteNotification(
      alarm.creator,
      Constants.NotificationKeys.NewAlarmMessage,
      notificationInfo
    )
  }
}

const addMessageToAlarmConversation =
  (alarmId, message) => (dispatch, getState) => {
    // eslint-disable-line no-unused-vars
    // {
    //   "text": "Sds\nDfs",
    //   "user": {
    //     "_id": "-KGvMIPwul2dUY181xLQ"
    //   },
    //   "createdAt": "2017-04-28T05:51:28.764Z",
    //   "_id": "e98d9c03-131e-468c-b0d1-b383d62d1705"
    // }
    // id, text, createdAt, userId and username
    const ref = GlobalConfig.rootFirebaseRef
    const id = ref.push().key
    const finalMessage = {
      id,
      text: message.text,
      createdAt: Date.now(),
      userId: GlobalConfig.uid,
      username: GlobalConfig.username,
      mobileNumber: getState().userInfo.formattedMobileNumberWithCC
    }
    dispatch(addNewMessageToAlarmConversation(alarmId, finalMessage))
    ref
      .child('conversations/alarms/' + alarmId)
      .child(id)
      .set(finalMessage)

    // Send out remote notifications to the creator is the message is sent by a participant
    // or to all the participants if the message is sent by the creator
    const alarmSelector = makeAlarmSelector()
    const alarm = alarmSelector(getState(), { alarmId })
    if (!isEmpty(alarm)) {
      sendRemoteNotificationsForNewMessage(alarm, message.text)
      const participants = AlarmUtils.getAlarmParticipants(
        alarm.type,
        alarm.backupGroup,
        alarm.backupContacts,
        alarm.recipient
      )
      let allParticipants = participants.slice()
      allParticipants.push({ id: alarm.creator })
      allParticipants.splice(
        Utils.getIndexOfObjectWithId(allParticipants, GlobalConfig.uid),
        1
      )
      const participantIds = allParticipants.map(participant => participant.id)
      participantIds.forEach(participantId => {
        ref
          .child('conversations/unseenMessages/' + alarmId)
          .child(participantId)
          .transaction(unseenMessagesCount => {
            if (!unseenMessagesCount) {
              return 1
            } else {
              return unseenMessagesCount + 1
            }
          })
      })
    }

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.SEND_MESSAGE_ON_CHAT,
      {}
    )
  }

// eslint-disable-next-line no-unused-vars
const loadLastConversationMessageForAlarm = alarmId => (dispatch, getState) => {
  GlobalConfig.rootFirebaseRef
    .child('conversations/alarms/' + alarmId)
    .orderByKey()
    .limitToLast(1)
    .once('value')
    .then(lastMessageSnapshot => {
      dispatch(
        addNewMessageToAlarmConversation(alarmId, lastMessageSnapshot.val())
      )
    })
}

const loadLatestConversationMessagesForAlarm =
  alarmId =>
  (
    dispatch,
    getState // eslint-disable-line no-unused-vars
  ) => {
    const ref = GlobalConfig.rootFirebaseRef
    let processedOldMessages = false
    FirebaseManager.addFirebaseRefChildAddedIfNotPresent(
      'Conversation' + alarmId,
      ref.child('conversations/alarms/' + alarmId),
      messageSnapshot => {
        if (processedOldMessages) {
          dispatch(
            addNewMessageToAlarmConversation(alarmId, messageSnapshot.val())
          )
        }
      }
    )
    ref
      .child('conversations/alarms/' + alarmId)
      .orderByKey()
      .limitToLast(GlobalConfig.numConversationMessagesToLoadInOneRequest + 1)
      .once('value')
      .then(conversationSnapshot => {
        if (conversationSnapshot.exists()) {
          dispatch(
            addMessagesToAlarmConversation(
              alarmId,
              Object.values(conversationSnapshot.val())
            )
          )
        } else {
          dispatch(addMessagesToAlarmConversation(alarmId, []))
        }
        processedOldMessages = true
        return ref
          .child('conversations/alarms/' + alarmId)
          .orderByKey()
          .limitToFirst(1)
          .once('value')
      })
      .then(firstConversationSnapshot => {
        if (firstConversationSnapshot.exists()) {
          const firstMessageKey = Object.keys(
            firstConversationSnapshot.val()
          )[0]
          dispatch(
            processFirstMessageKeyForAlarmConversation(alarmId, firstMessageKey)
          )
        }
      })
  }

const loadEarlierConversationMessagesForAlarm =
  alarmId => (dispatch, getState) => {
    const ref = GlobalConfig.rootFirebaseRef
    dispatch(setIsLoadingEarlierMessagesForAlarm(alarmId, true))
    const alarmConversation = getState().conversations.alarms[alarmId]
    if (!alarmConversation || alarmConversation.messages.length === 0) {
      dispatch(setIsLoadingEarlierMessagesForAlarm(alarmId, false))
      return
    }
    const earliestMessageInConversation =
      alarmConversation.messages[alarmConversation.messages.length - 1]
    ref
      .child('conversations/alarms/' + alarmId)
      .orderByKey()
      .endAt(earliestMessageInConversation.id)
      .limitToLast(GlobalConfig.numConversationMessagesToLoadInOneRequest + 1)
      .once('value')
      .then(conversationSnapshot => {
        if (conversationSnapshot.exists()) {
          dispatch(
            addMessagesToAlarmConversation(
              alarmId,
              Object.values(conversationSnapshot.val())
            )
          )
        } else {
          dispatch(addMessagesToAlarmConversation(alarmId, []))
        }
        dispatch(setIsLoadingEarlierMessagesForAlarm(alarmId, false))
      })
  }

const snoozeParticipantAlarm =
  (alarmId, snoozeInterval) => (dispatch, getState) => {
    retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
      if (!alarm) {
        NavigationUtils.showTransientAlert({
          message: I18n.t('unableToSnoozeAlarm'),
          duration: Constants.AlertDurations.LONG
        })
        FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
          [Constants.UserAnalyticsEventParameters.SOURCE]:
            'snoozeParticipantAlarm'
        })
        return
      }

      const creatorName = AlarmUtils.resolveAlarmCreatorName(
        getState().contacts.contacts,
        alarm.creator,
        alarm.creatorMobileNumber,
        alarm.creatorName
      )
      const snoozeDate = DateTimeUtils.shaveSecondsFromDate(Date.now())
      const softUpdatedAlarm = AlarmUtils.updateAlarmParticipant(
        alarm,
        GlobalConfig.uid,
        {
          lastSnoozeOn: snoozeDate,
          lastSnoozeFor: snoozeInterval
        }
      )
      dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

      const persistOnFirebase =
        getState().appState.isConnected &&
        getState().appState.authenticatedWithFirebase

      AlarmUtils.snoozeAlarmForParticipant(
        alarm,
        creatorName,
        snoozeInterval,
        snoozeDate,
        persistOnFirebase
      )

      if (!persistOnFirebase) {
        console.tron.log('Adding start snooze for participant pending action')

        dispatch(
          addStartSnoozeForParticipantSoftPendingAction(
            alarm,
            snoozeInterval,
            snoozeDate
          )
        )
      }

      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.SNOOZE_ALARM, {
        [Constants.UserAnalyticsEventParameters.SNOOZE_ALARM_DURATION]:
          AlarmUtils.getSnoozeDurationAsString(snoozeInterval)
      })
    })
  }

const updateSnoozeStatusForParticipantAlarm =
  (alarmId, snoozeDate, snoozeInterval) => (dispatch, getState) => {
    retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
      if (!alarm) {
        FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
          [Constants.UserAnalyticsEventParameters.SOURCE]:
            'snoozeParticipantAlarmFromAlarmAction'
        })
        return
      }

      const softUpdatedAlarm = AlarmUtils.updateAlarmParticipant(
        alarm,
        GlobalConfig.uid,
        {
          lastSnoozeOn: snoozeDate,
          lastSnoozeFor: snoozeInterval
        }
      )

      dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

      const persistOnFirebase =
        getState().appState.isConnected &&
        getState().appState.authenticatedWithFirebase

      if (!persistOnFirebase) {
        console.tron.log('Adding start snooze for participant pending action')

        dispatch(
          addStartSnoozeForParticipantSoftPendingAction(
            alarm,
            snoozeInterval,
            snoozeDate
          )
        )
      } else {
        AlarmUtils.updateSnoozeStatusForParticipantAlarmCore(
          alarm,
          snoozeDate,
          snoozeInterval
        )
      }
    })

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.SNOOZE_ALARM, {
      [Constants.UserAnalyticsEventParameters.SNOOZE_ALARM_DURATION]:
        AlarmUtils.getSnoozeDurationAsString(snoozeInterval)
    })
  }

const snoozeCreatorAlarm =
  (alarmId, snoozeInterval) => (dispatch, getState) => {
    const ownAlarmSelector = makeOwnAlarmSelector()
    const alarm = ownAlarmSelector(getState(), { alarmId })

    const snoozeDate = DateTimeUtils.shaveSecondsFromDate(Date.now())
    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      lastSnoozeByCreatorOn: snoozeDate,
      lastSnoozeByCreatorFor: snoozeInterval
    })

    dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    AlarmUtils.snoozeAlarmForCreator(
      alarm,
      snoozeInterval,
      snoozeDate,
      persistOnFirebase
    )

    if (!persistOnFirebase) {
      console.tron.log('Adding start snooze for creator pending action')

      dispatch(
        addStartSnoozeForCreatorSoftPendingAction(
          alarmId,
          snoozeInterval,
          snoozeDate
        )
      )
    }

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.SNOOZE_ALARM, {
      [Constants.UserAnalyticsEventParameters.SNOOZE_ALARM_DURATION]:
        AlarmUtils.getSnoozeDurationAsString(snoozeInterval)
    })
  }

const updateSnoozeStatusForCreatorAlarm =
  (alarmId, snoozeDate, snoozeInterval) => (dispatch, getState) => {
    const ownAlarmSelector = makeOwnAlarmSelector()
    const alarm = ownAlarmSelector(getState(), { alarmId })

    if (isEmpty(alarm)) {
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'snoozeCreatorAlarmFromAlarmAction'
      })
      return
    }

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      lastSnoozeByCreatorOn: snoozeDate,
      lastSnoozeByCreatorFor: snoozeInterval
    })
    dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    if (!persistOnFirebase) {
      console.tron.log('Adding start snooze for creator pending action')

      dispatch(
        addStartSnoozeForCreatorSoftPendingAction(
          alarmId,
          snoozeInterval,
          snoozeDate
        )
      )
    } else {
      AlarmUtils.updateSnoozeStatusForCreatorAlarm(
        alarmId,
        snoozeDate,
        snoozeInterval
      )
    }

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.SNOOZE_ALARM, {
      [Constants.UserAnalyticsEventParameters.SNOOZE_ALARM_DURATION]:
        AlarmUtils.getSnoozeDurationAsString(snoozeInterval)
    })
  }

const stopSnoozeForParticipantAlarm = alarmId => (dispatch, getState) => {
  retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
    if (!alarm) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToStopSnoozeForAlarm'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'stopSnoozeForParticipantAlarm'
      })
      return
    }

    const softUpdatedAlarm = AlarmUtils.updateAlarmParticipant(
      alarm,
      GlobalConfig.uid,
      {
        lastSnoozeOn: undefined,
        lastSnoozeFor: undefined
      }
    )

    dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase

    AlarmUtils.stopSnoozeForParticipantAlarm(alarm, persistOnFirebase)

    if (!persistOnFirebase) {
      console.tron.log('Adding stop snooze for participant pending action')

      dispatch(addStopSnoozeForParticipantSoftPendingAction(alarm))
    }

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.UNSNOOZE_ALARM, {})
  })
}

const stopSnoozeForCreatorAlarm = alarmId => (dispatch, getState) => {
  const ownAlarmSelector = makeOwnAlarmSelector()
  const alarm = ownAlarmSelector(getState(), { alarmId })
  const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
    lastSnoozeByCreatorOn: undefined,
    lastSnoozeByCreatorFor: undefined
  })
  dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

  const persistOnFirebase =
    getState().appState.isConnected &&
    getState().appState.authenticatedWithFirebase

  AlarmUtils.stopSnoozeForCreatorAlarm(alarmId, persistOnFirebase)

  if (!persistOnFirebase) {
    console.tron.log('Adding stop snooze for creator pending action')

    dispatch(addStopSnoozeForCreatorSoftPendingAction(alarmId))
  }

  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.UNSNOOZE_ALARM, {})
}

const showWelcomeAlarmsIfNeeded =
  navigation =>
  (
    dispatch, // eslint-disable-line no-unused-vars
    getState // eslint-disable-line no-unused-vars
  ) => {
    const promise = new Promise(resolve => {
      const ref = GlobalConfig.rootFirebaseRef
      ref
        .child('users')
        .child(GlobalConfig.uid)
        .child('shownWelcomeAlarms')
        .once('value')
        .then(shownWelcomeAlarmsSnapshot => {
          if (shownWelcomeAlarmsSnapshot.val() === false) {
            // If welcome alarms have not been shown, then launch the welcome alarm list overlay
            NavigationUtils.showWelcomeAlarms(navigation, resolve)
          } else {
            resolve()
          }
        })
    })
    return promise
  }

// If the user skips the process to create welcome alarms, mark the welcome alarms as shown
// Remove the shownWelcomeAlarms flag which is set to false on account creation
const markWelcomeAlarmsAsShown =
  () =>
  (
    dispatch, // eslint-disable-line no-unused-vars
    getState // eslint-disable-line no-unused-vars
  ) => {
    const ref = GlobalConfig.rootFirebaseRef
    ref
      .child('users')
      .child(GlobalConfig.uid)
      .child('shownWelcomeAlarms')
      .remove()
  }

// Cloud task to create welcome alarms once the user selects and saves welcome alarms
const createWelcomeAlarms =
  welcomeAlarms =>
  (
    dispatch, // eslint-disable-line no-unused-vars
    getState // eslint-disable-line no-unused-vars
  ) => {
    welcomeAlarms.forEach(alarm => {
      dispatch(addAlarm(alarm))
    })
  }

const deleteAccount = feedback => (dispatch, getState) => {
  // eslint-disable-line no-unused-vars
  NavigationUtils.showProgress(
    Constants.ProgressStates.IN_PROGRESS,
    I18n.t('deletingAccount')
  )

  FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.DELETE_ACCOUNT, {})
  return (
    dispatch(
      createSupportTicket(
        Constants.TICKET_TYPES.ACCOUNT_DELETION,
        getState().userInfo.email,
        feedback
      )
    )
      .then(() => {
        return TaskManager.addHttpsCloudTask('deleteAccount', {
          uid: GlobalConfig.uid,
          phoneId: GlobalConfig.phoneId || null
        })
      })
      // eslint-disable-next-line no-unused-vars
      .then(taskResult => {
        NavigationUtils.dismissProgress()
        NavigationUtils.showAlert('', I18n.t('accountDeleted'))
        GlobalConfig.uid = undefined
        dispatch(unloadAppData())
        dispatch(resetApp())
        AlarmManager.cancelAllNotifications()
        return StorageManager.removeData(['uid'])
      })
      .catch(error => {
        LogUtils.logError(new Error(error.error), 'Unable to delete account')
        dispatch(
          setProgress(
            Constants.ProgressStates.ERROR,
            I18n.t('unableToDeleteAccount', { error: error.error }),
            true
          )
        )
      })
  )
}

const updateSnoozingAlarms = alarms => (dispatch, getState) => {
  Object.keys(alarms).forEach(alarmId => {
    var snoozeDateAndDurationObject = alarms[alarmId]

    let snoozeDate

    let snoozeDuration = GlobalConfig.defaultSnoozeInterval

    // For backward comaptilibity if it's an older version the object
    // will be an integer for the timestamp
    if (typeof snoozeDateAndDurationObject === 'string') {
      const snoozeDateAndDurationArray = snoozeDateAndDurationObject.split(':')
      if (
        Array.isArray(snoozeDateAndDurationArray) &&
        snoozeDateAndDurationArray.length
      ) {
        snoozeDate = Number(snoozeDateAndDurationArray[0])
        snoozeDuration = Number(snoozeDateAndDurationArray[1])
      }
    } else {
      snoozeDate = snoozeDateAndDurationObject
    }
    const allAlarms = allAlarmsSelector(getState())
    const alarm = Utils.getObjectWithId(allAlarms, alarmId)
    if (alarm) {
      if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
        AlarmUtils.updateSnoozeStatusForCreatorAlarm(
          alarmId,
          snoozeDate,
          snoozeDuration
        )
      } else if (
        alarm.alarmCategory === Constants.AlarmCategories.PARTICIPANT_ALARM
      ) {
        AlarmUtils.updateSnoozeStatusForParticipantAlarmCore(
          alarm,
          snoozeDate,
          snoozeDuration
        )
      }
    }
  })
}

const updateAcknowledgedAlarms = alarms => (dispatch, getState) => {
  Object.keys(alarms).forEach(alarmIdAndOccurenceTime => {
    const alarmIdAndOccurenceTimeArray = alarmIdAndOccurenceTime.split(':')
    const alarmId = alarmIdAndOccurenceTimeArray[0]
    const occurrenceTime = Number(alarmIdAndOccurenceTimeArray[1])
    const timestamp = Number(alarms[alarmIdAndOccurenceTime])

    const allAlarms = allAlarmsSelector(getState())
    const alarm = Utils.getObjectWithId(allAlarms, alarmId)

    if (!alarm) {
      return
    }

    let alarmResponse
    switch (alarm.type) {
      case Constants.AlarmTypes.CASCADING:
        alarmResponse = Constants.PERSONAL_ALARM_DONE
        break
      case Constants.AlarmTypes.SIMULTANEOUS:
        alarmResponse = Constants.GROUP_ALARM_YES
        break
      case Constants.AlarmTypes.RECIPIENT:
        alarmResponse = Constants.RECIPIENT_ALARM_DONE
        break
      default:
        console.tron.log('Unknown alarm type')
    }

    if (!alarmResponse) {
      return
    }

    AlarmUtils.setPastAlarmOccurrenceResponse(
      alarm,
      occurrenceTime,
      timestamp,
      alarmResponse
    )
  })
}

const continueWithoutRegistration =
  () =>
  (
    dispatch,
    getState // eslint-disable-line no-unused-vars
  ) => {
    NavigationUtils.showProgress(
      Constants.ProgressStates.IN_PROGRESS,
      I18n.t('creatingUserProfile')
    )

    return TaskManager.addHttpsCloudTask('createUserWithoutMobileNumber', {
      os: Platform.OS
    })
      .then(taskResult => {
        NavigationUtils.dismissProgress()
        const uid = taskResult.result.uid
        GlobalConfig.uid = uid
      })
      .catch(error => {
        dispatch(
          setProgress(
            Constants.ProgressStates.ERROR,
            I18n.t('unabeToCreateUserProfile'),
            true
          )
        )
        LogUtils.logError(error, 'Unable to create user without mobile number')
      })
  }

// eslint-disable-next-line no-unused-vars
const addMisingConnection = id => (dispatch, getState) => {
  return TaskManager.addHttpsCloudTask('addConnections', {
    uids: [GlobalConfig.uid, id].join(',')
  })
}

// eslint-disable-next-line no-unused-vars
const updateLastActiveTime = () => (dispatch, getState) => {
  GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('lastActiveAt')
    .set(Date.now())
}

const updateAlarmPreReminderDuration =
  (alarmId, preReminderDuration) => (dispatch, getState) => {
    const ownAlarmSelector = makeOwnAlarmSelector()
    const alarm = ownAlarmSelector(getState(), { alarmId })

    if (isEmpty(alarm)) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToUpdateAlarmPreReminderDuration'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'updateAlarmPreReminderDuration'
      })
      return
    }

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      preReminderDuration: preReminderDuration
    })
    dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

    scheduleNotificationForOwnAlarm(softUpdatedAlarm)

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (!persistOnFirebase) {
      console.tron.log(
        'Adding pending action for add update pre reminder duration' + alarmId
      )

      dispatch(
        addUpdateAlarmPreReminderSoftPendingAction(alarm, preReminderDuration)
      )
    } else {
      const firebaseUpdateObj =
        AlarmUtils.createFirebaseObjForUpdatingAlarmPreReminderDuration(
          alarm,
          preReminderDuration
        )
      GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
    }
  }

const updateAlarmRingerSettings =
  (alarmId, ringerSettings) => (dispatch, getState) => {
    const ownAlarmSelector = makeOwnAlarmSelector()
    const alarm = ownAlarmSelector(getState(), { alarmId })

    if (isEmpty(alarm)) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('unableToUpdateAlarmRingerSettings'),
        duration: Constants.AlertDurations.LONG
      })
      FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
        [Constants.UserAnalyticsEventParameters.SOURCE]:
          'updateAlarmRingerSettings'
      })
      return
    }

    const softUpdatedAlarm = AlarmUtils.updateAlarm(alarm, {
      ringerSettings: ringerSettings
    })
    dispatch(softUpdateLocalAlarm(softUpdatedAlarm))

    scheduleNotificationForOwnAlarm(softUpdatedAlarm)

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (!persistOnFirebase) {
      console.tron.log(
        'Adding pending action for add update ringer settings' + alarmId
      )

      dispatch(
        addUpdateAlarmRingerSettingsSoftPendingAction(alarm, ringerSettings)
      )
    } else {
      const firebaseUpdateObj =
        AlarmUtils.createFirebaseObjForUpdatingAlarmRingerSettings(
          alarm,
          ringerSettings
        )
      GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
    }
  }

const updateParticipantPreReminderDuration =
  (alarmId, preReminderDuration) => (dispatch, getState) => {
    retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
      if (!alarm) {
        NavigationUtils.showTransientAlert({
          message: I18n.t('unableToUpdateParticipantPreReminderDuration'),
          duration: Constants.AlertDurations.LONG
        })
        FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
          [Constants.UserAnalyticsEventParameters.SOURCE]:
            'updateParticipantPreReminderDuration'
        })
        return
      }

      const softUpdatedAlarm = AlarmUtils.updateAlarmParticipant(
        alarm,
        GlobalConfig.uid,
        {
          preReminderDuration
        }
      )

      dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

      scheduleNotificationForParticipantAlarm(
        softUpdatedAlarm,
        getState().contacts.contacts
      )

      const persistOnFirebase =
        getState().appState.isConnected &&
        getState().appState.authenticatedWithFirebase

      if (!persistOnFirebase) {
        console.tron.log(
          'Adding pending action for add update pre-reminder duration' + alarmId
        )

        dispatch(
          addUpdateParticipantPreReminderDurationSoftPendingAction(
            alarm,
            GlobalConfig.uid,
            preReminderDuration
          )
        )
      } else {
        const firebaseUpdateObj =
          AlarmUtils.createFirebaseObjForUpdatingParticipantAlarmPreReminderDuration(
            alarm,
            preReminderDuration
          )
        GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
      }
    })
  }

const updateParticipantAlarmRingerSettings =
  (alarmId, ringerSettings) => (dispatch, getState) => {
    retrieveBackupAlarmByAlarmId(alarmId, getState).then(alarm => {
      if (!alarm) {
        NavigationUtils.showTransientAlert({
          message: I18n.t('unableToUpdateParticipantAlarmRingerSettings'),
          duration: Constants.AlertDurations.LONG
        })
        FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.ALARM_NOT_FOUND, {
          [Constants.UserAnalyticsEventParameters.SOURCE]:
            'updateParticipantAlarmRingerSettings'
        })
        return
      }

      const softUpdatedAlarm = AlarmUtils.updateAlarmParticipant(
        alarm,
        GlobalConfig.uid,
        {
          ringerSettings
        }
      )

      dispatch(softUpdateLocalParticipantAlarm(softUpdatedAlarm))

      scheduleNotificationForParticipantAlarm(
        softUpdatedAlarm,
        getState().contacts.contacts
      )

      const persistOnFirebase =
        getState().appState.isConnected &&
        getState().appState.authenticatedWithFirebase

      if (!persistOnFirebase) {
        console.tron.log(
          'Adding pending action for add update ringer settings' + alarmId
        )

        dispatch(
          addUpdateParticipantAlarmRingerSettingsSoftPendingAction(
            alarm,
            GlobalConfig.uid,
            ringerSettings
          )
        )
      } else {
        const firebaseUpdateObj =
          AlarmUtils.createFirebaseObjForUpdatingParticipantAlarmRingerSettings(
            alarm,
            ringerSettings
          )
        GlobalConfig.rootFirebaseRef.update(firebaseUpdateObj)
      }
    })
  }

const setAdditionalSetupInstructions = additionalSetupInstructions => ({
  type: ActionTypes.SET_ADDITIONAL_SETUP_INSTRUCTIONS,
  payload: {
    additionalSetupInstructions
  }
})

const scheduleExistingAlarms = () => (dispatch, getState) => {
  const allAlarms = allAlarmsSelector(getState())
  allAlarms.forEach(alarm => {
    if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
      scheduleNotificationForOwnAlarm(alarm)
    } else {
      scheduleNotificationForParticipantAlarm(
        alarm,
        getState().contacts.contacts
      )
    }
  })
}

const createSupportTicket =
  (type, emailId, message, extras = '') =>
  (dispatch, getState) => {
    let deviceString, deviceOsName, deviceOsVersion
    if (Platform.OS === 'web') {
      deviceString = `${WebUtils.getBrowserName()} ${WebUtils.getBrowserVersion()}`
      deviceOsName = WebUtils.getDeviceOsName()
      deviceOsVersion = WebUtils.getDeviceOsVersion()
    } else {
      deviceString =
        getState().userInfo.deviceManufacturer + '-' + DeviceInfo.getModel()
      deviceOsName = DeviceInfo.getSystemName()
      deviceOsVersion = DeviceInfo.getSystemVersion()
    }

    return TaskManager.addHttpsCloudTask('sendFeedbackEmail', {
      uid: GlobalConfig.uid,
      username: GlobalConfig.username,
      deviceUid: GlobalConfig.deviceUid,
      os: deviceOsName,
      osVersion: deviceOsVersion,
      locale: I18n.currentLocale(),
      device: deviceString,
      feedback: message,
      release: GlobalConfig.release,
      emailId: emailId,
      type: type,
      appState: JSON.stringify(
        objOmit(
          {
            ...getState().appState,
            ...getState().userSettings,
            isAuthenticated: getState().appState.authenticatedWithFirebase,
            premiumUser: upgradePurchasedSelector(getState()),
            userSince: DateTimeUtils.getDateTimeAsString(
              getState().userInfo.joinDate
            ),
            alarmsCount: getState().userInfo.alarmsCount,
            shareableAlarmLinksCount:
              getState().userInfo.shareableAlarmLinksCount,
            alarmsSavedToCalendarCount:
              getState().userInfo.alarmsSavedToCalendarCount,
            instantAlarmsCount: getState().userInfo.instantAlarmsCount,
            isUserRegistered: getState().userInfo.mobileNumber !== ''
          },
          [
            'latestRelease',
            'deviceToken',
            'currentScreen',
            'authenticatedWithFirebase',
            'skuDetails',
            'inspectorPanelConfig'
          ]
        )
      ),
      extras: extras
    })
  }

const makeGroupMembersActiveInAlarms =
  (groupId, memberIds) => (dispatch, getState) => {
    const ownAlarms = ownAlarmsSelector(getState())
    const impactedAlarms = ownAlarms.filter(alarm => {
      return (
        alarm.status === true &&
        (alarm.date > Date.now() || alarm.repeatType !== '') &&
        !!alarm.backupGroup &&
        alarm.backupGroup.id === groupId
      )
    })
    let firebaseUpdate = {}
    impactedAlarms.forEach(alarm => {
      memberIds.forEach(memberId => {
        firebaseUpdate[
          'alarms/' +
            alarm.id +
            '/backups/group/' +
            groupId +
            '/members/' +
            memberId +
            '/state'
        ] = 'active'
        firebaseUpdate['userInfos/' + memberId + '/backupForAlarms'] =
          alarm.date
        firebaseUpdate['alarms/' + alarm.id + '/lastUpdatedAt'] = Date.now()
      })
    })
    GlobalConfig.rootFirebaseRef.update(firebaseUpdate).then(() => {
      // Send remote notification to the new members
      impactedAlarms.forEach(alarm => {
        const notificationType =
          Constants.NotificationKeys.AddedAsParticipantNotification
        memberIds.forEach(memberId => {
          const participants = objGet(alarm, `backupGroup.members`, [])
          const participant = Utils.getObjectWithId(participants, memberId)

          if (!participant) {
            return
          }

          const notificationInfo = getNotificationInfoForBackupAlarmAdded(
            alarm,
            participant.order || 0
          )
          NotificationManager.sendRemoteNotification(
            participant.id,
            notificationType,
            notificationInfo
          )
        })
      })
    })
  }

const disablePremiumFeatures = () => (dispatch, getState) => {
  // dispatch(deleteAllUserDefinedAlarmCategories())

  if (Platform.OS === 'android') {
    const ringerSettings = ringerSettingsSelector(getState())
    if (ringerSettings.announceAlarmName) {
      const newRingerSettings = {
        ...ringerSettings,
        announceAlarmName: false
      }
      dispatch(updateGlobalAlarmRingerSettings(newRingerSettings))
    }
  }

  // Remove any active web sessions
  GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('activeSessionId')
    .remove()
  GlobalConfig.rootFirebaseRef
    .child('userInfos')
    .child(GlobalConfig.uid)
    .child('sessions')
    .remove()
}

const updateNotificationSettingsUserSettings =
  notificationSettings => (dispatch, getState) => {
    dispatch(setNotificationSettingsUserSettings(notificationSettings))

    const persistOnFirebase =
      getState().appState.isConnected &&
      getState().appState.authenticatedWithFirebase
    if (persistOnFirebase) {
      GlobalConfig.rootFirebaseRef
        .child('userSettings')
        .child(GlobalConfig.uid)
        .child('notificationSettings')
        .set(notificationSettings)
    } else {
      console.tron.log('Adding notification settingns pending action')
      dispatch(
        addUpdateNotificationSettingsSoftPendingAction(notificationSettings)
      )
    }
  }

export default {
  loadAppData,
  unloadAppData,
  deleteAlarm,
  markAlarmAsAcknowledged,
  markPersonalAlarmAsUnskipped,
  addAlarm,
  setConnectionStatus,
  addSystemAlerts,
  addSystemAlert,
  removeSystemAlert,
  handlePendingActions,
  editAlarm,
  setUserName,
  setUserMobileNumber,
  updateUserName,
  uploadUserAvatar,
  deleteUserAvatar,
  markBackupAlarmAsDelivered,
  markBackupAlarmAsSeen,
  uploadGroupAvatar,
  deleteGroupAvatar,
  updateGroupAvatarImages,
  markCreatorResponseForSimultaneousAlarm,
  markParticipantResponseForSimultaneousAlarm,
  setAuthenticatedWithFirebase,
  setProgress,
  resetProgress,
  updateTimezoneAndHandleTimezoneChangeIfNeeded,
  updateRingParticipantAlarmByDefaultUserSetting,
  setContactAvatarImages,
  setUserAvatarImages,
  setNotificationsEnabled,
  setCriticalAlertsEnabled,
  setBackgroundAppRefreshEnabled,
  markAlarmsAsExpired,
  markParticipantAlarmsAsExpired,
  setCurrentScreen,
  updateTimeFormatUserSetting,
  setDeviceToken,
  updateAlarmStatus,
  restoreAlarm,
  setBackupResponseStatusForAlarm,
  blockContact,
  unblockContact,
  addMessageToAlarmConversation,
  loadLatestConversationMessagesForAlarm,
  loadEarlierConversationMessagesForAlarm,
  updatePendingConversationMessageForAlarm,
  editTimeAndEnableAlarm,
  editTimeAndRestoreAlarm,
  snoozeParticipantAlarm,
  stopSnoozeForParticipantAlarm,
  showWelcomeAlarmsIfNeeded,
  createWelcomeAlarms,
  markWelcomeAlarmsAsShown,
  deleteAllAlarms,
  updatePendingFeedback,
  updatePendingProblem,
  setUserEmail,
  loadLastConversationMessageForAlarm,
  snoozeCreatorAlarm,
  resetUnseenMessagesForAlarm,
  updateUserCurrentlyTyping,
  resetUserCurrentlyTyping,
  loadCurrentlyTypingUsers,
  unloadCurrentlyTypingUsers,
  updateAppEnvInfo,
  stopSnoozeForCreatorAlarm,
  setRecipientResponseStatusForAlarm,
  markRecipientAlarmAsDone,
  markPersonalAlarmAsUndone,
  markRecipientAlarmAsUndone,
  resetApp,
  deleteAccount,
  removeLocalAlarms,
  updateSnoozingAlarms,
  updateAcknowledgedAlarms,
  syncAlarmsStatusLocally,
  setContactsPermissionStatus,
  continueWithoutRegistration,
  addMisingConnection,
  updateLastActiveTime,
  setLatestRelease,
  markBackupAlarmIdAsSeen,
  syncAlarmsStatus,
  setPastAlarmOccurrenceResponse,
  setAdditionalSetupInstructions,
  rescheduleExpiredAlarms,
  removeSystemMessage,
  addHiddenSystemMessage,
  updateParticipantAlarmRingerSettings,
  updateGlobalAlarmRingerSettings,
  updateAlarmRingerSettings,
  loadAlarmAcknowledgements,
  deleteInvitedContact,
  setConnectedToIap,
  setPurchases,
  addPurchase,
  incrementMaxAllowedAlarms,
  setAlarmNotificationChannelPopupEnabled,
  setAlarmLockScreenNotificationEnabled,
  addBackupAlarm,
  updateSnoozeStatusForCreatorAlarm,
  updateSnoozeStatusForParticipantAlarm,
  scheduleExistingAlarms,
  createSupportTicket,
  makeGroupMembersActiveInAlarms,
  setDeviceManufacturer,
  loadUserSettings,
  addPhoneContacts,
  addSkuDetails,
  updateSubscriberAlarm,
  deleteSubscriberAlarm,
  removeSubscribedAlarmSystemAlerts,
  incrementShareableAlarmLinkCount,
  incrementAlarmsSavedToCalendarCount,
  incrementInstantAlarmsCount,
  disablePremiumFeatures,
  setColorScheme,
  setWebColorScheme,
  updateWebColorScheme,
  updateColorScheme,
  softUpdateLocalAlarm,
  softUpdateLocalParticipantAlarm,
  addAlarmAcknowledgementAction,
  skipPersonalAlarm,
  skipPersonalParticipantAlarm,
  skipRecipientAlarm,
  skipRecipientCreatorAlarm,
  updateAlarm,
  toggleCategorizedAlarmView,
  createAlarmCategory,
  addAlarmToAlarmCategory,
  removeAlarmFromAlarmCategory,
  deleteAlarmCategory,
  updateAlarmCategory,
  setAlarmsSavedToCalendarCount,
  setInstantAlarmsCount,
  setShowAppRunningElsewhereAlert,
  setTabActiveIndex,
  showNewAlarmWizard,
  showNewInstantAlarmWizard,
  showNewGroupWizard,
  showEditGroupWizard,
  hideNewAlarmWizard,
  hideNewGroupWizard,
  hideEditGroupWizard,
  hideNewInstantAlarmWizard,
  showSelectAlarmTypeScreen,
  showAlarmDetailsScreen,
  showInstantAlarmDetailsScreen,
  hideAlarmDetailsScreen,
  hideInstantAlarmDetailsScreen,
  showGroupDetailsScreen,
  hideGroupDetailsScreen,
  showContactDetailsScreen,
  showChangeMemberStateScreen,
  hideContactDetailsScreen,
  hideChangeGroupMemberStateScreen,
  closeChangeGroupMemberStateScreen,
  showReportAProblemScreen,
  hideReportAProblemScreen,
  showEditAlarmWizard,
  showAlarmConversationScreen,
  hideAlarmConversationScreen,
  hideEditAlarmWizard,
  showProfileAndSettingsScreen,
  hideProfileAndSettingsScreen,
  showParticipantAlarmDetailsScreen,
  showInstantParticipantAlarmDetailsScreen,
  hideParticipantAlarmDetailsScreen,
  setCurrentlySelectedAlarmCategoryId,
  syncLocalOnlyOwnAlarms,
  closeNewAlarmWizard,
  closeEditAlarmWizard,
  closeNewGroupWizard,
  closeEditGroupWizard,
  unhideAlarmCategory,
  hideAlarmCategory,
  deleteAlarmsInCategory,
  showAlarmHistoryScreen,
  hideAlarmHistoryScreen,
  markRecipientCreatorAlarmAsUnskipped,
  markRecipientAlarmAsUnskipped,
  markPersonalParticipantAlarmAsUnskipped,
  hideSelectAlarmTypeScreen,
  setShowQuickReminder,
  resetInspectorPanel,
  closeInstantAlarmWizard,
  setIosNumNotificationsScheduled,
  setShowSidebar,
  updateRingOnEarphoneOnlyUserSetting,
  updateGestureToDismissAnAlarmUserSetting,
  deleteAlarmHistory,
  updateSpecifyTimezoneForAlarmUserSetting,
  showDuplicateAlarmWizard,
  hideDuplicateAlarmWizard,
  closeDuplicateAlarmWizard,
  updateGraduallyIncreaseVolumeUserSetting,
  setSeenSpecifyTimezoneAlert,
  setMobileAndWebTimezoneMismatchDetected,
  updateParticipantPreReminderDuration,
  updateAlarmPreReminderDuration,
  setBatteryOptimizationDisabled,
  setExistingAlarmsLoaded,
  updateNotificationSettingsUserSettings,
  handleExistingAlarmsLoaded,
  removeBackupAlarm,
  setScheduleExactAlarmsEnabled,
  addChecklist,
  updateChecklist,
  removeChecklist,
  uploadImage,
  deleteImage,
  setAppUpdateAvailable,
  setMuteAlarms,
  setCurrentlySelectedTaskListId,
  setShowAlertIncidents,
  setShowRecentlyDeletedAlarms
}
