import I18n from './translations/i18nTranslations'

const Constants = (function () {
  return {
    SEND_PUSH_NOTIFICATION_SPEC_ID: 'send_push_notification',
    EDIT_GROUP_SPEC_ID: 'edit_group',
    ALARMS_TAB_NAME: 'alarms',
    CONTACTS_TAB_NAME: 'contacts',
    GROUPS_TAB_NAME: 'groups',
    SETTINGS_TAB_NAME: 'settings',
    ADD_ALARM_PENDING_ACTION_NAME: 'addAlarm',
    DELETE_ALARM_PENDING_ACTION_NAME: 'deleteAlarm',
    EDIT_ALARM_PENDING_ACTION_NAME: 'editAlarm',
    ADD_CHECKLIST_PENDING_ACTION_NAME: 'addChecklist',
    REMOVE_CHECKLIST_PENDING_ACTION_NAME: 'removeChecklist',
    EDIT_CHECKLIST_PENDING_ACTION_NAME: 'editChecklist',
    ADD_ENABLE_DISABLE_ALARM_PENDING_ACTION_NAME: 'enableDisableAlarm',
    ADD_UPDATE_PARTICIPANT_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME:
      'updateParticipantAlarmRingerSettings',
    ADD_UPDATE_PARTICIPANT_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION_NAME:
      'updateParticipantAlarmPreReminderDuration',
    ADD_UPDATE_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME:
      'updateAlarmRingerSettings',
    ADD_UPDATE_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION_NAME:
      'updateAlarmPreReminderDuration',
    ADD_UPDATE_GLOBAL_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION_NAME:
      'updateGlobalAlarmRingerSetings',
    ADD_UPDATE_RING_PARTICIPANT_ALARMS_BY_DEFAULT_SETTING_SOFT_PENDING_ACTION_NAME:
      'updateRingParticipantAlarmsByDefaultSetting',
    ADD_UPDATE_SPECIFY_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION_NAME:
      'addUpdateSpecifyTimezoneForAlarm',
    ADD_ALARM_TO_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME:
      'addAlarmToAlarmCategory',
    REMOVE_ALARM_FROM_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME:
      'removeAlarmFromAlarmCategory',
    DELETE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME: 'deleteAlarmCategory',
    UPDATE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME: 'updateAlarmCategory',
    CREATE_ALARM_CATEGORY_SOFT_PENDING_ACTION_NAME: 'createAlarmCategory',
    START_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION_NAME: 'startSnoozeForCreator',
    STOP_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION_NAME: 'stopSnoozeForCreator',
    START_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION_NAME:
      'startSnoozeForParticipant',
    STOP_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION_NAME:
      'stopSnoozeForParticipant',
    MARK_PERSONAL_ALARM_DONE_SOFT_PENDING_ACTION_NAME: 'markPersonalAlarmDone',
    SKIP_PERSONAL_ALARM_SOFT_PENDING_ACTION_NAME: 'skipPersonalAlarm',
    SKIP_PERSONAL_PARTICIPANT_ALARM_SOFT_PENDING_ACTION_NAME:
      'skipPersonalParticipantAlarm',
    SKIP_RECIPIENT_ALARM_SOFT_PENDING_ACTION_NAME: 'skipRecipientAlarm',
    SKIP_RECIPIENT_CREATOR_ALARM_SOFT_PENDING_ACTION_NAME:
      'skipRecipientCreatorAlarm',
    MARK_RECIPIENT_ALARM_DONE_SOFT_PENDING_ACTION_NAME:
      'markRecipientAlarmDone',
    SET_PARTICIPANT_ALARM_RESPONSE_SOFT_PENDING_ACTION_NAME:
      'setParticipantAlarmResponse',
    SET_PAST_ALARM_OCCURRENCE_RESPONSE_SOFT_PENDING_ACTION_NAME:
      'setPastAlarmOccurrenceResponse',
    UPDATE_GESTURE_TO_DISMISS_AN_ALARM_SOFT_PENDING_ACTION_NAME:
      'updateGestureToDismissAnAlarm',
    UPDATE_NOTIFICATION_SETTINGS_SOFT_PENDING_ACTION_NAME:
      'updateNotificationSettings',
    UPDATE_GRADUALLY_INCREASE_VOLUME_SOFT_PENDING_ACTION_NAME:
      'updateGraduallyIncreaseVolume',
    UPDATE_RING_ON_EARPHONE_ONLY_SOFT_PENDING_ACTION_NAME:
      'updateRingOnEarphoneOnly',
    NO_MEMBERS: I18n.t('you'),
    NO_BACKUPS: I18n.t('none'),
    NO_RECIPIENT: I18n.t('none'),
    SET_RECIPIENT_ALARM_RESPONSE_SOFT_PENDING_ACTION_NAME:
      'setRecipientAlarmResponse',
    MARK_PARTICIPANT_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION_NAME:
      'markParticipantResponseForGroupAlarm',
    MARK_CREATOR_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION_NAME:
      'markCreatorResponseForGroupAlarm',
    APP_NAME: 'Galarm',
    ONE_TIME_ALARM_STRING: I18n.t('onceOnly'),
    ACCEPT_ALARM: 'Accept',
    REJECT_ALARM: 'Decline',
    PERSONAL_ALARM_DONE: 'Done',
    PERSONAL_ALARM_SKIP: 'Skip',
    PERSONAL_ALARM_UNSKIP: 'PersonalAlarmUnskip',
    PERSONAL_PARTICIPANT_ALARM_SKIP: 'Skip',
    PERSONAL_PARTICIPANT_ALARM_UNSKIP: 'PersonalParticipantAlarmUnskip',
    RECIPIENT_ALARM_DONE: 'Done',
    RECIPIENT_ALARM_SKIP: 'Skip',
    RECIPIENT_CREATOR_ALARM_SKIP: 'Skip',
    RECIPIENT_ALARM_UNSKIP: 'RecipientAlarmUnskip',
    RECIPIENT_CREATOR_ALARM_UNKSIP: 'RecipientAlarmCreatorUnskip',
    PERSONAL_ALARM_UNDONE: 'Undone',
    RECIPIENT_ALARM_UNDONE: 'Undone',
    GROUP_ALARM_YES: 'Confirm',
    GROUP_ALARM_NO: 'Decline',
    ALARM_RESPONSE_PENDING: 'Not Responded',
    PENDING_PARTICIPANT_ALARMS: I18n.t('pendingParticipantAlarms'),
    ALARMS_WITH_UNREAD_MESSAGES: I18n.t('alarmsWithUnreadChatMessages'),
    MISSED_ALARMS: I18n.t('missedAlarms'),
    SYSTEM_NOTIFICATIONS: I18n.t('systemNotifications'),
    THINKING_EMOJI: '\u{1F610}',
    THUMBS_UP_EMOJI: '\u{1F44D}',
    THUMBS_DOWN_EMOJI: '\u{1F44E}',
    PILL_EMOJI: '\u{1F48A}',
    MEMO_EMOJI: '\u{1F4DD}',
    CROWN_EMOJI: '\u{1F451}',
    FAMILY_EMOJI: '\u{1F46A}',
    CHAT_EMOJI: '\u{1F4AC}',
    LOCK_EMOJI: '\u{1F512}',
    COPYRIGHT_UNICODE: '\u{00A9}',
    REGISTERED_UNICODE: '\u{00AE}',
    BULLET_UNICODE: '\u{2022}',
    CHECKMARK_UNICODE: '\u{2705}',
    ADMIN_ROLE: 'admin',
    GMT_TIMEZONE_NAME: 'Etc/GMT',
    WEEKDAYS_STRING: I18n.t('weekdays'),
    WEEKENDS_STRING: I18n.t('weekends'),
    EVERY_DAY_STRING: I18n.t('everyDayString'),
    IOS_APP_LINK:
      'https://itunes.apple.com/us/app/galarm-alarms-reminders/id1187849174?mt=8',
    ANDROID_APP_LINK:
      'http://play.google.com/store/apps/details?id=com.galarmapp',
    FAQ_LINK: 'https://www.galarmapp.com/faq',
    TERMS_OF_SERVICE_LINK: 'https://www.galarmapp.com/termsofservice',
    PRIVACY_POLICY_LINK: 'https://www.galarmapp.com/privacypolicy',
    LICENSES_LINK: 'https://www.galarmapp.com/thirdpartylicensing',
    APP_WEBSITE_LINK: 'https://www.galarmapp.com',
    DOWNLOAD_LINK: 'http://bit.ly/get-galarm',
    SET_SHOW_DURATION: 240,
    SET_HIDE_DURATION: 300,
    CUSTOM_MUSIC_RINGTONE_NAME: 'Custom Music',
    MSEC_IN_MINUTE: 60000,
    MSEC_IN_SECOND: 1000,
    MSEC_IN_HOUR: 3600000,
    MSEC_IN_DAY: 8.64e7,
    NO_REMINDER_RECIPIENT_INTERVAL_KEY: 'noReminder',
    OTHER_CONTACTS_SUMMARY_SOURCE: 'otherContactSummary',
    RANDOM_RINGTONE_VALUE: 'random',
    SILENT_RINGTONE_VALUE: 'silent',
    LAST_DAY_OF_MONTH_REPEAT_STRING: 'lastDayOfMonth',
    LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING: 'lastDayOfWeekInMonth',
    ODD_NUMBERED_DAYS_REPEAT_STRING: 'oddNumberedDays',
    EVEN_NUMBERED_DAYS_REPEAT_STRING: 'evenNumberedDays',
    UNCATEGORIZED_ALARM_CATEGORY_ID: 'uncategorized',
    DAY_OF_WEEK_IN_MONTH_REPEAT_STRING: 'dayOfWeekInMonth',
    REPEAT_EVERY_N_MONTHS_REPEAT_STRING: 'repeatEveryNMonths',
    REPEAT_EVERY_N_DAYS_REPEAT_STRING: 'repeatEveryNDays',
    REPEAT_EVERY_N_YEARS_REPEAT_STRING: 'repeatEveryNYears',
    GALARM_WEB_ADDRESS: 'web.galarm.app',

    UNCATEGORIZED_ALARM_CATEGORY: {
      id: 'uncategorized',
      name: I18n.t('uncategorizedCategoryName'),
      color: 'uncategorized',
      source: 'system'
    },

    DEEP_LINK_COMMANDS: {
      NEW_ALARM: 'newAlarm',
      SUBSCRIBE_TO_ALARM: 'subscribeToAlarm',
      JOIN_ALARM: 'joinAlarm'
    },

    TimeFormats: {
      TWELVE_HOUR_FORMAT: '12Hour',
      TWENTY_FOUR_HOUR_FORMAT: '24Hour'
    },

    AlarmCreationModes: {
      NORMAL_ALARM: 'normalAlarm',
      QUICK_ALARM: 'quickAlarm',
      INSTANT_ALARM: 'instantAlarm'
    },

    AlarmCreationSources: {
      CREATED_MANUALLY: 'createdManually',
      CREATED_BY_DEEP_LINK: 'createdByDeepLink',
      CREATED_MANUALLY_FROM_WEB: 'createdManuallyFromWeb',
      DUPLICATED: 'duplicated',
      DUPLICATED_FROM_WEB: 'duplicatedFromWeb',
      CREATED_BY_ENTERPRISE_ALERT_API: 'createdByEnterpriseAlertApi',
      CREATED_BY_GOOGLE_ASSISTANT: 'createdFromGoogleAssistant'
    },

    EventSources: {
      ALARM_LIST_APP_WIDGET: 'AlarmListAppWidget',
      PRE_REMINDER: 'PreReminder',
      APP_ICON_QUICK_ACTION: 'AppIconQuickAction'
    },

    VibrationPatterns: {
      ALARM_IOS: [
        600, 600, 1200, 600, 600, 1200, 600, 600, 1200, 600, 600, 1200, 600,
        600, 1200
      ],
      ALARM_ANDROID: [
        0, 500, 1500, 500, 1500, 500, 1500, 500, 1500, 500, 1500, 500, 1500,
        500, 1500, 500
      ]
    },

    RingtoneDurations: [
      {
        label: I18n.t('seconds', { count: 2 }),
        value: 2000
      },
      {
        label: I18n.t('seconds', { count: 5 }),
        value: 5000
      },
      {
        label: I18n.t('seconds', { count: 15 }),
        value: 15000
      },
      {
        label: I18n.t('seconds', { count: 30 }),
        value: 30000
      },
      {
        label: I18n.t('minutes', { count: 1 }),
        value: 60000
      },
      {
        label: I18n.t('minutes', { count: 2 }),
        value: 120000
      },
      {
        label: I18n.t('minutes', { count: 5 }),
        value: 300000
      },
      {
        label: I18n.t('minutes', { count: 10 }),
        value: 600000
      },
      {
        label: I18n.t('minutes', { count: 30 }),
        value: 1800000
      }
    ],

    RepeatTypes: {
      DAYS: 'days',
      WEEKS: 'weeks',
      HOURS_AND_MINUTES: 'hoursAndMinutes',
      EVERY_M_HOURS_AND_N_MINUTES: 'everyMHoursNMinutes',
      EVERY_N_HOURS: 'everyNHours',
      DAYS_OF_WEEK: 'daysOfWeek',
      MONTHLY: 'monthly',
      NO_REPEAT: 'noRepeat',
      YEARLY: 'yearly',
      MONTHS: 'months',
      EVERY_DAY: 'everyDay',
      YEARS: 'years',
      ODD_NUMBERED_DAYS: 'oddNumberedDays',
      EVEN_NUMBERED_DAYS: 'evenNumberedDays'
    },

    // Keep in sync with group alarm yes and no
    GroupAlarmInstanceResponseNotificationTextMapping: {
      Confirm: 'confirmed',
      Decline: 'declined'
    },

    PersonalAlarmInstanceResponseNotificationTextMapping: {
      Done: 'done',
      Skip: 'skipped'
    },

    RecipientAlarmInstanceResponseNotificationTextMapping: {
      Done: 'done',
      Skip: 'skipped'
    },

    CalendarViews: {
      MONTH: 'month',
      WEEK: 'week',
      DAY: 'day',
      AGENDA: 'agenda'
    },

    NotificationTypes: {
      ALARM: 'alarm',
      BACKUP_ALARM: 'backupAlarm',
      ALARM_ADDED: 'alarmAdded',
      ALARM_DELETED: 'alarmDeleted',
      BACKUP_ALARM_ADDED: 'backupAlarmAdded',
      BACKUP_ALARM_REMOVED: 'backupAlarmRemoved',
      ALARM_ACKNOWLEDGED: 'alarmAcknowledged',
      BACKUP_RESPONDED: 'backupResponded',
      CREATOR_TIMEZONE_CHANGED: 'creatorTimezoneChanged',
      ADDED_TO_GROUP: 'addedToGroup',
      REMOVED_FROM_GROUP: 'removedFromGroup',
      MEMBER_ADDED_TO_GROUP: 'memberAddedToGroup',
      MEMBER_REMOVED_FROM_GROUP: 'memberRemovedFromGroup',
      SIMULTANEOUS_ALARM_RESPONSE: 'simultaneousAlarmResponse',
      NEW_ALARM_MESSAGE: 'newAlarmMessage',
      ALARM_UNACKNOWLEDGED: 'alarmUnacknowledged',
      INTERNAL_NOTIFICATION: 'internalNotification',
      BACKUP_ALARM_NAME_CHANGED: 'backupAlarmNameChanged',
      BACKUP_ALARM_NOTES_CHANGED: 'backupAlarmNotesChanged',
      USER_INACTIVE: 'userInactive',
      PARTICIPANT_ALARM_ENABLED: 'participantAlarmEnabled',
      PARTICIPANT_ALARM_DISABLED: 'participantAlarmDisabled',
      PARTICIPANT_ALARM_DELETED: 'participantAlarmDeleted',
      SUBSCRIBED_ALARM_EDITED: 'subscribedAlarmEdited',
      SUBSCRIBED_ALARM_DELETED: 'subscribedAlarmDeleted',
      ALARM_SKIPPED: 'alarmSkipped',
      ALARM_SUBSCRIBED: 'alarmSubscribed',
      ALARM_UNSKIPPED: 'alarmUnskipped',
      ENTERPRISE_ALERT_INCIDENT_ACKNOWLEDGED:
        'enterpriseAlertIncidentAcknowledged'
    },

    DayRepetitionOptions: [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ],

    MonthRepetitionOptions: [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december'
    ],

    RepeatDayToDayMapping: {
      every_sun: 'Sunday',
      every_mon: 'Monday',
      every_tue: 'Tuesday',
      every_wed: 'Wednesday',
      every_thu: 'Thursday',
      every_fri: 'Friday',
      every_sat: 'Saturday'
    },

    RepeatDayToShortDayMapping: {
      every_sun: 'Sun',
      every_mon: 'Mon',
      every_tue: 'Tue',
      every_wed: 'Wed',
      every_thu: 'Thu',
      every_fri: 'Fri',
      every_sat: 'Sat'
    },

    RepeatDayToDayMappingI18n: {
      every_sun: I18n.t('sunday'),
      every_mon: I18n.t('monday'),
      every_tue: I18n.t('tuesday'),
      every_wed: I18n.t('wednesday'),
      every_thu: I18n.t('thursday'),
      every_fri: I18n.t('friday'),
      every_sat: I18n.t('saturday')
    },

    RepeatDayToShortDayMappingI18n: {
      every_sun: I18n.t('sun'),
      every_mon: I18n.t('mon'),
      every_tue: I18n.t('tue'),
      every_wed: I18n.t('wed'),
      every_thu: I18n.t('thu'),
      every_fri: I18n.t('fri'),
      every_sat: I18n.t('sat')
    },

    RepeatAllDays: [
      'every_sun',
      'every_mon',
      'every_tue',
      'every_wed',
      'every_thu',
      'every_fri',
      'every_sat'
    ],

    Weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],

    Weekends: ['Sat', 'Sun'],

    AlarmTimeCategories: {
      LATER: I18n.t('later'),
      OLD: I18n.t('inactive'),
      UPCOMING: I18n.t('upcoming'),
      MISSED: I18n.t('expired')
    },

    TodayAlarmCategories: {
      PENDING: I18n.t('pending'),
      DONE: I18n.t('doneForToday')
    },

    AlarmTypes: {
      SIMULTANEOUS: 'Group',
      CASCADING: 'Personal',
      RECIPIENT: 'Recipient'
    },

    AlarmCategories: {
      MY_ALARM: 'myAlarm',
      PARTICIPANT_ALARM: 'participantAlarm'
    },

    RingerSettingsEntryPoints: {
      ALARM: 'alarm',
      GLOBAL: 'global'
    },

    APP_ICON_QUICK_ACTION_TYPES: {
      NEW_PERSONAL_ALARM: 'newPersonalAlarm',
      NEW_QUICK_REMINDER: 'newQuickReminder'
    },

    UPGRADE_TO_PREMIUM_ENTRY_POINTS: {
      AD_PLACEHOLDER: 'adPlaceholder',
      SETTINGS_SCREEN: 'settingsScreen',
      CHOOSE_ALARM_REPETITION: 'chooseAlarmRepetition',
      CREATE_GROUP_ALARM: 'createGroupAlarm',
      CREATE_BUDDY_ALARM: 'createBuddyAlarm',
      EDIT_ALARM: 'editAlarm',
      SUBSCRIPTION_CANCELED_SYSTEM_MESSAGE: 'subscriptionCanceledSystemMessage',
      ALARM_HISTORY_SCREEN: 'alarmHistoryScreen',
      CHOOSE_ALARM_RINGTONE: 'chooseAlarmRingtone',
      CHOOSE_PHONE_MUSIC_AS_RINGTONE: 'choosePhoneMusicAsRingtone',
      VIEW_PREMIUM_FEATURES: 'viewPremiumFeatures',
      SHAREABLE_ALARM_LINKS: 'shareableAlarmLinks',
      EXPORT_TO_CALENDAR: 'exportToCalendar',
      TTS: 'tts',
      CREATE_ALARM_CATEGORY: 'createAlarmCategory',
      GALARM_WEB: 'galarmWeb',
      INSTANT_ALARM: 'instantAlarm',
      PRE_REMINDER: 'preReminder',
      USER_TIP: 'userTip',
      DUPLICATE_ALARM: 'duplicateAlarm',
      ALARM_PHOTO: 'alarmPhoto',
      NEW_TASK_LIST: 'newTaskList',
      CALENDAR_VIEW: 'calendarView',
      UPDATE_PHONE_NUMBER: 'updatePhoneNumber'
    },

    SHOW_AS_ACTION: {
      ALWAYS: 'always',
      NEVER: 'never'
    },

    UserAnalyticsEvents: {
      USER_REGISTRATION: 'userRegistration',
      USER_REREGISTRATION: 'userReregistration',
      UNABLE_TO_VERIFY_CODE: 'unableToVerifyCode',
      OPEN_APP: 'openApp',
      NEW_ALARM: 'newAlarm',
      EDIT_ALARM: 'editAlarm',
      DELETE_ALARM: 'deleteAlarm',
      NEW_GROUP: 'newGroup',
      ADD_MEMBERS_TO_GROUP: 'addMembersToGroup',
      REMOVE_MEMBER_FROM_GROUP: 'removeMemberFromGroup',
      EDIT_GROUP_NAME: 'editGroupName',
      LEAVE_GROUP: 'leaveGroup',
      CHANGE_RINGTONE: 'changeRingtone',
      SHARE_WITH_FRIENDS: 'shareWithFriends',
      DISABLE_RING_ON_VIBRATE: 'disableRingOnVibrate',
      CHANGED_TIME_FORMAT: 'changedTimeFormat',
      DISABLE_RING_PARTICIPANT_ALARMS: 'disableRingParticipantAlarms',
      ENABLE_RING_PARTICIPANT_ALARMS: 'enableRingParticipantAlarms',
      VIEW_TUTORIAL: 'viewTutorial',
      SNOOZE_ALARM: 'snoozeAlarm',
      DELETE_ALL_ALARMS: 'deleteAllAlarms',
      OPEN_CHAT: 'openChat',
      SEND_MESSAGE_ON_CHAT: 'sendMessageOnChat',
      ALARMS_LIMIT_HIT: 'alarmsLimitHit',
      BLOCK_USER: 'blockUser',
      MARK_PERSONAL_ALARM_DONE: 'markPersonalAlarmDone',
      MARK_PERSONAL_ALARM_UNDONE: 'markPersonalAlarmUndone',
      MARK_BUDDY_ALARM_DONE: 'markBuddyAlarmDone',
      MARK_BUDDY_ALARM_UNDONE: 'markBuddyAlarmUndone',
      CONFIRM_GROUP_ALARM: 'confirmGroupAlarm',
      DECLINE_GROUP_ALARM: 'declineGroupAlarm',
      UNBLOCK_USER: 'unblockUser',
      DELETE_ACCOUNT: 'deleteAccount',
      REPORT_PROBLEM: 'reportProblem',
      SEND_FEEDBACK: 'sendFeedback',
      UNSNOOZE_ALARM: 'unsnoozeAlarm',
      ACCEPT_ALARM: 'acceptAlarm',
      DECLINE_ALARM: 'declineAlarm',
      SHARE_PHONE_NUMBER_CONCERN: 'sharePhoneNumberConcern',
      PICK_MUSIC: 'pickMusic',
      CONTINUE_WITHOUT_PHONE_NUMBER: 'continueWithoutPhoneNumber',
      CLICK_WHAT_WILL_I_MISS: 'clickWhatWillIMiss',
      CLICK_REGISTER_BY_EXISTING_USER: 'clickRegisterByExistingUser',
      EXISTING_USER_REGISTRATION: 'existingUserRegistration',
      MARK_PERSONAL_ALARM_DONE_NOTIFICATION_ACTION:
        'markPersonalAlarmDoneFromNotification',
      MARK_BUDDY_ALARM_DONE_NOTIFICATION_ACTION:
        'markBuddyAlarmDoneFromNotification',
      CONFIRM_GROUP_ALARM_NOTIFICATION_ACTION:
        'confirmGroupAlarmFromNotification',
      VIEW_ALARM_NOTIFICATION_ACTION: 'viewAlarmFromNotificationAction',
      SNOOZE_ALARM_NOTIFICATION_ACTION: 'snoozeAlarmFromNotificationAction',
      USER_TRIED_TO_SAVE_ALARM_ON_END_DATES_OF_MONTH:
        'userTriedToSaveAlarmOnEndDatesOfMonth',
      USER_TRIED_TO_SAVE_ALARM_ON_FIFTH_DAY_OF_WEEK_IN_MONTH:
        'userTriedToSaveAlarmOnFifthDoWInMonth',
      USER_SAVED_ALARM_ON_END_DATES_OF_MONTH: 'userSavedAlarmOnEndDatesOfMonth',
      USER_SAVED_ALARM_ON_FIFTH_DAY_OF_WEEK_IN_MONTH:
        'userSavedAlarmOnFifthDoWInMonth',
      CONTACTS_PERMISSION_REQUESTED: 'contactsPermissionRequested',
      APP_AUTOSTART_NOT_ENABLED: 'appAutostartNotEnabled',
      UNSUPPORTED_APP_VERSION_DETECTED: 'unsupportedAppVersionDetected',
      INVALID_PHONE_NUMBER_ENTERED: 'invalidPhoneNumberEntered',
      INVALID_COUNTRY_CODE_ENTERED: 'invalidCountryCodeEntered',
      CREATOR_PARTICIPANT_ALARM_DATE_CONFLICT:
        'creatorParticipantAlarmDateConflict',
      SEND_CODE_AGAIN: 'sendCodeAgain',
      SENT_CODE_AGAIN_SUCCESSFULLY: 'sentCodeAgainSuccessfully',
      FAILED_TO_SEND_AGAIN: 'failedToSendAgain',
      STORAGE_PERMISSION_DENIED: 'storagePermissionDenied',
      STORAGE_PERMISSION_ALLOWED: 'storagePermissionAllowed',
      HAVING_TROUBLE_REGISTERING_MESSAGE_SHOWN:
        'havingTroubleRegisteringMessageShown',
      SKIP_REGISTRATION_AFTER_HAVING_TROUBLE_REGISTERING:
        'skipRegistrationAfterTroubleRegistering',
      USER_ENTERED_INCORRECT_NUMBER: 'userEnteredIncorrectNumber',
      SMS_PERMISSION_DENIED: 'smsPermissionDenied',
      SMS_PERMISSION_ALLOWED: 'smsPermissionAllowed',
      VERIFICATION_CODE_DETECTED_AUTOMATICALLY:
        'verificationCodeDetectedAutomatically',
      EDIT_NUMBER_AFTER_CONFIRMATION: 'editNumberAfterConfirmation',
      CONFIRM_GROUP_ALARM_IN_ADVANCE: 'confirmGroupAlarmInAdvance',
      ENTITY_NOT_FOUND: 'entityNotFound',
      VIEW_ALARM_QUICK_ACTIONS: 'viewAlarmQuickActions',
      VIEW_ALARM_DETAILS_FROM_ALARM_ALERT: 'viewAlarmDetailsFromAlarmAlert',
      CLICK_CONTINUE_TO_REGISTER: 'clickContinueToRegister',
      CLICK_REGISTER_LATER: 'clickRegisterLater',
      CLICK_CONTINUE_AFTER_PROVIDING_PHONE_NUMBER:
        'clickContinueAfterProvidingPhoneNumber',
      CLICK_CONTINUE_WITHOUT_PHONE_NUMBER: 'clickContinueWithoutPhoneNumber',
      ERROR_VERIFYING_CODE: 'errorVerifyingCode',
      SHOW_RATE_THE_APP: 'showRateTheApp',
      RATE_THE_APP_NOW: 'rateTheAppNow',
      RATE_THE_APP_LATER: 'rateTheAppLater',
      RATE_THE_APP_NEVER: 'rateTheAppNever',
      FULL_CONTACTS_SYNC_AFTER_MANUAL_REFRESH:
        'fullContactsSyncAfterManualRefresh',
      FULL_CONTACTS_SYNC: 'fullContactsSync',
      FULL_MANUAL_CONTACTS_SYNC: 'fullManualContactsSync',
      IGNORE_CONTACTS_SYNC_NO_CHANGE_IN_CONTACTS:
        'ignoreContactsSyncNoChangeInContacts',
      ERROR_VERIFYING_CODE_DUE_TO_NO_CONNECTION:
        'errorVerifyingCodeDueToNoConnection',
      CLICK_CONTINUE_AFTER_ENTERING_VERIFICATION_CODE:
        'clickContinueAfterEnterVerificationCode',
      ERROR_SENDING_CODE_DUE_TO_NO_CONNECTION:
        'errorSendingCodeDueToNoConnection',
      ERROR_REGISTERING_LATER_DUE_TO_NO_CONNECTION:
        'errorRegisteringLaterDueToNoConnection',
      TAP_CREATE_ALARM_USER_INACTIVE_NOTIFICAITON:
        'tapCreateAlarmUserInactiveNotification',
      TAP_OK_USER_INACTIVE_NOTIFICAITON: 'tapOkUserInactiveNotification',
      TAP_USER_INACTIVE_NOTIFICAITON: 'tapUserInactiveNotification',
      ALARM_ENABLED: 'alarmEnabled',
      ALARM_DISABLED: 'alarmDisabled',
      TAP_UPDATED_APP_AVAILABLE_ALERT: 'tapUpdatedAppAvailableAlert',
      VIEW_ALARM_HISTORY: 'viewAlarmHistory',
      VIEW_MORE_ALARM_HISTORY: 'viewMoreAlarmHistory',
      VIEW_ALARM_PREVIOUS_OCCURRENCE_ACTIONS:
        'viewAlarmPreviousOccurrenceActions',
      SET_ALARM_PREVIOUS_OCCURRENCE_RESPONSE:
        'setAlarmPreviousOccurrenceResponse',
      TAP_DONT_SEE_COMPLETE_ALARM_HISTORY: 'tapDontSeeCompleteAlarmHistory',
      TAP_NOTIFICATION: 'tapNotification',
      TAP_SYSTEM_MESSAGE_ACTION: 'tapSystemMessageAction',
      VIEW_ALARMS: 'viewAlarms',
      VIEW_GROUPS: 'viewGroups',
      VIEW_CONTACTS: 'viewContacts',
      VIEW_NOTIFICATIONS: 'viewNotifications',
      VIEW_SETTINGS: 'viewSettings',
      USER_CHOSE_PHONE_NUMBER_HINT: 'userChosePhoneNumberHint',
      SHOW_ADDITIONAL_SETUP_INSTRUCTIONS: 'showAdditionalSetupInstructions',
      TAP_ADDITIONAL_SETUP_INSTRUCTIONS_BUTTON:
        'tapAdditionalSetupInstructionsButton',
      TAP_ADDITIONAL_SETUP_INSTRUCTIONS_MESSAGE:
        'tapAdditionalSetupInstructionsMessage',
      UPLOAD_NEW_PROFILE_IMAGE: 'uploadNewProfileImage',
      DO_NOT_SHOW_ADDITIONAL_INFO_ABOUT_ALARM:
        'doNotShowAdditionalInfoAboutAlarm',
      TAP_ADD_CONTACT: 'tapAddContact',
      TAP_ADD_MANUALLY: 'tapAddManually',
      TAP_ADD_CONTACT_FROM_PHONEBOOK: 'tapAddContactFromPhoneBook',
      TAP_INVITE: 'tapInvite',
      TAP_N_OTHER_CONTACTS: 'tapNOtherContacts',
      NEW_CONTACT: 'newContact',
      DELETE_CONTACT: 'deleteContact',
      EDIT_CONTACT_NAME: 'editContactName',
      RESEND_GALARM_INVITE: 'resendGalarmInvite',
      CONTACT_PERMISSION_CONTINUE: 'contactPermissionContinue',
      CONTACT_PERMISSION_LATER: 'contactPermissionLater',
      CHANGE_RINGER_SETTINGS: 'changeRingerSettings',
      ALARM_RINGTONE_DELETION_DETECTED: 'alarmRingtoneDeletionDetected',
      CLICK_BACK_AFTER_CONTINUE_TO_REGISTER: 'clickBackAfterContinueToRegister',
      DELTA_CONTACTS_SYNC: 'deltaContactsSync',
      FULL_CONTACTS_SYNC_AFTER_MAX_INTERVAL: 'fullContactsSyncAfterMaxInterval',
      IAP_ERROR: 'iapError',
      RATE_THE_APP_FROM_SETTINGS: 'rateTheAppFromSettings',
      UPGRADE_TO_PREMIUM: 'upgradeToPremium',
      TAP_AD_PLACEHOLDER: 'tapAdPlaceholder',
      WATCH_REWARDED_VIDEO: 'watchRewardedVideo',
      REWARDED_VIDEO_NOT_LOADED: 'rewardedVideoNotLoaded',
      REWARDED_VIDEO_BACK_PRESSED: 'rewardedVideoBackPressed',
      OPEN_LINK_EVENT: 'openLinkEvent',
      INITIAL_LINK_EVENT: 'initialLinkEvent',
      DEEP_LINK_EDIT_ALARM_TAP_EVENT: 'deepLinkEditAlarmTapEvent',
      USER_HAS_BIRTHDAYS_IN_CONTACT_BOOK: 'userHasBirthdaysInContactBook',
      ALARM_NOT_FOUND: 'alarmNotFound',
      REMOVED_INVALID_ALARM: 'removedInvalidAlarm',
      ADD_TO_CONTACTS: 'addToContacts',
      CREATE_PERSONAL_ALARM_LINK: 'createPersonalAlarmLink',
      CREATE_PERSONAL_ALARM_FROM_LINK: 'createPersonalAlarmFromLink',
      TAP_NEW_ALARM: 'tapNewAlarm',
      TAP_QUICK_ALARM: 'tapQuickAlarm',
      CHOOSE_ALARM_TYPE: 'chooseAlarmType',
      SAVE_NEW_ALARM: 'saveNewAlarm',
      SAVE_QUICK_ALARM: 'saveQuickAlarm',
      SAVE_INSTANT_ALARM: 'saveInstantAlarm',
      SKIP_REGISTRATION_FROM_VERIFY_CODE_SCREEN:
        'skipRegistrationFromVerifyCodeScreen',
      SKIP_PERSONAL_ALARM: 'skipPersonalAlarm',
      SKIP_PERSONAL_PARTICIPANT_ALARM: 'skipPersonalParticipantAlarm',
      SKIP_RECIPIENT_ALARM: 'skipRecipientAlarm',
      SKIP_RECIPIENT_CREATOR_ALARM: 'skipRecipientCreatorAlarm',
      HANDLE_PENDING_ACTION: 'handlePendingAction',
      CHOOSE_MONTHLY_REPEAT_TYPE: 'chooseMonthlyRepeatType',
      CHOOSE_YEARLY_REPEAT_TYPE: 'chooseYearlyRepeatType',
      SET_ALARM_CATEGORY_FROM_NEW_ALARM_SCREEN:
        'setAlarmCategoryFromNewAlarmScreen',
      CHANGE_ALARM_CATEGORY_FROM_EDIT_ALARM_SCREEN:
        'changeAlarmCategoryFromEditAlarmScreen',
      ADD_ALARM_TO_ALARM_CATEGORY: 'addAlarmToAlarmCategory',
      REMOVE_ALARM_FROM_ALARM_CATEGORY: 'removeAlarmFromAlarmCategory',
      CREATE_ALARM_CATEGORY: 'createAlarmCategory',
      UPDATE_ALARM_CATEGORY: 'updateAlarmCategory',
      DELETE_ALARM_CATEGORY: 'deleteAlarmCategory',
      UNHIDE_ALARM_CATEGORY: 'unhideAlarmCategory',
      HIDE_ALARM_CATEGORY: 'hideAlarmCategory',
      CLICK_SAVE_TO_CALENDAR: 'clickSaveToCalendar',
      SAVE_EVENT_TO_CALENDAR: 'saveEventToCalendar',
      REPEAT_TYPE_NOT_SUPPORTED_BY_CALENDAR: 'repeatTypeNotSupportedByCalendar',
      CHANGE_COLOR_SCHEME: 'changeColorScheme',
      CHANGE_WEB_COLOR_SCHEME: 'changeWebColorScheme',
      USER_SKIPPED_CREATING_WELCOME_ALARMS: 'userSkippedCreatingWelcomeAlarms',
      USER_CREATED_WELCOME_ALARMS: 'userCreatedWelcomeAlarms',
      WELCOME_ALARM_TIME_CHANGED: 'welcomeAlarmTimeChanged',
      ALARM_CATEGORY_ACTION_CLICKED: 'alarmCategoryActionClicked',
      INVALID_CONTACT_PARTICIPANT: 'invalidContactParticipant',
      INVALID_GROUP_PARTICIPANT: 'invalidGroupParticipant',
      CANCEL_ORPHAN_ALARM: 'cancelOrphanAlarm',
      FIXED_ORPHAN_ALARM: 'fixedOrphanAlarm',
      APP_IN_FOREGROUND_IN_HEADLESS_JS_TASK: 'appInForegroundInHeadlessJsTask',
      LOW_MEMORY_WARNING: 'lowMemoryWarning',
      DETECTED_TIMEZONE_MISMATCH: 'detectedTimezoneMismatch',
      START_SLEEP_TIMER: 'startSleepTimer',
      TAP_GALARM_ON_SOCIAL_MEDIA: 'tapGalarmOnSocialMedia',
      ENABLE_HIGH_POWER_MODE: 'enableHighPowerMode',
      DISABLE_HIGH_POWER_MODE: 'disableHighPowerMode',
      MARK_PERSONAL_ALARM_UNSKIPPED: 'personalAlarmUnskipped',
      MARK_RECIPIENT_ALARM_UNSKIPPED: 'recipientAlarmUnskipped',
      MARK_RECIPIENT_CREATOR_ALARM_UNSKIPPED: 'recipientCreatorAlarmUnskipped',
      MARK_PERSONAL_PARTICIPANT_ALARM_UNSKIPPED:
        'personalParticipantAlarmUnskipped',
      FIX_ALARM_KEEPS_REPEATING: 'fixAlarmKeepsRepeating',
      FIX_ALARM_DID_NOT_RING: 'fixAlarmDidNotRing',
      WEB_LOGIN_QR_CODE_SCANNED: 'webLoginQrCodeScanned',
      RELOAD_WEB_LOGIN_QR_CODE: 'reloadWebLoginQrCode',
      APP_OPENED_ON_MULTIPLE_BROWSERS: 'appOpenedOnMultipleBrowsers',
      APP_SESSION_RESUMED: 'appSessionResumed',
      APP_SESSION_DISMISSED: 'appSessionDismissed',
      WEB_LOG_OUT: 'webLogOut',
      WEB_SESSION_LOGGED_OUT_FROM_APP: 'webSessionLoggedOutFromApp',
      NEW_ALARM_WITH_PARTICIPANTS: 'newAlarmWithParticipants',
      RING_ALARM_ON_EARPHONES_ONLY: 'ringAlarmOnEarphonesOnly',
      RING_ALARM_ON_SPEAKER_AND_EARPHONES: 'ringAlarmOnSpeakerAndEarphones',
      VIEW_FAQ: 'viewFaq',
      VIEW_HOWTO_VIDEOS: 'viewHowtoVideos',
      UPDATE_GESTURE_TO_DISMISS_AN_ALARM: 'updateGestureToDimissAnAlarm',
      SPECIFY_TIMEZONE_FOR_ALARM: 'specifyTimezoneForAlarm',
      DUPLICATE_ALARM: 'duplicateAlarm',
      SHOW_DELETE_INACTIVE_ALARMS: 'showDeleteInactiveAlarms',
      ENABLE_GRADUALLY_INCREASE_VOLUME: 'enableGraduallyIncreaseVolume',
      DISABLE_GRADUALLY_INCREASE_VOLUME: 'disableGraduallyIncreaseVolume',
      MOBILE_AND_WEB_TIMEZONE_MISMATCH_DETECTED:
        'mobileAndWebTimezoneMismatchDetected',
      ALARM_LIST_APP_WIDGET_NEW_ALARM_CLICK: 'alarmListAppWidgetNewAlarmClick',
      ALARM_LIST_APP_WIDGET_VIEW_ALARM_CLICK:
        'alarmListAppWidgetViewAlarmDetails',
      VIEW_ALARM_DETAILS: 'viewAlarmDetails',
      NEW_PERSONAL_ALARM: 'newPersonalAlarm',
      NEW_QUICK_ALARM: 'newQuickReminder',
      CHOOSE_DAY_REPEAT_TYPE: 'chooseDayRepeatType',
      USER_TIP_DISMISS_BUTTON_TAPPED: 'userTipDismissButtonTapped',
      USER_TIP_ACTION_BUTTON_TAPPED: 'userTipActionButtonTapped',
      CREATE_QUICK_ALARM_FROM_GOOGLE_VOICE_COMMAND:
        'createQuickAlarmFromGoogleVoiceCommand',
      CREATE_ALARM_FROM_GOOGLE_VOICE_COMMAND:
        'createAlarmFromGoogleVoiceCommand',
      EXPORT_ALARM_DATA: 'exportAlarmData',
      VIEW_ALARM_FOLLOWERS: 'viewAlarmFollowers',
      SKIP_CONTACTS_SYNC_DUE_TO_TOO_MANY_CONTACTS:
        'skipContactsSyncDueToTooManyContacts',
      VERIFICATION_COMPLETE_WITHOUT_OTP: 'verificationCompleteWithoutOtp',
      ALARM_RESTORED: 'alarmRestored',
      ADD_PENDING_APP_UPDATE: 'addPendingAppUpdate',
      TAP_INSTALL_PENDING_APP_UPDATE: 'tapInstallPendingAppUpdate',
      UPLOADED_ALARM_PHOTO: 'uploadedAlarmPhoto',
      CREATE_CHECKLIST: 'createChecklist',
      RENAME_CHECKLIST: 'renameChecklist',
      ADD_ITEM_TO_CHECKLIST: 'addItemToChecklist',
      RENAME_ITEM_IN_CHECKLIST: 'renameItemInChecklist',
      CHECK_ITEM_IN_CHECKLIST: 'checkItemInChecklist',
      UNCHECK_ITEM_IN_CHECKLIST: 'uncheckItemInChecklist',
      CHANGE_ITEM_ORDER_IN_CHECKLIST: 'changeItemOrderInChecklist',
      REMOVE_ITEM_IN_CHECKLIST: 'removeItemInChecklist',
      DELETE_CHECKLIST: 'deleteChecklist',
      SHOW_COMPLETED_ITEMS_IN_CHECKLIST: 'showCompletedItemsInChecklist',
      HIDE_COMPLETED_ITEMS_IN_CHECKLIST: 'hideCompletedItemsInChecklist',
      DELETE_COMPLETED_ITEMS_IN_CHECKLIST: 'deleteCompletedItemsInChecklist',
      UNCHECK_ALL_ITEMS_IN_CHECKLIST: 'uncheckAllItemsInChecklist',
      UPGRADE_TO_PREMIUM_FEATURE_PROMPT: 'upgradeToPremiumFeaturePrompt',
      UPDATED_PHONE_NUMBER: 'updatedPhoneNumber',

      // Galarm Enterprise analytics events
      CREATE_ENTERPRISE_ACCOUNT: 'createEnterpriseAccount',
      START_SUBSCRIPTION_CHECKOUT_SESSION: 'startSubscriptionCheckoutSession',
      ASSIGN_KEY_TO_MEMBER: 'assignKeyToMember',
      REVOKE_KEY_FROM_MEMBER: 'revokeKeyFromMember',
      REMOVE_MEMBER: 'removeMember',
      ADD_MEMBER: 'addMember',
      EDIT_MEMBER: 'editMember',
      DELETE_TEAM: 'deleteTeam',
      DELETE_ROTATION: 'deleteRotation',
      ADD_TEAM: 'addTeam',
      EDIT_TEAM: 'editTeam',
      ADD_ROTATION: 'addRotation',
      EDIT_ROTATION: 'editRotation',
      TRIGGER_ALERT_FROM_UI: 'triggerAlertFromUI',
      DELETE_ALERT: 'deleteAlert',
      EDIT_ALERT: 'editAlert',
      ADD_ALERT: 'addAlert',
      CLOSE_INCIDENT_FROM_UI: 'closeIncidentFromUI'
    },

    PERSONAL_ALARM_LINK_SOURCES: {
      FIREBASE_CONSOLE: 'firebaseConsole',
      SHAREABLE_ALARM_LINK: 'shareableAlarmLink'
    },

    ALARM_CATEGORY_SOURCES: {
      USER: 'user',
      SYSTEM: 'system'
    },

    ALARM_CATEGORY_COLORS: [
      'pink',
      'blue',
      'deepPurple',
      'brown',
      'green',
      'orange',
      'cyan',
      'indigo',
      'lime',
      'teal'
    ],

    ADDITIONAL_SETUP_INSTRUCTIONS_DONE_ENTRY_POINTS: {
      ADDITIONAL_SETUP_INSTRUCTIONS_ALERT: 'AdditionalSetupInstructionsAlert',
      SYSTEM_MESSAGE: 'SystemMessage'
    },

    NEW_PROFILE_IMAGE_ENTRY_POINTS: {
      USER: 'user',
      GROUP: 'group'
    },

    ExistingUserRegistrationEntryPoints: {
      GROUP_LIST_SCREEN: 'groupList',
      CONTACT_LIST_SCREEN: 'contactList',
      SETTINGS_SCREEN: 'settings',
      EDIT_PARTICIPANT_LIST: 'editParticipantList',
      PARTICIPANT_ALARM_FILTER: 'participantAlarmFilter',
      NEW_GROUP_ALARM: 'newGroupAlarm',
      NEW_BUDDY_ALARM: 'newBuddyAlarm'
    },

    RefreshContactsEntryPoints: {
      NAVIGATION_BAR_BUTTON: 'navigationBarButton',
      PULL_DOWN_CONTACT_LIST: 'pullDownContactList',
      FOOTER_BUTTON: 'footerButton'
    },

    UserAnalyticsEventParameters: {
      UID: 'uid',
      ALARM_TYPE: 'alarmType',
      HAS_PARTICIPANTS: 'hasParticipants',
      TIME_BETWEEN_PARTICIPANT_ALERTS: 'timeBetweenParticipantAlerts',
      IS_REPEATING: 'isRepeating',
      REMIND_ME_AFTER: 'remindMeAfter',
      NUM_MEMBERS: 'numMembers',
      RINGTONE_TYPE: 'ringtoneType',
      RINGTONE_NAME: 'ringtoneName',
      ENTRY_POINT: 'entryPoint',
      REPEAT_TYPE: 'repeatType',
      REPEAT_STRING: 'repeatString',
      DAY_OF_MONTH: 'dayOfMonth',
      STATUS: 'status',
      DEVICE_MANUFACTURER: 'deviceManufacturer',
      APP_RELEASE: 'appRelease',
      COUNTRY: 'country',
      MOBILE_NUMBER: 'mobileNumber',
      LAST_PARTICIPANT_DATE: 'lastParticipantDate',
      CREATOR_DATE: 'creatorDate',
      COUNTRY_CODE: 'countryCode',
      ALARM_FILTER: 'alarmFilter',
      RING_GROUP_ALARM_AFTER_CONFIRMING_ADVANCE:
        'ringGroupAlarmAfterConfirmingInAdvance',
      ALARM_PREVIOUS_OCCURRENCE_RESPONSE: 'alarmPreviousOccurrenceResponse',
      NOTIFICATION_TYPE: 'notificationType',
      SYSTEM_MESSAGE_ACTION_NAME: 'systemMessageActionName',
      TIME_FORMAT_VALUE: 'timeFormat',
      BUTTON_NAME: 'buttonName',
      RINGTONE: 'ringtone',
      VIBRATE: 'vibrate',
      VOLUME: 'volume',
      RINGTONE_DURATION: 'ringtoneDuration',
      ANNOUNCE_ALARM_NAME: 'announceAlarmName',
      RING_ON_VIBRATE: 'ringOnVibrate',
      ALARM_CATEGORY: 'alarmCategory',
      CODE: 'code',
      ALARMS_LIMIT: 'alarmsLimit',
      TEXT: 'text',
      HAS_END_DATE: 'hasEndDate',
      INACTIVE_ALARMS_COUNT: 'inactiveAlarmsCount',
      URL: 'url',
      ALARM_CREATION_SOURCE: 'alarmCreationSource',
      NUMBER_OF_CONTACTS: 'numberOfContacts',
      SNOOZE_ALARM_DURATION: 'snoozeAlarmDuration',
      MEDIUM: 'medium',
      LOCALE: 'locale',
      ACTION_NAME: 'actionName',
      WEEK_NUMBER_OF_DAY_IN_MONTH: 'weekNumberOfDayInMonth',
      SOURCE: 'source',
      NUM_PARTICIPANTS: 'numParticipants',
      HAS_PRE_REMINDER: 'hasPreReminder',
      TIP_KEY: 'tipKey',
      DURATION: 'duration',
      TITLE: 'title',
      HOUR: 'hour',
      MIINUTE: 'minute',
      REASON: 'reason',
      PARTICIPANT_TYPE: 'participantType'
    },

    JsEvents: {
      SELECT_BACKUPS_DONE: 'selectBackupsDone',
      SAVE_NEW_ALARM: 'saveNewAlarm',
      SAVE_EDITED_ALARM: 'saveEditedAlarm',
      SAVE_ALARM_REPETITION: 'saveAlarmRepetition',
      SELECT_LIST_DONE: 'selectListDone',
      SAVE_GROUP_MEMBERS_CONFIGURATION: 'saveGroupMembersConfiguration',
      SAVE_NEW_GROUP: 'saveNewGroup',
      SELECT_MEMBERS_DONE: 'selectMembersDone'
    },

    ProgressStates: {
      IN_PROGRESS: 'inProgress',
      ERROR: 'error',
      COMPLETE: 'complete'
    },

    AlertTypes: {
      LEFT_GROUP: 'leftGroup',
      REMOVED_FROM_GROUP: 'removedFromGroup',
      MEMBER_LEFT_GROUP: 'memberLeftGroup',
      MEMBER_REMOVED_FROM_GROUP: 'memberRemovedFromGroup',
      MEMBER_ADDED_TO_GROUP: 'memberAddedToGroup',
      CREATOR_TIMEZONE_CHANGED: 'creatorTimezoneChanged',
      MEMBER_DELETED_ACCOUNT: 'memberDeletedAccount',
      RINGTONE_DELETED: 'ringtoneDeleted',
      DST_CHANGED: 'dstChanged',
      SUBSCRIPTION_CANCELED: 'subscriptionCanceled',
      SUBSCRIPTION_IN_RETRY_PERIOD: 'subscriptionInRetryPeriod',
      SUBSCRIBED_ALARM_EDITED: 'subscribedAlarmEdited',
      SUBSCRIBED_ALARM_DELETED: 'subscribedAlarmDeleted',
      RESPONDED_TO_USER_TICKET: 'respondedToUserTicket',
      NEW_TICKET_RECEIVED: 'newTicketReceived',
      SENT_ACCOUNT_BACKUP: 'sentAccountBackup',
      SLACK_VERIFICATION_CODE: 'slackVerificationCode',
      MEMBER_CHANGED_PHONE_NUMBER: 'memberChangedPhoneNumber'
    },

    CascadingAlarmIntervals: {
      '5mins': {
        label: I18n.t('minutes', { count: 5 }),
        value: 300000
      },
      '10mins': {
        label: I18n.t('minutes', { count: 10 }),
        value: 600000
      },
      '15mins': {
        label: I18n.t('minutes', { count: 15 }),
        value: 900000
      },
      '30mins': {
        label: I18n.t('minutes', { count: 30 }),
        value: 1800000
      },
      '1hr': {
        label: I18n.t('hours', { count: 1 }),
        value: 3600000
      },
      '2hr': {
        label: I18n.t('hours', { count: 2 }),
        value: 7200000
      },
      '3hr': {
        label: I18n.t('hours', { count: 3 }),
        value: 10800000
      }
    },

    RecipientAlarmIntervals: {
      '0mins': {
        label: I18n.t('atTheSameTime'),
        value: 0,
        alarmDetailsDisplayString: I18n.t('sameTimeAsBuddy')
      },
      '5mins': {
        label: I18n.t('minutesAfter', { count: 5 }),
        value: 300000,
        alarmDetailsDisplayString: I18n.t('minutesAfterBuddy', { count: 5 })
      },
      '10mins': {
        label: I18n.t('minutesAfter', { count: 10 }),
        value: 600000,
        alarmDetailsDisplayString: I18n.t('minutesAfterBuddy', { count: 10 })
      },
      '15mins': {
        label: I18n.t('minutesAfter', { count: 15 }),
        value: 900000,
        alarmDetailsDisplayString: I18n.t('minutesAfterBuddy', { count: 15 })
      },
      '30mins': {
        label: I18n.t('minutesAfter', { count: 30 }),
        value: 1800000,
        alarmDetailsDisplayString: I18n.t('minutesAfterBuddy', { count: 30 })
      },
      '1hr': {
        label: I18n.t('hoursAfter', { count: 1 }),
        value: 3600000,
        alarmDetailsDisplayString: I18n.t('hoursAfterBuddy', { count: 1 })
      },
      '2hr': {
        label: I18n.t('hoursAfter', { count: 2 }),
        value: 7200000,
        alarmDetailsDisplayString: I18n.t('hoursAfterBuddy', { count: 3 })
      },
      '3hr': {
        label: I18n.t('hoursAfter', { count: 3 }),
        value: 10800000,
        alarmDetailsDisplayString: I18n.t('hoursAfterBuddy', { count: 3 })
      },
      // Keep the key same as NO_REMINDER_RECIPIENT_INTERVAL_KEY
      noReminder: {
        label: I18n.t('dontRemindMe'),
        // This is set to 0 such that it doesn't affect the
        // repetition string for buddy alarm for the creator.
        value: 0,
        alarmDetailsDisplayString: I18n.t('dontRemindMe')
      }
    },

    // Values are mapped to internationalized values. Add/change as needed
    ParticipantStates: {
      ACTIVE: 'active',
      INACTIVE: 'inactive'
    },

    AlertDurations: {
      SHORT: 3,
      LONG: 5,
      VERY_LONG: 10,
      EXTREMELY_LONG: 15
    },

    AlertPositions: {
      BOTTOM: 'bottom',
      TOP: 'top'
    },

    ParticipantTypes: {
      GROUP: 'group',
      CONTACT: 'contact'
    },

    AndroidRingerModes: {
      RINGER_MODE_SILENT: 0,
      RINGER_MODE_VIBRATE: 1,
      RINGER_MODE_NORMAL: 2
    },

    AndroidAudioStreams: {
      STREAM_MUSIC: 3
    },

    Errors: {
      DEVICE_CHANGED: 'Device Changed',
      INVALID_UID: 'Invalid UID',
      UNSUPPORTED_VERSION: 'Unsupported Version',
      INVITED_CONTACT_DOES_NOT_EXIST: "Invited contact doesn't exist",
      ACCOUNT_DELETED: 'Account Deleted'
    },

    AlarmOccurrenceStatusesSummaryStringKey: {
      Personal: {
        own: {
          Done: 'ownAlarmOccurrenceDone',
          Skip: 'ownAlarmOccurrenceSkipped',
          Undone: 'ownAlarmOccurrenceUnacknowledged',
          true: 'ownAlarmOccurrenceUnacknowledged',
          false: 'alarmOccurrenceDisabled'
        },
        participant: {
          Done: 'participantAlarmOccurrenceDone',
          Skip: 'participantAlarmOccurrenceSkipped',
          Undone: 'participantAlarmOccurrenceUnacknowledged',
          true: 'participantAlarmOccurrenceUnacknowledged',
          false: 'alarmOccurrenceDisabled'
        }
      },
      Group: {
        Confirm: 'groupAlarmOccurrenceConfirmed',
        Decline: 'groupAlarmOccurrenceDeclined',
        true: 'ownAlarmOccurrenceUnacknowledged',
        false: 'alarmOccurrenceDisabled'
      },
      Recipient: {
        own: {
          Done: 'participantAlarmOccurrenceDone',
          Skip: 'participantAlarmOccurrenceSkipped',
          Undone: 'participantAlarmOccurrenceUnacknowledged',
          true: 'participantAlarmOccurrenceUnacknowledged',
          false: 'alarmOccurrenceDisabled'
        },
        participant: {
          Done: 'ownAlarmOccurrenceDone',
          Skip: 'ownAlarmOccurrenceSkipped',
          Undone: 'ownAlarmOccurrenceUnacknowledged',
          true: 'ownAlarmOccurrenceUnacknowledged',
          false: 'alarmOccurrenceDisabled'
        }
      }
    },

    ContactSources: {
      GALARM: 'galarm',
      PHONE_ADDRESS_BOOK: 'phoneAddressBook'
    },

    AD_UNIT_NAMES: {
      ALARM_LIST_SCREEN: 'ALARM_LIST_SCREEN',
      NOTIFICATIONS_SCREEN: 'NOTIFICATIONS_SCREEN',
      SETTINGS_SCREEN: 'SETTINGS_SCREEN',
      ALARM_DETAILS_SCREEN: 'ALARM_DETAILS_SCREEN',
      PARTICIPANT_ALARM_DETAILS_SCREEN: 'PARTICIPANT_ALARM_DETAILS_SCREEN',
      SELECT_ALARM_TYPE_SCREEN: 'SELECT_ALARM_TYPE_SCREEN',
      ALARM_CONVERSATION_SCREEN: 'ALARM_CONVERSATION_SCREEN',
      CONTACT_DETAILS_SCREEN: 'CONTACT_DETAILS_SCREEN',
      SELECT_RINGER_SETTINGS_SCREEN: 'SELECT_RINGER_SETTINGS_SCREEN',
      TEST_BANNER: 'TEST_BANNER'
    },

    ALARMS_COUNT_TYPES: {
      NEW: 'new',
      EDIT: 'edit'
    },

    IMAGE_TYPES: {
      FULL_IMAGE: 'fullImage',
      THUMBNAIL: 'thumbnail'
    },
    ENTITY_TYPES: {
      USER: 'user',
      GROUP: 'group',
      ALARM: 'alarm'
    },
    FILE_METADATA_KEYS: {
      IMAGE_TYPE: 'imageType',
      ENTITY_TYPE: 'entityType',
      UID: 'uid',
      UPDATED_ON: 'updatedOn',
      CACHE_CONTROL: 'cacheControl'
    },

    TICKET_TYPES: {
      GENERAL_FEEDBACK: 'General feedback',
      PROBLEM_REPORT: 'Problem report',
      ACCOUNT_DELETION: 'Account deletion'
    },

    PurchaseTypes: {
      PRODUCT: 'inapp',
      SUBSCRIPTION: 'subs'
    },

    SUBSCRIPTION_STATUSES: {
      VALID: 'valid',
      EXPIRED: 'expired',
      IN_RETRY_PERIOD: 'inRetryPeriod'
    },

    COLOR_SCHEMES: {
      LIGHT: 'light',
      DARK: 'dark',
      AUTO_LIGHT: 'autoLight',
      AUTO_DARK: 'autoDark'
    },

    COLOR_SCHEME_DISPLAY_NAMES: {
      light: I18n.t('light'),
      dark: I18n.t('dark'),
      autoLight: I18n.t('systemDefault'),
      autoDark: I18n.t('systemDefault')
    },

    IOS_NOTIFICATION_ACTIONS_IDENTIFIERS: {
      DONE: 'i_am_up_action',
      SNOOZE: 'snooze_alarm',
      RA_DONE: 'recipient_alarm_done_action',
      CONFIRM: 'respond_yes_action',
      DECLINE: 'respond_no_action',
      ACCEPT: 'accept_action',
      REJECT: 'reject_action',
      SKIP: 'skip_action',
      RA_SKIP: 'recipient_alarm_skip_action',
      PPA_SKIP: 'personal_participant_alarm_skip_action',
      RCA_SKIP: 'recipient_creator_alarm_skip_action'
    },

    AutoSnoozeDurations: [
      { label: I18n.t('minutes', { count: 1 }), value: 60000 },
      { label: I18n.t('minutes', { count: 5 }), value: 300000 },
      { label: I18n.t('minutes', { count: 10 }), value: 600000 },
      { label: I18n.t('minutes', { count: 15 }), value: 900000 },
      { label: I18n.t('minutes', { count: 30 }), value: 1800000 },
      { label: I18n.t('hours', { count: 1 }), value: 3600000 }
    ],

    AutoSnoozeCounts: [
      { label: I18n.t('nTimes', { count: 2 }), value: 2 },
      { label: I18n.t('nTimes', { count: 3 }), value: 3 },
      { label: I18n.t('nTimes', { count: 5 }), value: 5 },
      { label: I18n.t('forever'), value: -1 }
    ],

    SUBSCRIPTION_INTERVALS: {
      MONTHLY: 'month',
      YEARLY: 'year'
    },

    GESTURES_TO_DISMISS_AN_ALARM: {
      TAP: 'tap',
      SLIDE: 'slide'
    },

    RESOLUTION_CRITERIA: {
      ANY_ONE: 'anyOne',
      EVERY_ONE: 'everyOne'
    },

    NOTIFICATION_ORDERS: {
      SIMULTANEOUS: 'simultaneous',
      CASCADING: 'cascading'
    },

    TIMEZONE_SETTINGS: {
      DEVICE: 'device',
      EXPLICIT: 'explicit'
    },
    PRE_REMINDER_INTERVALS: [
      [
        {
          label: I18n.t('minsInterval', { count: 1 }),
          value: 60000
        },
        {
          label: I18n.t('hoursInterval', { count: 1 }),
          value: 1 * 3600000
        }
      ],
      [
        {
          label: I18n.t('minsInterval', { count: 10 }),
          value: 10 * 60000
        },
        {
          label: I18n.t('hoursInterval', { count: 4 }),
          value: 4 * 3600000
        }
      ],
      [
        {
          label: I18n.t('minsInterval', { count: 30 }),
          value: 30 * 60000
        },
        {
          label: I18n.t('daysInterval', { count: 1 }),
          value: 8.64e7
        }
      ]
    ],

    SETTING_FOCUS_INSTRUCTIONS: [
      I18n.t('settingFocusInstruction1'),
      I18n.t('settingFocusInstruction2'),
      I18n.t('settingFocusInstruction3'),
      I18n.t('settingFocusInstruction4')
    ],

    ENABLING_FOCUS_INSTRUCTIONS: [
      I18n.t('enablingFocusInstruction1'),
      I18n.t('enablingFocusInstruction2'),
      I18n.t('enablingFocusInstruction3'),
      I18n.t('enablingFocusInstruction4')
    ],

    ROTATION_TYPES: {
      DAYS: 'days',
      WEEKS: 'weeks',
      HOURS: 'hours'
    },

    ASSIGNEE_TYPES: {
      MEMBER: 'member',
      TEAM: 'team',
      ROTATION: 'rotation'
    },

    INCIDENT_STATES: {
      OPEN: 'open',
      CLOSED: 'closed'
    },

    LANGUAGES: [
      { code: 'en', name: I18n.t('english') },
      { code: 'ru', name: I18n.t('russian') },
      { code: 'de', name: I18n.t('german') },
      { code: 'fr', name: I18n.t('french') },
      { code: 'es', name: I18n.t('spanish') },
      { code: 'it', name: I18n.t('italian') },
      { code: 'pt', name: I18n.t('portuguese') },
      { code: 'ja', name: I18n.t('japanese') },
      { code: 'ko', name: I18n.t('korean') },
      { code: 'id', name: I18n.t('indonesian') }
    ],

    ENTERPRISE_SUBSCRIPTION_STATUSES: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
      TRIALING: 'trialing',
      INCOMPLETE: 'incomplete',
      INCOMPLETE_EXPIRED: 'incomplete_expired',
      PAST_DUE: 'past_due',
      UNPAID: 'unpaid',
      CANCELED: 'canceled'
    },

    USER_VERIFICATION_PURPOSES: {
      USER_REGISTRATION: 'userRegistration',
      UPDATE_PHONE_NUMBER: 'updatePhoneNumber'
    },

    NotificationKeys: {
      MyAlarmNotification: 'MyAlarmNotification', // '{0} - It\'s time!'
      ParticipantCascadingAlarmNotification:
        'ParticipantCascadingAlarmNotification', // '{0} (by {1}) - It\'s time to remind them.'
      ParticipantSimultaneousAlarmNotification:
        'ParticipantSimultaneousAlarmNotification', // '{0}. It\'s time to confirm your participation.'
      MyAlarmNotificationShort: 'MyAlarmNotificationShort', // 'It\'s time!'
      ParticipantCascadingAlarmNotificationShort:
        'ParticipantCascadingAlarmNotificationShort', // 'It\'s time to remind {0}.'
      ParticipantSimultaneousAlarmNotificationShort:
        'ParticipantSimultaneousAlarmNotificationShort', // 'It\'s time to confirm your participation.'
      AddedAsParticipantNotification: 'AddedAsParticipantNotification', // '{1} added you as a participant for {0} at {2}.'
      AlarmTimeChangedNotification: 'AlarmTimeChangedNotification', // '{0} has changed time for {1} from {2} to {3}.'
      RemovedAsParticipantNotification: 'RemovedAsParticipantNotification', // '{1} has removed you as a participant from {0}.'
      CascadingAlarmAcknowledgedNotification:
        'CascadingAlarmAcknowledgedNotification', // '{0} has acknowledged {1}.'
      ParticipantAlarmAcceptedNotification:
        'ParticipantAlarmAcceptedNotification', // '{0} has {1} {2}.'
      ParticipantAlarmDeclinedNotification:
        'ParticipantAlarmDeclinedNotification', // '{0} has {1} {2}.'
      SimultaneousAlarmInstanceConfirmedNotification:
        'SimultaneousAlarmInstanceConfirmedNotification', // '{0} has {1} participation for {2}.'
      SimultaneousAlarmInstanceDeclinedNotification:
        'SimultaneousAlarmInstanceDeclinedNotification', // '{0} has {1} participation for {2}.'
      NewAlarmMessage: 'NewAlarmMessage', // '{0} @ {1}: {2}'
      AddedAsRecipientNotification: 'AddedAsRecipientNotification', // '{0} has requested you to {1} at {2}'
      MyRecipientAlarmNotification: 'MyRecipientAlarmNotification', // '{0} - It's time to remind {1}'
      MyRecipientAlarmNotificationShort: 'MyRecipientAlarmNotificationShort', // 'It's time to remind {0}
      RecipientAlarmNotification: 'RecipientAlarmNotification', // {0} (by {1}) - It's time
      RecipientAlarmNotificationShort: 'RecipientAlarmNotificationShort', // By {0} - It's time
      RecipientAlarmAcknowledgedNotification:
        'RecipientAlarmAcknowledgedNotification', // '{0} has acknowledged {1}'
      RemovedAsRecipientNotification: 'RemovedAsRecipientNotification', // '{0} has removed you from {1}',
      RecipientAlarmUnacknowledgedNotification:
        'RecipientAlarmUnacknowledgedNotification', // '{0} has unacknowledged {1}',
      RecipientAlarmUnskippedNotification:
        'RecipientAlarmUnskippedNotification', // {0} has unskipped {1}
      CascadingAlarmUnacknowledgedNotification:
        'CascadingAlarmUnacknowledgedNotification', // '{0} has unacknowledged {1}.'
      CascadingAlarmUnskippedNotification:
        'CascadingAlarmUnskippedNotification', // {0} has skipped {1}
      AddedAsBackupNotification: 'AddedAsBackupNotification', // '{1} added you as a backup for {0} at {2}
      RemovedAsBackupNotification: 'RemovedAsBackupNotification', // '{1} removed you as a backup for {0} at {2}
      AlarmNameChangedNotification: 'AlarmNameChangedNotification', // '{0} has changed alarm {1} to {2}.
      AlarmNotesChangedNotification: 'AlarmNotesChangedNotification', // '{0} has updated notes in alarm {1}.
      ParticipantAlarmEnabled: 'ParticipantAlarmEnabled', // '{0} has enabled {1} at {2}
      ParticipantAlarmDisabled: 'ParticipantAlarmDisabled', // `{0} has disabled {1} at {2}
      ParticipantAlarmDeleted: 'ParticipantAlarmDeleted', // `{0} has deleted {1} at {2}
      SubscribedAlarmEditedNotification: 'SubscribedAlarmEditedNotification', // '{{0}} has changed {{1}}. Tap to review.'
      SubscribedAlarmDeletedNotification: 'SubscribedAlarmDeletedNotification', // '{{0}} has deleted {{1}}. Tap to review.'
      PersonalAlarmSkippedNotification: 'PersonalAlarmSkippedNotification', // '{0} has skipped {1}.'
      RecipientAlarmSkippedNotification: 'RecipientAlarmSkippedNotification', // // '{0} has skipped {1}.'
      AlarmSubscribedNotification: 'AlarmSubscribedNotification', //{0} started following {{1}}.
      AlarmMarkedDoneFromWebNotification: 'AlarmMarkedDoneFromWebNotification',
      AlarmSkippedFromWebNotification: 'AlarmSkippedFromWebNotification',
      AlarmConfirmedFromWebNotification: 'AlarmConfirmedFromWebNotification',
      AlarmDeclinedFromWebNotification: 'AlarmDeclinedFromWebNotification',
      InstantAlarmNotification: 'InstantAlarmNotification',
      InstantAlarmNotificationShort: 'InstantAlarmNotificationShort',
      AlarmAddedFromWebNotification: 'AlarmAddedFromWebNotification',
      AlarmEditedFromWebNotification: 'AlarmEditedFromWebNotification',
      AlarmDeletedFromWebNotification: 'AlarmDeletedFromWebNotification',
      AlarmEnabledFromWebNotification: 'AlarmEnabledFromWebNotification',
      AlarmDisabledFromWebNotification: 'AlarmDisabledFromWebNotification',
      AlarmAcceptedFromWebNotification: 'AlarmAcceptedFromWebNotification',
      AlarmRejectedFromWebNotification: 'AlarmRejectedFromWebNotification',
      EnterpriseAlertIncidentAcknowledged: 'EnterpriseAlertIncidentAcknowledged'
    }
  }
})()

export default Constants
