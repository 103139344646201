import {
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Header,
  Image,
  Layer,
  Menu,
  Text
} from 'grommet'
import { Checkmark, Close, MoreVertical } from 'grommet-icons'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LabelledText from './LabelledText'
import isEmpty from 'lodash/isEmpty'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import LabelText from './LabelText'
import ParticipantsListWithAvatar from './ParticipantsListWithAvatar'
import {
  FirebaseProxy,
  NavigationUtils,
  NotificationManager
} from 'galarm-ps-api'
import AppCanvas from './AppCanvas'
import AlarmType from './AlarmType'
import CategoryLabel from './CategoryLabel'
import ChatIcon from './ChatIcon'
import objGet from 'lodash/get'
import SetAlarmCategory from './SetAlarmCategory'
import ColoredButton from './ColoredButton'
import EntityNotFound from './EntityNotFound'
import { ChooseDurationContainer } from 'web-components'

import RichTextEditor from './RichTextEditor'

const {
  makeAlarmCategoryForAlarmIdSelector,
  makeParticipantAlarmDetailsSelector
} = Selectors

const ParticipantAlarmDetailsHeader = ({ alarm }) => {
  const dispatch = useDispatch()

  const [showAlarmCategoryPicker, setShowAlarmCategoryPicker] = useState(false)

  const displayAlarmCategoryPicker = () => setShowAlarmCategoryPicker(true)
  const hideAlarmCategoryPicker = () => setShowAlarmCategoryPicker(false)

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: alarm.id })
  )

  const closeParticipantAlarmDetailsScreen = () => {
    dispatch(ActionCreators.hideParticipantAlarmDetailsScreen())
  }

  const onViewPreviousOccurrences = () => {
    dispatch(ActionCreators.showAlarmHistoryScreen(alarm))
  }

  const menuOptions = [
    {
      label:
        alarmCategory.id === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
          ? I18n.t('setAlarmCategory')
          : I18n.t('changeAlarmCategory'),
      onClick: displayAlarmCategoryPicker
    }
  ]

  if (alarm.repeatType) {
    menuOptions.push({
      label: I18n.t('viewAlarmHistory'),
      onClick: onViewPreviousOccurrences
    })
  }

  return (
    <Header className="header" background="brand" pad="small">
      <Text color="white">{I18n.t('alarmDetails')}</Text>
      <Box direction="row">
        <Button icon={<Close />} onClick={closeParticipantAlarmDetailsScreen} />
        <Menu
          dropBackground="lightTint"
          icon={<MoreVertical />}
          items={menuOptions}
        />
      </Box>
      {showAlarmCategoryPicker && (
        <Layer background="lightTint">
          <Box margin="medium">
            <SetAlarmCategory
              onClose={hideAlarmCategoryPicker}
              selectedAlarmCategory={alarmCategory}
              alarmId={alarm.id}
            />
          </Box>
        </Layer>
      )}
    </Header>
  )
}

const ParticipantAlarmDetails = ({ alarmId }) => {
  console.log('alarmId', alarmId)

  const dispatch = useDispatch()
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const participantAlarmDetaisSelector = makeParticipantAlarmDetailsSelector()
  const alarm = useSelector(state =>
    participantAlarmDetaisSelector(state, { alarmId: alarmId })
  )

  const [showPreReminderOptions, setShowPreReminderOptions] = useState(false)

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: alarmId })
  )

  const alarmRepetitionString =
    AlarmUtils.createParticipantAlarmRepetitionString(alarm)

  const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
    colorScheme,
    alarmCategory.color
  )

  const alarmConversations = useSelector(state => state.conversations.alarms)

  const unseenMessagesForAlarm = objGet(
    alarmConversations,
    `[${alarmId}].unseenMessages`,
    0
  )

  const deviceOs = useSelector(state => state.userInfo.deviceOs)

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarm(alarm)
  const participantAlarmEffectiveDate = DateTimeUtils.getParticipantAlarmDate(
    {
      date: currAlarmDate,
      type: alarm.type,
      cascadingAlarmInterval: alarm.cascadingAlarmInterval,
      recipientAlarmInterval: alarm.recipientAlarmInterval
    },
    alarm.order || 0
  )

  const alarmAcknowledgement =
    AlarmUtils.getAlarmAcknowledgementStatusForOccurrence(
      alarm.id,
      currAlarmDate
    )

  const creator = {
    id: alarm.creator,
    name: alarm.creatorName,
    mobileNumber: alarm.creatorMobileNumber,
    responseStatus: Constants.ACCEPT_ALARM,
    isCreator: true
  }
  const markRecipientAlarmAsDone = () => {
    dispatch(ActionCreators.markRecipientAlarmAsDone(alarmId))
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmMarkedDoneFromWebNotification,
      notificationInfo
    )
  }

  const markRecipientAlarmAsUndone = () => {
    dispatch(ActionCreators.markRecipientAlarmAsUndone(alarmId))
  }

  const skipPersonalParticipantAlarm = () => {
    dispatch(ActionCreators.skipPersonalParticipantAlarm(alarmId))
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmSkippedFromWebNotification,
      notificationInfo
    )
  }

  const skipRecipientAlarm = () => {
    dispatch(ActionCreators.skipRecipientAlarm(alarmId))
  }

  const markPersonalParticipantAlarmAsUnskipped = () => {
    dispatch(ActionCreators.markPersonalParticipantAlarmAsUnskipped(alarmId))
  }

  const markRecipientAlarmUnskipped = () => {
    dispatch(ActionCreators.markRecipientAlarmAsUnskipped(alarmId))
  }

  const onRecipientAlarmDone = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.RECIPIENT_ALARM_SKIP
    ) {
      const durationString = DateTimeUtils.getShortDurationTillDateAsString(
        alarmAcknowledgement.timestamp
      )
      NavigationUtils.showAlert(
        I18n.t('alarmDone'),
        I18n.t('markAlarmUndone', { durationString }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: () => {
              markRecipientAlarmAsUndone()
            }
          }
        ]
      )
      return
    }

    const alarmExpired = AlarmUtils.hasParticipantAlarmExpired(alarmId)
    if (!alarmExpired) {
      const dateTimeString = DateTimeUtils.getDateTimeAsString(
        participantAlarmEffectiveDate
      )
      NavigationUtils.showAlert(
        I18n.t('markAlarmDoneInAdvance'),
        alarm.repeatType
          ? I18n.t('markAlarmDoneInAdvanceMessageRepeatingAlarm', {
              dateTimeString
            })
          : I18n.t('markAlarmDoneInAdvanceMessageOneTimeAlarm', {
              dateTimeString
            }),
        [
          {
            text: I18n.t('cancel')
          },
          {
            text: I18n.t('ok'),
            onPress: markRecipientAlarmAsDone
          }
        ]
      )
      return
    }
    markRecipientAlarmAsDone()
  }

  const onSkipRecipientAlarm = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.RECIPIENT_ALARM_SKIP
    ) {
      const durationString = DateTimeUtils.getShortDurationTillDateAsString(
        alarmAcknowledgement.timestamp
      )
      NavigationUtils.showAlert(
        I18n.t('alarmSkipped'),
        I18n.t('markAlarmUnskipped', { durationString }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: markRecipientAlarmUnskipped
          }
        ]
      )
      return
    }
    const alarmExpired = AlarmUtils.hasParticipantAlarmExpired(alarmId)
    if (!alarmExpired) {
      const dateTimeString = DateTimeUtils.getDateTimeAsString(
        participantAlarmEffectiveDate
      )
      NavigationUtils.showAlert(
        I18n.t('skipAlarmInAdvance'),
        alarm.repeatType
          ? I18n.t('skipAlarmInAdvanceMessageRepeatingAlarm', {
              dateTimeString
            })
          : I18n.t('skipAlarmInAdvanceMessageOneTimeAlarm', { dateTimeString }),
        [
          {
            text: I18n.t('cancel')
          },
          {
            text: I18n.t('ok'),
            onPress: skipRecipientAlarm
          }
        ]
      )
      return
    }
    skipRecipientAlarm()
  }

  const onSkipPersonalParticipantAlarm = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response ===
        Constants.PERSONAL_PARTICIPANT_ALARM_SKIP
    ) {
      const durationString = DateTimeUtils.getShortDurationTillDateAsString(
        alarmAcknowledgement.timestamp
      )
      NavigationUtils.showAlert(
        I18n.t('alarmSkipped'),
        I18n.t('markAlarmUnskipped', { durationString }),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: markPersonalParticipantAlarmAsUnskipped
          }
        ]
      )
      return
    }
    skipPersonalParticipantAlarm()
  }

  const onGroupAlarmYes = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.GROUP_ALARM_YES
    ) {
      NavigationUtils.showAlert(I18n.t('alarmAlreadyConfirmed'))
      return
    }

    const alarmExpired = AlarmUtils.hasParticipantAlarmExpired(alarmId)
    if (!alarmExpired && !alarmAcknowledgement) {
      NavigationUtils.showAlert(
        alarm.name,
        I18n.t('stillRingGroupAlarmAfterConfirmingInAdvance'),
        [
          {
            text: I18n.t('yes'),
            onPress: () => {
              onGroupAlarmYesCore(false)
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents.CONFIRM_GROUP_ALARM_IN_ADVANCE,
                {
                  [Constants.UserAnalyticsEventParameters
                    .RING_GROUP_ALARM_AFTER_CONFIRMING_ADVANCE]: 'true'
                }
              )
            }
          },
          {
            text: I18n.t('no'),
            onPress: () => {
              onGroupAlarmYesCore(true)
              FirebaseProxy.logEvent(
                Constants.UserAnalyticsEvents.CONFIRM_GROUP_ALARM_IN_ADVANCE,
                {
                  [Constants.UserAnalyticsEventParameters
                    .RING_GROUP_ALARM_AFTER_CONFIRMING_ADVANCE]: 'false'
                }
              )
            }
          }
        ]
      )
      return
    }

    onGroupAlarmYesCore(true)
  }

  const onGroupAlarmYesCore = (rescheduleAlarm = true) => {
    dispatch(
      ActionCreators.markParticipantResponseForSimultaneousAlarm(
        alarmId,
        Constants.GROUP_ALARM_YES,
        rescheduleAlarm
      )
    )
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmConfirmedFromWebNotification,
      notificationInfo
    )
  }

  const onGroupAlarmNo = () => {
    if (
      alarmAcknowledgement &&
      alarmAcknowledgement.response === Constants.GROUP_ALARM_NO
    ) {
      NavigationUtils.showAlert(I18n.t('alarmAlreadyDeclined'))
      return
    }

    onGroupAlarmNoCore()
  }

  const onGroupAlarmNoCore = () => {
    dispatch(
      ActionCreators.markParticipantResponseForSimultaneousAlarm(
        alarmId,
        Constants.GROUP_ALARM_NO,
        true
      )
    )
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ACKNOWLEDGED,
      alarmId: alarm.id,
      alarmType: alarm.type,
      alarmName: alarm.name
    }
    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmDeclinedFromWebNotification,
      notificationInfo
    )
  }

  const onChangePreReminderDuration = () => {
    setShowPreReminderOptions(true)
  }

  const onViewConversation = () => {
    dispatch(ActionCreators.showAlarmConversationScreen(alarm.id))
  }

  const viewConversation = (
    <Box align="center" pad="small" onClick={onViewConversation}>
      <ChatIcon unseenMessages={unseenMessagesForAlarm} />
      <LabelText color="primary">{I18n.t('chat')}</LabelText>
    </Box>
  )

  if (isEmpty(alarm)) {
    return (
      <EntityNotFound
        entityType={I18n.t('entityAlarm')}
        onClose={() =>
          dispatch(ActionCreators.hideParticipantAlarmDetailsScreen())
        }
      />
    )
  }

  const endDateString = alarm.endDate
    ? DateTimeUtils.getDateAsString(alarm.endDate)
    : I18n.t('noEndDate')

  const participantAlarmRingerSettings =
    AlarmUtils.getAlarmParticipantRingerSettings(alarm)

  const participantAlarmPreReminderDuration =
    AlarmUtils.getAlarmParticipantPreReminderDuration(alarm)

  const ringerSettingsLabel =
    deviceOs === 'ios'
      ? AlarmUtils.getIosRingerSettingsLabel(participantAlarmRingerSettings)
      : AlarmUtils.getAndroidRingerSettingsLabel(participantAlarmRingerSettings)

  const onSaveDurationForPreReminder = duration => {
    setShowPreReminderOptions(false)
    dispatch(
      ActionCreators.updateParticipantPreReminderDuration(alarm.id, duration)
    )
  }

  // As of now for the pre-reminder, any duration is valid, so we are returning true
  // eslint-disable-next-line no-unused-vars
  const isValidDurationForPreReminder = duration => {
    return true
  }

  return (
    <AppCanvas key={alarmId} overflow="auto">
      <ParticipantAlarmDetailsHeader alarm={alarm} />
      <Card
        margin="small"
        background="textBackgroundColor"
        width="600px"
        flex={{ shrink: 0 }}>
        <CardHeader pad="small" justify="between">
          <Box flex />
          <AlarmType type={alarm.type} creationMode={alarm.creationMode} />
          <Box flex align="end">
            {alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && (
              <CategoryLabel
                name={alarmCategory.name}
                color={alarmCategoryColor}
              />
            )}
          </Box>
        </CardHeader>
        <CardBody gap="medium" pad="medium">
          <Box direction="row" gap="medium">
            <LabelledText
              label={I18n.t('date')}
              text={DateTimeUtils.getDateAsString(
                participantAlarmEffectiveDate
              )}
              border="bottom"
            />
            <LabelledText
              label={I18n.t('time')}
              text={DateTimeUtils.getTimeAsString(
                participantAlarmEffectiveDate
              )}
              border="bottom"
            />
          </Box>
          <LabelledText
            label={I18n.t('title')}
            text={alarm.name}
            border="bottom"
            flex={{ shrink: 0 }}
          />

          <Box border="bottom">
            <LabelText>{I18n.t('preReminder')}</LabelText>
            <Box direction="row" gap="xsmall">
              <Text>
                {AlarmUtils.getPreReminderLabel(
                  participantAlarmPreReminderDuration
                )}
              </Text>
              <Button
                plain
                color="primary"
                onClick={onChangePreReminderDuration}
                label={I18n.t('changeParanthesis')}
              />
            </Box>
          </Box>

          <LabelledText
            label={I18n.t('repeat')}
            text={alarmRepetitionString}
            border="bottom"
          />
          {alarm.repeatType !== '' && !!alarm.endDate && (
            <LabelledText
              label={I18n.t('endDate')}
              text={endDateString}
              border="bottom"
            />
          )}
          <LabelledText
            label={I18n.t('soundAndVibration')}
            text={ringerSettingsLabel}
            border="bottom"
          />
          <Box border="bottom" direction="row" justify="between">
            <Box>
              <LabelText>{I18n.t('participants')}</LabelText>
              <ParticipantsListWithAvatar
                type={alarm.type}
                creator={creator}
                backupContacts={
                  alarm.backupContacts.length > 0
                    ? [creator, ...alarm.backupContacts]
                    : []
                }
                backupGroup={
                  alarm.backupGroup
                    ? {
                        ...alarm.backupGroup,
                        members: [creator, ...alarm.backupGroup.members]
                      }
                    : null
                }
                recipient={alarm.recipient}
                alarmId={alarm.id}
              />
            </Box>
            {viewConversation}
          </Box>
          {(!!alarm.notes || !!alarm.alarmPhotoUrl) && (
            <Box gap="medium">
              <Box>
                <LabelText color="darkTint">{I18n.t('notes')}</LabelText>
                {alarm.alarmPhotoUrl && (
                  <Box
                    margin={{
                      vertical: 'small',
                      left: 'large'
                    }}
                    width="80%">
                    <Image src={alarm.alarmPhotoUrl} />
                  </Box>
                )}
                {!!alarm.notes && (
                  <Box direction="row" border="bottom">
                    <Text style={{ wordBreak: 'break-all' }}>
                      <RichTextEditor
                        notes={alarm.notes}
                        readOnly="true"
                        toolbarHidden="true"
                      />
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </CardBody>
        {alarm.responseStatus !== Constants.REJECT_ALARM && (
          <CardFooter pad="small" background="brand">
            <Box flex direction="row" justify="around">
              {alarm.type === Constants.AlarmTypes.RECIPIENT && (
                <ColoredButton
                  color="white"
                  icon={<Checkmark />}
                  label={I18n.t(Constants.RECIPIENT_ALARM_DONE)}
                  onClick={onRecipientAlarmDone}
                />
              )}
              {alarm.type === Constants.AlarmTypes.CASCADING && (
                <ColoredButton
                  color="white"
                  label={I18n.t(Constants.PERSONAL_PARTICIPANT_ALARM_SKIP)}
                  onClick={onSkipPersonalParticipantAlarm}
                />
              )}
              {alarm.type === Constants.AlarmTypes.SIMULTANEOUS && (
                <ColoredButton
                  color="white"
                  label={I18n.t(Constants.GROUP_ALARM_YES)}
                  onClick={onGroupAlarmYes}
                />
              )}
              {alarm.type === Constants.AlarmTypes.SIMULTANEOUS && (
                <ColoredButton
                  color="white"
                  label={I18n.t(Constants.GROUP_ALARM_NO)}
                  onClick={onGroupAlarmNo}
                />
              )}
              {alarm.type === Constants.AlarmTypes.RECIPIENT && (
                <ColoredButton
                  color="white"
                  label={I18n.t(Constants.RECIPIENT_ALARM_SKIP)}
                  onClick={onSkipRecipientAlarm}
                />
              )}
            </Box>
          </CardFooter>
        )}
        {showPreReminderOptions && (
          <Layer background="lightTint">
            <Box margin="medium">
              <ChooseDurationContainer
                onSaveDuration={duration => {
                  onSaveDurationForPreReminder(duration)
                }}
                getDisplayTextForDuration={duration =>
                  AlarmUtils.getDisplayTextForPreReminderDuration(
                    participantAlarmEffectiveDate,
                    duration
                  )
                }
                getDurationAsString={duration =>
                  AlarmUtils.getPreReminderDurationAsString(duration)
                }
                title={I18n.t('preReminder')}
                intervals={Constants.PRE_REMINDER_INTERVALS}
                initialDuration={participantAlarmPreReminderDuration}
                isValidDuration={duration =>
                  isValidDurationForPreReminder(duration)
                }
                onClose={() => setShowPreReminderOptions(false)}
                additionalHelperText={duration =>
                  AlarmUtils.getPreReminderHelperText(duration)
                }
              />
            </Box>
          </Layer>
        )}
      </Card>
    </AppCanvas>
  )
}

export default ParticipantAlarmDetails
