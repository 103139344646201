import React from 'react'
import { Card, CardBody, Text, Box, CheckBox } from 'grommet'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import BigText from './BigText'
import { NavigationUtils, NotificationManager } from 'galarm-ps-api'
import ParticipantsAvatarList from './ParticipantsAvatarList'
import ParticipantResponseSwitchContainer from './ParticipantResponseSwitchContainer'
import InstantAlarmSummary from './InstantAlarmSummary'
import InstantParticipantAlarmSummary from './InstantParticipantAlarmSummary'
import ChatIconSmall from './ChatIconSmall'
import objGet from 'lodash/get'
import { SecondaryText } from 'web-components'

const { makeAlarmCategoryForAlarmIdSelector } = Selectors

const UnifiedAlarmSummary = item => {
  if (item.infoMessage) {
    return <InfoMessage item={item} />
  } else if (item.sectionName) {
    return <Section item={item} />
  } else if (
    item.alarmCategory === Constants.AlarmCategories.MY_ALARM &&
    item.creationMode === Constants.AlarmCreationModes.INSTANT_ALARM
  ) {
    return <InstantAlarmSummary item={item} />
  } else if (
    item.alarmCategory === Constants.AlarmCategories.PARTICIPANT_ALARM &&
    item.creationMode === Constants.AlarmCreationModes.INSTANT_ALARM
  ) {
    return <InstantParticipantAlarmSummary item={item} />
  } else if (item.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
    return <AlarmSummary item={item} />
  } else if (
    item.alarmCategory === Constants.AlarmCategories.PARTICIPANT_ALARM
  ) {
    return <ParticipantAlarmSummary item={item} />
  } else if (item.isDeleted) {
    return <RecentlyDeletedAlarmSummary item={item} />
  }
}

const InfoMessage = ({ item }) => {
  return (
    <Card background="textBackgroundColor">
      <CardBody direction="row" justify="between">
        <Box justify="center" align="center" pad="small">
          <Text>{item.infoMessage}</Text>
        </Box>
      </CardBody>
    </Card>
  )
}

const Section = ({ item }) => {
  return (
    <Box justify="center" align="center">
      <Text color="darkTint" weight="bold">
        {item.sectionName}
      </Text>
    </Box>
  )
}

const RecentlyDeletedAlarmSummary = ({ item }) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const { name, date, alarmDeletedTimestamp } = item

  const timeString = DateTimeUtils.getLocalizedTimeAsString(date)
  const dateString = DateTimeUtils.getLocalizedDayDateWoYearAsString(date)

  return (
    <Card background="textBackgroundColor">
      <CardBody direction="row" justify="between">
        <Box pad="small">
          <Text color={colorScheme.textColor} truncate>
            {name}
          </Text>
          <Box direction="row" gap="xsmall">
            <BigText color={colorScheme.textColor}>{timeString}</BigText>
            <Text alignSelf="end" color={colorScheme.textColor}>
              {' '}
              {dateString}
            </Text>
          </Box>
          <SecondaryText>
            {I18n.t('deletedOnDate', {
              alarmDate: DateTimeUtils.getDateTimeAsString(
                alarmDeletedTimestamp
              )
            })}
          </SecondaryText>
        </Box>
      </CardBody>
    </Card>
  )
}

const AlarmSummary = ({ item }) => {
  const dispatch = useDispatch()
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const {
    id,
    name,
    status,
    date,
    recipient,
    backupGroup,
    backupContacts,
    repeatType = '',
    repeat = '',
    type,
    endDate,
    creator,
    creatorName,
    creatorMobileNumber,
    creatorTimezone,
    lastSnoozeByCreatorFor,
    lastSnoozeByCreatorOn
  } = item

  const disableAlarm = () => {
    const participants = AlarmUtils.getAlarmParticipants(
      type,
      backupGroup,
      backupContacts,
      recipient
    )
    const filteredParticipants = participants.filter(participant => {
      return participant.responseStatus !== Constants.REJECT_ALARM
    })
    if (
      type === Constants.AlarmTypes.SIMULTANEOUS &&
      filteredParticipants.length > 0
    ) {
      NavigationUtils.showAlert(
        I18n.t('confirm'),
        I18n.t('confirmDisableGroupAlarm'),
        [
          {
            text: I18n.t('no')
          },
          {
            text: I18n.t('yes'),
            onPress: () => disableAlarmCore()
          }
        ]
      )
    } else {
      NavigationUtils.showAlert(I18n.t('confirmDisableAlarm'), '', [
        {
          text: I18n.t('no')
        },
        {
          text: I18n.t('yes'),
          onPress: () => disableAlarmCore()
        }
      ])
    }
  }

  const disableAlarmCore = () => {
    dispatch(ActionCreators.updateAlarmStatus(id, false))

    // Send a notification to the mobile device.
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_DELETED,
      alarmId: item.id,
      alarmType: item.type,
      alarmName: item.name,
      alarmDate: item.date.toString()
    }

    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmDisabledFromWebNotification,
      notificationInfo
    )
  }

  const enableAlarm = () => {
    const currDate = Date.now()
    const alarmEndDate = endDate
    let newAlarmDate = date
    if (currDate > date) {
      if (repeatType === '') {
        newAlarmDate = DateTimeUtils.getNextOccurrenceOfTime(date)
      } else {
        newAlarmDate = AlarmUtils.getNextDateForAlarm(
          date,
          GlobalConfig.defaultAlarmEndDate, // Use 0 as end date to get the correct start date for the alarm
          repeatType,
          repeat,
          creatorTimezone
        )
      }
      dispatch(ActionCreators.editTimeAndEnableAlarm(id, newAlarmDate))
    } else {
      dispatch(ActionCreators.updateAlarmStatus(id, true))
    }

    let newAlarmEndDate = GlobalConfig.defaultAlarmEndDate

    if (alarmEndDate > newAlarmDate) {
      newAlarmEndDate = alarmEndDate
    }

    if (alarmEndDate && !newAlarmEndDate) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('noAlarmDateSetForAlarm')
      })
    }

    // Send a notification to the mobile device.
    const notificationInfo = {
      type: Constants.NotificationTypes.ALARM_ADDED,
      alarmId: item.id,
      alarmType: item.type,
      alarmName: item.name,
      alarmDate: item.date.toString()
    }

    NotificationManager.sendRemoteNotification(
      GlobalConfig.uid,
      Constants.NotificationKeys.AlarmEnabledFromWebNotification,
      notificationInfo
    )
  }

  const onStatusChange = event => {
    const newStatus = event.target.checked
    if (newStatus) {
      enableAlarm()
    } else {
      disableAlarm()
    }
  }

  const onViewAlarm = event => {
    if (event.target.type !== 'checkbox') {
      dispatch(ActionCreators.showAlarmDetailsScreen(item.id))
    }
  }

  const onViewConversation = event => {
    event.stopPropagation()
    dispatch(ActionCreators.showAlarmConversationScreen(item.id))
  }

  const isLastSnoozeTimeInSnoozeDuration = () => {
    const currDate = Date.now()
    return (
      lastSnoozeByCreatorOn &&
      lastSnoozeByCreatorFor &&
      currDate - lastSnoozeByCreatorOn < lastSnoozeByCreatorFor
    )
  }

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: item.id })
  )

  const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
    colorScheme,
    alarmCategory.color
  )

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarmId(id)
  const alarmAcknowledgement =
    AlarmUtils.getAlarmAcknowledgementStatusForOccurrence(id, currAlarmDate)

  const alarmInSnooze = isLastSnoozeTimeInSnoozeDuration()
  const alarmExpired = AlarmUtils.hasAlarmExpired(id)

  let textColor

  if (alarmExpired) {
    textColor = 'warningTextColor'
  } else {
    textColor = 'textColor'
  }
  const timeString = DateTimeUtils.getLocalizedTimeAsString(currAlarmDate)
  const dateString =
    DateTimeUtils.getLocalizedDayDateWoYearAsString(currAlarmDate)
  const repeatString = AlarmUtils.createAlarmRepetitionStringForAlarmSummary(
    repeatType,
    repeat
  )

  const snoozeTime =
    lastSnoozeByCreatorOn + lastSnoozeByCreatorFor || Date.now()
  const snoozeTimeString = DateTimeUtils.isDateToday(snoozeTime)
    ? DateTimeUtils.getLocalizedTimeAsString(snoozeTime)
    : DateTimeUtils.getLocalizedDateTimeWoYearAsString(snoozeTime)

  const alarmCreator = {
    id: creator,
    name: creatorName,
    mobileNumber: creatorMobileNumber,
    responseStatus: Constants.ACCEPT_ALARM,
    isCreator: true
  }

  const alarmParticipants = AlarmUtils.getAlarmParticipants(
    item.type,
    item.backupGroup,
    item.backupContacts,
    item.recipient
  ).filter(
    participant => participant.state !== Constants.ParticipantStates.INACTIVE
  )
  const numAlarmParticipants = alarmParticipants.length

  const alarmConversations = useSelector(state => state.conversations.alarms)
  const unseenMessagesForAlarm = objGet(
    alarmConversations,
    `[${item.id}].unseenMessages`,
    0
  )

  return (
    <Card
      onClick={onViewAlarm}
      background="textBackgroundColor"
      style={{ opacity: status ? 1 : 0.5 }}>
      <CardBody direction="row" justify="between">
        <Box direction="row">
          {alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && (
            <Box width={'10px'} background={alarmCategoryColor} />
          )}
          <Box pad="small">
            <Text color={textColor} truncate>
              {name}
            </Text>
            <Box direction="row" gap="xsmall">
              <BigText color={textColor}>{timeString}</BigText>
              <Text alignSelf="end" color={textColor}>
                {' ' + dateString}
              </Text>
            </Box>
            <Text color={textColor}>{repeatString}</Text>
            <ParticipantsAvatarList
              creator={alarmCreator}
              backupContacts={
                backupContacts.length > 0
                  ? [alarmCreator, ...backupContacts]
                  : []
              }
              backupGroup={
                backupGroup
                  ? {
                      ...backupGroup,
                      members: [alarmCreator, ...backupGroup.members]
                    }
                  : null
              }
              recipient={recipient}
            />
          </Box>
        </Box>
        <Box pad="small" justify="between" align="end">
          <CheckBox checked={status} toggle onChange={onStatusChange} />
          {numAlarmParticipants ? (
            <Box align="center" pad="small" onClick={onViewConversation}>
              <ChatIconSmall unseenMessages={unseenMessagesForAlarm} />
            </Box>
          ) : (
            <Box />
          )}
          {alarmInSnooze && alarmExpired && !alarmAcknowledgement && status && (
            <Box direction="row" justify="end">
              <SecondaryText>
                {I18n.t('snoozing', { timeString: snoozeTimeString })}
              </SecondaryText>
            </Box>
          )}
          {!!alarmAcknowledgement && (
            <SecondaryText>
              {AlarmUtils.getAlarmAcknowledString(type, alarmAcknowledgement)}
            </SecondaryText>
          )}
          {!alarmInSnooze && !alarmAcknowledgement && <Box />}
        </Box>
      </CardBody>
    </Card>
  )
}

const ParticipantAlarmSummary = ({ item }) => {
  const dispatch = useDispatch()
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const {
    id,
    name,
    status,
    responseStatus,
    recipient,
    backupGroup,
    backupContacts,
    creator,
    creatorName,
    creatorMobileNumber,
    type,
    lastSnoozeByCreatorFor,
    lastSnoozeByCreatorOn
  } = item

  const isLastSnoozeTimeInSnoozeDuration = () => {
    const currDate = Date.now()
    return (
      lastSnoozeByCreatorOn &&
      lastSnoozeByCreatorFor &&
      currDate - lastSnoozeByCreatorOn < lastSnoozeByCreatorFor
    )
  }

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: item.id })
  )

  const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
    colorScheme,
    alarmCategory.color
  )

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarmId(id)
  const alarmAcknowledgement =
    AlarmUtils.getAlarmAcknowledgementStatusForOccurrence(id, currAlarmDate)

  const alarmInSnooze = isLastSnoozeTimeInSnoozeDuration()
  const alarmExpired = AlarmUtils.hasParticipantAlarmExpired(id)

  const alarmStatus = status && responseStatus !== Constants.REJECT_ALARM

  const alarmCreator = {
    id: creator,
    name: creatorName,
    mobileNumber: creatorMobileNumber,
    responseStatus: Constants.ACCEPT_ALARM,
    isCreator: true
  }

  let textColor

  if (alarmExpired) {
    textColor = 'warningTextColor'
  } else {
    textColor = 'textColor'
  }

  const participantAlarmEffectiveDate = DateTimeUtils.getParticipantAlarmDate(
    {
      date: currAlarmDate,
      type: item.type,
      cascadingAlarmInterval: item.cascadingAlarmInterval,
      recipientAlarmInterval: item.recipientAlarmInterval
    },
    item.order || 0
  )

  const timeString = DateTimeUtils.getLocalizedTimeAsString(
    participantAlarmEffectiveDate
  )
  const dateString = DateTimeUtils.getLocalizedDayDateWoYearAsString(
    participantAlarmEffectiveDate
  )
  const repeatString =
    AlarmUtils.createParticipantAlarmRepetitionStringForAlarmSummary(item)

  const snoozeTime =
    lastSnoozeByCreatorOn + lastSnoozeByCreatorFor || Date.now()
  const snoozeTimeString = DateTimeUtils.isDateToday(snoozeTime)
    ? DateTimeUtils.getLocalizedTimeAsString(snoozeTime)
    : DateTimeUtils.getLocalizedDayDateWoYearAsString(snoozeTime)

  const onResponseStatusChangeCore = selectedResponseStatus => {
    if (type === Constants.AlarmTypes.RECIPIENT) {
      dispatch(
        ActionCreators.setRecipientResponseStatusForAlarm(
          id,
          selectedResponseStatus
        )
      )
    } else {
      dispatch(
        ActionCreators.setBackupResponseStatusForAlarm(
          id,
          selectedResponseStatus
        )
      )
    }
  }

  const onResponseStatusChange = selectedResponseStatus => {
    // If no change in response status, ignore
    if (responseStatus === selectedResponseStatus) {
      return
    }

    const notificationMessage =
      selectedResponseStatus === Constants.ACCEPT_ALARM
        ? I18n.t('confirmAcceptAlarm')
        : I18n.t('confirmRejectAlarm')
    NavigationUtils.showAlert(notificationMessage, '', [
      {
        text: I18n.t('no')
      },
      {
        text: I18n.t('yes'),
        onPress: () => {
          onResponseStatusChangeCore(selectedResponseStatus)

          // Send a notification to the mobile device.
          const notificationInfo = {
            type:
              selectedResponseStatus === Constants.ACCEPT_ALARM
                ? Constants.NotificationTypes.BACKUP_ALARM_ADDED
                : Constants.NotificationTypes.PARTICIPANT_ALARM_DELETED,
            alarmId: item.id,
            alarmType: item.type,
            alarmName: item.name,
            alarmDate: item.date.toString()
          }
          NotificationManager.sendRemoteNotification(
            GlobalConfig.uid,
            selectedResponseStatus === Constants.ACCEPT_ALARM
              ? Constants.NotificationKeys.AlarmAcceptedFromWebNotification
              : Constants.NotificationKeys.AlarmRejectedFromWebNotification,
            notificationInfo
          )
        }
      }
    ])
  }

  const onViewConversation = event => {
    event.stopPropagation()
    dispatch(ActionCreators.showAlarmConversationScreen(item.id))
  }

  const alarmConversations = useSelector(state => state.conversations.alarms)

  const unseenMessagesForAlarm = objGet(
    alarmConversations,
    `[${item.id}].unseenMessages`,
    0
  )

  return (
    <Card
      onClick={() => {
        dispatch(ActionCreators.showParticipantAlarmDetailsScreen(item.id))
      }}
      background="textBackgroundColor"
      style={{ opacity: alarmStatus ? 1 : 0.5 }}>
      <CardBody direction="row" justify="between">
        <Box direction="row">
          {alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && (
            <Box width={'10px'} background={alarmCategoryColor} />
          )}
          <Box pad="small">
            <Text color={textColor} truncate>
              {name}
            </Text>
            <Box direction="row" gap="xsmall">
              <BigText color={textColor}>{timeString}</BigText>
              <Text alignSelf="end" color={textColor}>
                {' ' + dateString}
              </Text>
            </Box>
            <Text color={textColor}>{repeatString}</Text>
            <ParticipantsAvatarList
              creator={alarmCreator}
              backupContacts={
                backupContacts.length > 0
                  ? [alarmCreator, ...backupContacts]
                  : []
              }
              backupGroup={
                backupGroup
                  ? {
                      ...backupGroup,
                      members: [alarmCreator, ...backupGroup.members]
                    }
                  : null
              }
              recipient={recipient}
            />
          </Box>
        </Box>
        <Box pad="small" justify="between" align="end">
          <ParticipantResponseSwitchContainer
            responseStatus={responseStatus}
            onResponseStatusChange={onResponseStatusChange}
            alarmStatus={alarmStatus}
          />
          <Box align="center" pad="small" onClick={onViewConversation}>
            <ChatIconSmall unseenMessages={unseenMessagesForAlarm} />
          </Box>
          {alarmInSnooze && alarmExpired && !alarmAcknowledgement && status && (
            <Box direction="row" justify="end">
              <SecondaryText>
                {I18n.t('snoozing', { timeString: snoozeTimeString })}
              </SecondaryText>
            </Box>
          )}
          {!!alarmAcknowledgement && (
            <SecondaryText>
              {AlarmUtils.getAlarmAcknowledString(type, alarmAcknowledgement)}
            </SecondaryText>
          )}
          {!alarmInSnooze && !alarmAcknowledgement && <Box />}
        </Box>
      </CardBody>
    </Card>
  )
}

export default UnifiedAlarmSummary
