import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlarmsByCategoriesList from './AlarmsByCategoriesList'
import AlarmList from './AlarmList'
import { ActionCreators, AlarmUtilsWithExtras, Selectors } from 'galarm-shared'
import { Box, Layer } from 'grommet'
import { Add } from 'grommet-icons'
import { I18n } from 'galarm-config'
import { colorThemes } from 'galarm-config'
import { Fab, Action } from 'react-tiny-fab'
import TintedImage from './TintedImage'
import EditAlarmCategory from './EditAlarmCategory'
import SearchBar from './SearchBar'
import RecentlyDeletedAlarmList from './RecentlyDeletedAlarmList'

import 'react-tiny-fab/dist/styles.css'
import { alertIncidentsSelector } from 'galarm-shared/src/store/selectors'

const {
  makeAlarmsInCategorySelector,
  allAlarmsSelector,
  canCreateNewAlarmDataSelector
} = Selectors

// eslint-disable-next-line no-unused-vars
const NewAlarmFAB = () => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  const canCreateNewAlarmData = useSelector(canCreateNewAlarmDataSelector)

  const [createNewCategory, setCreateNewCategory] = useState(false)

  const dispatch = useDispatch()
  const categorizedAlarmView = useSelector(
    state => state.userSettings.categorizedAlarmView
  )

  const currentlySelectedAlarmCategoryId = useSelector(
    state => state.appState.currentlySelectedAlarmCategoryId
  )

  const createAlarmCategory = alarmCategory =>
    dispatch(ActionCreators.createAlarmCategory(alarmCategory))

  const onNewAlarm = () => {
    dispatch(
      ActionCreators.showSelectAlarmTypeScreen({
        alarmCategoryId: currentlySelectedAlarmCategoryId
      })
    )
  }

  const onQuickReminder = () => {
    const canCreateNewAlarm =
      AlarmUtilsWithExtras.canCreateNewAlarmAndShowAlertsIfNeeded(
        canCreateNewAlarmData
      )
    if (!canCreateNewAlarm) {
      return
    }

    dispatch(ActionCreators.setShowQuickReminder(true))
  }

  const onNewAlarmCategory = () => {
    setCreateNewCategory(true)
  }

  return (
    <>
      <Fab
        mainButtonStyles={{
          backgroundColor: colorThemes.getColorTheme(colorScheme).primary
        }}
        style={{
          bottom: 0,
          left: 0
        }}
        icon={<Add color="white" />}
        event="hover"
        alwaysShowTitle={true}>
        <Action
          text={I18n.t('quickAlarm')}
          onClick={() => onQuickReminder()}
          style={{
            backgroundColor: colorThemes.getColorTheme(colorScheme).primary
          }}>
          <TintedImage
            tintColor="white"
            src={require('galarm-res/img/web/ic_quick_alarm.svg').default}
          />
        </Action>
        <Action
          text={I18n.t('newAlarm')}
          onClick={() => onNewAlarm()}
          style={{
            backgroundColor: colorThemes.getColorTheme(colorScheme).primary
          }}>
          <TintedImage
            tintColor="white"
            src={require('galarm-res/img/web/ic_alarm.svg').default}
          />
        </Action>
        {categorizedAlarmView && !currentlySelectedAlarmCategoryId && (
          <Action
            text={I18n.t('newAlarmCategoryTitle')}
            onClick={() => onNewAlarmCategory()}
            style={{
              backgroundColor: colorThemes.getColorTheme(colorScheme).primary
            }}>
            <TintedImage
              tintColor="white"
              src={require('galarm-res/img/web/ic_category_view.svg').default}
            />
          </Action>
        )}
      </Fab>
      {createNewCategory && (
        <Layer background="lightTint">
          <Box margin="medium">
            <EditAlarmCategory
              onSaveAlarmCategory={createAlarmCategory}
              onClose={() => setCreateNewCategory(false)}
            />
          </Box>
        </Layer>
      )}
    </>
  )
}

const UnifiedAlarmList = ({ showSearchBar, hideSearchBar }) => {
  const [searchText, setSearchText] = useState('')

  const categorizedAlarmView = useSelector(
    state => state.userSettings.categorizedAlarmView
  )

  const showAlertIncidents = useSelector(
    state => state.appState.showAlertIncidents
  )

  const currentlySelectedAlarmCategoryId = useSelector(
    state => state.appState.currentlySelectedAlarmCategoryId
  )

  const showRecentlyDeletedAlarms = useSelector(
    state => state.appState.showRecentlyDeletedAlarms
  )

  const allAlarms = useSelector(state => allAlarmsSelector(state))
  const alertIncidents = useSelector(alertIncidentsSelector)

  const alarmsInCategorySelector = makeAlarmsInCategorySelector()
  const alarmsInCategory = useSelector(state =>
    alarmsInCategorySelector(state, {
      id: currentlySelectedAlarmCategoryId
    })
  )
  const recentlyDeletedAlarms = useSelector(
    state => state.alarms.recentlyDeletedAlarms
  )

  if (showSearchBar) {
    let filteredAlarms = allAlarms.filter(alarm =>
      alarm.name.toLowerCase().includes(searchText.toLowerCase())
    )
    return (
      <Box flex overflow="auto" style={{ position: 'relative' }}>
        <Box
          flex={{ shrink: 0 }}
          margin={{ horizontal: 'small', top: 'medium' }}>
          <SearchBar
            onChangeText={setSearchText}
            searchText={searchText}
            onCloseSearchBar={hideSearchBar}
            focus
          />
        </Box>
        <AlarmList alarms={filteredAlarms} />
        <NewAlarmFAB />
      </Box>
    )
  }

  if (showAlertIncidents) {
    return (
      <Box flex overflow="auto" style={{ position: 'relative' }}>
        <AlarmList alarms={alertIncidents} />
      </Box>
    )
  } else if (showRecentlyDeletedAlarms) {
    return (
      <Box flex overflow="auto" style={{ position: 'relative' }}>
        <RecentlyDeletedAlarmList alarms={recentlyDeletedAlarms} />
      </Box>
    )
  } else if (categorizedAlarmView) {
    if (currentlySelectedAlarmCategoryId) {
      return (
        <Box flex overflow="auto" style={{ position: 'relative' }}>
          <AlarmList alarms={alarmsInCategory} />
          <NewAlarmFAB />
        </Box>
      )
    }
    return (
      <Box flex overflow="auto" style={{ position: 'relative' }}>
        <AlarmsByCategoriesList />
        <NewAlarmFAB />
      </Box>
    )
  } else {
    return (
      <Box flex overflow="auto" style={{ position: 'relative' }}>
        <AlarmList alarms={allAlarms} />
        <NewAlarmFAB />
      </Box>
    )
  }
}

export default UnifiedAlarmList
