import React from 'react'
import { Card, CardBody, Text, Box } from 'grommet'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionCreators,
  AlarmUtils,
  DateTimeUtils,
  Selectors
} from 'galarm-shared'
import { Constants, I18n } from 'galarm-config'
import BigText from './BigText'
import ChatIconSmall from './ChatIconSmall'
import objGet from 'lodash/get'
import { SecondaryText } from 'web-components'

const { makeAlarmCategoryForAlarmIdSelector } = Selectors

const InstantParticipantAlarmSummary = ({ item }) => {
  const dispatch = useDispatch()
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)

  const {
    id,
    name,
    status,
    type,
    lastSnoozeByCreatorFor,
    lastSnoozeByCreatorOn,
    source
  } = item

  const isLastSnoozeTimeInSnoozeDuration = () => {
    const currDate = Date.now()
    return (
      lastSnoozeByCreatorOn &&
      lastSnoozeByCreatorFor &&
      currDate - lastSnoozeByCreatorOn < lastSnoozeByCreatorFor
    )
  }

  const alarmCategorySelector = makeAlarmCategoryForAlarmIdSelector()
  const alarmCategory = useSelector(state =>
    alarmCategorySelector(state, { alarmId: item.id })
  )

  const alarmCategoryColor = AlarmUtils.getColorForAlarmCategory(
    colorScheme,
    alarmCategory.color
  )

  const currAlarmDate = AlarmUtils.getCurrentDateForAlarmId(id)
  const alarmAcknowledgement =
    AlarmUtils.getAlarmAcknowledgementStatusForOccurrence(id, currAlarmDate)

  const alarmInSnooze = isLastSnoozeTimeInSnoozeDuration()
  const alarmExpired = AlarmUtils.hasParticipantAlarmExpired(id)

  let textColor

  if (!status) {
    textColor = 'darkTint'
  } else if (alarmExpired) {
    textColor = 'warningTextColor'
  } else {
    textColor = 'textColor'
  }

  const timeString = DateTimeUtils.getTimeAsString(currAlarmDate)
  const dateString =
    DateTimeUtils.getLocalizedDayDateWoYearAsString(currAlarmDate)

  const snoozeTime =
    lastSnoozeByCreatorOn + lastSnoozeByCreatorFor || Date.now()
  const snoozeTimeString = DateTimeUtils.isDateToday(snoozeTime)
    ? DateTimeUtils.getTimeAsString(snoozeTime)
    : DateTimeUtils.getLocalizedDateTimeWoYearAsString(snoozeTime)

  const alarmConversations = useSelector(state => state.conversations.alarms)
  const unseenMessagesForAlarm = objGet(
    alarmConversations,
    `[${item.id}].unseenMessages`,
    0
  )

  const onViewConversation = event => {
    event.stopPropagation()
    dispatch(ActionCreators.showAlarmConversationScreen(item.id))
  }

  return (
    <Card
      onClick={() => {
        dispatch(
          ActionCreators.showInstantParticipantAlarmDetailsScreen(item.id)
        )
      }}
      background="textBackgroundColor">
      <CardBody direction="row" justify="between">
        <Box direction="row">
          {alarmCategory.id !== Constants.UNCATEGORIZED_ALARM_CATEGORY_ID && (
            <Box width={'10px'} background={alarmCategoryColor} />
          )}
          <Box pad="small">
            <Text color={textColor} truncate>
              {name}
            </Text>
            <Box direction="row" gap="xsmall">
              <BigText color={textColor}>{timeString}</BigText>
              <Text alignSelf="end" color={textColor}>
                {' ' + dateString}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box pad="small" justify="between" align="end">
          {source !==
            Constants.AlarmCreationSources.CREATED_BY_ENTERPRISE_ALERT_API && (
            <Box align="center" pad="small" onClick={onViewConversation}>
              <ChatIconSmall unseenMessages={unseenMessagesForAlarm} />
            </Box>
          )}
          {alarmInSnooze && alarmExpired && !alarmAcknowledgement && status && (
            <Box direction="row" justify="end">
              <SecondaryText>
                {I18n.t('snoozing', { timeString: snoozeTimeString })}
              </SecondaryText>
            </Box>
          )}
          {!!alarmAcknowledgement && (
            <SecondaryText>
              {AlarmUtils.getAlarmAcknowledString(type, alarmAcknowledgement)}
            </SecondaryText>
          )}
          {!alarmInSnooze && !alarmAcknowledgement && <Box />}
        </Box>
      </CardBody>
    </Card>
  )
}

export default InstantParticipantAlarmSummary
