import ActionTypes from '../actions/types'
import { createReducer } from 'reduxsauce'
import { GlobalConfig } from 'galarm-config'
import Utils from '../utils/Utils'
import { Platform } from 'react-native'
import objGet from 'lodash/get'
import { REHYDRATE } from 'redux-persist'

const INITIAL_STATE = {
  ringParticipantAlarmsByDefault:
    GlobalConfig.defaultValueForRingParticipantAlarmsByDefault,
  ringOnVibrate: GlobalConfig.defaultRingOnVibrate,
  timeFormat: GlobalConfig.defaultTimeFormat,
  additionalSetupInstructions: {},
  removedSystemMessages: [],
  hiddenSystemMessages: [],
  alarmRingtone: GlobalConfig.defaultAlarmRingtone,
  vibrate: GlobalConfig.defaultVibrate,
  ringOnEarphoneOnly: GlobalConfig.defaultRingOnEarphoneOnly,
  gestureToDismissAnAlarm: GlobalConfig.defaultGestureToDismissAlarm,
  graduallyIncreaseVolume: GlobalConfig.defaultGraduallyIncreaseVolume,
  volume: GlobalConfig.defaultVolume,
  ringtoneDuration: GlobalConfig.defaultRingtoneDuration,
  autoSnooze: GlobalConfig.defaultAutoSnooze,
  autoSnoozeDuration: GlobalConfig.defaultAutoSnoozeDuration,
  autoSnoozeCount: GlobalConfig.defaultAutoSnoozeCount,
  skuDetails: {},
  announceAlarmName: GlobalConfig.defaultAnnounceAlarmName,
  colorScheme: GlobalConfig.defaultColorScheme,
  categorizedAlarmView: Platform.OS === 'web' ? false : true,
  webColorScheme: GlobalConfig.defaultColorScheme,
  specifyTimezoneForAlarm: GlobalConfig.defaultSpecifyTimezoneForAlarm,
  notificationSettings: GlobalConfig.defaultNotificationSettings,
  muteAlarms: false,
  criticalAlerts: GlobalConfig.defaultCriticalAlerts,
  criticalAlertsVolume: GlobalConfig.defaultCriticalAlertsVolume
}

const rehydrateState = (state, action) => {
  const persistedSettings = objGet(
    action,
    'payload.userSettings',
    INITIAL_STATE
  )
  return {
    ...state,
    ...persistedSettings
  }
}

const setUserSettings = (state, action) => {
  return {
    ...state,
    ...action.payload.userSettings
  }
}

const setRingParticipantAlarmsByDefault = (state, action) => ({
  ...state,
  ringParticipantAlarmsByDefault: action.payload.ringParticipantAlarmsByDefault
})

const setGlobalAlarmRingerSettings = (state, action) => ({
  ...state,
  ...action.payload.ringerSettings
})

const setRingOnEarphoneOnly = (state, action) => ({
  ...state,
  ringOnEarphoneOnly: action.payload.ringOnEarphoneOnly
})

const setGestureToDismissAnAlarm = (state, action) => ({
  ...state,
  gestureToDismissAnAlarm: action.payload.gestureToDismissAnAlarm
})

const setSpecifyTimezoneForAlarm = (state, action) => ({
  ...state,
  specifyTimezoneForAlarm: action.payload.specifyTimezoneForAlarm
})

const setGraduallyIncreaseVolume = (state, action) => ({
  ...state,
  graduallyIncreaseVolume: action.payload.graduallyIncreaseVolume
})

const setAlarmRingtone = (state, action) => ({
  ...state,
  alarmRingtone: action.payload.alarmRingtone
})

const setTimeFormat = (state, action) => ({
  ...state,
  timeFormat: action.payload.timeFormat
})

const setColorScheme = (state, action) => ({
  ...state,
  colorScheme: action.payload.colorScheme
})

const setWebColorScheme = (state, action) => ({
  ...state,
  webColorScheme: action.payload.webColorScheme
})

const setAdditionalSetupInstructions = (state, action) => ({
  ...state,
  additionalSetupInstructions: action.payload.additionalSetupInstructions
})

const setNotificationSettings = (state, action) => ({
  ...state,
  notificationSettings: action.payload.notificationSettings
})

const addHiddenSystemMessage = (state, action) => {
  const systemMessageId = action.payload.id

  const systemMessageIndex = Utils.getIndexOfObjectWithId(
    state.hiddenSystemMessages,
    systemMessageId
  )

  let newHiddenSystemMessages = state.hiddenSystemMessages.slice()

  // New system message
  if (systemMessageIndex === -1) {
    newHiddenSystemMessages.push({
      id: systemMessageId,
      remindMeLaterTime: Date.now()
    })
  } else {
    // Update the system message time
    newHiddenSystemMessages.splice(systemMessageIndex, 1, {
      id: systemMessageId,
      remindMeLaterTime: Date.now()
    })
  }
  return {
    ...state,
    hiddenSystemMessages: newHiddenSystemMessages
  }
}
const removeSystemMessage = (state, action) => {
  const systemMessageId = action.payload.id

  let newRemovedSystemMessages = state.removedSystemMessages.slice()

  newRemovedSystemMessages.push(systemMessageId)

  return {
    ...state,
    removedSystemMessages: newRemovedSystemMessages
  }
}

const addSkuDetails = (state, action) => {
  const skuDetails = action.payload.skuDetails
  if (!skuDetails || !Array.isArray(skuDetails) || skuDetails.length === 0) {
    return state
  }
  const newSkuDetails = { ...state.skuDetails }
  skuDetails.forEach(skuDetail => {
    newSkuDetails[skuDetail.productId] = skuDetail
  })
  return {
    ...state,
    skuDetails: newSkuDetails
  }
}

const toggleCategorizedAlarmView = state => {
  return {
    ...state,
    categorizedAlarmView: !state.categorizedAlarmView
  }
}

const setMuteAlarms = (state, action) => {
  return {
    ...state,
    muteAlarms: action.payload.muteAlarms
  }
}

const ACTION_HANDLERS = {
  [REHYDRATE]: rehydrateState,
  [ActionTypes.SET_USER_SETTINGS]: setUserSettings,
  [ActionTypes.SET_RING_PARTICIPANT_ALARMS_BY_DEFAULT]:
    setRingParticipantAlarmsByDefault,
  [ActionTypes.SET_ALARM_RINGTONE]: setAlarmRingtone,
  [ActionTypes.SET_TIME_FORMAT]: setTimeFormat,
  [ActionTypes.SET_COLOR_SCHEME]: setColorScheme,
  [ActionTypes.SET_WEB_COLOR_SCHEME]: setWebColorScheme,
  [ActionTypes.SET_ADDITIONAL_SETUP_INSTRUCTIONS]:
    setAdditionalSetupInstructions,
  [ActionTypes.REMOVE_SYSTEM_MESSAGE]: removeSystemMessage,
  [ActionTypes.ADD_HIDDEN_SYSTEM_MESSAGE]: addHiddenSystemMessage,
  [ActionTypes.SET_GLOBAL_ALARM_RINGER_SETTINGS]: setGlobalAlarmRingerSettings,
  [ActionTypes.ADD_SKU_DETAILS]: addSkuDetails,
  [ActionTypes.TOGGLE_CATEGORIZED_ALARM_VIEW]: toggleCategorizedAlarmView,
  [ActionTypes.SET_RING_ON_EARPHONE_ONLY]: setRingOnEarphoneOnly,
  [ActionTypes.SET_GESTURE_TO_DISMISS_ALARM]: setGestureToDismissAnAlarm,
  [ActionTypes.SET_SPECIFY_TIMEZONE_FOR_ALARM]: setSpecifyTimezoneForAlarm,
  [ActionTypes.SET_GRADUALLY_INCREASE_VOLUME]: setGraduallyIncreaseVolume,
  [ActionTypes.SET_NOTIFICATION_SETTINGS]: setNotificationSettings,
  [ActionTypes.SET_MUTE_ALARMS]: setMuteAlarms
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
