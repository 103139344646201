import { Box, Button, Card, CardBody, Header, Menu, Text } from 'grommet'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LabelledText from './LabelledText'
import LabelText from './LabelText'
import MembersListWithAvatar from './MembersListWithAvatar'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import isEmpty from 'lodash/isEmpty'
import AppCanvas from './AppCanvas'
import { ActionCreators, TaskManager, Utils, Selectors } from 'galarm-shared'
import { Close, MoreVertical } from 'grommet-icons'
import { FirebaseProxy, LogUtils, NavigationUtils } from 'galarm-ps-api'
import GroupAvatar from './GroupAvatar'
import objGet from 'lodash/get'
import ColoredButton from './ColoredButton'
import TintedImage from './TintedImage'
import EntityNotFound from './EntityNotFound'

const { makeGroupSelector, ownAlarmsSelector } = Selectors

const GroupDetailsHeader = ({ group }) => {
  const dispatch = useDispatch()

  const ownAlarms = useSelector(ownAlarmsSelector)
  const impactedAlarms = ownAlarms.filter(alarm => {
    return (
      alarm.status === true &&
      (alarm.date > Date.now() || alarm.repeatType !== '') &&
      !!alarm.backupGroup &&
      alarm.backupGroup.id === group.id
    )
  })

  const onLeaveGroupCore = member => {
    dispatch(
      ActionCreators.setProgress(
        Constants.ProgressStates.IN_PROGRESS,
        I18n.t('leavingGroup')
      )
    )
    TaskManager.addHttpsCloudTask('leaveGroup', {
      id: group.id,
      uid: GlobalConfig.uid, // Same as member.id
      username: member.name, // Same as member.name
      name: group.name,
      role: member.role
    })
      .then(() => {
        dispatch(ActionCreators.hideGroupDetailsScreen())
        NavigationUtils.dismissProgress()
      })
      .catch(error => {
        dispatch(
          ActionCreators.setProgress(
            Constants.ProgressStates.ERROR,
            I18n.t('unableToLeaveGroup', { error: error.error }),
            true
          )
        )
        LogUtils.logError(new Error(error.error), 'Unable to leave group')
      })

    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.LEAVE_GROUP, {})
  }

  const onLeaveGroup = () => {
    const member = Utils.getObjectWithId(group.members, GlobalConfig.uid)
    if (!member) {
      console.log(
        'Unable to leave group because you are not a member of the group ' +
          group.name
      )
      return
    }

    let alertMessage = I18n.t('leaveGroupConfirm')

    if (impactedAlarms.length > 0) {
      const alarmNames = impactedAlarms.map(alarm => alarm.name).join('\n')
      alertMessage = I18n.t('leaveGroupAlert', { alarmNames })
    }

    NavigationUtils.showAlert(I18n.t('confirm'), alertMessage, [
      {
        text: I18n.t('no')
      },
      {
        text: I18n.t('yes'),
        onPress: () => {
          onLeaveGroupCore(member)
        }
      }
    ])
  }

  const onEditGroup = () => {
    dispatch(ActionCreators.showEditGroupWizard(group.id))
  }

  const closeGroupDetailsScreen = () => {
    dispatch(ActionCreators.hideGroupDetailsScreen())
  }

  return (
    <Header className="header" background="brand" pad="small">
      <Text color="white">{I18n.t('groupDetails')}</Text>
      <Box direction="row">
        <Button icon={<Close />} onClick={closeGroupDetailsScreen} />
        <Menu
          id="group-details-menu"
          dropBackground="lightTint"
          icon={<MoreVertical />}
          items={[
            {
              label: I18n.t('editGroup'),
              onClick: onEditGroup
            },
            {
              label: I18n.t('leaveGroup'),
              onClick: onLeaveGroup
            }
          ]}
        />
      </Box>
    </Header>
  )
}

const GroupDetails = ({ groupId }) => {
  console.log('groupId', groupId)

  const dispatch = useDispatch()

  const groupDetailsSelector = makeGroupSelector()
  const group = useSelector(state => {
    if (groupId) {
      return groupDetailsSelector(state, { groupId: groupId })
    }
    return {}
  })

  const groupAvatarUrl = objGet(group, 'images.avatarThumbnailUrl', undefined)

  const createNewAlarmWithGroup = () => {
    dispatch(
      ActionCreators.showSelectAlarmTypeScreen({
        selectedGroup: {
          ...group,
          members: group.members.filter(
            member => member.id !== GlobalConfig.uid
          )
        }
      })
    )
  }

  const createInstantAlarmWithGroup = () => {
    dispatch(
      ActionCreators.showNewInstantAlarmWizard({
        selectedGroup: {
          ...group,
          members: group.members.filter(
            member => member.id !== GlobalConfig.uid
          )
        }
      })
    )
  }

  const removeGroup = () => {
    GlobalConfig.rootFirebaseRef
      .child('userInfos')
      .child(GlobalConfig.uid)
      .child('removedFromGroups')
      .child(group.id)
      .remove()
      .then(() => {
        dispatch(ActionCreators.hideGroupDetailsScreen())
      })
      .catch(error => {
        LogUtils.logError(error, 'Unable to remove group')
        NavigationUtils.showProgress(
          Constants.ProgressStates.ERROR,
          I18n.t('unableToRemoveGroup', { error: error.message }),
          true
        )
        return
      })
  }

  if (isEmpty(group)) {
    return (
      <EntityNotFound
        entityType={I18n.t('entityGroup')}
        onClose={() => dispatch(ActionCreators.hideGroupDetailsScreen())}
      />
    )
  }

  if (group.isRemovedGroup) {
    return (
      <Box
        flex={true}
        justify="center"
        align="center"
        background="textBackgroundColor">
        <Text color="textColor">
          {I18n.t('groupLeft', { groupName: group.name })}
        </Text>
        <Button
          color="primary"
          margin="medium"
          onClick={removeGroup}
          label={I18n.t('removeGroup')}
        />
      </Box>
    )
  }

  return (
    <AppCanvas key={groupId} overflow="auto">
      <GroupDetailsHeader group={group} />
      <Card
        margin="small"
        background="textBackgroundColor"
        width="600px"
        flex={{ shrink: 0 }}>
        <CardBody gap="medium" pad="medium">
          {groupAvatarUrl && (
            <Box pad="small" align="center">
              <GroupAvatar url={groupAvatarUrl} name={group.name} size="4xl" />
            </Box>
          )}
          <Box direction="row" align="center">
            <LabelledText
              label={I18n.t('name')}
              text={group.name}
              border="bottom"
            />
            <Box
              id="create-instant-alarm-with-group"
              pad={{ left: 'medium', right: 'small' }}
              onClick={createInstantAlarmWithGroup}>
              <TintedImage
                height="24px"
                src={require('galarm-res/img/web/ic_instant_alarm.svg').default}
                tintColor="primary"
              />
            </Box>
          </Box>
          <Box>
            <LabelText>{I18n.t('members')}</LabelText>
            <MembersListWithAvatar group={group} />
          </Box>
          <ColoredButton
            primary
            color="primary"
            margin={{ top: '5px' }}
            onClick={createNewAlarmWithGroup}
            label={I18n.t('createAlarmWithGroup', { name: group.name })}
          />
        </CardBody>
      </Card>
    </AppCanvas>
  )
}

export default GroupDetails
