import React from 'react'
import PersonalAlarmImage from 'galarm-res/img/web/ic_personal_alarm.svg'
import GroupAlarmImage from 'galarm-res/img/web/ic_group_alarm.svg'
import BuddyAlarmImage from 'galarm-res/img/web/ic_buddy_alarm.svg'
import { Box, Card, CardBody, DropButton, Text, Header } from 'grommet'
import { Constants, I18n } from 'galarm-config'
import { useDispatch } from 'react-redux'
import { ActionCreators } from 'galarm-shared'
import TintedImage from './TintedImage'
import { Close } from 'grommet-icons'

const SelectAlarmType = props => {
  const dispatch = useDispatch()

  const alarmTypes = [
    {
      title: I18n.t('myself'),
      description: I18n.t('personalAlarmsFeatureDescription'),
      onSelect: () => {
        dispatch(
          ActionCreators.showNewAlarmWizard({
            alarmType: Constants.AlarmTypes.CASCADING,
            alarmCategoryId:
              props.alarmCategoryId ||
              Constants.UNCATEGORIZED_ALARM_CATEGORY_ID,
            alarmDate: props.alarmDate,
            selectedGroup: props.selectedGroup || null,
            selectedContacts: props.selectedContacts || []
          })
        )
        props.onClose()
      },
      iconSource: PersonalAlarmImage
    },
    {
      title: I18n.t('group'),
      description: I18n.t('groupAlarmsFeatureDescription'),
      onSelect: () => {
        dispatch(
          ActionCreators.showNewAlarmWizard({
            alarmType: Constants.AlarmTypes.SIMULTANEOUS,
            alarmCategoryId:
              props.alarmCategoryId ||
              Constants.UNCATEGORIZED_ALARM_CATEGORY_ID,
            alarmDate: props.alarmDate,
            selectedGroup: props.selectedGroup || null,
            selectedContacts: props.selectedContacts || []
          })
        )
        props.onClose()
      },
      iconSource: GroupAlarmImage
    },
    {
      title: I18n.t('someoneElse'),
      description: I18n.t('buddyAlarmsFeatureDescription'),
      onSelect: () => {
        dispatch(
          ActionCreators.showNewAlarmWizard({
            alarmType: Constants.AlarmTypes.RECIPIENT,
            alarmCategoryId:
              props.alarmCategoryId ||
              Constants.UNCATEGORIZED_ALARM_CATEGORY_ID,
            alarmDate: props.alarmDate,
            selectedContacts: props.selectedContacts || []
          })
        )
        props.onClose()
      },
      iconSource: BuddyAlarmImage
    }
  ]

  return (
    <Box flex={{ shrink: 0 }} align="center">
      <Header
        fill="horizontal"
        className="header"
        background="brand"
        pad="medium">
        <Text size="large" color="white">
          {I18n.t('likeToCreateAlarm')}
        </Text>
        <Box onClick={props.onClose}>
          <Close color="white" />
        </Box>
      </Header>
      <Box direction="row" gap="large" margin="large">
        {alarmTypes.map((alarmType, index) => (
          <Card
            key={index}
            background="textBackgroundColor"
            pad="medium"
            width="200px"
            onClick={alarmType.onSelect}>
            <CardBody gap="small" align="center">
              <TintedImage
                tintColor="primary"
                src={alarmType.iconSource}
                height="80px"
                width="80px"
              />
              <Text
                size="large"
                textAlign="center"
                color="textColor"
                margin="xsmall">
                {alarmType.title}
              </Text>
              <DropButton
                plain
                color="primary"
                label={I18n.t('whatsThis')}
                dropAlign={{ top: 'bottom', left: 'right' }}
                onClick={event => event.stopPropagation()}
                hoverIndicator="background"
                dropContent={
                  <Box width="200px" pad="small">
                    <Text>{alarmType.description}</Text>
                  </Box>
                }
              />
            </CardBody>
          </Card>
        ))}
      </Box>
    </Box>
  )
}

export default SelectAlarmType
