import React, { useState } from 'react'
import { Constants, GlobalConfig, I18n } from 'galarm-config'
import { FirebaseProxy, LogUtils, NavigationUtils } from 'galarm-ps-api'
import objGet from 'lodash/get'
import { ActionCreators, AlarmUtils, DateTimeUtils } from 'galarm-shared'
import * as RNLocalize from 'react-native-localize'
import ShareAlarmImage from 'galarm-res/img/share-alarm.png'
import { Box, Button, CheckBox, Heading, Image, Text } from 'grommet'
import { useDispatch } from 'react-redux'

const ShareAlarm = ({ alarm, onClose }) => {
  const [relativeTimezone, setRelativeTimezone] = useState(false)
  const dispatch = useDispatch()

  const createDynamicLinkForSharingPersonalAlarm = async ({
    alarmId,
    alarmName,
    dateString,
    relativeTimezone
  }) => {
    const link = `https://www.galarmapp.com?command=subscribeToAlarm&alarmId=${alarmId}&relativeTimezone=${relativeTimezone}`
    const linkInfo = {
      dynamicLinkInfo: {
        link: link,
        domainUriPrefix: GlobalConfig.dynamicLinkDomain,
        androidInfo: {
          androidPackageName: 'com.galarmapp',
          androidMinPackageVersionCode: '7340635',
          androidFallbackLink: Constants.ANDROID_APP_LINK
        },
        iosInfo: {
          iosBundleId: 'com.acintyo.galarm',
          iosAppStoreId: '1187849174',
          iosMinimumVersion: '3.2.3',
          iosFallbackLink: Constants.IOS_APP_LINK
        },
        socialMetaTagInfo: {
          socialTitle: alarmName,
          socialDescription: I18n.t('sharePersonalAlarmDeepLinkDescription', {
            dateString
          }),
          socialImageLink:
            'https://firebasestorage.googleapis.com/v0/b/migrateto3.appspot.com/o/galarm-logo.png?alt=media&token=75e01317-d25e-4dbe-aeb0-36199ab378dd'
        },
        analyticsInfo: {
          googlePlayAnalytics: {
            utmSource: 'subscribe-to-alarm'
          }
        }
      },
      suffix: {
        option: 'SHORT'
      }
    }

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${GlobalConfig.firebaseApiKey}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(linkInfo)
        }
      ).then(response => response.json())

      const dynamicLink = response.shortLink

      console.log('Dynamic link is', link, dynamicLink)

      if (dynamicLink) {
        let firebaseUpdate = {}
        const linkId = GlobalConfig.rootFirebaseRef.child('links').push().key
        firebaseUpdate['alarms/' + alarmId + '/link'] = {
          url: dynamicLink,
          relativeTimezone: relativeTimezone
        }
        firebaseUpdate['alarms/' + alarmId + '/lastUpdatedAt'] = Date.now()
        firebaseUpdate['links/' + linkId] = {
          dynamicLink: dynamicLink,
          deepLink: link,
          relativeTimezone: relativeTimezone
        }

        await GlobalConfig.rootFirebaseRef.update(firebaseUpdate)
        return dynamicLink
      } else {
        return undefined
      }
    } catch (error) {
      LogUtils.logError(error)
      return undefined
    }
  }

  const onSharePersonalAlarm = async () => {
    const nextDate = AlarmUtils.getNextDateForAlarm(
      alarm.date,
      alarm.endDate,
      alarm.repeatType,
      alarm.repeat,
      alarm.creatorTimezone
    )

    if (!nextDate) {
      NavigationUtils.showTransientAlert({
        message: I18n.t('alarmDoesntHaveNextDate')
      })
      return
    }

    let dynamicLink
    if (alarm.link) {
      dynamicLink = alarm.link.url
    } else if (alarm.parentLink) {
      dynamicLink = alarm.parentLink.url
    } else {
      // If time is not relative, then add the timezone as well.
      // Otherwise the alarm will ring relative to the timezone,
      // hence no need to add the timezone to the date string.
      const dateString = relativeTimezone
        ? DateTimeUtils.getDateTimeAsString(nextDate)
        : DateTimeUtils.getDateTimeAsStringWithTimezone(
            nextDate,
            RNLocalize.getTimeZone()
          )
      dynamicLink = await createDynamicLinkForSharingPersonalAlarm({
        alarmId: alarm.id,
        alarmName: alarm.name,
        dateString: dateString,
        relativeTimezone: relativeTimezone
      })
      dispatch(
        ActionCreators.incrementShareableAlarmLinkCount(
          Constants.AlarmTypes.CASCADING
        )
      )
      FirebaseProxy.logEvent(
        Constants.UserAnalyticsEvents.CREATE_PERSONAL_ALARM_LINK,
        {}
      )
    }
    console.log('dynamic link for alarm ' + dynamicLink)
    if (dynamicLink && navigator.clipboard) {
      await navigator.clipboard.writeText(dynamicLink)
      NavigationUtils.showTransientAlert({
        message: I18n.t('alarmLinkCopiedToClipboard')
      })
    }
  }

  let message,
    actionButtons = [],
    newLink = false

  if (alarm.link) {
    message = I18n.t('alreadySharedAlarmDescription', {
      url: alarm.link.url,
      count: Object.keys(objGet(alarm, 'link.subscribers', {})).length
    })

    actionButtons = [
      {
        text: I18n.t('shareLink'),
        onClick: async () => {
          onClose()
          await onSharePersonalAlarm()
        }
      },
      {
        text: I18n.t('close'),
        onClick: () => onClose()
      }
    ]
  } else if (alarm.parentLink) {
    message = I18n.t('shareParentAlarmDescription', {
      creatorName: alarm.parentLink.creatorName
    })
    actionButtons = [
      {
        text: I18n.t('shareLink'),
        onClick: async () => {
          onClose()
          await onSharePersonalAlarm()
        }
      },
      {
        text: I18n.t('close'),
        onClick: () => onClose()
      }
    ]
  } else {
    message = I18n.t('shareAlarmDescription')
    actionButtons = [
      {
        text: I18n.t('shareLink'),
        onClick: async () => {
          onClose()
          await onSharePersonalAlarm()
        }
      },
      {
        text: I18n.t('close'),
        onClick: () => onClose()
      }
    ]
    // Uncomment when relative timezone is released
    newLink = true
  }

  return (
    <Box align="center" gap="small">
      <Heading level={4}>{I18n.t('shareAlarm')}</Heading>
      <Image src={ShareAlarmImage} height="100px" width="100px" />
      <Text style={{ whiteSpace: 'pre-line' }}>{message}</Text>
      {newLink && (
        <Box fill="horizontal" margin={{ top: 'small' }}>
          <CheckBox
            name="relativetimezone"
            checked={relativeTimezone}
            label={I18n.t('alarmLinkRelativeToTimezone')}
            onChange={event => setRelativeTimezone(event.target.checked)}
          />
        </Box>
      )}
      <Box
        direction="row"
        alignSelf="end"
        gap="small"
        margin={{ top: 'small' }}>
        {actionButtons.map((button, index) => (
          <Button key={index} onClick={button.onClick} label={button.text} />
        ))}
      </Box>
    </Box>
  )
}

export default ShareAlarm
