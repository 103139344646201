import { AlarmUtils, Selectors } from 'galarm-shared'
import { Constants, GlobalConfig } from 'galarm-config'
import { connect } from 'react-redux'
import { NewAlarmContainer } from './NewAlarmContainer'
import * as RNLocalize from 'react-native-localize'

const {
  upgradePurchasedSelector,
  numGroupAlarmsSelector,
  numBuddyAlarmsSelector,
  alarmCategoriesSelector,
  requiredPermissionsEnabledSelector
} = Selectors

class DuplicateAlarmContainer extends NewAlarmContainer {
  state = {
    id: GlobalConfig.rootFirebaseRef.push().key,
    name: this.props.baseAlarm.name,
    date: this.props.baseAlarm.date,
    backupContacts: this.props.baseAlarm.backupContacts || [],
    backupGroup: this.props.baseAlarm.backupGroup || null,
    notes: this.props.baseAlarm.notes || '',
    alarmPhotoUrl: this.props.baseAlarm.alarmPhotoUrl || '',
    repeat: this.props.baseAlarm.repeat || '',
    repeatType: this.props.baseAlarm.repeatType,
    creatorTimezone: RNLocalize.getTimeZone(),
    timezoneSetting: Constants.TIMEZONE_SETTINGS.DEVICE,
    preReminderDuration:
      this.props.baseAlarm.preReminderDuration ||
      GlobalConfig.defaultPreReminderDuration,
    type: this.props.baseAlarm.type,
    isEdit: false,
    cascadingAlarmInterval: this.props.baseAlarm.cascadingAlarmInterval || null,
    recipientAlarmInterval: this.props.baseAlarm.recipientAlarmInterval || null,
    recipient: this.props.baseAlarm.recipient || null,
    ringerSettings:
      this.props.baseAlarm.ringerSettings ||
      AlarmUtils.getDefaultRingerSettings(),
    creationMode: Constants.AlarmCreationModes.NORMAL_ALARM,
    endDate: this.props.baseAlarm.endDate || GlobalConfig.defaultAlarmEndDate,
    alarmCategoryId:
      this.props.alarmCategoryId || Constants.UNCATEGORIZED_ALARM_CATEGORY_ID,
    source: Constants.AlarmCreationSources.DUPLICATED_FROM_WEB
  }
}

const mapStateToProps = state => ({
  creatorName: state.userInfo.name,
  creatorMobileNumber: state.userInfo.formattedMobileNumberWithCC,
  requiredPermissionsEnabled: requiredPermissionsEnabledSelector(state),
  isAuthenticated: state.appState.authenticatedWithFirebase,
  rateTheAppInfo: state.userInfo.rateTheAppInfo,
  mobileNumber: state.userInfo.mobileNumber,
  upgradePurchased: upgradePurchasedSelector(state),
  numGroupAlarms: numGroupAlarmsSelector(state),
  numBuddyAlarms: numBuddyAlarmsSelector(state),
  colorScheme: state.userSettings.webColorScheme,
  alarmCategories: alarmCategoriesSelector(state)
})

export default connect(mapStateToProps)(DuplicateAlarmContainer)
